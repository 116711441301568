import { IsUnion } from "@src/utils/types";
import { action, makeObservable, observable } from "mobx";
import { computedFn } from "mobx-utils";

export class UnionState<
  UnionType extends IsUnion<UnionType> extends true ? any : never,
> {
  @observable value: UnionType;

  constructor(defaultValue: UnionType) {
    makeObservable(this);
    this.value = defaultValue;
  }

  @action.bound set(value: UnionType): void {
    this.value = value;
  }

  is = computedFn((value: UnionType): boolean => {
    return this.value === value;
  });
}
