import { observer } from "mobx-react-lite";

type ModuleTitleProps =
  | {
      text: string;
      children?: undefined;
    }
  | {
      text?: undefined;
      children: JSX.Element;
    };

const ModuleTitle = ({ text, children }: ModuleTitleProps) => (
  <h1 className="leading-tight font-bold text-4xl md:text-5xl truncate">
    {children ?? text}
  </h1>
);

export default observer(ModuleTitle);
