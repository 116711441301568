import {
  Box,
  BoxProps,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from "@chakra-ui/react";
import { UserToolTip_UserFragmentFragment } from "@src/__generated__/urql-graphql";
import { Avatar } from "@src/components/ui-kit/Avatar";
import { Icon } from "@src/components/ui-kit/Icon";
import React, { FunctionComponent } from "react";

// extend BoxProps to support AvatarGroup (https://github.com/chakra-ui/chakra-ui/issues/3329)
export type UserTooltipProps = BoxProps & {
  user: UserToolTip_UserFragmentFragment | undefined;
};

export const UserTooltip: FunctionComponent<
  React.PropsWithChildren<UserTooltipProps>
> = ({ user, children, ...props }) => {
  if (!user) return null;
  return (
    <Popover placement="top-start" trigger="hover">
      <PopoverTrigger>
        <Box {...props}>{children}</Box>
      </PopoverTrigger>

      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody p="3">
            <HStack align="start" spacing="3">
              <Avatar
                name={user.full_name}
                colorScheme={user.profile?.hex_color}
                size="lg"
                src={user.image?.urls.thumbnail}
              />
              <Stack>
                <Text fontSize="xs">
                  <b>{user.codename}</b> {user.full_name}
                </Text>
                <HStack>
                  <Icon name="briefcase-01" w="4" h="4" color="grey.500" />
                  <Text fontSize="xs">
                    {user.profile.defaultWorktype.title} ({user.team.name})
                  </Text>
                </HStack>
              </Stack>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
