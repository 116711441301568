import { ErrorScreen } from "@components/ui-kit";
import { t } from "@lingui/macro";

export const NotFoundRFI = () => (
  <ErrorScreen
    title={t`I didn’t find the request for invoice you are looking for.`}
    link={{
      text: t`See all requests for invoicing`,
      href: "/invoices/for-invoicing",
    }}
  />
);
