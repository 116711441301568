import { Country } from "@src/__generated__/urql-graphql";

type toCountryOptionsParams = {
  countries: {
    id: Country["id"];
    name: Country["name"];
  }[];
  modifier: (option: CountryOption) => CountryOption;
};

type CountryOption = {
  label: Country["name"];
  value: Country["id"];
};

export const toCountryOptions = (
  countries: toCountryOptionsParams["countries"],
  modifier: toCountryOptionsParams["modifier"] = (option) => option,
): CountryOption[] =>
  countries.map((i) =>
    modifier({
      label: i.name,
      value: i.id,
    }),
  );
