import { observer } from "mobx-react-lite";
import { p } from ".";
import s from "./ProgressBar.module.css";
import { ouWorkInvoicedWidth } from "./width";

export const ProgressBarOurWorkInvoiced = observer<{
  spent_budget: number;
  total_budget: number;
  credit: number;
  credit_balance: number;
}>(function ProgressBarOurWorkInvoiced({
  spent_budget,
  total_budget,
  credit,
  credit_balance,
}) {
  let width = ouWorkInvoicedWidth(credit, total_budget, spent_budget);

  let green = 0;
  let red = 0;
  let gray = 0;
  let dotted = 0;
  let dottedGreen = 0;

  if (credit_balance < 0) {
    if (credit > total_budget) {
      if (total_budget > spent_budget) {
        green = (credit / total_budget) * 100;
        red = ((credit - total_budget) / credit) * 100;
        dotted = 100 - green - red;
      } else {
        green = (total_budget / credit) * 100;
        red = ((credit - total_budget) / credit) * 100;
        dotted = 0;
      }
    } else {
      green = (credit / total_budget) * 100;
      dotted = (-credit_balance / total_budget) * 100;
      gray = 100 - green - dotted;
    }
  } else {
    if (credit > total_budget) {
      if (total_budget > spent_budget) {
        red = ((credit - total_budget) / credit) * 100;

        dottedGreen =
          ((Math.min(credit_balance + spent_budget, total_budget) -
            spent_budget) /
            credit) *
          100;
        green = 100 - red - dottedGreen;
      } else {
        red = ((credit - total_budget) / credit) * 100;

        dottedGreen = 0;
        green = 100 - red - dottedGreen;
      }
    } else {
      green = (spent_budget / total_budget) * 100;
      dottedGreen = (credit_balance / total_budget) * 100;
      gray = 100 - green - dottedGreen;
    }
  }

  if ([total_budget, credit_balance, credit].includes(0)) {
    gray = 100;
    width = 100;
  }
  return (
    <div>
      <div className={s.box} style={{ width: p(width) }}>
        {!!green && (
          <div className={s.green} style={{ width: p(green) }}>
            {/* {p(green)} */}
          </div>
        )}
        {!!dottedGreen && (
          <div
            className={s.dottedGreen}
            style={{ width: p(dottedGreen) }}
          ></div>
        )}
        {!!dotted && (
          <div className={s.dottedGray} style={{ width: p(dotted) }}></div>
        )}
        {!!gray && (
          <div className={s.gray} style={{ width: p(gray) }}>
            {/* {p(gray)} */}
          </div>
        )}
        {!!red && (
          <div className={s.red} style={{ width: p(red) }}>
            {/* {p(red)} */}
          </div>
        )}
      </div>
    </div>
  );
});
