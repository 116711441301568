import { HStack } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import {
  useCreateOrUpdateTrackedTimeForDayMutation,
  useRemoveRowFromWeekTimeReportMutation,
  WeeklyTimeReportRowDay,
} from "@src/__generated__/graphql";
import { RemoveButton } from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { trackEvent } from "@src/services/amplitude";
import { onError } from "@src/utils/apollo";
import { toApiDate } from "@src/utils/dates";
import { formatDuration } from "@src/utils/formatters";
import { useStore as useAppStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { WeekRow } from "../../models";
import { useStore } from "../../store";
import { TimeTrackingLabel } from "./TimeTrackingLabel";
import s from "./WeekEntry.module.css";
import WeekEntryTimeInput from "./WeekEntryTimeInput";

interface WeekEntryProps {
  item: WeekRow;
}

const WeekEntry = ({ item }: WeekEntryProps) => {
  const appStore = useAppStore();
  const store = useStore();

  const [remove, { loading: removeLoading }] =
    useRemoveRowFromWeekTimeReportMutation({
      onCompleted() {
        store.timeTrackingItemsRefetch?.();
        store.weekTimeReportRefetch?.();
        trackEvent("time-entry", "Deleted row");
      },
      ...onError(),
    });

  const handleDelete = () => {
    if (!store.currentUser) return;

    remove({
      variables: {
        task_id: item.task.id,
        time_tracking_work_type_id: item.timeTrackingWorkType.id,
        user_id: store.currentUser.id,
        first_date_of_week: toApiDate(store.stepper.startOfWeek),
      },
    });
  };

  const [update] = useCreateOrUpdateTrackedTimeForDayMutation({
    onCompleted(data) {
      if (data?.createOrUpdateTrackedTimeForDay) {
        store.timeTrackingItemsRefetch?.();
        appStore.UIStore.toast({ title: t`Saved`, status: "success" });
        trackEvent("time-entry", "Updated row");
      }
    },
    ...onError(),
  });

  const onEntryChange = useCallback(
    (date: Date, trackedTime: WeeklyTimeReportRowDay["tracked_time"]) => {
      if (!store.currentUser) return;

      update({
        variables: {
          user_id: store.currentUser.id,
          task_id: item.task.id,
          time_tracking_work_type_id: item.timeTrackingWorkType.id,
          tracked_for_date: toApiDate(date),
          tracked_time: trackedTime,
        },
      });
    },
    [update],
  );

  return (
    <div className={s.EntryItemWrapper}>
      {item.task.ourWorkBudgetItem && (
        <TimeTrackingLabel
          project={item.task.ourWorkBudgetItem.project}
          brandName={item.task.ourWorkBudgetItem.project.brand.name}
          budgetItemTitle={item.task.ourWorkBudgetItem.title}
          taskName={item.task.name}
          taskId={item.task.id}
          workTypeTitle={item.timeTrackingWorkType.title}
        />
      )}
      {item.days.map((day) => (
        <WeekEntryTimeInput
          key={day.date.getDate()}
          trackedTime={day.trackedTime}
          onDebouncedChange={(trackedTime) =>
            onEntryChange(day.date, trackedTime)
          }
        />
      ))}

      <HStack justify="end">
        <div className="px-2 mr-2">
          {!item.task.billable && <Icon name="non-billable" />}
          {formatDuration(item.totalTrackedTime, { noPadStart: true })}
        </div>
        <RemoveButton onClick={handleDelete} isLoading={removeLoading} />
      </HStack>
    </div>
  );
};

export default observer(WeekEntry);
