import { appStore } from "@src/stores/AppStore";
import { addDays, endOfWeek, isThisWeek, startOfWeek, subDays } from "date-fns";
import { action, computed, makeObservable, observable } from "mobx";
import { now } from "mobx-utils";

// TODO: make DatePaginator & DateRangePaginator
export class DateStepper {
  @observable current: Date;
  @observable weekStartsOn =
    appStore.workspaceStore.settings?.startOfWeekNumber;

  constructor(defaultDate?: Date) {
    makeObservable(this);
    this.current = defaultDate || new Date();

    this.prevDay = this.prevDay.bind(this);
    this.nextDay = this.nextDay.bind(this);

    this.prevWeek = this.prevWeek.bind(this);
    this.nextWeek = this.nextWeek.bind(this);
  }

  @computed get startOfWeek() {
    return startOfWeek(this.current, { weekStartsOn: this.weekStartsOn });
  }

  @computed get endOfWeek() {
    return endOfWeek(this.current, { weekStartsOn: this.weekStartsOn });
  }

  @computed get isCurrentWeek() {
    return isThisWeek(this.current);
  }

  @computed get isCurrentToday() {
    const currentDayObservable = new Date(now()).getDate();

    return this.current.getDate() === currentDayObservable;
  }

  @action.bound refreshCurrent() {
    this.current = this.current;
  }

  @action.bound setCurrent(date: Date) {
    this.current = date;
  }

  @action.bound setToday() {
    this.setCurrent(new Date());
  }
  @action.bound setThisWeek() {
    this.setCurrent(
      startOfWeek(new Date(), { weekStartsOn: this.weekStartsOn }),
    );
  }
  prevDay() {
    this.setCurrent(subDays(this.current, 1));
  }

  nextDay() {
    this.setCurrent(addDays(this.current, 1));
  }

  prevWeek() {
    this.setCurrent(
      startOfWeek(subDays(this.current, 7), {
        weekStartsOn: this.weekStartsOn,
      }),
    );
  }

  nextWeek() {
    this.setCurrent(
      startOfWeek(addDays(this.current, 7), {
        weekStartsOn: this.weekStartsOn,
      }),
    );
  }
}
