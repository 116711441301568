import { Flex } from "@chakra-ui/react";
import { useStore } from "@src/utils/hooks";
import {
  Calendar,
  CalendarControls,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarWeek,
} from "@uselessdev/datepicker";
import { FunctionComponent } from "react";

export const CalendarPicker: FunctionComponent<Calendar> = (props) => {
  const { workspaceStore } = useStore();

  return (
    <Flex justify="center">
      <Calendar
        singleDateSelection={true}
        weekStartsOn={workspaceStore.settings?.startOfWeekNumber}
        highlightToday
        {...props}
      >
        <CalendarControls>
          <CalendarPrevButton />
          <CalendarNextButton />
        </CalendarControls>

        <CalendarMonths>
          <CalendarMonth>
            <CalendarMonthName />
            <CalendarWeek />
            <CalendarDays />
          </CalendarMonth>
        </CalendarMonths>
      </Calendar>
    </Flex>
  );
};
