import {
  Box,
  Button,
  HStack,
  Spinner,
  TabPanel,
  useToast,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import {
  TaskPermissionTypeEnum,
  UpdateTaskDescriptionMutation,
  useCanUpdateOrDeleteTaskMutation,
  useUpdateTaskDescriptionMutation,
} from "@src/__generated__/urql-graphql";
import {
  EMPTY_EDITOR_LENGTH,
  TextEditor,
  TextEditorRef,
} from "@src/components/ui-kit/TextEditor/TextEditor";
import { trackEvent } from "@src/services/amplitude";
import { useStore } from "@src/utils/hooks";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

export const DescriptionTab = observer(function DescriptionTab() {
  const { taskDetailModalStore: store } = useStore();
  const toast = useToast();

  const ref = useRef<TextEditorRef>(null);
  const [value, setValue] = useState("");
  const mentionedUserIds = useRef<string[]>([]);
  const attachmentIds = useRef<string[]>([]);

  const [{ fetching: fetchingCanUpdateTask }, canUpdateTask] =
    useCanUpdateOrDeleteTaskMutation();

  const turnEditModeOn = async () => {
    if (store.descriptionState.is("write")) return;

    try {
      const { data } = await canUpdateTask({
        task_id: store.taskId.value!,
        type: TaskPermissionTypeEnum.Update,
      });

      if (data?.canUpdateOrDeleteTask) {
        store.descriptionState.set("write");
        ref.current?.focus();
      } else {
        toast({
          title: <Trans>Insufficient permissions</Trans>,
          status: "error",
        });
      }
    } catch {}
  };

  const onCancel = () => {
    ref.current?.setContent(store.task.value?.description?.body || null);
    store.descriptionState.set("read");
    ref.current?.clearDraft();
    runInAction(() => {
      store.descriptionChanged = false;
    });
  };

  const onSave = () => {
    if (!store.task.value) return;

    const taskFileIds: string[] = (store.task.value?.files || []).map(
      (file) => file.public_id,
    );

    update({
      task_id: store.task.value.id,
      description: {
        body: value,
        mentioned_user_ids: mentionedUserIds.current,
      },
      addFiles: attachmentIds.current.filter((id) => !taskFileIds.includes(id)),
    }).then(({ data }) => data && onCompleted(data));
  };

  const [{ fetching: loading }, update] = useUpdateTaskDescriptionMutation();
  const onCompleted = (data: UpdateTaskDescriptionMutation) => {
    if (data.updateTask && store.task.value) {
      store.task.value.revision = data.updateTask.revision;
      store.descriptionState.set("read");
      store.task.value.setDescription(data.updateTask.description);
      store.task.value.setFiles(data.updateTask.files ?? []);
      ref.current?.clearDraft();
      trackEvent("task", "Updated description");
      store.descriptionChanged = false;
    }
  };

  useEffect(() => {
    loading ? store.descriptionLoading.on() : store.descriptionLoading.off();
  }, [loading]);

  return (
    <TabPanel px="8">
      <Box
        w="full"
        ml="-2"
        px={store.descriptionState.is("read") ? "2" : "0"}
        py="2"
        _hover={{
          bg: store.descriptionState.is("read") ? "grey.50" : "transparent",
        }}
        boxSizing="content-box"
        transition="background ease 0.3s"
        rounded="lg"
      >
        <Box
          sx={
            store.descriptionState.is("write")
              ? {
                  border: "1px solid",
                  borderColor: "grey.100",
                  rounded: "lg",
                  p: "1",
                  ml: "2",
                }
              : undefined
          }
          pos="relative"
          onClick={turnEditModeOn}
        >
          {fetchingCanUpdateTask && <Spinner size="sm" />}
          <TextEditor
            ref={ref}
            ml={store.descriptionState.is("write") ? "2" : "0"}
            // INFO: In case some client will want this back
            // draftKey={getTaskDetailDraftKey({
            //   taskId: store.taskId.value!,
            //   editorName: 'description',
            //   tabId: TaskTabIdEnum.Description,
            // })}
            // enableDrafts
            showToolbar
            isEditable={store.descriptionState.is("write")}
            initialValue={store.task.value?.description?.body || undefined}
            attachments={(store.task.value?.files || []).map((file) => ({
              id: file.public_id,
              filename: file.filename,
              mime_type: file.mime_type,
              viewable: file.urls.viewable!,
              original: file.urls.original!,
              thumbnail: file.urls.thumbnail!,
            }))}
            onChange={(editorValue, { mentioned_user_ids, attachment_ids }) => {
              mentionedUserIds.current = mentioned_user_ids;
              attachmentIds.current = attachment_ids;

              if (!editorValue) return;
              const stringifiedValue = editorValue.toString();

              if (value === stringifiedValue) {
                store.descriptionChanged = false;
              } else if (stringifiedValue.length <= EMPTY_EDITOR_LENGTH) {
                store.descriptionChanged = false;
              } else {
                store.descriptionChanged = true;
              }

              setValue(stringifiedValue);
            }}
            placeholder={t`Add a description...`}
            sx={{
              ...(store.descriptionState.is("write")
                ? { minH: "100px", overflowY: "auto", maxH: "500px" }
                : {}),
            }}
            onFocus={() => {
              // TODO: if callback not present we are getting `TypeError: callback is undefined`
            }}
            onBlur={() => {
              // TODO: if callback not present we are getting `TypeError: callback is undefined`
            }}
          />
        </Box>
        {store.descriptionState.is("write") && (
          <HStack justify="end" mt="4" spacing="2">
            <Button colorScheme="grey" onClick={onCancel} variant="outline">
              <Trans>Cancel</Trans>
            </Button>
            <Button
              isDisabled={store.needsRevision.value}
              isLoading={loading}
              onClick={onSave}
            >
              <Trans>Save</Trans>
            </Button>
          </HStack>
        )}
      </Box>
    </TabPanel>
  );
});
