import { Trans } from "@lingui/macro";
import { SharepointIcon } from "@src/components/ui-kit/Icon";
import { ButtonLink, ButtonLinkProps } from "@src/components/ui-kit/Link";
import { FC } from "react";

type SharepointLinkProps = Omit<ButtonLinkProps, "isExternal"> & {
  href: string;
};

export const SharepointLink: FC<SharepointLinkProps> = (props) => {
  return (
    <ButtonLink
      leftIcon={<SharepointIcon w="5" h="5" />}
      isExternal
      {...props}
      variant="outline"
      colorScheme="grey"
      size="sm"
    >
      <Trans>Open in sharepoint</Trans>
    </ButtonLink>
  );
};
