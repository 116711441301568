import {
  TimeTrackingEntryFragment,
  TrackedForDayDocument,
  TrackedForDayQuery,
  TrackedForDayQueryVariables,
  User,
} from "@src/__generated__/urql-graphql";
import { TrackedForDayModal } from "@src/components/modules/resource-planning/timeline/TrackedForDayModal";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { makeObservable, observable } from "mobx";

type TrackedForDayModalOptions = {
  userId: User["id"];
  date: Date;
  onSubmit?: () => void;
};

export class TrackedForDayModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  readonly modalId = "trackedForDayModal";

  @observable.ref timeTrackingItems: TimeTrackingEntryFragment[] = [];
  isLoading = false;

  @observable modalState = new DisclosureState<TrackedForDayModalOptions>({
    onOpen: () => {
      if (!this.modalState.additionalData) return;
      this.fetchTimeTrackingItems({
        userId: this.modalState.additionalData.userId,
        date: this.modalState.additionalData.date,
      });
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <TrackedForDayModal />,
      });
    },
    onClose: () => {
      this.timeTrackingItems = [];
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
    },
  });

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  fetchTimeTrackingItems = async ({
    userId,
    date,
  }: Pick<TrackedForDayModalOptions, "userId" | "date">) => {
    this.isLoading = true;
    this.timeTrackingItems = [];
    const { data } = await client
      .query<TrackedForDayQuery, TrackedForDayQueryVariables>(
        TrackedForDayDocument,
        {
          user_id: userId,
          date,
        },
      )
      .toPromise();

    if (data?.timeTrackingItems) {
      this.timeTrackingItems = data.timeTrackingItems;
    }
    this.isLoading = false;
  };
}
