import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { getMonths } from "@src/config/constants";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useToggle } from "react-use";

const FIRST_MONTH = 0;
const LAST_MONTH = 11;

export interface TValue {
  id?: string;

  year: number;

  /**
   * From 0 to 11
   */
  month: number;
}

interface MonthPickerProps {
  value: TValue;
  onChange: (value: TValue) => void;
  options?: TValue[];
  returnToCurrentMonth?: boolean;
  disablePopover?: boolean;
  className?: string;
}

const MonthPicker = ({
  value,
  onChange,
  options,
  returnToCurrentMonth,
  disablePopover,
  className,
}: MonthPickerProps) => {
  const [active, toggle] = useToggle(false);

  const [yearNavigation, setYearNavigation] = useState(value.year);

  useEffect(() => setYearNavigation(value.year), [value.year]);

  // ASC order by Date
  const sortedOptions = useMemo(
    () =>
      options?.sort(
        (a, b) =>
          new Date(a.year, a.month).getTime() -
          new Date(b.year, b.month).getTime(),
      ),
    [options],
  );

  const availableMonths = useMemo(() => {
    if (sortedOptions) {
      return sortedOptions
        .filter((i) => i.year === yearNavigation)
        .map((i) => ({ month: i.month, id: i.id }))
        .sort((a, b) => a.month - b.month);
    }

    return getMonths().map((_, idx) => ({ month: idx, id: undefined }));
  }, [yearNavigation, sortedOptions]);

  const prevMonth = useMemo(() => {
    if (!sortedOptions) {
      let { month, year } = value;

      if (month === FIRST_MONTH) {
        month = LAST_MONTH;
        year -= 1;
      } else {
        month -= 1;
      }

      return { month, year };
    }

    const currentIdx = sortedOptions.findIndex(
      (i) => i.year === value.year && i.month === value.month,
    );

    // Not found
    if (currentIdx === -1) {
      return undefined;
    }

    return sortedOptions[currentIdx - 1];
  }, [value, options]);

  const nextMonth = useMemo(() => {
    if (!sortedOptions) {
      let { month, year } = value;

      if (month === LAST_MONTH) {
        month = FIRST_MONTH;
        year += 1;
      } else {
        month += 1;
      }

      return { month, year };
    }

    const currentIdx = sortedOptions.findIndex(
      (i) => i.year === value.year && i.month === value.month,
    );

    // Not found
    if (currentIdx === -1) {
      return undefined;
    }

    return sortedOptions[currentIdx + 1];
  }, [value, options]);

  const prevInnerYear = () => {
    setYearNavigation((year) => year - 1);
  };

  const nextInnerYear = () => {
    setYearNavigation((year) => year + 1);
  };

  const currentMonth = useMemo(() => {
    if (!returnToCurrentMonth) {
      return undefined;
    }

    const date = new Date(Date.now());
    const [month, year] = [date.getMonth(), date.getFullYear()];

    if (month === value.month && year === value.year) {
      return undefined;
    }

    if (!sortedOptions) {
      return { month, year };
    } else {
      const option = sortedOptions.find(
        (i) => i.year === year && i.month === month,
      );

      if (option) {
        return option;
      }
    }

    return undefined;
  }, [returnToCurrentMonth, value, sortedOptions]);

  return (
    <HStack className={className} spacing="2">
      <Popover isOpen={active} onClose={() => toggle(false)}>
        <PopoverTrigger>
          <ButtonGroup colorScheme="grey" isAttached variant="outline">
            <IconButton
              aria-label={t`prev`}
              disabled={!prevMonth}
              icon={<Icon name="chevron-left" />}
              onClick={() => prevMonth && onChange(prevMonth)}
            />
            <Button
              onClick={disablePopover ? undefined : toggle}
            >{`${getMonths()[value.month]} ${value.year}`}</Button>
            <IconButton
              aria-label={t`next`}
              disabled={!nextMonth}
              icon={<Icon name="chevron-right" />}
              onClick={() => nextMonth && onChange(nextMonth)}
            />
          </ButtonGroup>
        </PopoverTrigger>
        <PopoverContent w="200px">
          <PopoverArrow />
          <PopoverHeader>
            <div className="flex items-center justify-between">
              <IconButton
                aria-label={t`Previous year`}
                icon={<Icon name="chevron-left" />}
                onClick={prevInnerYear}
                variant="ghost"
              />
              <span className="font-semibold text-2xl text-inkLighter">
                {yearNavigation}
              </span>
              <IconButton
                aria-label={t`Next year`}
                icon={<Icon name="chevron-right" />}
                onClick={nextInnerYear}
                variant="ghost"
              />
            </div>
          </PopoverHeader>
          <PopoverBody>
            <List>
              {availableMonths.length === 0 && (
                <ListItem>
                  <Trans>Empty</Trans>
                </ListItem>
              )}
              {availableMonths.map(({ month, id }) => (
                <ListItem
                  key={id}
                  px="4"
                  py="2"
                  bg={
                    value.year === yearNavigation && value.month === month
                      ? "blue.50"
                      : undefined
                  }
                  _hover={{ bg: "grey.50" }}
                  cursor="pointer"
                  onClick={() => {
                    onChange({ id, month, year: yearNavigation });
                    toggle();
                  }}
                >{`${getMonths()[month]} ${yearNavigation}`}</ListItem>
              ))}
            </List>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      {currentMonth && (
        <Button
          fontWeight="normal"
          onClick={() => {
            onChange(currentMonth);
            toggle(false);
          }}
          variant="ghost"
        >{t`Return to current month`}</Button>
      )}
    </HStack>
  );
};

export default observer(MonthPicker);
