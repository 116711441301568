import { Divider, HStack, Stack } from "@chakra-ui/layout";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tooltip,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import {
  AddButton,
  InputDatePicker,
  NumberInput,
  RemoveButton,
} from "@src/components/ui-kit";
import { cannot } from "@src/utils/components/permissions";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import { useStore } from "@src/utils/hooks";
import { subDays } from "date-fns";
import { observer } from "mobx-react-lite";

export const HourlyRates = observer(function HourlyRates() {
  const { workspaceStore, editUserModalStore } = useStore();

  return (
    <Stack>
      <HStack>
        <FormLabel w="150px" mr="0">
          <Trans>From</Trans>
        </FormLabel>
        <FormLabel w="150px" mr="0">
          <Trans>To</Trans>
        </FormLabel>
        <FormLabel flex="1">
          <Trans>Internal hourly rate</Trans>
        </FormLabel>
      </HStack>
      <Stack spacing="4">
        {editUserModalStore.form.hourlyRates.$.map((rate, index) => (
          <HStack key={rate.$.id.value} pos="relative">
            <FormControl isInvalid={rate.hasError}>
              <HStack alignItems="start">
                <WrapComponent
                  if={index === 0}
                  with={(children) => (
                    <Tooltip label={t`User creation date`}>
                      <div>{children}</div>
                    </Tooltip>
                  )}
                >
                  <InputDatePicker
                    usePortal
                    w="150px"
                    isDisabled={index === 0}
                    selected={rate.$.valid_from.value}
                    onChange={(val) => {
                      const newValue = val?.start;
                      rate.$.valid_from.onChange(newValue);
                      const prevRate =
                        editUserModalStore.form.hourlyRates.$[index - 1];
                      if (!prevRate || newValue === undefined) return;
                      prevRate.$.valid_to.onChange(subDays(newValue, 1));
                      editUserModalStore.form.hourlyRates.validate();
                    }}
                    error={rate.$.valid_from.error}
                    placeholder=""
                  />
                </WrapComponent>
                <InputDatePicker
                  usePortal
                  w="150px"
                  isDisabled={
                    index !==
                      editUserModalStore.form.hourlyRates.$.length - 1 &&
                    !!rate.$.valid_to.value
                  }
                  selected={rate.$.valid_to.value}
                  onChange={(val) => {
                    rate.$.valid_to.onChange(val?.start);
                    editUserModalStore.form.hourlyRates.validate();
                  }}
                  error={rate.$.valid_to.error}
                  placeholder={t`Not specified`}
                />
                <NumberInput
                  currency
                  disabled={cannot("user_updateWorkData")}
                  suffix={workspaceStore.settings?.currency.code}
                  value={rate.$.rate.value.toString()}
                  onChange={(val) => rate.$.rate.onChange(Number(val))}
                />
                {index !== 0 && (
                  <RemoveButton
                    onClick={() =>
                      editUserModalStore.form.removeHourlyRate(index)
                    }
                  />
                )}
              </HStack>
              <FormErrorMessage>{rate.formError}</FormErrorMessage>
            </FormControl>
            {index !== editUserModalStore.form.hourlyRates.$.length - 1 && (
              <HStack w="650px" position="absolute" bottom="-4" h="3">
                <Divider />
                <AddButton
                  size="xs"
                  iconType="normal"
                  alignSelf="center"
                  onClick={() =>
                    editUserModalStore.form.addHourlyRate(index + 1, "split")
                  }
                />
              </HStack>
            )}
          </HStack>
        ))}
      </Stack>
      <AddButton
        iconType="normal"
        alignSelf="start"
        onClick={() => editUserModalStore.form.addHourlyRate()}
      >
        <Trans>Add</Trans>
      </AddButton>
    </Stack>
  );
});
