import { Icon, IconProps } from "@chakra-ui/react";

const OrderedList = (props: IconProps) => (
  <Icon w="20px" h="20px" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.66309 14.8097L4.49534 13.9986C5.11612 13.3919 5.58043 12.7229 5.58043 11.9882C5.58043 11.4923 5.4091 11.0613 5.08073 10.7559C4.75471 10.4527 4.30295 10.3 3.78872 10.3C3.29221 10.3 2.85988 10.4514 2.52986 10.7426C2.2012 11.0326 1.9967 11.4412 1.91686 11.917C1.86384 12.233 2.11943 12.4815 2.40284 12.4815H2.56934C2.82712 12.4815 3.02335 12.2908 3.06812 12.0654C3.12409 11.7837 3.22222 11.6213 3.32889 11.5282C3.43292 11.4373 3.57803 11.3835 3.78872 11.3835C4.00911 11.3835 4.14599 11.4476 4.22774 11.5298C4.30978 11.6123 4.37269 11.7497 4.37269 11.9675C4.37269 12.3573 4.17254 12.7352 3.743 13.1913L1.80967 15.2267C1.73928 15.3008 1.70004 15.3991 1.70004 15.5013C1.70004 15.7215 1.87855 15.9 2.09876 15.9H5.10805C5.3842 15.9 5.60805 15.6762 5.60805 15.4V15.3097C5.60805 15.0336 5.3842 14.8097 5.10805 14.8097H3.66309ZM3.2188 8.8998C3.2188 9.17595 3.44266 9.3998 3.7188 9.3998H3.92719C4.20333 9.3998 4.42719 9.17595 4.42719 8.8998V4.2998C4.42719 4.02366 4.20333 3.7998 3.92719 3.7998H3.62547C3.52029 3.7998 3.41779 3.83297 3.33255 3.89459L2.30699 4.63596C2.17694 4.72997 2.09991 4.8807 2.09991 5.04117V5.11535C2.09991 5.52271 2.56095 5.75911 2.89173 5.52136L3.2188 5.28628V8.8998ZM7.37503 4.16602H17.125C17.3321 4.16602 17.5 4.35256 17.5 4.58268V5.41601C17.5 5.64613 17.3321 5.83268 17.125 5.83268H7.37503C7.16792 5.83268 7.00003 5.64613 7.00003 5.41601V4.58268C7.00003 4.35256 7.16792 4.16602 7.37503 4.16602ZM7.37503 9.16601H17.125C17.3321 9.16601 17.5 9.35256 17.5 9.58268V10.416C17.5 10.6461 17.3321 10.8327 17.125 10.8327H7.37503C7.16792 10.8327 7.00003 10.6461 7.00003 10.416V9.58268C7.00003 9.35256 7.16792 9.16601 7.37503 9.16601ZM17.125 14.166H7.37503C7.16792 14.166 7.00003 14.3526 7.00003 14.5827V15.416C7.00003 15.6461 7.16792 15.8327 7.37503 15.8327H17.125C17.3321 15.8327 17.5 15.6461 17.5 15.416V14.5827C17.5 14.3526 17.3321 14.166 17.125 14.166Z"
      fill="currentColor"
    />
  </Icon>
);

export default OrderedList;
