import { action, makeObservable, observable } from "mobx";

export class BooleanState {
  @observable value = false;

  constructor(defaultValue = false) {
    makeObservable(this);
    this.value = defaultValue;
  }

  @action.bound on() {
    this.value = true;
  }

  @action.bound off() {
    this.value = false;
  }

  @action.bound toggle() {
    this.value = !this.value;
  }
}
