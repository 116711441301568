import { Tooltip } from "@chakra-ui/react";
import { SortOrder } from "@src/__generated__/urql-graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import cs from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC, ReactNode } from "react";
import Cell, { CellProps } from "./Cell";

interface SortableHeaderCellProps extends CellProps {
  sortable: boolean;
  sortOrder: SortOrder | undefined;
  onClick: (order: SortOrder) => void;
  tooltip?: ReactNode;
}

const SortableHeaderCell: FC<
  React.PropsWithChildren<SortableHeaderCellProps>
> = ({ sortable, sortOrder, onClick, tooltip, children, ...cellProps }) => {
  const onChangeSortOrder = () => {
    onClick(sortOrder === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc);
  };

  const withTooltip = (children: ReactNode, tooltip: ReactNode) => (
    <div className={cs("flex items-center", alignToJustify(cellProps.align))}>
      {children}
      <Tooltip
        label={
          <div
            className="flex p-3 text-lg"
            style={{ minWidth: 100, maxWidth: 300 }}
          >
            {tooltip}
          </div>
        }
      >
        <div className="ml-1">
          <Icon
            name="info-circle"
            color="#919EAB"
            style={{ transform: "scale(0.8)" }}
            variant="solid"
          />
        </div>
      </Tooltip>
    </div>
  );

  return (
    <Cell type="th" sortable={sortable} {...cellProps}>
      {sortable ? (
        <button
          className="Polaris-DataTable__Heading"
          onClick={onChangeSortOrder}
        >
          {tooltip ? withTooltip(children, tooltip) : children}
          <span className="Polaris-DataTable__Icon">
            {!sortOrder ? (
              <svg viewBox="0 0 20 20" width="20" height="20">
                <path
                  d="M13 8l-3-3-3 3h6zm-.1 4L10 14.9 7.1 12h5.8z"
                  fillRule="evenodd"
                />
              </svg>
            ) : sortOrder === SortOrder.Desc ? (
              <svg viewBox="0 0 20 20" width="20" height="20">
                <path d="M5 8l5 5 5-5z" fillRule="evenodd" />
              </svg>
            ) : sortOrder === SortOrder.Asc ? (
              <svg viewBox="0 0 20 20" width="20" height="20">
                <path d="M15 12l-5-5-5 5z" />
              </svg>
            ) : null}
          </span>
        </button>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment>
          {tooltip ? withTooltip(children, tooltip) : children}
        </React.Fragment>
      )}
    </Cell>
  );
};

const alignToJustify = (align: CellProps["align"]) => {
  switch (align) {
    case "center":
      return "justify-center";
    case "right":
      return "justify-end";
    default:
      return "justify-start";
  }
};

export default observer(SortableHeaderCell);
