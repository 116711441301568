import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { TextField } from "@src/components/ui-kit";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

export const ProjectCreateBudgetItemModal = observer(
  function ProjectCreateBudgetItemModal() {
    const { projectCreateBudgetItemModalStore: store } = useStore();

    return (
      <Modal
        isOpen
        onClose={() => {
          if (store.isFetching) return;
          store.modalState.onClose();
        }}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Trans>Create new budget item</Trans>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TextField
              label={t`Budget item title`}
              placeholder={t`Budget item title`}
              error={store.budgetItemTitle.error}
              value={store.budgetItemTitle.value}
              onChange={store.budgetItemTitle.onChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              isDisabled={store.budgetItemTitle.hasError}
              isLoading={store.isFetching}
              onClick={store.createBudgetItem}
            >
              <Trans>Create</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);
