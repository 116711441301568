import { RemoveButton } from "@components/ui-kit";
import { t } from "@lingui/macro";
import { useDeleteTimeTrackingItemMutation } from "@src/__generated__/graphql";
import { onError } from "@src/utils/apollo";
import { observer } from "mobx-react-lite";

interface DeleteButtonProps {
  id: string;
  onDelete: () => void;
}

const DeleteButton = ({ id, onDelete }: DeleteButtonProps) => {
  const [remove, { loading }] = useDeleteTimeTrackingItemMutation({
    onCompleted() {
      onDelete();
    },
    ...onError(),
  });

  const onClick = async () => {
    remove({ variables: { id } });
  };

  return (
    <RemoveButton colorScheme="red" onClick={onClick} isLoading={loading}>
      {t`Delete`}
    </RemoveButton>
  );
};

export default observer(DeleteButton);
