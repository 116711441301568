import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  HStack,
  StyleProps,
  ToastOptions,
  UseToastOptions,
  VStack,
} from "@chakra-ui/react";
import { useMemo } from "react";

type Props = {
  onClose: () => void;
} & Pick<UseToastOptions, "status" | "title" | "description" | "isClosable">;
export const CustomToast = ({
  status,
  title,
  description,
  isClosable,
  onClose,
}: Props) => {
  const color = useMemo(() => {
    return getToastColor(status);
  }, [status]);

  return (
    <Alert
      bg="white"
      borderWidth="1px"
      borderColor="gray.300"
      rounded="xl"
      status={status}
    >
      <HStack align="start" justify="start" w="full">
        <AlertIcon color={color} />
        <VStack align="start" w="full">
          <AlertTitle>{title}</AlertTitle>
          {description && <AlertDescription>{description}</AlertDescription>}
        </VStack>
      </HStack>
      {isClosable && <CloseButton color="gray.400" onClick={onClose} />}
    </Alert>
  );
};

function getToastColor(
  status: ToastOptions["status"] = "default",
): StyleProps["color"] {
  switch (status) {
    case "error":
      return "red.500";
    case "warning":
      return "orange.300";
    case "success":
      return "green.600";
    default:
      return "grey.500";
  }
}
