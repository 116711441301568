import { AccountingAccount } from "@src/__generated__/graphql";
import { IOption } from "@src/components/ui-kit";

export function accountingAccountsToOptions(
  account: Array<Pick<AccountingAccount, "id" | "title" | "account_number">>,
): IOption[] {
  return account.map((account) => ({
    value: account.id,
    label: `${account.account_number} - ${account.title}`,
  }));
}
