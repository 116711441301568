import { Route } from "nextjs-routes";

type StaticRoute = Exclude<Route, { query: any }>["pathname"];

class RouteHistory {
  private history: Map<StaticRoute, StaticRoute> = new Map();

  save(pathname: StaticRoute, url: StaticRoute) {
    pathname = removeTrailingSlash(pathname);
    this.history.set(pathname, url);
  }

  get(pathname: StaticRoute) {
    pathname = removeTrailingSlash(pathname);
    const url = this.history.get(pathname);

    return url ?? pathname;
  }
}

export const removeTrailingSlash = (url: StaticRoute): StaticRoute => {
  if (url.endsWith("/")) {
    return url.slice(0, -1) as StaticRoute;
  }

  return url;
};

export const routeHistory = new RouteHistory();
