import { ApolloProvider } from "@apollo/client";
import { Box, ChakraProvider } from "@chakra-ui/react";
import LoginProvider from "@components/HOC/LoginProvider/LoginProvider";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import * as Sentry from "@sentry/nextjs";
import "@shopify/polaris/styles.min.css";
import "@src/assets/css/global.css";
import "@src/assets/css/overrides/overrides.css";
import "@src/assets/css/overrides/polaris.css";
import "@src/assets/css/overrides/tippy-theme-unstyled.css";
import "@src/assets/css/tailwind.css";
import { DevTools } from "@src/components/HOC/DevTools/DevTools";
import { clearEditorDrafts } from "@src/components/ui-kit/TextEditor/TextEditorDraftStorage";
import { UnexpectedError } from "@src/components/widgets/ErrorPage";
import { useRecordMyUrl } from "@src/helpers/route-history";
import { client as apolloClient } from "@src/services/apollo-client";
import { client } from "@src/services/client";
import { AppStoreContext, appStore } from "@src/stores/AppStore";
import { theme } from "@src/theme";
import { FreshsurveyWidget } from "@src/third-party/FreshsurveyWidget";
import { IntercomWidget } from "@src/third-party/IntercomWidget";
import { useRecordPrevUrl } from "@src/utils/hooks/useRecordPrevUrl";
import "fix-date";
import "focus-visible/dist/focus-visible";
import { configure } from "mobx";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef } from "react";
import "tippy.js/dist/tippy.css";
import { Provider as UrqlProvider } from "urql";
import "../../scripts/wdyr";

configure({ enforceActions: "never" });

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { pathname } = useRouter();
  const toastContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    clearEditorDrafts();
  }, []);

  useRecordPrevUrl();

  useRecordMyUrl([
    "/invoices/for-invoicing",
    "/invoices/outgoing-invoices",
    "/invoices/proforma-invoices",
    "/expenses",
  ]);

  return (
    <I18nProvider i18n={i18n}>
      <ApolloProvider client={apolloClient}>
        <ChakraProvider
          /**
           * This is to fix the issue with the popover being hidden behind the drawer
           * @see https://github.com/chakra-ui/chakra-ui/discussions/5974
           */
          portalZIndex={422}
          theme={theme}
          toastOptions={{
            portalProps: {
              containerRef: toastContainerRef,
            },
          }}
        >
          <Sentry.ErrorBoundary fallback={UnexpectedError}>
            <AppStoreContext.Provider value={appStore}>
              <UrqlProvider value={client}>
                <LoginProvider>
                  <DevTools>
                    {appStore.authStore.PUBLIC_PAGES.includes(
                      pathname,
                    ) ? null : (
                      <Fragment>
                        <FreshsurveyWidget />
                        <IntercomWidget />
                      </Fragment>
                    )}
                    <Component {...pageProps} />
                  </DevTools>
                </LoginProvider>
              </UrqlProvider>
            </AppStoreContext.Provider>
          </Sentry.ErrorBoundary>

          {/**
           * Workaround for the issue with the toast being hidden behind modals when setting `portalZIndex`
           * @see https://github.com/chakra-ui/chakra-ui/issues/6276#issuecomment-1429862773
           */}
          <Box ref={toastContainerRef} zIndex="toast" />
        </ChakraProvider>
      </ApolloProvider>
    </I18nProvider>
  );
};

export default MyApp;
