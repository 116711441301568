import { Trans } from "@lingui/macro";
import { PermissionList } from "@src/__generated__/urql-graphql";
import { appStore } from "@src/stores/AppStore";
import { Me } from "@src/stores/models/Me";
import React, { FunctionComponent } from "react";

export type IPerms = keyof PermissionList;

/**
 * Checks if a user has at least one of provided permissions.
 */
const check = (permissions: IPerms | IPerms[]): boolean => {
  const allPermissions = appStore.authStore.user?.permissions;

  if (!allPermissions) return false;

  if (Array.isArray(permissions)) {
    return permissions.some((p) => allPermissions[p] === true);
  }

  return !!allPermissions[permissions];
};

type IProps = {
  do: IPerms | IPerms[];
  eval?: (user: Me) => boolean;
};

/**
 * Checks if a user has at least one of provided permissions.
 */
export function can(
  args: IProps | IPerms | IPerms[],
  ...rest: IPerms[]
): boolean {
  let allowed = false;

  if (typeof args === "string") {
    allowed = check([args, ...rest]);
  } else if (Array.isArray(args)) {
    allowed = check([...args, ...rest]);
  } else {
    allowed = check(args.do) && (args.eval?.(appStore.authStore.user!) ?? true);
  }
  return allowed;
}

/**
 * Checks if a user doesn't have all provided permissions.
 */
export function cannot(
  args: IProps | IPerms | IPerms[],
  ...rest: IPerms[]
): boolean {
  return !can(args, ...rest);
}

export const Can: FunctionComponent<React.PropsWithChildren<IProps>> = (
  props,
) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return can(props) ? <React.Fragment>{props.children}</React.Fragment> : null;
};

type IWithCanProps = IProps & {
  /**
   * It should show error message
   * @default true
   */
  fail?: boolean;
};

export function withCan<P extends object>(
  args: IWithCanProps | IPerms | IPerms[],
  Cmp: React.ComponentType<React.PropsWithChildren<P>>,
) {
  return function _can(props: P) {
    if (typeof args === "string") {
      return can(args) ? <Cmp {...props} /> : null;
    }

    if (typeof args === "object" && !Array.isArray(args)) {
      if (can(args)) {
        return <Cmp {...props} />;
      } else {
        return args.fail !== false ? (
          <div className="flex w-full h-screen items-center justify-center text-3xl font-bold">
            <Trans>Not authorized</Trans>
          </div>
        ) : null;
      }
    }

    return can(args) ? <Cmp {...props} /> : null;
  };
}
