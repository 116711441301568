import { ApolloQueryResult, QueryLazyOptions } from "@apollo/client";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { Filters } from "./components/filters/models";
import { OrderBy } from "./components/sorting/OrderBy";

export interface ICommonQueryVariables<T, U> {
  page: PaginationState["asParams"]["page"];
  first?: PaginationState["asParams"]["first"];
  filters: {
    where?: Filters<U>["asGraphQueryParam"];
    orderBy?: OrderBy<T>["asWhereParam"];
    search?: string;
  };
}

/**
 * create common query variables for listings
 * @param store
 */
export function commonQueryVariables<T, U>(
  store: {
    pagination?: PaginationState;
    orderBy?: OrderBy<T>;
    where?: Filters<U>;
    searchTerm?: string;
  },
  merge?: { where: Filters<U> },
): ICommonQueryVariables<T, U> {
  const variables: ICommonQueryVariables<T, U> = {
    ...(store.pagination ? store.pagination.asParams : { page: 1 }),
    filters: {
      ...(store.orderBy && { orderBy: store.orderBy.asWhereParam }),
      ...(store.searchTerm != null &&
        store.searchTerm !== "" && { search: store.searchTerm }),
    },
  };

  if (store.where && merge?.where) {
    variables.filters.where = {
      AND: [
        ...store.where.asGraphQueryParam.AND,
        ...merge.where.asGraphQueryParam.AND,
      ],
    };
  } else if (store.where) {
    variables.filters.where = store.where.asGraphQueryParam;
  } else if (merge?.where) {
    variables.filters.where = merge.where.asGraphQueryParam;
  }
  return variables;
}

/**
 * create common query variables for listings
 * @param store
 */
export function commonSearchParams<T, U>(store: {
  pagination?: PaginationState;
  orderBy?: OrderBy<T>;
  where?: Filters<U>;
  searchTerm?: string;
}) {
  return {
    ...(store.pagination && store.pagination.asURLSearchParam),
    ...(store.orderBy && store.orderBy.asURLSearchParam),
    ...(store.where && store.where.asURLSearchParam),
    search: store.searchTerm ?? "",
  };
}

export type TRefetch<Vars, Query> = (
  variables?: Vars,
) => Promise<ApolloQueryResult<Query>>;

export type TRefetchLazy<Vars> = (variables?: QueryLazyOptions<Vars>) => void;
