import { Button, ButtonProps } from "@chakra-ui/react";
import { Icon } from "@src/components/ui-kit/Icon";

interface AddButtonProps extends Omit<ButtonProps, "icon"> {
  iconSize?: "default" | "small";

  /** @default "circle" */
  iconType?: "circle" | "normal";
}

export const AddButton = ({
  iconSize = "default",
  iconType,
  ...rest
}: AddButtonProps) => (
  <Button
    fontWeight="normal"
    _hover={{ bg: "transparent", textDecoration: "underline" }}
    leftIcon={
      <Icon
        style={{
          fill: "currentcolor",
          transform: iconSize === "small" ? "scale(0.8)" : "scale(1.1)",
        }}
        name={iconType === "normal" ? "plus" : "plus-circle"}
      />
    }
    variant="ghost"
    {...rest}
  />
);
