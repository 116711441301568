import {
  Team,
  WorkspaceUserManagementSetting,
} from "@src/__generated__/urql-graphql";
import { SettingsModalStore } from "@src/stores/SettingsModalStore";
import { TToFieldStates } from "@src/utils/forms/ts-utils";
import { required } from "@src/utils/forms/validators";
import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import { v4 as uuidV4 } from "uuid";

type TeamStateProps = Omit<Team, "deleted_at" | "id" | "user_count"> & {
  copy_users_from_team_id?: Team["id"];
};
class TeamState extends FormState<TToFieldStates<TeamStateProps>> {
  id?: Team["id"];
  user_count: Team["user_count"];
  internalId: string;
  copy_users_from_team_name?: Team["name"];
  constructor(
    id?: Team["id"],
    user_count?: Team["user_count"],
    fields: TeamStateProps = {
      name: "",
      is_default: false,
      copy_users_from_team_id: undefined,
    },
  ) {
    super({
      name: new FieldState(fields.name).validators(required),
      is_default: new FieldState(fields.is_default),
      copy_users_from_team_id: new FieldState(fields.copy_users_from_team_id),
    });
    this.id = id;
    this.user_count = user_count ?? 0;
    this.internalId = uuidV4();
  }
}

export class UserManagementState {
  @observable teamToDelete: TeamState | undefined = undefined;
  @observable newTeam: TeamState | undefined = undefined;
  readonly form = new FormState({
    teams: new FormState<TeamState[]>([]),
    require_two_factor_authentication: new FieldState(false),
  });
  private store: SettingsModalStore;

  constructor(store: SettingsModalStore) {
    this.store = store;
    makeObservable(this);
  }

  init(settings: WorkspaceUserManagementSetting) {
    this.form.$.teams = new FormState(
      settings.teams
        .filter((team) => !team.deleted_at)
        .map((team) => new TeamState(team.id, team.user_count, team)),
    );
    this.form.$.require_two_factor_authentication.onChange(
      settings.require_two_factor_authentication,
    );
  }

  addNewTeam() {
    const newLength = this.form.$.teams.$.push(new TeamState());

    // If we just added our very first team, then no team is default currently.
    // We need to make the new one default.
    if (newLength === 1) {
      this.form.$.teams.$[0].$.is_default.onChange(true);
    }
  }

  @action.bound requestTeamRemoval(team: TeamState) {
    this.teamToDelete = team;
    if (team.id && team.user_count > 0) {
      this.store.userManagementStore.fetchTeamUsers(team.id);

      // Pick one of the other teams to copy users to. This is just initial value, user can change it.
      this.newTeam = this.form.$.teams.$.find(
        (t) => t !== team && t.$.copy_users_from_team_id?.$ === undefined,
      );
    }
  }

  @action.bound removeTeam(team: TeamState) {
    if (team.user_count > 0 && !this.newTeam) {
      throw new Error("New team must be set when removing a team with members");
    }

    // Make sure to reassign default flag to another team if the one being deleted was default.
    if (team.$.is_default.$) {
      this.form.$.teams.$.find(
        (teamState) => teamState !== team,
      )?.$.is_default.onChange(true);
    }

    if (this.newTeam) {
      this.newTeam.$.copy_users_from_team_id?.onChange(team.id);
      this.newTeam.copy_users_from_team_name = team.$.name.$;
    }

    this.form.$.teams = new FormState(
      this.form.$.teams.$.filter((teamState) => teamState !== team),
    );

    this.cleanUpTeamRemoval();
  }

  makeDefault(team: TeamState) {
    this.form.$.teams.$.forEach((teamState) => {
      teamState.$.is_default.onChange(teamState === team);
    });
  }

  @action.bound setNewTeamForUsers(teamInternalId: TeamState["internalId"]) {
    this.newTeam = this.form.$.teams.$.find(
      (t) => t.internalId === teamInternalId,
    );
  }

  @action.bound cleanUpTeamRemoval() {
    this.teamToDelete = undefined;
    this.newTeam = undefined;
  }
}
