import { TASK_ID_QUERY_KEY } from "@src/components/widgets/Modals/ModalCommunication/CommunicationModalHeader";
import { isEqual } from "lodash";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { usePrevious } from "react-use";
import { getQueryParamOptional } from "../routing";

/**
 * updates URI without triggering refresh
 */
export const useUpdateURI = (
  params: Record<string, string>,
  /** E.g. Keep ?id=13 in URL */
  persistQueryParam?: string,
) => {
  const router = useRouter();
  const prevPathname = usePrevious(router.pathname);

  useEffect(() => {
    if (prevPathname !== router.pathname) return;

    const persistedParams = [TASK_ID_QUERY_KEY];
    if (persistQueryParam) {
      persistedParams.push(persistQueryParam);
    }

    persistedParams.forEach((persistedParam) => {
      const param = getQueryParamOptional(persistedParam, router.query);
      if (param) {
        params = {
          [persistedParam]: param,
          ...params,
        };
      }
    });

    if (isEqual(router.query, params)) return;

    router.push(
      {
        pathname: router.pathname,
        query: params,
      },
      undefined,
      { shallow: true },
    );
  }, [params]);
};
