import { AvatarProps, Box, HStack, Text } from "@chakra-ui/react";
import { Avatar } from "@src/components/ui-kit";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

interface MentionSuggestionsListProps {
  items: {
    full_name: string;
    id: string;
    image: string;
    profile: { hex_color?: string | null };
  }[];
  command(editorMention: { label: string; id: string }): void;
}

export type MentionSuggestionsListRef = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
};

export const MentionSuggestionsList = forwardRef<
  MentionSuggestionsListRef,
  MentionSuggestionsListProps
>(function MentionSuggestionsList(props, ref) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({
        id: item.id,
        label: item.full_name,
      });
    }
  };

  const keyDownHandler: Record<KeyboardEvent["key"], () => void> = {
    ArrowUp: () =>
      setSelectedIndex(
        (selectedIndex + props.items.length - 1) % props.items.length,
      ),
    ArrowDown: () => setSelectedIndex((selectedIndex + 1) % props.items.length),
    Enter: () => selectItem(selectedIndex),
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle<any, MentionSuggestionsListRef>(ref, () => ({
    onKeyDown: ({ event }) => {
      if (Object.keys(keyDownHandler).includes(event.key)) {
        keyDownHandler[event.key]();
        return true;
      } else {
        return false;
      }
    },
  }));

  if (props.items.length === 0) {
    return null;
  }

  return (
    <Box
      // @ts-expect-error
      ref={ref}
      pos="relative"
      overflowY="auto"
      maxH="30vh"
      py="2"
      bg="white"
      border="1px solid"
      borderColor="grey.100"
      shadow="lg"
      rounded="lg"
    >
      {props.items.map((user, index) => (
        <HStack
          key={user.id}
          as={"button"}
          w="full"
          minH="44px"
          px="3"
          bg={index === selectedIndex ? "blue.50" : "transparent"}
          _hover={{ bg: "blue.50" }}
          onClick={() => selectItem(index)}
        >
          <Avatar
            mr="1"
            name={user.full_name}
            src={user.image}
            colorScheme={user.profile?.hex_color as AvatarProps["bg"]}
          />
          <Text
            color={index === selectedIndex ? "blue.800" : "grey.800"}
            _hover={{ color: "blue.800" }}
          >
            {user.full_name}
          </Text>
        </HStack>
      ))}
    </Box>
  );
});
