import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import themeSizes from "../foundations/sizes";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  badge: {
    bg: "gray.500",
    border: "2px solid",
  },
  container: {
    borderRadius: "full",
  },
  excessLabel: {
    bg: "gray.800",
    color: "white",
    borderRadius: "full",
  },
});

function getSize(size: keyof typeof themeSizes | "100%") {
  const themeSize = size !== "100%" ? themeSizes[size] : undefined;
  return definePartsStyle({
    container: {
      width: size,
      height: size,
      fontSize: `calc(${themeSize ?? size} / 2.2)`,
      fontWeight: "semibold",
    },
    excessLabel: {
      width: size,
      height: size,
    },
    label: {
      fontSize: `calc(${themeSize ?? size} / 2.2)`,
      lineHeight: size !== "100%" ? themeSize ?? size : undefined,
      fontWeight: "semibold",
    },
  });
}

const sizes = {
  "2xs": getSize(4),
  xs: getSize(6),
  sm: getSize(8),
  md: getSize(12),
  lg: getSize(16),
  xl: getSize(24),
  "2xl": getSize(32),
  full: getSize("100%"),
};

export const avatarTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: "sm",
  },
});
