import { TModuleSettings, TPermissionList } from "@src/stores/models/types";
import { Route } from "nextjs-routes";

type ModuleSettings = Omit<TModuleSettings, "__typename">;

/**
 * Loop Map and get my first allowed welcome page.
 */
export type TModules = keyof ModuleSettings;
export const welcomePageByModule: [TModules, Route["pathname"]][] = [
  ["projects", "/projects"],
  ["budgetovac", "/budgets/export"],
  ["planovac", "/resource-planning"],
  ["invoices", "/invoices/for-invoicing"],
  ["expenses", "/expenses"],
  ["billing_plan", "/invoices/billing-plan"],
  ["clients", "/brands"],
  ["contractors", "/contractors"],
  ["time_tracking", "/time-tracking/day"],
  ["reports", "/reports/time-report"],
  ["documents", "/documents"],
];

/**
 * Check if user has any permission for the page.
 */
export type TPages = TModules | "users" | "settings" | "tasks";
export const permissionRouteMap = new Map<TPages, (keyof TPermissionList)[]>([
  ["tasks", ["task_read_all", "task_read_own"]],
  [
    "projects",
    [
      "project_read_all",
      "project_read_own",
      "project_read_team",
      "wip_read_own",
      "wip_read_all",
      "wip_read_team",
    ],
  ],
  ["budgetovac", ["integration_budgetovac"]],
  ["planovac", ["integration_forecasting"]],
  [
    "invoices",
    [
      "outgoingInvoice_read_all",
      "outgoingInvoice_read_own",
      "outgoingInvoice_read_team",
      "requestForInvoicing_read_all",
      "requestForInvoicing_read_own",
      "requestForInvoicing_read_team",
      "expense_read_all",
      "expense_read_own",
      "expense_read_team",
    ],
  ],
  ["expenses", ["expense_read_all", "expense_read_own"]],
  [
    "billing_plan",
    ["billingPlan_create", "billingPlan_update", "billingPlan_listing"],
  ],
  ["clients", ["brand_read"]],
  ["contractors", ["contractor_read"]],
  [
    "time_tracking",
    [
      "timeTracking_read_all",
      "timeTracking_read_own",
      "timeTracking_read_team",
    ],
  ],
  [
    "reports",
    [
      "timeTracking_report_read_all",
      "timeTracking_report_read_own",
      "timeTracking_report_read_team",
    ],
  ],
  ["documents", ["otherDocument_read", "contract_read", "order_read"]],
  ["users", ["user_read"]],
  ["settings", ["workspaceSetting_edit"]],
]);
