import { Box, TabPanel } from "@chakra-ui/react";
import { Spinner } from "@components/ui-kit";
import { useLastSeenCommentMutation } from "@src/__generated__/graphql";
import Comment from "@src/components/widgets/Modals/ModalCommunication/components/comment/Comment";
import { CommentAppearance } from "@src/components/widgets/Modals/ModalCommunication/components/comment/CommentAppearance";
import useComments from "@src/components/widgets/Modals/ModalCommunication/useComments";
import { onError } from "@src/utils/apollo";
import { useStore } from "@src/utils/hooks";
import useIntersectionObserver from "@src/utils/hooks/useIntersectionObserver";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

export const CommunicationTab = observer(function CommunicationTab() {
  const { taskDetailModalStore: store } = useStore();

  const [lastSeenComment] = useLastSeenCommentMutation({ ...onError() });

  const [
    comments,
    {
      loadingComments,
      hasMoreComments,
      fetchMoreComments,
      replaceComment,
      deleteComment,
    },
  ] = useComments(
    { taskId: store.taskId.value },
    store.drawerState.isOpen,
    store,
  );

  const loadMore = useCallback(() => {
    if (hasMoreComments && !loadingComments) {
      fetchMoreComments();
    }
  }, [hasMoreComments, loadingComments]);

  useIntersectionObserver({
    threshold: 0.25,
    target: store.commentsLoaderRef,
    onIntersect: loadMore,
  });

  useIntersectionObserver({
    threshold: 0.7,
    target: store.firstCommentRef,
    onIntersect: () => {
      if (comments && !!comments.length && !store.seenLastComment.value) {
        store.seenLastComment.on();
        lastSeenComment({
          variables: {
            comment_id: comments[0].id,
          },
        });
      }
    },
  });

  return (
    <TabPanel px="0">
      <Box ref={store.commentsContainerRef}>
        {comments.map((comment, index) => {
          if (index === 0) {
            return (
              <CommentAppearance key={comment.id} commentId={comment.id}>
                <Comment
                  ref={store.firstCommentRef}
                  comment={comment}
                  onUpdated={replaceComment}
                  onDeleted={deleteComment}
                  taskId={store.task.value?.id}
                />
              </CommentAppearance>
            );
          } else {
            return (
              <Comment
                key={comment.id}
                comment={comment}
                onUpdated={replaceComment}
                onDeleted={deleteComment}
                taskId={store.task.value?.id}
              />
            );
          }
        })}
      </Box>
      <Box ref={store.commentsLoaderRef} h="10">
        {loadingComments && <Spinner />}
      </Box>
    </TabPanel>
  );
});
