import { t } from "@lingui/macro";
import { Banner } from "@src/components/ui-kit";
import { isSameDay, isToday } from "date-fns";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

interface BannerPastTimerRunningProps {
  runningDate?: Date;
  currentDate: Date;
  onAction: (runningDate: Date) => void;
}

const BannerPastTimerRunning = ({
  runningDate,
  currentDate,
  onAction,
}: BannerPastTimerRunningProps) => {
  const showBanner = useMemo<boolean>(() => {
    return !!runningDate && !isSameDay(runningDate, currentDate);
  }, [runningDate, currentDate]);

  if (!showBanner || !runningDate) {
    return null;
  }

  return (
    <Banner
      mb="6"
      status="info"
      title={
        isToday(runningDate)
          ? t`Great job! You are tracking your time right now`
          : t`You have a past timer running`
      }
      primaryAction={{
        title: t`Click here to jump back and edit it`,
        onClick: () => onAction(runningDate),
      }}
    />
  );
};

export default observer(BannerPastTimerRunning);
