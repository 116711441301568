import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { useGetWorkTypeOptionsQuery } from "@src/__generated__/urql-graphql";
import { Select } from "@src/components/ui-kit";
import { fieldToSelectProps } from "@src/utils/forms/inputHelpers";
import { useStore } from "@src/utils/hooks";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";

export const SelectWorkTypeIfUserNotInTaskModal = observer(
  function SelectWorkTypeIfUserNotInTaskModal() {
    const { selectWorkTypeIfUserNoInTaskModalStore: store } = useStore();
    const additionalData = computed(
      () => store.modalState.additionalData,
    ).get();

    const [{ fetching: fetchingWorkTypeOptions, data }] =
      useGetWorkTypeOptionsQuery({
        pause:
          !additionalData?.payload.userItem.stats?.task_id ||
          !store.modalState.isOpen,
        variables: {
          taskId: additionalData?.payload.userItem.stats?.task_id ?? "",
        },
      });

    const workTypeOptions = computed(() => {
      return (
        data?.task?.ourWorkBudgetItem?.project?.availableTimeTrackingWorkTypes.map(
          (type) => ({
            value: type.id,
            label: type.title,
          }),
        ) ?? []
      );
    }).get();

    return (
      <Modal
        isOpen
        onClose={() => {
          additionalData?.onCancel?.();
          store.modalState.onClose();
        }}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Trans>Select position</Trans>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {fetchingWorkTypeOptions ? (
              <Center>
                <Spinner />
              </Center>
            ) : (
              <Select
                label={t`Position`}
                {...fieldToSelectProps(store.workTypeId, workTypeOptions)}
              />
            )}
          </ModalBody>
          <ModalFooter gap="2">
            <Button
              colorScheme="grey"
              isLoading={store.loading}
              onClick={() => {
                additionalData?.onCancel?.();
                store.modalState.onClose();
              }}
              variant="outline"
            >
              <Trans>Close</Trans>
            </Button>
            <Button isLoading={store.loading} onClick={store.onSubmit}>
              <Trans>Confirm</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);
