import {
  HStack,
  IconButton,
  StackDivider,
  StackProps,
  Tooltip,
} from "@chakra-ui/react";
import { EmojiPopover } from "@components/ui-kit/EmojiPopover";
import { t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { useUpload } from "@src/components/ui-kit/TextEditor/attachment/useUpload";
import { OrderedList as OrderedListIcon } from "@src/components/ui-kit/TextEditor/icons";
import { HyperlinkButton } from "@src/components/ui-kit/TextEditor/toolbar/HyperlinkButton";
import { Editor } from "@tiptap/core";
import { Selection } from "@tiptap/pm/state";
import { ChangeEvent, FC, useRef } from "react";

interface ToolbarMenuProps extends StackProps {
  editor: Editor;
}

export const ToolbarMenu: FC<ToolbarMenuProps> = ({ editor, ...props }) => {
  const fileElRef = useRef<HTMLInputElement>(null);

  const onUpload = useUpload();

  const openFileDialog = () => fileElRef.current?.click();

  const onHandleFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      if (files.length === 0) return;

      for (let i = 0; i < files.length; ++i) {
        const file = files[i];
        onUpload()
          .upload(file)
          .then((res) => {
            if (res) {
              editor.commands.setAttachment({
                loading: false,
                id: res.public_id,
                mime_type: res.mime_type,
                filename: res.filename,
                viewable: res.urls.viewable ?? undefined,
                thumbnail: res.urls.thumbnail ?? undefined,
                original: res.urls.original ?? undefined,
              });
            }
            if (files.length === i) {
              // to move cursor after attachment (can it be done better?)
              editor.commands.insertContentAt(
                Selection.atEnd(editor.state.doc).$head.pos,
                "<p></p>",
              );
              editor.commands.focus("end");
            }
          });
      }
    }
    // reset
    e.target.value = "";
  };

  return (
    <HStack
      pos="relative"
      top="0"
      right="0"
      left="0"
      overflow="scroll"
      w="full"
      p="1"
      bg="white"
      divider={<StackDivider borderColor="grey.100" />}
      {...props}
    >
      <HStack>
        <IconButton
          bg={editor.isActive("bold") ? "grey.50" : "initial"}
          aria-label={t`bold`}
          colorScheme="grey"
          icon={<Icon name="bold-01" />}
          onClick={() => editor.chain().focus().toggleBold().run()}
          size="sm"
          variant="ghost"
        />
        <IconButton
          bg={editor.isActive("italic") ? "grey.50" : "initial"}
          aria-label={t`italic`}
          colorScheme="grey"
          icon={<Icon name="italic-01" />}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          size="sm"
          variant="ghost"
        />
        <IconButton
          bg={editor.isActive("strike") ? "grey.50" : "initial"}
          aria-label={t`strike`}
          colorScheme="grey"
          icon={<Icon name="strikethrough-01" />}
          onClick={() => editor.chain().focus().toggleStrike().run()}
          size="sm"
          variant="ghost"
        />
        <IconButton
          bg={editor.isActive("underline") ? "grey.50" : "initial"}
          aria-label={t`underline`}
          colorScheme="grey"
          icon={<Icon name="underline-01" />}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          size="sm"
          variant="ghost"
        />
      </HStack>

      <HStack>
        <IconButton
          bg={editor.isActive("bulletList") ? "grey.50" : "initial"}
          aria-label={t`bulletList`}
          colorScheme="grey"
          icon={<Icon name="dotpoints-02" />}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          size="sm"
          variant="ghost"
        />
        <IconButton
          bg={editor.isActive("orderedList") ? "grey.50" : "initial"}
          aria-label={t`orderedList`}
          colorScheme="grey"
          icon={<OrderedListIcon />}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          size="sm"
          variant="ghost"
        />
      </HStack>
      <HyperlinkButton editor={editor} />
      <IconButton
        bg={editor.isActive("taskList") ? "grey.50" : "initial"}
        aria-label={t`task list`}
        colorScheme="grey"
        icon={<Icon name="check-square" />}
        onClick={() => editor.chain().focus().toggleTaskList().run()}
        size="sm"
        variant="ghost"
      />

      <EmojiPopover
        onEmojiSelect={({ native }) => {
          editor.commands.insertContent(native);
        }}
        trigger={
          <Tooltip
            label={t`Find another reaction`}
            placement="top"
            shouldWrapChildren
          >
            <IconButton
              aria-label={t`add reaction`}
              colorScheme="grey"
              icon={<Icon name="face-happy" />}
              size="sm"
              variant="ghost"
            />
          </Tooltip>
        }
      />

      <IconButton
        bg={editor.isActive("codeBlock") ? "grey.50" : "initial"}
        aria-label={t`code`}
        colorScheme="grey"
        icon={<Icon name="code-02" />}
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        size="sm"
        variant="ghost"
      />

      <HStack>
        <input
          type="file"
          multiple
          ref={fileElRef}
          onChange={onHandleFiles}
          style={{ display: "none" }}
        />
        <IconButton
          aria-label={t`attachment`}
          colorScheme="grey"
          icon={<Icon name="paperclip" />}
          onClick={openFileDialog}
          size="sm"
          variant="ghost"
        />
      </HStack>

      <HStack>
        <IconButton
          aria-label={t`undo`}
          colorScheme="grey"
          icon={<Icon name="corner-up-left" />}
          isDisabled={!editor.can().undo()}
          onClick={() => editor.chain().focus().undo().run()}
          size="sm"
          variant="ghost"
        />
        <IconButton
          aria-label={t`redo`}
          colorScheme="grey"
          icon={<Icon name="corner-up-right" />}
          isDisabled={!editor.can().redo()}
          onClick={() => editor.chain().focus().redo().run()}
          size="sm"
          variant="ghost"
        />
      </HStack>
    </HStack>
  );
};
