import { Box } from "@chakra-ui/react";
import { SelectProps } from "@src/components/ui-kit/Select/Select";
import { chakraComponents } from "chakra-react-select";

const CustomMenuList = (
  props: any,
  topExtraContent?: SelectProps["topExtraContent"],
  bottomExtraContent?: SelectProps["bottomExtraContent"],
) => {
  return (
    <Box
      sx={{
        ">div": { border: "none" },
      }}
      overflow="hidden"
      borderRadius="md"
    >
      <chakraComponents.MenuList {...props}>
        {topExtraContent}
        {props.children}
      </chakraComponents.MenuList>
      {bottomExtraContent}
    </Box>
  );
};

export default CustomMenuList;
