import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { required } from "@src/utils/forms/validators";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import { IOption } from "../Select";
import { ProjectCategoryEditModal } from "./EditModal";

type ProjectCategoryModalOptions = {
  categories: IOption[];
};

export class ProjectCategoryModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  readonly modalId = "projectCategoryModal";
  editForm = new FormState({
    id: new FieldState<string | undefined>(undefined),
    name: new FieldState<string | undefined>(undefined).validators(required),
  });

  @observable modalState = new DisclosureState<ProjectCategoryModalOptions>({
    onOpen: () => {
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <ProjectCategoryEditModal />,
      });
    },
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
    },
  });

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @action selectCategory = (category: IOption) => {
    this.editForm.reset();
    this.editForm.clearFormError();
    this.editForm.$.name.onChange(category.label);
    this.editForm.$.id.onChange(category.value);
  };

  @action clearCategory = () => {
    this.editForm.reset();
    this.editForm.clearFormError();
  };

  @action.bound updateCategory() {
    if (!this.editForm.$.name.$) return;
    for (const category of this.appStore.projectCategorySelectStore
      .categories) {
      if (category.value === this.editForm.$.id.$) {
        category.label = this.editForm.$.name.$;
      }
    }
    this.clearCategory();
  }

  @action.bound deleteCategory(id: string) {
    this.appStore.projectCategorySelectStore.categories =
      this.appStore.projectCategorySelectStore.categories.filter(
        ({ value }) => {
          return value !== id;
        },
      );
    if (this.editForm.$.id.$ !== id) return;
    this.clearCategory();
  }

  @computed get isSelectedCategory() {
    return this.editForm.$.id.value !== undefined;
  }
}
