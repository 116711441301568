import {
  PlanningRangeTypeEnum,
  UserProfilePlanningRange,
} from "@src/__generated__/graphql";
import { UserProfilePlanningRange as UserProfilePlanningRangeUrql } from "@src/__generated__/urql-graphql";
import { MAX_JS_DATE } from "@src/utils/dates";
import { WeekDays } from "@src/utils/types";
import { isWeekend, isWithinInterval } from "date-fns";
import { MarkOptional } from "ts-essentials";

export function getUsersPlannableCapacityForDate(
  planningRanges: MarkOptional<
    UserProfilePlanningRange | UserProfilePlanningRangeUrql,
    "active" | "utilising" | "id"
  >[],
  date: Date,
) {
  let capacity = 0;
  const activeRangeRecord = planningRanges.find((range) => {
    return isWithinInterval(date, {
      start: range.valid_from,
      end: range.valid_to ?? MAX_JS_DATE,
    });
  });
  const dayOfWeek = date
    .toLocaleString("en-us", {
      weekday: "long",
    })
    .toLowerCase() as keyof WeekDays<unknown>;

  switch (activeRangeRecord?.type.value) {
    case PlanningRangeTypeEnum.Custom:
      capacity = activeRangeRecord?.weekly_capacities?.[dayOfWeek] ?? 0;
      break;
    case PlanningRangeTypeEnum.Week:
      capacity = activeRangeRecord.daily_capacity ?? 0;
    case PlanningRangeTypeEnum.Workweek:
      capacity = isWeekend(date) ? 0 : activeRangeRecord.daily_capacity ?? 0;
  }

  return capacity;
}
