import { TimeTrackingWorkType } from "@src/__generated__/urql-graphql";

export type toWorkTypeOptionsParams = {
  users: Pick<TimeTrackingWorkType, "id" | "title">[];
  modifier: (option: WorkTypeOption) => WorkTypeOption;
};

export type WorkTypeOption = {
  label: TimeTrackingWorkType["title"];
  value: TimeTrackingWorkType["id"];
};

export const toWorkTypeOptions = (
  users: toWorkTypeOptionsParams["users"],
  modifier: toWorkTypeOptionsParams["modifier"] = (option) => option,
): WorkTypeOption[] =>
  users.map((i) =>
    modifier({
      label: i.title,
      value: i.id,
    }),
  );
