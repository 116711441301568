import { useEffect, useState } from "react";

/**
 * force scroll to hash id
 */
export const useScrollToHashID = (ready: boolean) => {
  const [timeoutUsed, setTimeoutUsed] = useState(false);

  useEffect(() => {
    const forceToScroll = () => {
      if (!window.location.hash) return;
      window.location = window.location;
    };

    if (ready && !timeoutUsed) {
      setTimeoutUsed(true);
      const timeoutId = setTimeout(forceToScroll, 1000);

      return () => clearTimeout(timeoutId);
    }

    return () => {};
  }, [ready]);
};
