import {
  Link as ChakraNextLink,
  LinkProps as ChakraNextLinkProps,
} from "@chakra-ui/next-js";
import {
  Button,
  ButtonProps,
  Link as ChakraLink,
  MenuItem,
  MenuItemProps,
} from "@chakra-ui/react";
import { FC } from "react";

type LinkProps = Omit<ChakraNextLinkProps, "isExternal" | "href"> &
  (
    | {
        isExternal?: false;
        href: ChakraNextLinkProps["href"];
      }
    | { isExternal: true; href: string }
  );
export type ButtonLinkProps = LinkProps & ButtonProps;
type MenuItemLinkProps = LinkProps & MenuItemProps;

export const Link: FC<LinkProps> = (props) =>
  props.isExternal ? <ChakraLink {...props} /> : <ChakraNextLink {...props} />;
export const ButtonLink: FC<ButtonLinkProps> = (props) => (
  <Button as={Link} {...props} />
);
export const MenuItemLink: FC<MenuItemLinkProps> = (props) => (
  <MenuItem as={Link} {...props} />
);
