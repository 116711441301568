import { SqlOperator } from "@src/__generated__/urql-graphql";
import Router from "next/router";
import { Query, StaticRoute } from "nextjs-routes";
import { Filter, Filters } from "./components/filters/models";
import { assertIsDefined } from "./types";

export function getQueryParam(name: string, query: Query) {
  const _param = query[name];
  const param = Array.isArray(_param) ? _param[0] : _param;

  assertIsDefined(param);

  return param;
}

export function getQueryParamOptional(
  name: string,
  query: Query,
): string | undefined {
  const _param = query[name];
  const param = Array.isArray(_param) ? _param[0] : _param;

  return param;
}

/**
 * @active it's meant to represent active tab, quick-filter, ...
 * in URL as query param.
 */
interface IActiveParam {
  active: string;
}

export function createActiveQueryParam(active: string): IActiveParam {
  return { active };
}

export function parseActiveQueryParam(query: Query): string | undefined {
  return getQueryParamOptional("active", query);
}

export function filtersToUrlParam(
  filters: {
    column: string;
    value: string[];
    operator: SqlOperator;
  }[],
) {
  return new Filters(
    filters.map(
      ({ column, operator, value }) =>
        new Filter({
          column,
          operator,
          value,
          options: [],
        }),
    ),
  ).asURLSearchParam;
}

export function isPathActive(
  href: StaticRoute<"pathname">,
  active?: boolean,
): boolean {
  if (active !== undefined) return active;
  if (!href) return false;

  if (typeof href === "string") {
    return Router.route === (href as string)?.split("?")[0];
  }

  if (typeof href !== "object") return false;

  return Router.route === href.pathname.toString().split("?")[0];
}
