import { extendTheme } from "@chakra-ui/react";
import { avatarTheme } from "@src/theme/components/avatar";
import { modalTheme } from "@src/theme/components/modal";
import { switchTheme } from "@src/theme/components/switch";
import { tabsTheme } from "@src/theme/components/tabs";
import { textareaTheme } from "@src/theme/components/textarea";
import foundations from "@src/theme/foundations";
import { remoteHtmlStyles } from "@src/theme/remoteHtmlStyles";
import { CalendarDefaultTheme } from "@uselessdev/datepicker";
import { StepsTheme as Steps } from "chakra-ui-steps";
import {
  Badge,
  Button,
  CalendarDay,
  CalendarMonth,
  Checkbox,
  FormLabel,
  Heading,
  Input,
  Link,
  Popover,
  Spinner,
  Tooltip,
} from "./components";

export const theme = extendTheme(
  CalendarDefaultTheme,
  {
    ...foundations,
    components: {
      Button,
      Badge,
      Input,
      Textarea: textareaTheme,
      CalendarDay,
      CalendarMonth,
      Modal: modalTheme,
      Heading,
      Spinner,
      Popover,
      Tooltip,
      Link,
      FormLabel,
      Checkbox,
      Tabs: tabsTheme,
      Switch: switchTheme,
      Avatar: avatarTheme,
      Calendar: {
        parts: ["calendar"],
        baseStyle: {
          calendar: {
            rounded: "xl",
          },
        },
      },
      Steps,
    },
  },
  remoteHtmlStyles,
);
