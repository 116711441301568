import { t } from "@lingui/macro";
import { CapacityAllocationTypeEnum } from "@src/__generated__/urql-graphql";
import { CSSUnitValue, Time } from "@src/utils/types";

export const COLUMN_WIDTH: CSSUnitValue = "230px";
export const DAY_STRIP_WIDTH: CSSUnitValue = "44px";
export const CAPACITY_STRIP_HEIGHT: CSSUnitValue = "28px";
export const COLUMN_HEADER_HEIGHT: CSSUnitValue = "56px";
export const EMPTY_FILTERS_HEIGHT: CSSUnitValue = "64px";
export const USED_FILTERS_HEIGHT: CSSUnitValue = "94px";
export const ONE_HOUR_HEIGHT_OF_ALLOCATION = 65;
export const ONE_ROW_OF_ALLOCATION = 19.5;
export const FIFTEEN_MINUTES_HEIGHT_OF_ALLOCATION =
  ONE_HOUR_HEIGHT_OF_ALLOCATION / 4;
export const DEFAULT_TIME_DIFFERENCE: Time = "01:00";
export const getCommonCapacityAllocationTypeOptions = () => [
  {
    label: t`Task`,
    value: CapacityAllocationTypeEnum.Task,
  },
  {
    label: t`Meeting`,
    value: CapacityAllocationTypeEnum.Meeting,
  },
  {
    label: t`Time off`,
    value: CapacityAllocationTypeEnum.TimeOff,
  },
  {
    label: t`Home office`,
    value: CapacityAllocationTypeEnum.HomeOffice,
  },
];
