import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { cssVar } from "@chakra-ui/theme-tools";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const $width = cssVar("switch-track-width");
const $height = cssVar("switch-track-height");

const POLARIS_FIX_MULTIPLIER = 1.6;

const sizes = {
  sm: definePartsStyle({
    container: {
      [$width.variable]: `${POLARIS_FIX_MULTIPLIER * 1.375}rem`,
      [$height.variable]: "sizes.3",
    },
  }),
  md: definePartsStyle({
    container: {
      [$width.variable]: `${POLARIS_FIX_MULTIPLIER * 1.875}rem`,
      [$height.variable]: "sizes.4",
    },
  }),
  lg: definePartsStyle({
    container: {
      [$width.variable]: `${POLARIS_FIX_MULTIPLIER * 2.875}rem`,
      [$height.variable]: "sizes.6",
    },
  }),
};

export const switchTheme = defineMultiStyleConfig({ sizes });
