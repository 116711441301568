import { ErrorScreen } from "@components/ui-kit";
import { t } from "@lingui/macro";

export const NotFoundProforma = () => (
  <ErrorScreen
    title={t`I didn’t find the proforma invoice you are looking for.`}
    link={{
      text: t`See all proforma invoices`,
      href: "/invoices/proforma-invoices",
    }}
  />
);
