import { Button } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import {
  useCreateTimeTrackingItemMutation,
  useUpdateTimeTrackingItemMutation,
} from "@src/__generated__/graphql";
import { onError } from "@src/utils/apollo";
import { observer } from "mobx-react-lite";
import { EntryForm } from "../../form";

interface SaveButtonProps {
  userId: string;
  form: EntryForm;
  onSave: () => void;
}

const SaveButton = ({ userId, form, onSave }: SaveButtonProps) => {
  const [create, { loading: createLoading }] =
    useCreateTimeTrackingItemMutation({
      onCompleted() {
        onSave();
      },
      ...onError(),
    });

  const [update, { loading: updateLoading }] =
    useUpdateTimeTrackingItemMutation({
      onCompleted() {
        onSave();
      },
      ...onError(),
    });

  const onClick = async () => {
    const { hasError } = await form.validate();
    if (hasError) {
      return;
    }

    if (form.id.value) {
      update({ variables: form.serializeUpdate(userId) });
    } else {
      create({ variables: form.serializeCreate(userId) });
    }
  };

  return (
    <Button isLoading={createLoading || updateLoading} onClick={onClick}>
      {t`Save entry`}
    </Button>
  );
};

export default observer(SaveButton);
