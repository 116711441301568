import { Button, Tooltip } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { TaskCommentReaction, User } from "@src/__generated__/urql-graphql";
import { Emoji } from "@src/components/ui-kit/Emoji";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

interface CommentReactionProps {
  emoji_id: TaskCommentReaction["emoji_id"];
  users: Pick<User, "id" | "full_name">[];
  onClick: () => void;
}

export const CommentReaction: FunctionComponent<CommentReactionProps> =
  observer(function CommentReaction({ users, emoji_id, onClick }) {
    const { authStore } = useStore();
    const isCurrentUserReacted = !!users.some(
      (user) => user.id === authStore.user?.id,
    );
    const reactionLabel = t`${users
      .map((user) => user.full_name)
      .join(", ")} reacted with ${emoji_id} emoji`;

    return (
      <Tooltip label={reactionLabel}>
        <Button
          bg={isCurrentUserReacted ? "teal.100" : "white"}
          borderColor={isCurrentUserReacted ? "teal.300" : "grey.200"}
          _hover={{
            bg: isCurrentUserReacted ? "teal.100" : "white",
          }}
          aria-label={emoji_id}
          colorScheme="grey"
          leftIcon={<Emoji id={emoji_id} />}
          onClick={onClick}
          rounded="full"
          size="xs"
          variant="outline"
        >
          {users.length}
        </Button>
      </Tooltip>
    );
  });
