import { OurWorkBudgetItem } from "@src/__generated__/urql-graphql";

type toBudgetItemOptionsParams = {
  budgetItems: {
    id: OurWorkBudgetItem["id"];
    title: OurWorkBudgetItem["title"];
    category: Pick<OurWorkBudgetItem["category"], "name">;
  }[];
  modifier: (option: BudgetItemOption) => BudgetItemOption;
};

type BudgetItemOption = {
  label: string;
  value: OurWorkBudgetItem["id"];
};

export const toBudgetItemOptions = (
  budgetItems: toBudgetItemOptionsParams["budgetItems"],
  modifier: toBudgetItemOptionsParams["modifier"] = (option) => option,
): BudgetItemOption[] =>
  budgetItems.map((i) =>
    modifier({
      label: `${i.title} - ${i.category.name}`,
      value: i.id,
    }),
  );
