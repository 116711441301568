import { Button } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import {
  useCreateTimeTrackingItemMutation,
  useStartTimerMutation,
} from "@src/__generated__/graphql";
import { trackEvent } from "@src/services/amplitude";
import { onError } from "@src/utils/apollo";
import { observer } from "mobx-react-lite";
import { EntryForm } from "../../form";

interface StartButtonProps {
  userId: string;
  form: EntryForm;
  onStart: () => void;
}

const StartButton = ({ userId, form, onStart }: StartButtonProps) => {
  const [create, { loading }] = useCreateTimeTrackingItemMutation({
    onCompleted({ createTimeTrackingItem }) {
      start({
        variables: { time_tracking_item_id: createTimeTrackingItem.id },
      });
    },
    ...onError(),
  });

  const [start, { loading: startLoading }] = useStartTimerMutation({
    onCompleted() {
      onStart();
      trackEvent("time-entry", "Started tracking");
    },
    ...onError(),
  });

  const onClick = async () => {
    const { hasError } = await form.validate();
    if (hasError) {
      return;
    }

    create({ variables: form.serializeCreate(userId) });
  };

  return (
    <Button isLoading={loading || startLoading} onClick={onClick}>
      {t`Start timer`}
    </Button>
  );
};

export default observer(StartButton);
