import { SelectOptions, SelectProps } from "@components/ui-kit/Select";
import { formatIBAN } from "@src/utils/formatters";
import { FieldState } from "formstate";
import { NumberModel } from "../models";

interface FieldToInputProps {
  onChange: any;
  value: string;
  error?: string;
}

type FieldToNumberInputProps = FieldToInputProps & { decimalDigits: number };

const IBAN_LENGTH = 24 - 1; // -1 because we are counting from 0
const IBAN_SPACES = 5;

export const fieldToInputProps = (
  field: FieldState<any>,
  sanitize: (val: any) => any = (val) => val,
): FieldToInputProps => {
  const props: FieldToInputProps = {
    value: field.value,
    onChange: (val: string) => field.onChange(sanitize(val)),
  };

  if (field.hasError) {
    props.error = field.error;
  }

  return props;
};

export const fieldToIBANInputProps = (
  field: FieldState<any>,
  sanitize: (val: any) => any = (val) => val,
): FieldToInputProps => {
  const props: FieldToInputProps = {
    value: formatIBAN(field.value),
    onChange: (val: string) =>
      field.onChange(
        sanitize(
          val
            .replaceAll("\t", "")
            .replaceAll(" ", "")
            .slice(0, IBAN_LENGTH + IBAN_SPACES),
        ),
      ),
  };

  if (field.hasError) {
    props.error = field.error;
  }

  return props;
};

export const fieldToSelectProps = (
  field: FieldState<any>,
  options: SelectOptions,
  sanitize: (val: any) => any = (val) => val,
  afterOnChange?: () => void,
) => {
  const props: Pick<SelectProps, "value" | "onChange" | "options" | "error"> = {
    value: field.value,
    onChange: (val) => {
      field.onChange(sanitize(val));
      setTimeout(() => {
        afterOnChange?.();
      }, 300);
    },
    options,
  };

  if (field.hasError) {
    props.error = field.error;
  }

  return props;
};

export const fieldNumberModelToInputProps = (
  field: FieldState<NumberModel>,
  afterOnChange?: (val: string) => any,
): FieldToNumberInputProps => {
  return {
    decimalDigits: field.value.denominator,
    value: field.value.value.toString(),
    onChange: (val: string) => {
      NumberModel.setFieldState(field, val);
      afterOnChange?.(val);
    },
    error: field.error,
  };
};

export const fieldToCheckboxProps = (
  field: FieldState<boolean>,
  afterOnChange?: () => void,
) => {
  const props: {
    isChecked?: boolean;
    onChange(arg: React.ChangeEvent<HTMLInputElement>): void;
    error?: string;
  } = {
    isChecked: field.value,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      field.onChange(e.target.checked);
      setTimeout(() => {
        afterOnChange?.();
      }, 300);
    },
  };

  if (field.hasError) {
    props.error = field.error;
  }

  return props;
};
