import { Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { CapacityAllocationTypeEnum } from "@src/__generated__/urql-graphql";
import { ModalConfirm } from "@src/components/ui-kit";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

export const DeleteAllocationModal = observer(function DeleteAllocationModal() {
  const { deleteAllocationModalStore, workspaceStore } = useStore();
  const additionalData = deleteAllocationModalStore.modalState.additionalData;

  return (
    <ModalConfirm
      destructive
      cancelBtnTitle={t`Cancel`}
      confirmBtnTitle={t`Delete`}
      onConfirm={deleteAllocationModalStore.onDelete}
      isOpen={deleteAllocationModalStore.modalState.isOpen}
      onClose={deleteAllocationModalStore.modalState.onClose}
    >
      <Text mb="3">
        <Trans>Are you sure that you want to delete capacity allocation?</Trans>
      </Text>

      {(additionalData?.itemCount ?? 0) > 1 &&
        additionalData?.affectOccurrences === "allOccurrences" && (
          <RadioGroup
            onChange={(value) => {
              deleteAllocationModalStore.deleteAllItems.onChange(
                value === "true" ? true : false,
              );
            }}
            value={deleteAllocationModalStore.deleteAllItems.value.toString()}
          >
            <Stack>
              <Radio value="true">
                <Trans>All allocations</Trans>
              </Radio>
              <Radio value="false">
                <Trans>This allocation and all following</Trans>
              </Radio>
            </Stack>
          </RadioGroup>
        )}

      {workspaceStore.settings?.timetastic_integration &&
        additionalData?.type === CapacityAllocationTypeEnum.TimeOff && (
          <Text mt="6" color="red.600" fontWeight="semibold">
            <Trans>Time-off must also be deleted in Timetastic</Trans>
          </Text>
        )}

      <Text mt="6" color="red.600" fontWeight="semibold">
        <Trans>
          By deleting this allocation, you will not delete tracked time
        </Trans>
      </Text>
    </ModalConfirm>
  );
});
