import {
  Button,
  ButtonProps,
  ComponentWithAs,
  forwardRef,
} from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";

export const BulkActionsButton: ComponentWithAs<
  "button",
  Omit<ButtonProps, "children">
> = forwardRef((props, ref) => {
  return (
    <Button
      ref={ref}
      colorScheme="grey"
      rightIcon={<Icon name="chevron-down" />}
      variant="outline"
      {...props}
    >
      <Trans>Bulk actions</Trans>
    </Button>
  );
});
