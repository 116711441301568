import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import {
  StartDayOfWeekEnum,
  WorkspacePlanningSettings,
} from "@src/__generated__/urql-graphql";
import {
  AllocationItemStyleEnum,
  PLANNING_SETTING_PROPERTIES,
  PlanningViewTypeEnum,
  WorkspaceSettings,
} from "@src/stores/models/WorkspaceSettings";
import { isTimeBiggerThan } from "@src/utils/time";
import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";

export const WORKING_TO_SMALLER_THAN_WORKING_FROM =
  "WORKING_TO_SMALLER_THAN_WORKING_FROM";

export class PlanningUtilizationState {
  readonly working_from = new FieldState<
    WorkspacePlanningSettings["working_from"]
  >("00:00");
  readonly working_to = new FieldState<WorkspacePlanningSettings["working_to"]>(
    "00:00",
  );
  readonly start_day_of_week = new FieldState<
    WorkspacePlanningSettings["start_day_of_week"]
  >(StartDayOfWeekEnum.Monday);
  readonly default_user_plannable_capacity = new FieldState<string>(
    "8",
  ).validators((value) => {
    const numericValue = Number(value);
    return (numericValue < 0 || numericValue > 24) && "error";
  });
  readonly specific_time_in_planning_enabled = new FieldState<
    WorkspacePlanningSettings["specific_time_in_planning_enabled"]
  >(false);
  readonly hide_planning_total_daily_capacity = new FieldState<
    WorkspacePlanningSettings["hide_planning_total_daily_capacity"]
  >(false);
  readonly planning_alphabet_user_order = new FieldState<
    WorkspacePlanningSettings["planning_alphabet_user_order"]
  >(false);
  readonly disable_home_office_in_planning = new FieldState<
    WorkspacePlanningSettings["disable_home_office_in_planning"]
  >(false);
  readonly planning_sort_prioritize_time_specific_items = new FieldState<
    WorkspacePlanningSettings["planning_sort_prioritize_time_specific_items"]
  >(false);

  readonly allocation_item_style = new FieldState<AllocationItemStyleEnum>(
    AllocationItemStyleEnum.COLOR_FILL,
  );
  readonly view_type = new FieldState<PlanningViewTypeEnum>(
    PlanningViewTypeEnum.WEEK_FROM_NOW,
  );
  readonly adjust_allocation_height_by_duration = new FieldState<boolean>(
    false,
  );
  readonly show_project = new FieldState<boolean>(false);
  readonly show_task = new FieldState<boolean>(false);
  readonly show_budget_item = new FieldState<boolean>(false);
  readonly show_note = new FieldState<boolean>(false);
  @observable text_order: Array<
    | PLANNING_SETTING_PROPERTIES.SHOW_PROJECT
    | PLANNING_SETTING_PROPERTIES.SHOW_TASK
    | PLANNING_SETTING_PROPERTIES.SHOW_BUDGET_ITEM
  > = [
    PLANNING_SETTING_PROPERTIES.SHOW_PROJECT,
    PLANNING_SETTING_PROPERTIES.SHOW_TASK,
    PLANNING_SETTING_PROPERTIES.SHOW_BUDGET_ITEM,
  ];

  readonly default_utilization_range_from = new FieldState<
    WorkspacePlanningSettings["default_utilization_range_from"]
  >(0);
  readonly default_utilization_range_to = new FieldState<
    WorkspacePlanningSettings["default_utilization_range_to"]
  >(100);

  readonly form = new FormState({
    working_from: this.working_from,
    working_to: this.working_to,
    start_day_of_week: this.start_day_of_week,
    default_user_plannable_capacity: this.default_user_plannable_capacity,
    specific_time_in_planning_enabled: this.specific_time_in_planning_enabled,
    hide_planning_total_daily_capacity: this.hide_planning_total_daily_capacity,
    planning_alphabet_user_order: this.planning_alphabet_user_order,
    disable_home_office_in_planning: this.disable_home_office_in_planning,
    planning_sort_prioritize_time_specific_items:
      this.planning_sort_prioritize_time_specific_items,
    default_utilization_range_from: this.default_utilization_range_from,
    default_utilization_range_to: this.default_utilization_range_to,
  }).validators(
    ($) =>
      !isTimeBiggerThan($.working_to.$, $.working_from.$) &&
      WORKING_TO_SMALLER_THAN_WORKING_FROM,
  );

  constructor() {
    makeObservable(this);
  }

  init(
    settings: WorkspacePlanningSettings,
    defaultPlanningViewSettings: WorkspaceSettings["default_planning_view_settings"],
  ) {
    this.working_from.onChange(settings.working_from);
    this.working_to.onChange(settings.working_to);
    this.start_day_of_week.onChange(settings.start_day_of_week);
    this.default_user_plannable_capacity.onChange(
      String(settings.default_user_plannable_capacity / 60 / 60),
    );
    this.specific_time_in_planning_enabled.onChange(
      settings.specific_time_in_planning_enabled,
    );
    this.hide_planning_total_daily_capacity.onChange(
      settings.hide_planning_total_daily_capacity,
    );
    this.planning_alphabet_user_order.onChange(
      settings.planning_alphabet_user_order,
    );
    this.disable_home_office_in_planning.onChange(
      settings.disable_home_office_in_planning,
    );
    this.planning_sort_prioritize_time_specific_items.onChange(
      settings.planning_sort_prioritize_time_specific_items,
    );

    this.allocation_item_style.onChange(
      defaultPlanningViewSettings[
        PLANNING_SETTING_PROPERTIES.ALLOCATION_ITEM_STYLE
      ],
    );
    this.view_type.onChange(
      defaultPlanningViewSettings[PLANNING_SETTING_PROPERTIES.VIEW_TYPE],
    );
    this.adjust_allocation_height_by_duration.onChange(
      defaultPlanningViewSettings[
        PLANNING_SETTING_PROPERTIES.ADJUST_ALLOCATION_HEIGHT_BY_DURATION
      ],
    );
    this.show_project.onChange(
      defaultPlanningViewSettings[PLANNING_SETTING_PROPERTIES.SHOW_PROJECT],
    );
    this.show_task.onChange(
      defaultPlanningViewSettings[PLANNING_SETTING_PROPERTIES.SHOW_TASK],
    );
    this.show_budget_item.onChange(
      defaultPlanningViewSettings[PLANNING_SETTING_PROPERTIES.SHOW_BUDGET_ITEM],
    );
    this.show_note.onChange(
      defaultPlanningViewSettings[PLANNING_SETTING_PROPERTIES.SHOW_NOTE],
    );

    this.default_utilization_range_from.onChange(
      settings.default_utilization_range_from,
    );
    this.default_utilization_range_to.onChange(
      settings.default_utilization_range_to,
    );
  }

  @action.bound reorder({ active, over }: DragEndEvent) {
    if (!over?.id || active.id === over.id) return;

    const oldIndex = this.text_order.findIndex((item) => item === active.id);
    const newIndex = this.text_order.findIndex((item) => item === over.id);

    this.text_order = arrayMove(this.text_order, oldIndex, newIndex);
  }
}
