import { Box, BoxProps } from "@chakra-ui/react";
import { CSSUnits } from "@src/utils/types";
import React, { FC, useLayoutEffect, useRef } from "react";

export interface ContentContainerProps extends BoxProps {
  fullWidth?: boolean;
  noGutters?: boolean;
  onChangeDimensions?: (height: number, width: number) => void;
}

const CONTAINER_WIDTH: `${string}${CSSUnits}` = "1680px";

const ContentContainer: FC<React.PropsWithChildren<ContentContainerProps>> = ({
  fullWidth = true,
  noGutters,
  children,
  onChangeDimensions,
  ...props
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const { current } = divRef;

    if (current?.offsetWidth && current?.offsetHeight) {
      onChangeDimensions?.(current.offsetHeight, current.offsetWidth);
    }
  }, [divRef.current?.offsetWidth, divRef.current?.offsetHeight]);

  return (
    <Box
      ref={divRef}
      maxW={fullWidth ? "100%" : CONTAINER_WIDTH}
      mx="auto"
      px={{
        base: noGutters ? 0 : 4,
        md: noGutters ? 0 : 10,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export { ContentContainer };
