
export type ObjectFieldTypes = {
    [key: string]: { [key: string]: string | string[] }
};

export type OpTypes = {
    [key: string]: string | string[]
};

export type ScalarLocations = {
 scalars: string[],
 inputObjectFieldTypes: ObjectFieldTypes;
 outputObjectFieldTypes: ObjectFieldTypes;
 operationMap: OpTypes;
};

export const scalarLocations : ScalarLocations = {
  "inputObjectFieldTypes": {
    "BankAccountMoveDocumentInput": {
      "date": "Date"
    },
    "BankAccountMoveQueryArgs": {
      "where": "BankAccountMoveWhereConditions"
    },
    "BankAccountMoveWhereConditions": {
      "AND": "BankAccountMoveWhereConditions",
      "OR": "BankAccountMoveWhereConditions"
    },
    "BillingPlanBudgetItemInput": {
      "billingPlanMonths": "BillingPlanMonthInput"
    },
    "BillingPlanItemInput": {
      "date": "Date"
    },
    "BillingPlanMonthInput": {
      "date": "Date"
    },
    "BillingPlanOverviewQueryArgs": {
      "orderBy": "BillingPlanOrderByClause",
      "where": "BillingPlanOverviewWhereConditions"
    },
    "BillingPlanOverviewWhereConditions": {
      "AND": "BillingPlanOverviewWhereConditions",
      "OR": "BillingPlanOverviewWhereConditions"
    },
    "BrandCreditAccountsWhereConditions": {
      "AND": "BrandCreditAccountsWhereConditions",
      "OR": "BrandCreditAccountsWhereConditions"
    },
    "BrandQueryArgs": {
      "orderBy": "BrandOrderByClause",
      "where": "BrandsWhereConditions"
    },
    "BrandRevenueQueryArgs": {
      "from": "Date",
      "orderBy": "BrandRevenueOrderByClause",
      "to": "Date",
      "where": "BrandRevenueWhereConditions"
    },
    "BrandRevenueWhereConditions": {
      "AND": "BrandRevenueWhereConditions",
      "OR": "BrandRevenueWhereConditions"
    },
    "BrandsWhereConditions": {
      "AND": "BrandsWhereConditions",
      "OR": "BrandsWhereConditions"
    },
    "BudgetArgs": {
      "orderBy": "BudgetOrderByClause",
      "where": "BudgetWhereConditions"
    },
    "BudgetExportOptionsInput": {
      "date": "Date",
      "valid_until": "Date"
    },
    "BudgetWhereConditions": {
      "AND": "BudgetWhereConditions",
      "OR": "BudgetWhereConditions"
    },
    "CapacityAllocationRepetitionSettings": {
      "end": "Date"
    },
    "ClientQueryArgs": {
      "orderBy": "ClientOrderByClause",
      "where": "ClientsWhereConditions"
    },
    "ClientsWhereConditions": {
      "AND": "ClientsWhereConditions",
      "OR": "ClientsWhereConditions"
    },
    "CompleteCapacityAllocationUserInput": {
      "existingTimeTrackingItems": "UpdateTimeTrackingItemInput",
      "newTimeTrackingItem": "CreateTimeTrackingItemInput"
    },
    "ContractQueryArgs": {
      "orderBy": "ContractOrderByClause",
      "where": "ContractsWhereConditions"
    },
    "ContractorBasicQueryArgs": {
      "orderBy": "ContractorOrderByClause"
    },
    "ContractorQueryArgs": {
      "orderBy": "ContractorOrderByClause",
      "where": "ContractorsWhereConditions"
    },
    "ContractorsWhereConditions": {
      "AND": "ContractorsWhereConditions",
      "OR": "ContractorsWhereConditions"
    },
    "ContractsWhereConditions": {
      "AND": "ContractsWhereConditions",
      "OR": "ContractsWhereConditions"
    },
    "CreateCapacityAllocationInput": {
      "attendees": "CapacityAllocationAttendee",
      "deadline": "Date",
      "deadline_time": "Time",
      "from_date": "Date",
      "from_time": "Time",
      "repetition_settings": "CapacityAllocationRepetitionSettings",
      "to_date": "Date",
      "to_time": "Time"
    },
    "CreateContractInput": {
      "valid_from": "Date",
      "valid_to": "Date"
    },
    "CreateCurrencyExchangeRateInput": {
      "valid_from": "Date",
      "valid_to": "Date"
    },
    "CreateExchangeRateSettingsInput": {
      "valid_from": "Date",
      "valid_to": "Date"
    },
    "CreateExpenseInput": {
      "date_of_supply": "Date",
      "due_date": "Date",
      "expense_items": "ExpenseItemInput",
      "expense_vat_items": "ExpenseVatItemInput",
      "files": "FileInput",
      "issue_date": "Date",
      "paid_at": "Date"
    },
    "CreateOrderInput": {
      "date_of_order": "Date"
    },
    "CreateProjectInput": {
      "expected_end_date": "Date",
      "start_date": "Date"
    },
    "CreateRequestForInvoicingInput": {
      "date_of_supply": "Date",
      "due_date": "Date",
      "expense_budget_splits": "ExpenseBudgetSplitInput",
      "invoice_items": "InvoiceItemInput",
      "issue_date": "Date",
      "our_work_budget_splits": "OurWorkBudgetSplitInput",
      "prepayment_splits": "PrepaymentSplitInput",
      "timeMaterialsInvoiceData": "timeMaterialsInputData"
    },
    "CreateTaskInput": {
      "deadline": "DateTime",
      "description": "TaskDescriptionInput",
      "from": "Date",
      "positions": "TaskPositionInput",
      "to": "Date"
    },
    "CreateTimeTrackingItemInput": {
      "tracked_for_date": "Date"
    },
    "CreditAccountQueryArgs": {
      "orderBy": "BrandCreditAccountOrderByClause",
      "where": "BrandCreditAccountsWhereConditions"
    },
    "DateRange": {
      "from": "Date",
      "to": "Date"
    },
    "ExpenseItemInput": {
      "positions": "ExpenseItemPositionInput"
    },
    "ExpenseItemPositionInput": {
      "report_date": "Date"
    },
    "ExpenseQueryArgs": {
      "orderBy": "ExpenseOrderByClause",
      "where": "ExpensesWhereConditions"
    },
    "ExpensesWhereConditions": {
      "AND": "ExpensesWhereConditions",
      "OR": "ExpensesWhereConditions"
    },
    "InputAccountingAccount": {
      "budget_categories": "InputAccountingAccountBudgetCategory"
    },
    "InputUpdateWorkspaceAccountingAccountSettings": {
      "accounting_accounts": "InputAccountingAccount"
    },
    "InputWorkspaceCompanySetting": {
      "companies": "WorkspaceCompanyInput"
    },
    "InputWorkspaceFinancialSetting": {
      "currencies": "CurrencyInput",
      "vat_rates": "VatRateInput"
    },
    "InputWorkspacePlanningSetting": {
      "working_from": "Time",
      "working_to": "Time"
    },
    "InputWorkspaceTaskSettings": {
      "task_priorities": "WorkspaceTaskPriorityInput",
      "task_statuses": "WorkspaceTaskStatusInput"
    },
    "InputWorkspaceUserManagementSetting": {
      "teams": "InputWorkspaceTeamSetting"
    },
    "MoveCapacityAllocationItemInput": {
      "date": "Date"
    },
    "OIProformaQueryArgs": {
      "orderBy": "OutgoingProformaInvoiceOrderByClause",
      "where": "OIProformaWhereConditions"
    },
    "OIProformaWhereConditions": {
      "AND": "OIProformaWhereConditions",
      "OR": "OIProformaWhereConditions"
    },
    "OIQueryArgs": {
      "orderBy": "OutgoingInvoiceOrderByClause",
      "where": "OIWhereConditions"
    },
    "OIWhereConditions": {
      "AND": "OIWhereConditions",
      "OR": "OIWhereConditions"
    },
    "OrderQueryArgs": {
      "orderBy": "OrderOrderByClause",
      "where": "OrdersWhereConditions"
    },
    "OrdersWhereConditions": {
      "AND": "OrdersWhereConditions",
      "OR": "OrdersWhereConditions"
    },
    "OtherDocumentQueryArgs": {
      "orderBy": "OtherDocumentOrderByClause",
      "where": "OtherDocumentsWhereConditions"
    },
    "OtherDocumentsWhereConditions": {
      "AND": "OtherDocumentsWhereConditions",
      "OR": "OtherDocumentsWhereConditions"
    },
    "OurWorkBudgetSplitInput": {
      "budget_positions": "BudgetPositionsInput"
    },
    "PlanningDailyViewFilters": {
      "from": "Date",
      "to": "Date",
      "where": "PlanningDailyViewWhereCondition"
    },
    "PlanningDailyViewUnassignedTasksFilters": {
      "from": "Date",
      "to": "Date",
      "where": "PlanningViewUnassignedTasksWhereConditions"
    },
    "PlanningDailyViewWhereCondition": {
      "AND": "PlanningDailyViewWhereCondition",
      "OR": "PlanningDailyViewWhereCondition"
    },
    "PlanningRangeInput": {
      "valid_from": "Date",
      "valid_to": "Date",
      "weekly_capacities": "WeeklyCapacitiesInput"
    },
    "PlanningViewUnassignedTasksWhereConditions": {
      "AND": "PlanningViewUnassignedTasksWhereConditions",
      "OR": "PlanningViewUnassignedTasksWhereConditions"
    },
    "ProjectAllQueryArgs": {
      "orderBy": "ProjectOrderByClause",
      "where": "ProjectsAllWhereConditions"
    },
    "ProjectQueryArgs": {
      "orderBy": "ProjectOrderByClause",
      "where": "ProjectsWhereConditions"
    },
    "ProjectRevenueQueryArgs": {
      "from": "Date",
      "orderBy": "ProjectRevenueOrderByClause",
      "to": "Date",
      "where": "ProjectRevenueWhereConditions"
    },
    "ProjectRevenueWhereConditions": {
      "AND": "ProjectRevenueWhereConditions",
      "OR": "ProjectRevenueWhereConditions"
    },
    "ProjectsAllWhereConditions": {
      "AND": "ProjectsAllWhereConditions",
      "OR": "ProjectsAllWhereConditions"
    },
    "ProjectsWhereConditions": {
      "AND": "ProjectsWhereConditions",
      "OR": "ProjectsWhereConditions"
    },
    "RequestForInvoicingQueryArgs": {
      "orderBy": "RequestForInvoicingOrderByClause",
      "where": "RequestsForInvoicingWhereConditions"
    },
    "RequestsForInvoicingWhereConditions": {
      "AND": "RequestsForInvoicingWhereConditions",
      "OR": "RequestsForInvoicingWhereConditions"
    },
    "TaskQueryArgs": {
      "orderBy": "TaskOrderByClause",
      "where": "TaskWhereConditions"
    },
    "TaskWhereConditions": {
      "AND": "TaskWhereConditions",
      "OR": "TaskWhereConditions"
    },
    "TimeTrackingProjectReportQueryArgs": {
      "orderBy": "TimeTrackingProjectReportOrderByClause",
      "where": "TimeTrackingProjectReportsWhereConditions"
    },
    "TimeTrackingProjectReportsWhereConditions": {
      "AND": "TimeTrackingProjectReportsWhereConditions",
      "OR": "TimeTrackingProjectReportsWhereConditions"
    },
    "TimeTrackingReportsWhereConditions": {
      "AND": "TimeTrackingReportsWhereConditions",
      "OR": "TimeTrackingReportsWhereConditions"
    },
    "UpdateBillingPlanInput": {
      "items": "BillingPlanItemInput"
    },
    "UpdateBudgetItem": {
      "deadline": "Date"
    },
    "UpdateCapacityAllocationForDayInput": {
      "attendees": "CapacityAllocationAttendee",
      "date": "Date",
      "deadline": "Date",
      "deadline_time": "Time",
      "from_time": "Time",
      "to_time": "Time"
    },
    "UpdateCapacityAllocationInput": {
      "attendees": "CapacityAllocationAttendee",
      "deadline": "Date",
      "deadline_time": "Time",
      "from_date": "Date",
      "from_time": "Time",
      "repetition_settings": "CapacityAllocationRepetitionSettings",
      "to_date": "Date",
      "to_time": "Time"
    },
    "UpdateCapacityAllocationItemInput": {
      "date": "Date",
      "from_time": "Time",
      "to_time": "Time"
    },
    "UpdateContractInput": {
      "valid_from": "Date",
      "valid_to": "Date"
    },
    "UpdateCurrencyExchangeRateInput": {
      "valid_from": "Date",
      "valid_to": "Date"
    },
    "UpdateExchangeRateSettingsInput": {
      "valid_from": "Date",
      "valid_to": "Date"
    },
    "UpdateExpenseInput": {
      "date_of_supply": "Date",
      "due_date": "Date",
      "expense_items": "ExpenseItemInput",
      "expense_vat_items": "ExpenseVatItemInput",
      "files": "FileInput",
      "issue_date": "Date",
      "paid_at": "Date"
    },
    "UpdateFinanceSettingsInput": {
      "bankAccounts": "BankAccountSettingInput"
    },
    "UpdateOrderInput": {
      "date_of_order": "Date"
    },
    "UpdateOutgoingInvoiceInput": {
      "date_of_supply": "Date",
      "due_date": "Date",
      "expense_budget_splits": "ExpenseBudgetSplitInput",
      "invoice_items": "InvoiceItemInput",
      "issue_date": "Date",
      "our_work_budget_splits": "OurWorkBudgetSplitInput",
      "prepayment_splits": "PrepaymentSplitInput",
      "timeMaterialsInvoiceData": "timeMaterialsInputData"
    },
    "UpdateOutgoingProformaInvoiceInput": {
      "due_date": "Date",
      "invoice_items": "InvoiceItemInput",
      "issue_date": "Date"
    },
    "UpdatePlanningSettings": {
      "planning_view_settings": "PlanningViewSettingInput"
    },
    "UpdateProjectBudgetInput": {
      "budget_valid_from": "Date",
      "expenses": "ExpenseBudgetItemInput",
      "ourWork": "OurWorkBudgetItemInput"
    },
    "UpdateProjectInput": {
      "expected_end_date": "Date",
      "start_date": "Date"
    },
    "UpdateRequestForInvoicingInput": {
      "date_of_supply": "Date",
      "due_date": "Date",
      "expense_budget_splits": "ExpenseBudgetSplitInput",
      "invoice_items": "InvoiceItemInput",
      "issue_date": "Date",
      "our_work_budget_splits": "OurWorkBudgetSplitInput",
      "prepayment_splits": "PrepaymentSplitInput",
      "timeMaterialsInvoiceData": "timeMaterialsInputData"
    },
    "UpdateTaskInput": {
      "deadline": "DateTime",
      "description": "TaskDescriptionInput",
      "from": "Date",
      "positions": "TaskPositionInput",
      "to": "Date"
    },
    "UpdateTimeTrackingBudgetItemInput": {
      "tracking_enabled_from": "Date",
      "tracking_enabled_to": "Date"
    },
    "UpdateTimeTrackingItemInput": {
      "tracked_for_date": "Date"
    },
    "UpdateTimeTrackingRatesForBrandInput": {
      "time_tracking_rates_per_work_type": "UpdateTimeTrackingRateInput"
    },
    "UpdateTimeTrackingRatesForProjectInput": {
      "time_tracking_rates_per_work_type": "UpdateTimeTrackingRateInput"
    },
    "UpdateTimeTrackingSettingsInput": {
      "our_work_budget_items": "UpdateTimeTrackingBudgetItemInput"
    },
    "UserProfileRateInput": {
      "valid_from": "Date",
      "valid_to": "Date"
    },
    "UserQueryArgs": {
      "orderBy": "UserOrderByClause",
      "where": "UsersWhereConditions"
    },
    "UsersWhereConditions": {
      "AND": "UsersWhereConditions",
      "OR": "UsersWhereConditions"
    },
    "UtilizationReportWhereConditions": {
      "AND": "UtilizationReportWhereConditions",
      "OR": "UtilizationReportWhereConditions"
    },
    "WhereConditions": {
      "AND": "WhereConditions",
      "HAS": "WhereConditionsRelation",
      "OR": "WhereConditions"
    },
    "WhereConditionsRelation": {
      "condition": "WhereConditions"
    },
    "WipQueryArgs": {
      "orderBy": "WipOrderByClause",
      "where": "WipsWhereConditions"
    },
    "WipsWhereConditions": {
      "AND": "WipsWhereConditions",
      "OR": "WipsWhereConditions"
    },
    "WorkspaceCompanyInput": {
      "bank_accounts": "WorkspaceCompanyBankAccountSettingInput",
      "sequences": "WorkspaceSequenceSettingInput"
    },
    "lockProjectInput": {
      "orderBy": "WipOrderByClause",
      "where": "WipsWhereConditions"
    },
    "timeMaterialsInputData": {
      "commission_data": "timeMaterialsCommissionInputData",
      "from": "Date",
      "to": "Date"
    },
    "timeMaterialsInvoiceWhereConditions": {
      "AND": "timeMaterialsInvoiceWhereConditions",
      "OR": "timeMaterialsInvoiceWhereConditions"
    },
    "timeMaterialsQueryArgs": {
      "where": "timeMaterialsInvoiceWhereConditions"
    },
    "updateBillingPlanBudgetInput": {
      "billingPlanBudgetItem": "BillingPlanBudgetItemInput"
    },
    "updateProjectBillingPlanBudgetInput": {
      "billingPlanBudgets": "updateBillingPlanBudgetInput"
    },
    "updateProjectBillingPlanSettingsInput": {
      "email_template": "ProjectBillingPlanEmailTemplateInput",
      "from": "Date",
      "to": "Date"
    },
    "updateWorkspaceNotifications": {
      "notifications": "WorkspaceNotificationInput"
    }
  },
  "outputObjectFieldTypes": {
    "AccountingAccount": {
      "budget_categories": "AccountingAccountBudgetCategory",
      "deleted_at": "DateTime"
    },
    "AssignableFile": {
      "created_at": "DateTime",
      "urls": "AssignableFileUrlPair"
    },
    "AttachedOutgoingProformaInvoicePivot": {
      "invoice": "OutgoingProformaInvoice"
    },
    "Attendee": {
      "timeTrackingWorkType": "TimeTrackingWorkType",
      "user": "User"
    },
    "AutocompleteCompanyData": {
      "results": "AutocompleteCompanyDataResult"
    },
    "BankAccountMove": {
      "currency": "Currency",
      "date": "Date",
      "documents": "BankAccountMoveDocument"
    },
    "BankAccountMoveDocument": {
      "amount": "Date",
      "date": "Date"
    },
    "BankAccountMovePaginator": {
      "data": "BankAccountMove",
      "paginatorInfo": "PaginatorInfo"
    },
    "BillingEntity": {
      "brands": "Brand",
      "country": "Country",
      "created_at": "DateTime",
      "defaultCurrency": "Currency",
      "updated_at": "DateTime",
      "workspaceBankAccount": "WorkspaceBankAccount",
      "workspaceCompany": "WorkspaceCompany"
    },
    "BillingPlanBudget": {
      "billingPlanBudgetItem": "BillingPlanBudgetItem",
      "category": "BillingPlanBudgetItemCategory"
    },
    "BillingPlanBudgetItem": {
      "months": "BillingPlanMonth"
    },
    "BillingPlanByMonths": {
      "items": "BillingPlanItem"
    },
    "BillingPlanInvoice": {
      "brandCreditAccount": "BrandCreditAccount",
      "date": "Date",
      "outgoingInvoice": "OutgoingInvoice",
      "proformaInvoice": "OutgoingProformaInvoice"
    },
    "BillingPlanItem": {
      "date": "Date"
    },
    "BillingPlanMonth": {
      "date": "Date"
    },
    "BillingPlanMonthStat": {
      "invoices": "BillingPlanInvoice",
      "items": "BillingPlanItem",
      "project": "Project"
    },
    "BillingPlanMonthStatPaginator": {
      "data": "BillingPlanMonthStat",
      "paginatorInfo": "PaginatorInfo"
    },
    "Brand": {
      "archived_at": "DateTime",
      "client": "Client",
      "contacts": "Contact",
      "contracts": "ContractPaginator",
      "created_at": "DateTime",
      "creditAccounts": "BrandCreditAccount",
      "defaultBillingEntity": "BillingEntity",
      "externalLinks": "ExternalLink",
      "image": "AssignableFile",
      "otherDocuments": "OtherDocumentPaginator",
      "otherResponsiblePersons": "User",
      "projects": "Project",
      "responsiblePerson": "User",
      "stats": "BrandStats",
      "timeTrackingRates": "TimeTrackingRate",
      "updated_at": "DateTime"
    },
    "BrandCreditAccount": {
      "brand": "Brand",
      "created_at": "DateTime",
      "creditAccount": "CreditAccount",
      "credits": "BrandCreditAccountCreditStats",
      "debits": "BrandCreditAccountDebitStats",
      "expenseBudgetCategories": "ExpenseBudgetCategory",
      "ourWorkBudgetCategories": "OurWorkBudgetCategory",
      "stats": "BrandCreditAccountStats",
      "team": "Team",
      "updated_at": "DateTime"
    },
    "BrandCreditAccountCreditStats": {
      "date": "Date",
      "originatesBrandCreditAccount": "BrandCreditAccount",
      "outgoingInvoice": "OutgoingInvoice"
    },
    "BrandCreditAccountDebitStats": {
      "brandCreditAccount": "BrandCreditAccount",
      "expenseBudgetItem": "ExpenseBudgetItem",
      "ourWorkBudgetItem": "OurWorkBudgetItem",
      "performed_at": "Date"
    },
    "BrandCreditAccountPaginator": {
      "data": "BrandCreditAccount",
      "paginatorInfo": "PaginatorInfo"
    },
    "BrandPaginator": {
      "data": "Brand",
      "paginatorInfo": "PaginatorInfo"
    },
    "BrandRevenue": {
      "paginatorInfo": "PaginatorInfo",
      "result": "BrandWithRevenueResult"
    },
    "BrandRevenueSummary": {
      "brand_profit_share": "BrandRevenueShare",
      "brand_revenue_share": "BrandRevenueShare",
      "client_revenue_share": "BrandRevenueShare",
      "expense_revenue_for_date_range": "ExpenseRevenueByCategory",
      "profit_top_clients": "TopClient",
      "revenue_top_clients": "TopClient"
    },
    "BrandSimple": {
      "archived_at": "DateTime",
      "defaultBillingEntity": "BillingEntity",
      "deleted_at": "DateTime"
    },
    "BrandWithRevenue": {
      "expenses_billings_for_date_range": "ProjectRevenueBillingsByExpenseCategory",
      "expenses_profit_for_date_range": "ProjectRevenueProfitByExpenseCategory",
      "image": "AssignableFile",
      "ourWork": "OurWorkBudgetItemWithRevenue"
    },
    "BrandWithRevenueResult": {
      "brands": "BrandWithRevenue",
      "expensesStructured": "ExpenseStructuredBudgetItemWithRevenue",
      "expenses_billings_for_date_range": "ProjectRevenueBillingsByExpenseCategory",
      "expenses_profit_for_date_range": "ProjectRevenueProfitByExpenseCategory",
      "image": "AssignableFile",
      "ourWork": "OurWorkBudgetItemWithRevenue"
    },
    "Budget": {
      "assignment_date": "Date",
      "brand": "Brand",
      "created_at": "DateTime",
      "currency": "Currency",
      "project": "Project",
      "temporaryBrand": "TemporaryBrand",
      "temporaryProject": "TemporaryProject",
      "updated_at": "DateTime"
    },
    "BudgetGroup": {
      "budgets": "Budget"
    },
    "BudgetPaginator": {
      "data": "BudgetGroup",
      "paginatorInfo": "PaginatorInfo"
    },
    "BudgetPdfSettings": {
      "date": "Date",
      "valid_until": "Date"
    },
    "CapacityAllocation": {
      "attendees": "Attendee",
      "capacityAllocationItems": "CapacityAllocationItem",
      "createdBy": "User",
      "created_at": "DateTime",
      "deadline": "Date",
      "deadline_time": "Time",
      "from_date": "Date",
      "from_time": "Time",
      "repetition_settings": "RepetitionSettings",
      "task": "Task",
      "teams": "TeamAttendee",
      "timeOffType": "TimeOffType",
      "to_date": "Date",
      "to_time": "Time"
    },
    "CapacityAllocationItem": {
      "capacityAllocation": "CapacityAllocation",
      "createdBy": "User",
      "date": "Date",
      "from_time": "Time",
      "stats": "CapacityAllocationItemStats",
      "teams": "TeamAttendee",
      "timeOffType": "TimeOffType",
      "timeTrackingWorkType": "TimeTrackingWorkType",
      "to_time": "Time",
      "user": "User",
      "userItems": "CapacityAllocationItem",
      "userStats": "CapacityAllocationUserItemStats"
    },
    "CapacityAllocationItemStats": {
      "deadline": "Date",
      "deadline_time": "Time",
      "tracking_enabled_from": "Date",
      "tracking_enabled_to": "Date"
    },
    "Client": {
      "billingEntities": "BillingEntity",
      "brands": "Brand",
      "contacts": "Contact",
      "contracts": "ContractPaginator",
      "created_at": "DateTime",
      "currencyExchangeRates": "CurrencyExchangeRate",
      "currencyExchangeRatesStructured": "CurrencyExchangeRateStructured",
      "image": "AssignableFile",
      "otherDocuments": "OtherDocumentPaginator",
      "otherResponsiblePersons": "User",
      "responsiblePerson": "User",
      "updated_at": "DateTime",
      "workspaceCompany": "WorkspaceCompany"
    },
    "ClientPaginator": {
      "data": "Client",
      "paginatorInfo": "PaginatorInfo"
    },
    "ClientSimple": {
      "brands": "BrandSimple",
      "created_at": "DateTime",
      "deleted_at": "DateTime",
      "updated_at": "DateTime"
    },
    "CompanyData": {
      "bankAccounts": "CompanyBankAccount",
      "country": "Country"
    },
    "Contact": {
      "created_at": "DateTime",
      "image": "AssignableFile",
      "updated_at": "DateTime"
    },
    "Contract": {
      "brands": "Brand",
      "clients": "Client",
      "contractors": "Contractor",
      "created_at": "DateTime",
      "files": "AssignableFile",
      "pdfFiles": "AssignableFile",
      "projects": "Project",
      "updated_at": "DateTime",
      "urls": "MediaUrlList",
      "valid_from": "Date",
      "valid_to": "Date"
    },
    "ContractPaginator": {
      "data": "Contract",
      "paginatorInfo": "PaginatorInfo"
    },
    "Contractor": {
      "contracts": "ContractPaginator",
      "contractsNonPaginated": "Contract",
      "country": "Country",
      "created_at": "DateTime",
      "defaultAccountingAccount": "AccountingAccount",
      "otherDocuments": "OtherDocumentPaginator",
      "updated_at": "DateTime"
    },
    "ContractorPaginator": {
      "data": "Contractor",
      "paginatorInfo": "PaginatorInfo"
    },
    "ContractorsBasic": {
      "contractorsList": "ContractorBasic"
    },
    "Country": {
      "created_at": "DateTime",
      "updated_at": "DateTime"
    },
    "CreditAccount": {
      "created_at": "DateTime",
      "team": "Team",
      "updated_at": "DateTime"
    },
    "CurrencyExchangeRate": {
      "created_at": "DateTime",
      "currency": "Currency",
      "updated_at": "DateTime",
      "valid_from": "Date",
      "valid_to": "Date"
    },
    "CurrencyExchangeRateStructured": {
      "currency": "Currency",
      "exchangeRates": "CurrencyExchangeRate"
    },
    "DateIntegerDataPoint": {
      "date": "Date"
    },
    "Expense": {
      "contract": "Contract",
      "contractor": "Contractor",
      "country": "Country",
      "currency": "Currency",
      "date_of_supply": "Date",
      "defaultAccountingAccount": "AccountingAccount",
      "due_date": "Date",
      "expenseItems": "ExpenseItem",
      "expenseItemsStructured": "StructuredExpenseItemsItem",
      "expenseVatItems": "ExpenseVatItem",
      "expense_documents": "AssignableFile",
      "issue_date": "Date",
      "lockedDocument": "LockDocument",
      "paid_at": "Date",
      "payment_type": "PaymentType",
      "projects": "Project",
      "stats": "ExpenseStats",
      "updated_at": "DateTime"
    },
    "ExpenseBudgetCategory": {
      "created_at": "DateTime",
      "deleted_at": "DateTime",
      "updated_at": "DateTime"
    },
    "ExpenseBudgetItem": {
      "category": "ExpenseBudgetCategory",
      "completed_at": "DateTime",
      "created_at": "DateTime",
      "creditAccount": "CreditAccount",
      "deleted_at": "DateTime",
      "expenseStats": "ExpenseBudgetItemExpenseStats",
      "invoiceStats": "ExpenseBudgetItemInvoiceStats",
      "prepaymentStats": "ExpenseBudgetItemPrepaymentStats",
      "project": "Project",
      "requested_for_deletion_at": "DateTime",
      "stats": "ExpenseBudgetItemStats",
      "updated_at": "DateTime"
    },
    "ExpenseBudgetItemExpenseStats": {
      "contractor": "Contractor",
      "date": "Date",
      "expense": "Expense"
    },
    "ExpenseBudgetItemInvoiceStats": {
      "expenseBudgetItem": "ExpenseBudgetItem",
      "outgoingInvoice": "OutgoingInvoice"
    },
    "ExpenseBudgetItemPrepaymentStats": {
      "brandCreditAccount": "BrandCreditAccount",
      "date": "Date",
      "expenseBudgetItem": "ExpenseBudgetItem"
    },
    "ExpenseBudgetItemSimple": {
      "category": "ExpenseBudgetCategory",
      "completed_at": "DateTime",
      "created_at": "DateTime",
      "deleted_at": "DateTime",
      "project": "ProjectSimple",
      "stats": "ExpenseBudgetItemStatsSimple",
      "updated_at": "DateTime"
    },
    "ExpenseBudgetItemWithRevenue": {
      "expenseBudgetItem": "ExpenseBudgetItem"
    },
    "ExpenseItem": {
      "accountingAccount": "AccountingAccount",
      "approvals": "ExpenseItemApproval",
      "budgetItem": [
        "ExpenseBudgetItem",
        "OurWorkBudgetItem"
      ],
      "positions": "ExpenseItemPosition",
      "vatRate": "VatRate"
    },
    "ExpenseItemApproval": {
      "created_at": "DateTime",
      "user": "User"
    },
    "ExpenseItemForProject": {
      "created_at": "Date"
    },
    "ExpenseItemPosition": {
      "report_date": "Date",
      "task": "Task"
    },
    "ExpensePaginator": {
      "data": "Expense",
      "paginatorInfo": "PaginatorInfo"
    },
    "ExpenseRevenueByCategory": {
      "category": "ExpenseBudgetCategory"
    },
    "ExpenseStructuredBudgetItem": {
      "budgetItems": "ExpenseBudgetItem"
    },
    "ExpenseStructuredBudgetItemWithRevenue": {
      "budgetItems": "ExpenseBudgetItemWithRevenue",
      "expenseBudgetCategory": "ExpenseBudgetCategory"
    },
    "FinanceSettings": {
      "bankAccounts": "BankAccountSetting"
    },
    "LockDocument": {
      "locked_to": "DateTime",
      "user": "User"
    },
    "MapQuestAddress": {
      "country": "Country"
    },
    "Mutation": {
      "UpdateCapacityAllocationForDay": "CapacityAllocation",
      "UpdateWorkspaceNotifications": "WorkspaceNotification",
      "addDocumentsToAccountMove": "BankAccountMove",
      "addManualAccountMove": "BankAccountMove",
      "addRowToWeekTimeReport": "WeeklyTimeReportRow",
      "addTaskFiles": "Task",
      "addTaskLink": "TaskLink",
      "archiveBrand": "Brand",
      "archiveProject": "Project",
      "archiveProjectsBulk": "BulkActionResponse",
      "archiveUser": "User",
      "bulkArchiveBrand": "BulkActionResponse",
      "bulkDeleteBrand": "BulkActionResponse",
      "changeRateCardOnProject": "Project",
      "cloneExpense": "Expense",
      "closeWip": "WorkInProgressProjectStat",
      "closeWipBulk": "BulkActionResponse",
      "completeCapacityAllocationItem": "CapacityAllocationItem",
      "completeExpenseBudgetItem": "ExpenseBudgetItem",
      "completeExpenseBudgetItemBulk": "ExpenseBudgetItem",
      "copyCapacityAllocationItem": "CapacityAllocationItem",
      "createAssignableFile": "AssignableFile",
      "createBillingEntity": "BillingEntity",
      "createBillingPlan": "BillingPlanByMonths",
      "createBrand": "Brand",
      "createBrandCreditAccount": "BrandCreditAccount",
      "createCapacityAllocation": "CapacityAllocation",
      "createClient": "Client",
      "createContact": "Contact",
      "createContract": "Contract",
      "createContractor": "Contractor",
      "createCurrencyExchangeRate": "CurrencyExchangeRate",
      "createEmailTemplate": "EmailTemplate",
      "createExchangeRateForWorkspace": "CurrencyExchangeRate",
      "createExpense": "Expense",
      "createExpenseBudgetItem": "ExpenseBudgetItem",
      "createExternalLink": "ExternalLink",
      "createFilterTemplate": "FilterTemplate",
      "createOrder": "Order",
      "createOtherDocument": "OtherDocument",
      "createOurWorkBudgetItem": "OurWorkBudgetItemOverview",
      "createOutgoingInvoice": "OutgoingInvoice",
      "createOutgoingProformaInvoice": "OutgoingProformaInvoice",
      "createProject": "Project",
      "createProjectCategory": "ProjectCategory",
      "createRequestForInvoicing": "RequestForInvoicing",
      "createSequence": "Sequence",
      "createTask": "Task",
      "createTaskComment": "TaskComment",
      "createTimeTrackingItem": "TimeTrackingItem",
      "createTimeTrackingWorkType": "TimeTrackingWorkType",
      "createUpdateExpenseContractor": "Expense",
      "createUser": "User",
      "createVatRate": "VatRate",
      "deleteAssignableFile": "BooleanResult",
      "deleteBillingEntity": "BillingEntity",
      "deleteBrand": "Brand",
      "deleteBrandCreditAccount": "BrandCreditAccount",
      "deleteCapacityAllocation": "CapacityAllocation",
      "deleteCapacityAllocationItem": "CapacityAllocationItem",
      "deleteClient": "Client",
      "deleteContact": "Contact",
      "deleteContract": "Contract",
      "deleteContractor": "Contractor",
      "deleteContractorsBulk": "BulkActionResponse",
      "deleteExchangeRateForWorkspace": "CurrencyExchangeRate",
      "deleteExpense": "Expense",
      "deleteOrder": "Order",
      "deleteOtherDocument": "OtherDocument",
      "deleteProject": "Project",
      "deleteProjectCategory": "ProjectCategory",
      "deleteProjectsBulk": "BulkActionResponse",
      "deleteRequestForInvoicing": "RequestForInvoicing",
      "deleteRequestsForInvoicingBulk": "BulkActionResponse",
      "deleteTask": "Task",
      "deleteTaskComment": "TaskComment",
      "deleteTaskLink": "TaskLink",
      "deleteTimeTrackingItem": "TimeTrackingItem",
      "deleteTimeTrackingWorkType": "TimeTrackingWorkType",
      "deleteUser": "User",
      "disableTwoFactorAuthentication": "User",
      "duplicateProject": "Project",
      "duplicateTask": "Task",
      "excludeBankAccountMove": "BankAccountMove",
      "findAddressForExpense": "MapQuestAddress",
      "finishProject": "Project",
      "invoiceProject": "RequestForInvoicing",
      "invoiceProjectByCurrentMonthBillingPlan": "RequestForInvoicing",
      "invoiceProjectsBulk": "RequestForInvoicing",
      "lastSeen": "Task",
      "lockDocument": "LockDocument",
      "lockWip": "WorkInProgressProjectStat",
      "lockWipBulk": "BulkActionResponse",
      "markExpenseAsPaid": "Expense",
      "markExpenseAsPaidBulk": "BulkActionResponse",
      "markNotificationAsRead": "Notification",
      "markNotificationAsUnread": "Notification",
      "markOutgoingInvoiceAsPaid": "OutgoingInvoice",
      "markOutgoingInvoiceAsPaidBulk": "BulkActionResponse",
      "markOutgoingProformaInvoiceAsPaid": "OutgoingProformaInvoice",
      "moveCapacityAllocationItem": "CapacityAllocationItem",
      "moveTimeTrackingItemsToTask": "BulkActionResponse",
      "moveTimeTrackingItemsToWorkType": "BulkActionResponse",
      "openWip": "WorkInProgressProjectStat",
      "openWipBulk": "BulkActionResponse",
      "pinProject": "Project",
      "pinProjectsBulk": "BulkActionResponse",
      "proformaInvoiceProject": "RequestForInvoicing",
      "redistributeProjectCredit": "Project",
      "removeRowFromWeekTimeReport": "WeeklyTimeReportRow",
      "removeTaskFiles": "Task",
      "reopenCapacityAllocationItem": "CapacityAllocationItem",
      "reopenExpenseBudgetItem": "ExpenseBudgetItem",
      "reopenProject": "Project",
      "reorderCapacityAllocationItems": "CapacityAllocationItem",
      "resetBillingPlanNotifications": "Project",
      "setUserProfilePlanningRanges": "UserProfile",
      "setUserProfileRates": "UserProfile",
      "stopTimerForCapacityAllocationItem": "CapacityAllocationItem",
      "submitProgress": "SubmitProgressResult",
      "submitProgressBulk": "OurWorkBudgetItem",
      "submitRequestForInvoicing": "RequestForInvoicing",
      "submitRequestsForInvoicingBulk": "BulkActionResponse",
      "toggleTaskCommentReaction": "TaskCommentReaction",
      "unarchiveBrand": "Brand",
      "unarchiveProject": "Project",
      "unarchiveProjectsBulk": "BulkActionResponse",
      "unarchiveUser": "User",
      "unlockDocument": "LockDocument",
      "unlockWip": "WorkInProgressProjectStat",
      "unlockWipBulk": "BulkActionResponse",
      "unpinProject": "Project",
      "unpinProjectsBulk": "BulkActionResponse",
      "updateBillingEntity": "BillingEntity",
      "updateBillingPlan": "BillingPlanByMonths",
      "updateBrand": "Brand",
      "updateBrandCreditAccount": "BrandCreditAccount",
      "updateCapacityAllocation": "CapacityAllocation",
      "updateCapacityAllocationItem": "CapacityAllocationItem",
      "updateClient": "Client",
      "updateCompanySettings": "CompanySettings",
      "updateContact": "Contact",
      "updateContract": "Contract",
      "updateContractor": "Contractor",
      "updateCurrencyExchangeRate": "CurrencyExchangeRate",
      "updateEmailTemplate": "EmailTemplate",
      "updateExchangeRateForWorkspace": "CurrencyExchangeRate",
      "updateExpense": "Expense",
      "updateExpenseBudgetItemNote": "ExpenseBudgetItem",
      "updateExternalLink": "ExternalLink",
      "updateFinanceSettings": "FinanceSettings",
      "updateNotificationSetting": "NotificationSetting",
      "updateNotificationSettings": "NotificationSettings",
      "updateOrder": "Order",
      "updateOtherDocument": "OtherDocument",
      "updateOurWorkBudgetItemNote": "OurWorkBudgetItem",
      "updateOurWorkBudgetItems": "OurWorkBudgetItem",
      "updateOutgoingInvoice": "OutgoingInvoice",
      "updateOutgoingInvoiceNumber": "OutgoingInvoice",
      "updateOutgoingProformaInvoice": "OutgoingProformaInvoice",
      "updatePassword": "User",
      "updatePaymentSettings": "NotificationSettings",
      "updatePlanningSettings": "WorkspaceSettings",
      "updateProfile": "User",
      "updateProject": "Project",
      "updateProjectBillingPlanBudget": "BillingPlanBudget",
      "updateProjectBillingPlanSettings": "Project",
      "updateProjectBudget": "Project",
      "updateProjectCategory": "ProjectCategory",
      "updateProjectSettings": "ProjectSettings",
      "updateRequestForInvoicing": "RequestForInvoicing",
      "updateSequence": "Sequence",
      "updateTask": "Task",
      "updateTaskComment": "TaskComment",
      "updateTaskPriority": "Task",
      "updateTaskStatus": "Task",
      "updateTimeTrackingItem": "TimeTrackingItem",
      "updateTimeTrackingItemsBillable": "BulkActionResponse",
      "updateTimeTrackingRatesForBrand": "Brand",
      "updateTimeTrackingRatesForProject": "Project",
      "updateTimeTrackingSettingsForProject": "Project",
      "updateTimeTrackingWorkType": "TimeTrackingWorkType",
      "updateUser": "User",
      "updateVatRate": "VatRate",
      "updateWorkspaceAccountingAccountSettings": "WorkspaceAccountingAccountSettings",
      "updateWorkspaceCompanies": "WorkspaceCompanySetting",
      "updateWorkspaceFinancialSettings": "WorkspaceFinancialSetting",
      "updateWorkspacePlanningSettings": "WorkspacePlanningSettings",
      "updateWorkspaceProjectSettings": "WorkspaceProjectSettings",
      "updateWorkspaceTaskSettings": "WorkspaceTaskSettings",
      "updateWorkspaceUserManagementSettings": "WorkspaceUserManagementSetting"
    },
    "Notification": {
      "created_at": "DateTime",
      "read_at": "DateTime"
    },
    "NotificationPaginator": {
      "data": "Notification",
      "paginatorInfo": "PaginatorInfo"
    },
    "NotificationPerTask": {
      "notifications": "Notification",
      "task": "Task"
    },
    "Order": {
      "brand": "Brand",
      "created_at": "DateTime",
      "date_of_order": "Date",
      "files": "AssignableFile",
      "outgoingInvoices": "OutgoingInvoice",
      "pdfFiles": "AssignableFile",
      "projects": "Project",
      "updated_at": "DateTime",
      "urls": "MediaUrlList"
    },
    "OrderPaginator": {
      "data": "Order",
      "paginatorInfo": "PaginatorInfo"
    },
    "OtherDocument": {
      "brands": "Brand",
      "clients": "Client",
      "contractors": "Contractor",
      "created_at": "DateTime",
      "files": "AssignableFile",
      "pdfFiles": "AssignableFile",
      "projects": "Project",
      "updated_at": "DateTime",
      "urls": "MediaUrlList"
    },
    "OtherDocumentPaginator": {
      "data": "OtherDocument",
      "paginatorInfo": "PaginatorInfo"
    },
    "OtherPeopleTracked": {
      "user": "User"
    },
    "OurWorkBudgetCategory": {
      "created_at": "DateTime",
      "deleted_at": "DateTime",
      "updated_at": "DateTime"
    },
    "OurWorkBudgetItem": {
      "budget_positions": "BudgetPosition",
      "category": "OurWorkBudgetCategory",
      "completed_at": "DateTime",
      "created_at": "DateTime",
      "creditAccount": "CreditAccount",
      "currentWorkInProgressItemStat": "WorkInProgressItemStat",
      "deadline": "Date",
      "deleted_at": "DateTime",
      "expenseStats": "OurWorkBudgetItemExpenseStats",
      "invoiceStats": "OurWorkBudgetItemInvoiceStats",
      "lastWorkInProgressItemStat": "WorkInProgressItemStat",
      "prepaymentStats": "OurWorkBudgetItemPrepaymentStats",
      "project": "Project",
      "requested_for_deletion_at": "DateTime",
      "stats": "OurWorkBudgetItemStats",
      "tasks": "Task",
      "timeTrackingSettings": "TimeTrackingBudgetItem",
      "updated_at": "DateTime"
    },
    "OurWorkBudgetItemExpenseStats": {
      "expense": "Expense",
      "position": "TimeTrackingWorkType",
      "report_date": "Date",
      "task": "Task"
    },
    "OurWorkBudgetItemForPlanovac": {
      "completed_at": "DateTime",
      "created_at": "DateTime",
      "deleted_at": "DateTime",
      "timeTrackingSettings": "TimeTrackingBudgetItemForPlanovac",
      "updated_at": "DateTime"
    },
    "OurWorkBudgetItemInvoiceStats": {
      "ourWorkBudgetItem": "OurWorkBudgetItem",
      "outgoingInvoice": "OutgoingInvoice"
    },
    "OurWorkBudgetItemOverview": {
      "budget_positions": "BudgetPosition",
      "category": "OurWorkBudgetCategory",
      "completed_at": "DateTime",
      "created_at": "DateTime",
      "creditAccount": "CreditAccount",
      "currentWorkInProgressItemStat": "WorkInProgressItemStat",
      "deadline": "Date",
      "deleted_at": "DateTime",
      "expenseStats": "OurWorkBudgetItemExpenseStats",
      "invoiceStats": "OurWorkBudgetItemInvoiceStats",
      "lastWorkInProgressItemStat": "WorkInProgressItemStat",
      "prepaymentStats": "OurWorkBudgetItemPrepaymentStats",
      "project": "Project",
      "requested_for_deletion_at": "DateTime",
      "stats": "OurWorkBudgetItemStats",
      "tasks": "Task",
      "timeTrackingSettings": "TimeTrackingBudgetItem",
      "updated_at": "DateTime"
    },
    "OurWorkBudgetItemPrepaymentStats": {
      "brandCreditAccount": "BrandCreditAccount",
      "date": "Date",
      "ourWorkBudgetItem": "OurWorkBudgetItem"
    },
    "OurWorkBudgetItemSimple": {
      "category": "OurWorkBudgetCategory",
      "completed_at": "DateTime",
      "created_at": "DateTime",
      "deleted_at": "DateTime",
      "project": "ProjectSimple",
      "stats": "OurWorkBudgetItemStats",
      "tasks": "TaskSimple",
      "timeTrackingSettings": "TimeTrackingBudgetItem",
      "updated_at": "DateTime"
    },
    "OurWorkBudgetItemWithRevenue": {
      "ourWorkBudgetItem": "OurWorkBudgetItem"
    },
    "OurWorkOverviewBudgetItem": {
      "budgetItems": "OurWorkBudgetItemOverview"
    },
    "OurWorkStructuredBudgetItem": {
      "budgetItems": "OurWorkBudgetItem"
    },
    "OutgoingInvoice": {
      "billingEntity": "BillingEntity",
      "brands": "Brand",
      "budget_positions": "BudgetPosition",
      "client": "Client",
      "contact": "Contact",
      "created_at": "DateTime",
      "currency": "Currency",
      "date_of_supply": "Date",
      "due_date": "Date",
      "emailLogs": "OutgoingInvoiceEmailLog",
      "expenseBudgetSplits": "OutgoingInvoiceExpenseBudgetSplitItem",
      "filesToAttach": "AssignableFile",
      "invoiceItems": "OutgoingInvoiceItem",
      "invoicePdf": "MediaUrlList",
      "issue_date": "Date",
      "orders": "Order",
      "ourWorkBudgetSplits": "OutgoingInvoiceOurWorkBudgetSplitItem",
      "owner": "User",
      "paid_at": "Date",
      "prepaymentSplits": "OutgoingInvoicePrepaymentSplitItem",
      "proformaInvoicesPivot": "AttachedOutgoingProformaInvoicePivot",
      "projects": "Project",
      "relatesToOutgoingProformaInvoice": "OutgoingProformaInvoice",
      "sequence": "Sequence",
      "stats": "OutgoingInvoiceStats",
      "teams": "Team",
      "timeMaterialsInvoiceData": "TimeMaterialsInvoiceData",
      "updated_at": "DateTime"
    },
    "OutgoingInvoiceEmailLog": {
      "created_at": "DateTime",
      "recipientLog": "RecipientLog",
      "recipients": "Recipient",
      "sent_at": "DateTime"
    },
    "OutgoingInvoiceExpenseBudgetSplitItem": {
      "created_at": "DateTime",
      "updated_at": "DateTime"
    },
    "OutgoingInvoiceOurWorkBudgetSplitItem": {
      "created_at": "DateTime",
      "updated_at": "DateTime"
    },
    "OutgoingInvoicePaginator": {
      "data": "OutgoingInvoice",
      "paginatorInfo": "PaginatorInfo"
    },
    "OutgoingInvoicePrepaymentSplitItem": {
      "brandCreditAccount": "BrandCreditAccount",
      "created_at": "DateTime",
      "updated_at": "DateTime"
    },
    "OutgoingProformaInvoice": {
      "billingEntity": "BillingEntity",
      "brands": "Brand",
      "client": "Client",
      "contact": "Contact",
      "created_at": "DateTime",
      "currency": "Currency",
      "due_date": "Date",
      "emailLogs": "OutgoingInvoiceEmailLog",
      "filesToAttach": "AssignableFile",
      "invoiceItems": "OutgoingInvoiceItem",
      "invoicePdf": "MediaUrlList",
      "issue_date": "Date",
      "owner": "User",
      "paid_at": "Date",
      "projects": "Project",
      "proofOfPayment": "OutgoingInvoice",
      "stats": "OutgoingProformaInvoiceStats",
      "teams": "Team",
      "updated_at": "DateTime"
    },
    "OutgoingProformaInvoicePaginator": {
      "data": "OutgoingProformaInvoice",
      "paginatorInfo": "PaginatorInfo"
    },
    "PlanningDailyViewCapacityAllocationUserItem": {
      "item": "CapacityAllocationItem",
      "stats": "CapacityAllocationUserItemStats",
      "timeTrackingWorkType": "TimeTrackingWorkType",
      "user": "User"
    },
    "PlanningDailyViewPaginator": {
      "data": "PlanningDailyViewUserByDay",
      "last_updated_at": "DateTime",
      "paginatorInfo": "PaginatorInfo"
    },
    "PlanningDailyViewTimeOff": {
      "createdBy": "User",
      "created_at": "DateTime",
      "from_date": "Date",
      "from_time": "Time",
      "repetition_settings": "RepetitionSettings",
      "teams": "TeamAttendee",
      "timeOffType": "TimeOffType",
      "to_date": "Date",
      "to_time": "Time"
    },
    "PlanningDailyViewTimeOffItem": {
      "assignees": "TimeOffAssignee",
      "date": "Date",
      "from_time": "Time",
      "timeOff": "PlanningDailyViewTimeOff",
      "timeOffType": "TimeOffType",
      "to_time": "Time"
    },
    "PlanningDailyViewUnassignedTasksPerDay": {
      "afterDeadline": "Task",
      "date": "Date",
      "other": "Task"
    },
    "PlanningDailyViewUserByDay": {
      "items": "PlanningDailyViewUserDay",
      "user": "User"
    },
    "PlanningDailyViewUserDay": {
      "date": "Date",
      "items": "CapacityAllocationItem"
    },
    "PositionSpent": {
      "timeTrackingWorkType": "TimeTrackingWorkType"
    },
    "Project": {
      "archivedBy": "User",
      "archived_at": "DateTime",
      "availableTimeTrackingWorkTypes": "TimeTrackingWorkType",
      "billingPlanByMonths": "BillingPlanByMonths",
      "billingPlanItems": "BillingPlanItem",
      "billingPlanNotifications": "BillingPlanNotifications",
      "billingPlanSetting": "ProjectBillingPlanSetting",
      "brand": "Brand",
      "budgetovacBudget": "ProjectBudgetPivot",
      "categories": "ProjectCategory",
      "contact": "Contact",
      "contracts": "ContractPaginator",
      "created_at": "DateTime",
      "currentMonthBillingPlanStats": "BillingPlanStats",
      "currentWorkInProgressProjectStat": "WorkInProgressProjectStat",
      "expected_end_date": "Date",
      "expenses": "ExpenseBudgetItem",
      "expensesStructured": "ExpenseStructuredBudgetItem",
      "expensesWithoutOther": "ExpenseBudgetItem",
      "expensesWithoutOtherStructured": "ExpenseStructuredBudgetItem",
      "externalLinks": "ExternalLink",
      "finishedBy": "User",
      "finished_at": "DateTime",
      "invoiceStats": "ProjectInvoiceStat",
      "lastInvoice": "ProjectInvoiceStat",
      "lastWorkInProgressProjectStat": "WorkInProgressProjectStat",
      "lowestPossibleDateForStartDateChange": "DateTime",
      "orders": "OrderPaginator",
      "ordersSimpleMap": "OrderSimple",
      "otherDocuments": "OtherDocumentPaginator",
      "otherProjectManagers": "User",
      "ourWork": "OurWorkBudgetItem",
      "ourWorkOverview": "OurWorkOverviewBudgetItem",
      "ourWorkStructured": "OurWorkStructuredBudgetItem",
      "prepaymentStats": "ProjectPrepaymentStat",
      "proformaInvoices": "OutgoingProformaInvoice",
      "projectManager": "User",
      "start_date": "Date",
      "stats": "ProjectStats",
      "statsCached": "ProjectStats",
      "team": "Team",
      "timeTrackingRates": "TimeTrackingRate",
      "updated_at": "DateTime"
    },
    "ProjectBillingPlanSetting": {
      "email_template": "ProjectBillingPlanEmailTemplate",
      "from": "Date",
      "to": "Date"
    },
    "ProjectDatesForBudgetChange": {
      "defaultDateForBudgetChange": "Date",
      "lowestPossibleDateForBudgetChange": "Date"
    },
    "ProjectFinishSummary": {
      "incomplete_expense_budget_items": "ExpenseBudgetItem",
      "incomplete_our_work_budget_items": "OurWorkBudgetItem",
      "overinvoiced_expense_budget_items": "ExpenseBudgetItem",
      "overinvoiced_our_work_budget_items": "OurWorkBudgetItem",
      "underinvoiced_expense_budget_items": "ExpenseBudgetItem",
      "underinvoiced_our_work_budget_items": "OurWorkBudgetItem"
    },
    "ProjectForExpense": {
      "brand": "BrandSimple",
      "expenses": "ExpenseBudgetItemSimple",
      "finished_at": "DateTime",
      "ourWorkBudgetItems": "OurWorkBudgetItemSimple"
    },
    "ProjectForPlanovac": {
      "archived_at": "DateTime",
      "availableTimeTrackingWorkTypes": "TimeTrackingWorkType",
      "brand": "BrandSimple",
      "created_at": "DateTime",
      "deleted_at": "DateTime",
      "finished_at": "DateTime",
      "ourWork": "OurWorkBudgetItem",
      "updated_at": "DateTime"
    },
    "ProjectForPlanovacPaginator": {
      "data": "ProjectForPlanovac",
      "paginatorInfo": "PaginatorInfo"
    },
    "ProjectForTask": {
      "availableTimeTrackingWorkTypes": "TimeTrackingWorkType",
      "expected_end_date": "Date",
      "ourWork": "OurWorkBudgetItemSimple",
      "start_date": "Date"
    },
    "ProjectGraph": {
      "spentBudgetData": "DateIntegerDataPoint",
      "totalBudgetData": "DateIntegerDataPoint",
      "wipData": "DateIntegerDataPoint"
    },
    "ProjectInvoiceStat": {
      "outgoingInvoice": "OutgoingInvoice",
      "outgoing_invoice_date": "Date"
    },
    "ProjectPaginator": {
      "data": "Project",
      "paginatorInfo": "PaginatorInfo"
    },
    "ProjectPrepaymentStat": {
      "brandCreditAccount": "BrandCreditAccount",
      "date": "Date",
      "expenseBudgetItem": "ExpenseBudgetItem",
      "ourWorkBudgetItem": "OurWorkBudgetItem"
    },
    "ProjectRevenue": {
      "paginatorInfo": "PaginatorInfo",
      "projects": "ProjectWithRevenueItem"
    },
    "ProjectRevenueSummary": {
      "brand_profit_share": "BrandRevenueShare",
      "brand_revenue_share": "BrandRevenueShare",
      "client_revenue_share": "BrandRevenueShare",
      "expense_revenue_for_date_range": "ExpenseRevenueByCategory",
      "profit_top_projects": "TopProject",
      "revenue_top_projects": "TopProject"
    },
    "ProjectSimple": {
      "archived_at": "DateTime",
      "availableTimeTrackingWorkTypes": "TimeTrackingWorkType",
      "brand": "BrandSimple",
      "created_at": "DateTime",
      "deleted_at": "DateTime",
      "finished_at": "DateTime",
      "updated_at": "DateTime"
    },
    "ProjectSimplePaginator": {
      "data": "ProjectSimple",
      "paginatorInfo": "PaginatorInfo"
    },
    "ProjectStats": {
      "expenses": "ProjectExpenseStats",
      "ourWork": "ProjectOurWorkStats"
    },
    "ProjectWithRevenue": {
      "archived_at": "DateTime",
      "brand": "Brand",
      "expensesStructured": "ExpenseStructuredBudgetItemWithRevenue",
      "expenses_billings_for_date_range": "ProjectRevenueBillingsByExpenseCategory",
      "expenses_profit_for_date_range": "ProjectRevenueProfitByExpenseCategory",
      "finished_at": "DateTime",
      "ourWork": "OurWorkBudgetItemWithRevenue",
      "projectManager": "User"
    },
    "ProjectWithRevenueItem": {
      "archived_at": "DateTime",
      "brand": "Brand",
      "expenses_billings_for_date_range": "ProjectRevenueBillingsByExpenseCategory",
      "expenses_profit_for_date_range": "ProjectRevenueProfitByExpenseCategory",
      "finished_at": "DateTime",
      "ourWork": "OurWorkBudgetItemWithRevenue",
      "projectManager": "User"
    },
    "Query": {
      "EmailTemplates": "EmailTemplate",
      "WorkspaceNotifications": "WorkspaceNotification",
      "accountingAccountsSimpleMap": "AccountingAccount",
      "availableTaskPositionsHoursByOurWorkBudget": "TaskPositionHour",
      "bankAccountMoves": "BankAccountMovePaginator",
      "bankAccounts": "BankAccount",
      "billingPlanBudget": "BillingPlanBudget",
      "billingPlanOverview": "BillingPlanMonthStatPaginator",
      "billingPlanOverviewSummary": "BillingPlanOverviewSummary",
      "brand": "Brand",
      "brandCreditAccount": "BrandCreditAccount",
      "brandCreditAccounts": "BrandCreditAccountPaginator",
      "brandCreditAccountsSummary": "BrandCreditAccountsSummary",
      "brandRevenue": "BrandRevenue",
      "brandRevenueSummary": "BrandRevenueSummary",
      "brands": "BrandPaginator",
      "brandsForClient": "Brand",
      "brandsSimpleMap": "BrandSimple",
      "brandsSummary": "BrandsSummary",
      "budgets": "BudgetPaginator",
      "bulkConvertAmountFromWorkspaceCurrencyUsingUserExchangeRates": "ConvertedAmountWithIdentifier",
      "bulkConvertAmountToWorkspaceCurrencyUsingUserExchangeRates": "ConvertedAmountWithIdentifier",
      "capacityAllocationDetail": "CapacityAllocation",
      "capacityAllocationItemDetailQuery": "CapacityAllocationItem",
      "capacityAllocationItemList": "CapacityAllocationItem",
      "capacityAllocationItemTimeTrackingItems": "TimeTrackingItem",
      "client": "Client",
      "clientExchangeRatesForBudgetovac": "CurrencyExchangeRate",
      "clients": "ClientPaginator",
      "clientsSimpleMap": "ClientSimple",
      "commentHistory": "TaskComment",
      "companySettings": "CompanySettings",
      "contacts": "Contact",
      "contract": "Contract",
      "contractor": "Contractor",
      "contractors": "ContractorPaginator",
      "contractorsBasic": "ContractorsBasic",
      "contractorsSimpleMap": "ContractorSimple",
      "contractorsSummary": "ContractorsSummary",
      "contracts": "ContractPaginator",
      "contractsSimpleMap": "ContractSimple",
      "contractsSummary": "ContractsSummary",
      "convertAmountToWorkspaceCurrencyUsingExchangeRate": "ConvertedAmount",
      "countrySimpleMap": "CountrySimple",
      "creditAccount": "CreditAccount",
      "currencies": "Currency",
      "currency": "Currency",
      "defaultCurrencies": "DefaultCurrency",
      "expense": "Expense",
      "expenseBudgetCategories": "ExpenseBudgetCategory",
      "expenseBudgetItem": "ExpenseBudgetItem",
      "expenseBudgetItemsByItemCode": "ExpenseBudgetItemSimple",
      "expenseBudgetItemsSimpleMap": "ExpenseBudgetItemSimple",
      "expenseBudgetItemsWithStatus": "ExpenseBudgetItemWithStatus",
      "expenseItemsForProject": "ExpenseItemForProject",
      "expenses": "ExpensePaginator",
      "expensesSummary": "ExpensesSummary",
      "externalLinks": "ExternalLink",
      "filterTemplates": "FilterTemplate",
      "financeSettings": "FinanceSettings",
      "fondContractorsSimpleMap": "FondContractorSimple",
      "generateExpensePaymentOrder": "ExpensePaymentOrderResponse",
      "getAutocompleteForCompanyData": "AutocompleteCompanyData",
      "getAvailableProjectsForBulkMovingTimeTrackingItems": "ProjectSimple",
      "getAvailableWorkTypesForBulkMovingTimeTrackingItems": "TimeTrackingWorkType",
      "getCompanyDataByRegistrationNo": "CompanyData",
      "getInvoiceEmailData": "InvoiceEmailData",
      "getLatestExpenseInternalNumberPerType": "LatestExpenseInternalNumberPerType",
      "getProformaInvoiceEmailData": "InvoiceEmailData",
      "getProjectCanBeFinishedSummary": "ProjectFinishSummary",
      "lowestDateWithOpenProgress": "Date",
      "me": "User",
      "notificationSettings": "NotificationSetting",
      "notificationWorkspaceSettings": "NotificationSettings",
      "notifications": "NotificationPaginator",
      "order": "Order",
      "orders": "OrderPaginator",
      "ordersSummary": "OrdersSummary",
      "ordersWithPdf": "Order",
      "otherDocument": "OtherDocument",
      "otherDocuments": "OtherDocumentPaginator",
      "otherDocumentsSummary": "OtherDocumentsSummary",
      "otherDocumentsWithPdf": "OtherDocument",
      "ourWorkBudgetCategories": "OurWorkBudgetCategory",
      "ourWorkBudgetItem": "OurWorkBudgetItem",
      "ourWorkBudgetItemsByItemCode": "OurWorkBudgetItemSimple",
      "ourWorkBudgetItemsSimpleMap": "OurWorkBudgetItemSimple",
      "outgoingInvoice": "OutgoingInvoice",
      "outgoingInvoices": "OutgoingInvoicePaginator",
      "outgoingInvoicesSummary": "OutgoingInvoicesSummary",
      "outgoingProformaInvoice": "OutgoingProformaInvoice",
      "outgoingProformaInvoices": "OutgoingProformaInvoicePaginator",
      "outgoingProformaInvoicesSummary": "OutgoingProformaInvoicesSummary",
      "paymentSettings": "PaymentSettings",
      "paymentTypes": "PaymentType",
      "planningDailyViewPeople": "PlanningDailyViewPaginator",
      "planningDailyViewUnassignedTasks": "PlanningDailyViewUnassignedTasksPerDay",
      "project": "Project",
      "projectCategories": "ProjectCategory",
      "projectDatesForBudgetChange": "ProjectDatesForBudgetChange",
      "projectForExpense": "ProjectForExpense",
      "projectForTask": "ProjectForTask",
      "projectGraph": "ProjectGraph",
      "projectHasTimeTrackedForPositions": "TimeTrackingWorkType",
      "projectManagerSimpleMap": "UserSimple",
      "projectRateCard": "ProjectRateCard",
      "projectRevenue": "ProjectRevenue",
      "projectRevenueDetail": "ProjectWithRevenue",
      "projectRevenueSummary": "ProjectRevenueSummary",
      "projectSettings": "ProjectSettings",
      "projects": "ProjectPaginator",
      "projectsAll": "ProjectSimplePaginator",
      "projectsForPlanovac": "ProjectForPlanovacPaginator",
      "projectsSimpleMap": "ProjectSimple",
      "projectsSummary": "ProjectsSummary",
      "projectsWithPeriods": "WorkInProgressProjectStatPaginator",
      "requestForInvoicing": "RequestForInvoicing",
      "requestsForInvoicing": "RequestForInvoicingPaginator",
      "requestsForInvoicingSummary": "RequestsForInvoicingSummary",
      "responsiblePersonsSimpleMap": "UserSimple",
      "roles": "Role",
      "runningTimer": "Timer",
      "sequenceSettings": "Sequence",
      "task": "Task",
      "taskAndCommunicationNotifications": "TaskAndCommunicationNotificationsPaginator",
      "taskComments": "TaskCommentPaginator",
      "taskLinkTypes": "TaskLinkType",
      "taskPositionStat": "TaskPositionStat",
      "taskPriorities": "TaskPriority",
      "taskStatuses": "TaskStatus",
      "tasks": "TaskPaginator",
      "tasksSimpleMap": "TaskSimple",
      "teamSimpleMap": "Team",
      "timeMaterialsInvoiceData": "TimeMaterialsDataForInvoicing",
      "timeOffTypesQuery": "TimeOffType",
      "timeTrackingItem": "TimeTrackingItem",
      "timeTrackingItems": "TimeTrackingItem",
      "timeTrackingItemsPaginated": "TimeTrackingItemPaginator",
      "timeTrackingOptionsForProject": "TimeTrackingOptionsForProject",
      "timeTrackingProjectReport": "TimeTrackingProjectReport",
      "timeTrackingReport": "TimeTrackingReportPaginator",
      "timeTrackingReportSummary": "TimeTrackingReportSummary",
      "timeTrackingSummaryPerDay": "TimeTrackingSummaryPerDay",
      "timeTrackingWorkTypes": "TimeTrackingWorkType",
      "unusedPaidOutgoingProformaInvoices": "OutgoingProformaInvoice",
      "updateWorkspacePlanningSettings": "WorkspacePlanningSettings",
      "user": "User",
      "userMonthlyReport": "UserMonthlyReportPaginator",
      "userSimpleMap": "UserSimple",
      "users": "UserPaginator",
      "usersForPlanovac": "UserForPlanovac",
      "usersSummary": "UsersSummary",
      "utilizationReport": "UtilizationReportPaginator",
      "utilizationReportGroups": "UtilizationReportGroupPaginator",
      "utilizationReportSummary": "UtilizationReport",
      "utilizationTaskReport": "UtilizationTaskReport",
      "utilizationUserReport": "UtilizationUserReport",
      "vatRates": "VatRate",
      "verifyAccessTokenFromThirdParty": "SingleSignOnData",
      "weekTimeReport": "WeekTimeReport",
      "wipForBudgetAndPeriod": "WorkInProgressItemStat",
      "wipPeriodsMap": "WipPeriod",
      "wipProjectsPeriodSummary": "PeriodSummary",
      "workspaceAccountingAccountSetting": "WorkspaceAccountingAccountSettings",
      "workspaceCompaniesSettings": "WorkspaceCompanySetting",
      "workspaceExchangeRatesForBudgetovac": "CurrencyExchangeRate",
      "workspaceFinancialSettings": "WorkspaceFinancialSetting",
      "workspaceProjectSettings": "WorkspaceProjectSettings",
      "workspaceSettings": "WorkspaceSettings",
      "workspaceTaskSettings": "WorkspaceTaskSettings",
      "workspaceUserManagementSetting": "WorkspaceUserManagementSetting"
    },
    "RequestForInvoicing": {
      "billingEntity": "BillingEntity",
      "brands": "Brand",
      "budget_positions": "BudgetPosition",
      "client": "Client",
      "contact": "Contact",
      "created_at": "DateTime",
      "currency": "Currency",
      "date_of_supply": "Date",
      "due_date": "Date",
      "expenseBudgetSplits": "RequestForInvoicingExpenseBudgetSplitItem",
      "filesToAttach": "AssignableFile",
      "invoiceItems": "OutgoingInvoiceItem",
      "issue_date": "Date",
      "orders": "Order",
      "ourWorkBudgetSplits": "RequestForInvoicingOurWorkBudgetSplitItem",
      "owner": "User",
      "prepaymentSplits": "RequestForInvoicingPrepaymentSplitItem",
      "proformaInvoices": "OutgoingProformaInvoice",
      "projects": "Project",
      "stats": "RequestForInvoicingStats",
      "teams": "Team",
      "timeMaterialsInvoiceData": "TimeMaterialsInvoiceData",
      "updated_at": "DateTime"
    },
    "RequestForInvoicingExpenseBudgetSplitItem": {
      "created_at": "DateTime",
      "updated_at": "DateTime"
    },
    "RequestForInvoicingOurWorkBudgetSplitItem": {
      "created_at": "DateTime",
      "updated_at": "DateTime"
    },
    "RequestForInvoicingPaginator": {
      "data": "RequestForInvoicing",
      "paginatorInfo": "PaginatorInfo"
    },
    "RequestForInvoicingPrepaymentSplitItem": {
      "created_at": "DateTime",
      "updated_at": "DateTime"
    },
    "StructuredExpenseItemsItem": {
      "brand": "Brand",
      "expense_items": "ExpenseItem"
    },
    "SubmitProgressResult": {
      "changedWorkInProgressItemStats": "WorkInProgressItemStat",
      "lastWorkInProgressProjectStat": "WorkInProgressProjectStat"
    },
    "Task": {
      "commentFiles": "AssignableFile",
      "createdBy": "User",
      "created_at": "DateTime",
      "deadline": "DateTime",
      "description": "TaskDescription",
      "files": "AssignableFile",
      "from": "Date",
      "linkedTasks": "TaskLink",
      "otherPeopleTracked": "OtherPeopleTracked",
      "ourWorkBudgetItem": "OurWorkBudgetItem",
      "positions": "TaskPosition",
      "positionsSpent": "PositionSpent",
      "priority": "TaskPriority",
      "stats": "TaskStats",
      "status": "TaskStatus",
      "teams": "Team",
      "timeTrackingItems": "TimeTrackingItem",
      "to": "Date",
      "updated_at": "DateTime"
    },
    "TaskAndCommunicationNotificationsPaginator": {
      "data": "NotificationPerTask",
      "paginatorInfo": "PaginatorInfo"
    },
    "TaskComment": {
      "created_at": "DateTime",
      "files": "AssignableFile",
      "in_reply_to": "TaskComment",
      "reactions": "TaskCommentReaction",
      "task": "Task",
      "timestamp": "DateTime",
      "user": "User"
    },
    "TaskCommentPaginator": {
      "data": "TaskComment",
      "paginatorInfo": "PaginatorInfo"
    },
    "TaskCommentReaction": {
      "user": "User"
    },
    "TaskLink": {
      "link_type": "TaskLinkType",
      "linked_task": "Task"
    },
    "TaskPaginator": {
      "data": "Task",
      "paginatorInfo": "PaginatorInfo"
    },
    "TaskPosition": {
      "timeTrackingWorkType": "TimeTrackingWorkType",
      "user": "User"
    },
    "TaskPositionStat": {
      "timeTrackingWorkType": "TimeTrackingWorkType",
      "user": "User"
    },
    "TaskSimple": {
      "created_at": "DateTime",
      "deadline": "DateTime",
      "from": "Date",
      "positions": "TaskPosition",
      "priority": "TaskPriority",
      "status": "TaskStatus",
      "to": "Date",
      "updated_at": "DateTime"
    },
    "Team": {
      "deleted_at": "DateTime"
    },
    "TeamAttendee": {
      "team": "Team"
    },
    "TimeMaterialsBudgetItems": {
      "data": "TimeMaterialsBudgetItemInvoiceData"
    },
    "TimeMaterialsDataForInvoicing": {
      "expenseBudgetItems": "TimeMaterialsBudgetItems",
      "ourWorkBudgetItems": "TimeMaterialsBudgetItems",
      "project": "Project"
    },
    "TimeMaterialsInvoiceData": {
      "commission_data": "TimeMaterialsCommissionData",
      "from": "Date",
      "to": "Date"
    },
    "TimeOffAssignee": {
      "user": "User"
    },
    "TimeTrackingBudgetItem": {
      "ourWorkBudgetItem": "OurWorkBudgetItem",
      "timeTrackingItems": "TimeTrackingItem",
      "tracking_enabled_from": "Date",
      "tracking_enabled_to": "Date"
    },
    "TimeTrackingBudgetItemForPlanovac": {
      "tracking_enabled_from": "Date",
      "tracking_enabled_to": "Date"
    },
    "TimeTrackingBudgetItemSimpleMap": {
      "available_time_tracking_work_types": "TimeTrackingWorkType"
    },
    "TimeTrackingItem": {
      "capacityAllocationItem": "CapacityAllocationItem",
      "created_at": "DateTime",
      "deleted_at": "DateTime",
      "project": "Project",
      "task": "Task",
      "timeTrackingBudgetItem": "TimeTrackingBudgetItem",
      "timeTrackingWorkType": "TimeTrackingWorkType",
      "tracked_for_date": "Date",
      "updated_at": "DateTime",
      "user": "User"
    },
    "TimeTrackingItemPaginator": {
      "data": "TimeTrackingItem",
      "paginatorInfo": "PaginatorInfo"
    },
    "TimeTrackingOptionsForProject": {
      "availableTimeTrackingWorkTypes": "TimeTrackingWorkType",
      "ourWork": "OurWorkBudgetItemSimple"
    },
    "TimeTrackingRate": {
      "timeTrackingWorkType": "TimeTrackingWorkType"
    },
    "TimeTrackingReport": {
      "tracked_for_date": "Date"
    },
    "TimeTrackingReportGroup": {
      "items": "TimeTrackingReport"
    },
    "TimeTrackingReportPaginator": {
      "data": "TimeTrackingReportGroup",
      "paginatorInfo": "PaginatorInfo"
    },
    "TimeTrackingSummaryPerDay": {
      "date": "Date"
    },
    "Timer": {
      "ends_at": "DateTime",
      "starts_at": "DateTime",
      "timeTrackingItem": "TimeTrackingItem"
    },
    "TopClient": {
      "brands": "TopBrand"
    },
    "TopProject": {
      "expense_budget_categories": "ExpenseBudgetCategoryTopProject",
      "our_work": "OurWorkTopProject"
    },
    "User": {
      "archived_at": "DateTime",
      "created_at": "DateTime",
      "image": "AssignableFile",
      "permissions": "PermissionList",
      "profile": "UserProfile",
      "roles": "Role",
      "team": "Team",
      "updated_at": "DateTime"
    },
    "UserForPlanovac": {
      "archived_at": "DateTime",
      "created_at": "DateTime",
      "deleted_at": "DateTime",
      "image": "AssignableFile",
      "profile": "UserProfile",
      "updated_at": "DateTime"
    },
    "UserMonthlyReport": {
      "tracked_time_per_team": "UserMonthlyReportPerTeam"
    },
    "UserMonthlyReportPaginator": {
      "data": "UserMonthlyReport",
      "paginatorInfo": "PaginatorInfo",
      "teams": "Team"
    },
    "UserPaginator": {
      "data": "User",
      "paginatorInfo": "PaginatorInfo"
    },
    "UserProfile": {
      "defaultWorktype": "TimeTrackingWorkType",
      "planningRanges": "UserProfilePlanningRange",
      "rates": "UserProfileRate"
    },
    "UserProfilePlanningRange": {
      "type": "PlanningRangeType",
      "valid_from": "Date",
      "valid_to": "Date",
      "weekly_capacities": "WeeklyCapacities"
    },
    "UserProfileRate": {
      "valid_from": "Date",
      "valid_to": "Date"
    },
    "UserSimple": {
      "archived_at": "DateTime",
      "created_at": "DateTime",
      "deleted_at": "DateTime",
      "image": "AssignableFile",
      "profile": "UserProfile",
      "updated_at": "DateTime"
    },
    "UtilizationReportGroup": {
      "items": "UtilizationUserReport",
      "report": "UtilizationReport"
    },
    "UtilizationReportGroupPaginator": {
      "data": "UtilizationReportGroup",
      "paginatorInfo": "PaginatorInfo"
    },
    "UtilizationReportPaginator": {
      "data": "UtilizationUserReport",
      "paginatorInfo": "PaginatorInfo"
    },
    "UtilizationTaskReport": {
      "task": "Task"
    },
    "UtilizationUserReport": {
      "report": "UtilizationReport",
      "user": "User"
    },
    "WeekTimeReport": {
      "dailyTotals": "WeeklyTimeReportTotalsPerDay",
      "rows": "WeeklyTimeReportRow",
      "suggestions": "Task"
    },
    "WeeklyTimeReportRow": {
      "created_at": "DateTime",
      "task": "Task",
      "timeTrackingWorkType": "TimeTrackingWorkType",
      "trackedTime": "WeeklyTimeReportRowDay"
    },
    "WeeklyTimeReportRowDay": {
      "date": "Date"
    },
    "WeeklyTimeReportTotalsPerDay": {
      "date": "Date"
    },
    "WipPeriod": {
      "ends_at": "Date",
      "starts_at": "Date"
    },
    "WorkInProgressItemStat": {
      "period": "WipPeriod",
      "project": "Project"
    },
    "WorkInProgressProjectStat": {
      "budgetItemsStats": "WorkInProgressItemStat",
      "period": "WipPeriod",
      "project": "Project"
    },
    "WorkInProgressProjectStatPaginator": {
      "data": "WorkInProgressProjectStat",
      "paginatorInfo": "PaginatorInfo"
    },
    "WorkspaceAccountingAccount": {
      "budget_categories": "AccountingAccountBudgetCategory"
    },
    "WorkspaceAccountingAccountSettings": {
      "accounting_accounts": "WorkspaceAccountingAccount"
    },
    "WorkspaceCompany": {
      "bankAccounts": "WorkspaceBankAccount",
      "country": "Country",
      "invoicePdfSettings": "WorkspaceCompanyInvoicePdfSettings"
    },
    "WorkspaceCompanySetting": {
      "bank_accounts": "WorkspaceBankAccount",
      "pdfSettings": "WorkspaceInvoicePdfSettings",
      "sequences": "WorkspaceSequence"
    },
    "WorkspaceFinancialSetting": {
      "currencies": "Currency",
      "vat_rates": "VatRate"
    },
    "WorkspaceInvoicePdfSettings": {
      "logo_assignable_file": "AssignableFile",
      "stamp_assignable_file": "AssignableFile"
    },
    "WorkspacePlanningSettings": {
      "working_from": "Time",
      "working_to": "Time"
    },
    "WorkspaceSettings": {
      "budget_pdf_export_settings": "BudgetPdfSettings",
      "companies": "WorkspaceCompany",
      "country": "Country",
      "currency": "Currency",
      "currencyExchangeRatesStructured": "CurrencyExchangeRateStructured",
      "defaultVatRate": "VatRate",
      "exportSettings": "ExportSettings",
      "moduleSettings": "ModuleSettings",
      "planning_view_settings": "PlanningViewSetting",
      "trial_from": "Date",
      "working_from": "Time",
      "working_to": "Time"
    },
    "WorkspaceTaskSettings": {
      "task_priorities": "WorkspaceTaskPriority",
      "task_statuses": "WorkspaceTaskStatus"
    },
    "WorkspaceUserManagementSetting": {
      "teams": "Team"
    }
  },
  "operationMap": {},
  "scalars": [
    "Time",
    "Date",
    "DateTime"
  ]
};
