import { TaskQuery, User } from "@src/__generated__/urql-graphql";
import { compact } from "lodash";
import { action, makeObservable, observable } from "mobx";

type TTask = TaskQuery["task"];
type TTaskPositionStat = TaskQuery["taskPositionStat"];

export class TaskModel {
  id: TTask["id"];
  name: TTask["name"];
  @observable status: TTask["status"];
  deadline: TTask["deadline"];
  cloudUrl: TTask["cloudUrl"];
  from?: Date;
  to?: Date;
  @observable.ref description: TTask["description"];
  positionsStats: TTaskPositionStat;
  positions: TTask["positions"];
  priority: TTask["priority"];
  stats: TTask["stats"];
  ourWorkBudgetItem: TTask["ourWorkBudgetItem"];
  @observable linkedTasks: TTask["linkedTasks"];
  @observable files: TTask["files"];
  commentFiles: TTask["commentFiles"];
  revision: TTask["revision"];
  createdBy: TTask["createdBy"];
  otherPeopleTracked: TTask["otherPeopleTracked"];
  attachments_download_url: TTask["attachments_download_url"];
  otherPeopleTrackedTotal: TTask["otherPeopleTrackedTotal"];
  @observable.ref billable: TTask["billable"];

  userIdsWithEditPermission: User["id"][];

  constructor(task: TTask, positions: TTaskPositionStat) {
    makeObservable(this);
    this.id = task.id;
    this.name = task.name;
    this.status = task.status;
    this.createdBy = task.createdBy;
    this.to = task.to ?? undefined;
    this.from = task.from ?? undefined;
    this.deadline = task.deadline;
    this.priority = task.priority;
    this.description = task.description;
    this.positionsStats = positions;
    this.positions = task.positions;
    this.stats = task.stats;
    this.ourWorkBudgetItem = task.ourWorkBudgetItem;
    this.linkedTasks = task.linkedTasks;
    this.files = task.files;
    this.cloudUrl = task.cloudUrl;
    this.commentFiles = task.commentFiles;
    this.revision = task.revision;
    this.otherPeopleTracked = task.otherPeopleTracked;
    this.attachments_download_url = task.attachments_download_url;
    this.otherPeopleTrackedTotal = task.otherPeopleTrackedTotal;
    this.billable = task.billable;
    this.userIdsWithEditPermission = compact([
      task.createdBy?.id,
      task.ourWorkBudgetItem?.project.projectManager.id,
      ...(task.ourWorkBudgetItem?.project.otherProjectManagers ?? []).map(
        ({ id }) => id,
      ),
    ]);
  }

  get deadlineDate() {
    return this.deadline ? new Date(this.deadline) : undefined;
  }

  @action.bound setDescription(value: TaskModel["description"]) {
    this.description = value;
  }

  @action.bound setFiles(files: TaskModel["files"]) {
    this.files = files;
  }

  @action.bound removeFile(publicId: TaskModel["files"][0]["public_id"]) {
    this.files = this.files.filter((i) => i.public_id !== publicId);
  }

  @action.bound setStatus(value: TaskModel["status"]) {
    this.status = value;
  }
}
