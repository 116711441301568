import {
  Box,
  Button,
  Collapse,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Icon } from "@src/components/ui-kit/Icon";
import cs from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC, ReactNode } from "react";
import { useToggle } from "react-use";

interface IPopoverAction {
  content: string;
  onAction: () => void;
}

interface PopoverActionsProps {
  actions: IPopoverAction[];
}

export interface ExpandableItemProps {
  title?: string;
  header?: ReactNode;
  popoverActions?: PopoverActionsProps["actions"];

  /** @default false */
  defaultExpanded?: boolean;

  noExpand?: boolean;

  /** Extends header className */
  headerClassName?: string;

  /** Override main class name */
  mainClassName?: string;
}

const ExpandableItem: FC<React.PropsWithChildren<ExpandableItemProps>> = ({
  title,
  header,
  popoverActions,
  defaultExpanded = false,
  noExpand,
  headerClassName,
  children,
}) => {
  const [expanded, toggleExpanded] = useToggle(defaultExpanded);

  return (
    <section>
      <HStack
        className={cs("flex items-center", headerClassName)}
        px="2"
        rounded="md"
      >
        <div
          className={cs(
            "flex items-center mr-auto",
            noExpand || "cursor-pointer",
          )}
          onClick={noExpand ? undefined : toggleExpanded}
        >
          {noExpand || (
            <span className="pl-2 pr-4">
              <Icon name={expanded ? "chevron-up" : "chevron-down"} />
            </span>
          )}
          <span className="text-turquoiseDark">{title}</span>
          {header}
        </div>

        {popoverActions && popoverActions.length !== 0 && (
          <Menu>
            <MenuButton
              as={Button}
              colorScheme="grey"
              rightIcon={<Icon name="dots-horizontal" />}
              variant="outline"
            />
            <MenuList>
              {popoverActions.map((action) => (
                <MenuItem
                  key={action.content}
                  onClick={() => action.onAction()}
                >
                  {action.content}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </HStack>

      {noExpand || (
        <Collapse animateOpacity in={expanded}>
          <Box p="12px" shadow="md" rounded="md">
            {children}
          </Box>
        </Collapse>
      )}
    </section>
  );
};

export default observer(ExpandableItem);
