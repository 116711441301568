import {
  Box,
  BoxProps,
  Center,
  Circle,
  Flex,
  HStack,
  IconButton,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import {
  AssignableFile,
  useDeleteAssignableFileMutation,
} from "@src/__generated__/graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import { ImageModal } from "@src/components/widgets/Modals/ImageModal";
import { VideoModal } from "@src/components/widgets/Modals/VideoModal";
import { onError } from "@src/utils/apollo";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const ATTACHMENT_VIEW_WIDTH = "200px";
export const ATTACHMENT_VIEW_HEIGHT = "150px";

type AttachmentViewProps = BoxProps & {
  id: string;
  name: string;
  urls: AssignableFile["urls"];
  mimeType: AssignableFile["mime_type"];
  removeDisabled?: boolean;
  onRemoved?: (id: string) => void;
  // TODO: we could add actions array to allow multiple custom actions
  customRemove?: () => void;
};

export const AttachmentView = observer(function AttachmentView({
  id,
  name,
  urls,
  mimeType,
  removeDisabled,
  onRemoved,
  customRemove,
  ...props
}: AttachmentViewProps) {
  const detailModal = useDisclosure();
  const [state, setState] = useState<
    "isProcessing" | "preview" | "withoutPreview"
  >("withoutPreview");
  const isVideo = mimeType.startsWith("video/");
  const isImage = mimeType.startsWith("image/");
  const isProcessing =
    !urls.viewable && !!urls.original && (isVideo || isImage);

  useEffect(() => {
    if (isProcessing) {
      setState("isProcessing");
    }
    if (!isProcessing && urls.viewable) {
      setState("preview");
    }
    if (!isProcessing && !urls.viewable) {
      setState("withoutPreview");
    }
  }, [mimeType, urls]);

  const [remove, { loading: loadingRemove }] = useDeleteAssignableFileMutation({
    onCompleted(result) {
      if (result.deleteAssignableFile?.result) {
        onRemoved?.(id);
      }
    },
    ...onError(),
  });

  const onRemove = () => remove({ variables: { publicId: id } });

  const openUrl = (url: string | undefined | null) =>
    url && window.open(url, "_blank");

  return (
    <React.Fragment>
      <Box
        display="inline-flex"
        overflow="hidden"
        w={ATTACHMENT_VIEW_WIDTH}
        minW={ATTACHMENT_VIEW_WIDTH}
        h={ATTACHMENT_VIEW_HEIGHT}
        mr="2"
        data-component-name="Attachment"
        data-drag-handle
        {...props}
      >
        <Box
          pos="relative"
          overflow="hidden"
          w="full"
          h="full"
          bg="grey.100"
          borderWidth="1px"
          borderColor="grey.100"
          rounded="xl"
        >
          <Flex
            sx={{
              '[data-component-name="Attachment"]:hover &': {
                display: "block",
              },
            }}
            pos="absolute"
            top="0"
            right="0"
            bottom="0"
            left="0"
            display="none"
            bg="blackAlpha.600"
          />
          {state === "isProcessing" && (
            <Center flexDir="column" w="full" h="full" bg="grey.100">
              <Icon w="5" h="5" name="film-01" color="grey.600" />
              <Text>
                <Trans>Creating preview...</Trans>
              </Text>
            </Center>
          )}
          {state === "preview" && (
            <Image
              w="full"
              h="full"
              objectFit="contain"
              alt={name}
              onError={() => setState("withoutPreview")}
              src={urls.thumbnail ?? ""}
            />
          )}
          {state === "withoutPreview" && (
            <Center h="full" minH={ATTACHMENT_VIEW_HEIGHT} bg="grey.100">
              <Icon name="paperclip" w="7" h="7" color="grey.600" />
            </Center>
          )}

          <Box
            pos="absolute"
            top="0"
            right="0"
            bottom="0"
            left="0"
            cursor={urls.viewable ? "pointer" : "default"}
            onClick={(e) => {
              e.stopPropagation();
              if (!urls.viewable) return;
              detailModal.onOpen();
            }}
          >
            <Center h="full">
              {!isProcessing && isVideo && (
                <Circle bg="white" size="12">
                  <Icon
                    ml="3px"
                    name="play"
                    variant="solid"
                    w="6"
                    h="6"
                    color="blue.600"
                  />
                </Circle>
              )}
              {!isVideo && urls.viewable && (
                <Icon
                  name="search-md"
                  sx={{
                    '[data-component-name="Attachment"]:hover &': {
                      display: "flex",
                    },
                  }}
                  display="none"
                  w="10"
                  h="10"
                  color="white"
                />
              )}
            </Center>
          </Box>

          <HStack
            sx={{
              '[data-component-name="Attachment"]:hover &': { display: "flex" },
            }}
            pos="absolute"
            right="0"
            bottom="0"
            display="none"
            p="2"
          >
            <IconButton
              bg="white"
              aria-label={t`download`}
              colorScheme="grey"
              icon={<Icon name="download-02" w="5" h="5" color="grey.700" />}
              onClick={(e) => {
                e.stopPropagation();
                openUrl(urls.original);
              }}
              size="sm"
              variant="unstyled"
            />
            {!removeDisabled && (
              <IconButton
                bg="white"
                aria-label={t`delete`}
                colorScheme="grey"
                icon={<Icon name="trash-03" w="5" h="5" color="grey.700" />}
                isLoading={loadingRemove}
                onClick={(e) => {
                  e.stopPropagation();
                  customRemove ? customRemove() : onRemove();
                }}
                size="sm"
                variant="unstyled"
              />
            )}
          </HStack>
          <Box
            pos="absolute"
            top="0"
            right="0"
            left="0"
            px="2"
            py="1.5"
            fontSize="2xs"
            fontWeight="semibold"
            bg="grey.200"
            opacity="0.9"
            isTruncated
            noOfLines={1}
          >
            {name}
          </Box>
        </Box>
      </Box>
      {isVideo && urls.viewable && (
        <VideoModal
          title={name}
          url={urls.viewable}
          originalUrl={urls.original!}
          isOpen={detailModal.isOpen}
          onClose={detailModal.onClose}
        />
      )}
      {!isVideo && urls.viewable && (
        <ImageModal
          title={name}
          url={urls.viewable}
          originalUrl={urls.original!}
          isOpen={detailModal.isOpen}
          onClose={detailModal.onClose}
        />
      )}
    </React.Fragment>
  );
});
