import { t } from "@lingui/macro";
import { useTeamSelectOptionsQuery } from "@src/__generated__/urql-graphql";
import { Select, SelectProps } from "@src/components/ui-kit";
import mapToOptions from "@src/utils/map-to-options";
import {
  TeamOption,
  toTeamOptionsParams,
} from "@src/utils/map-to-options/teams";
import { FunctionComponent, useEffect, useState } from "react";

type TeamSelectProps = Omit<SelectProps, "options"> & {
  optionModifier?: toTeamOptionsParams["modifier"];
  options?: SelectProps["options"];
};

export const TeamSelect: FunctionComponent<TeamSelectProps> = ({
  optionModifier = (option) => option,
  options: passedOptions,
  ...props
}) => {
  const [options, setOptions] = useState<TeamOption[]>([]);
  const [{ data, fetching }] = useTeamSelectOptionsQuery({
    pause: !!passedOptions,
  });

  useEffect(() => {
    if (data?.teamSimpleMap) {
      setOptions(mapToOptions.teams(data.teamSimpleMap, optionModifier));
    }
  }, [data]);

  return (
    <Select
      isLoading={fetching}
      placeholder={t`Select team`}
      options={passedOptions || options}
      {...props}
    />
  );
};
