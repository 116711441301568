import { ApolloClient, ApolloLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/nextjs";
import { API_URL } from "@src/config/constants";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { OperationTypeNode } from "graphql";
import Router from "next/router";
import { route } from "nextjs-routes";

const cache = new InMemoryCache();

const uploadLink = createUploadLink({
  uri: API_URL,
  credentials: "include",
});

const logoutHandler = onError(({ networkError, response }) => {
  if (response?.errors?.length && response?.errors?.length > 0) {
    response?.errors?.forEach((error) => {
      Sentry.captureEvent({
        message: "GraphQL ERROR: " + error.message,
      });
    });
  }

  if (
    networkError &&
    "statusCode" in networkError &&
    networkError.statusCode === 401
  ) {
    Router.push({
      pathname: route({ pathname: "/login" }),
      query: { redirect_to: location.href },
    });
  }
});

const previousOperations = new Map<string, AbortController>();

const aborterLink = new ApolloLink((operation, forward) => {
  if (
    operation.query.definitions.some(
      (definition) =>
        "operation" in definition &&
        definition.operation === OperationTypeNode.MUTATION,
    )
  ) {
    return forward(operation);
  }

  const originalContext = operation.getContext();
  previousOperations.get(operation.operationName)?.abort();
  previousOperations.set(operation.operationName, new AbortController());

  operation.setContext({
    ...originalContext,
    fetchOptions: {
      ...originalContext?.fetchOptions,
      signal:
        originalContext?.fetchOptions?.signal !== undefined
          ? originalContext.fetchOptions?.signal
          : previousOperations.get(operation.operationName)?.signal,
    },
  });

  return forward(operation);
});

/**
 * NOTE:
 * ApolloClient automatically cancels query requests with same operationName if they were spammed after
 * each other.
 *
 * To disable this behavior, pass null signal to client query/mutation options:
 *
 * context: {
 *   fetchOptions: {
 *     signal: null,
 *   },
 * },
 *
 * or pass in new signal for manual handling.
 */
const client = new ApolloClient({
  cache,
  link: from([logoutHandler, aborterLink, uploadLink]),
  connectToDevTools: true,
  queryDeduplication: true,
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
  },
});

export { client };
