import { useRouter } from "next/router";
import { Route } from "nextjs-routes";
import { useEffect, useMemo } from "react";
import { removeTrailingSlash, routeHistory } from "../RouteHistory";

type StaticRoute = Exclude<Route, { query: any }>["pathname"];

/**
 *
 * To find match in @pathnameListToRecord
 * we compare the pathnames without trailing slash.
 *
 */
export const useRecordMyUrl = (pathnameListToRecord: StaticRoute[]) => {
  const router = useRouter();

  const pathnameList = useMemo(
    () => pathnameListToRecord.map((i) => removeTrailingSlash(i)),
    [],
  );

  useEffect(() => {
    const handleRoute = (currentUrl: StaticRoute) => {
      const currentPathname = parsePathname(currentUrl);
      const pathname = pathnameList.find((i) => i === currentPathname);

      if (pathname) {
        routeHistory.save(pathname, currentUrl);
      }
    };

    router.events.on("routeChangeComplete", handleRoute);

    return () => {
      router.events.off("routeChangeComplete", handleRoute);
    };
  }, []);
};

const parsePathname = (url: StaticRoute): StaticRoute => {
  return removeTrailingSlash(
    new URL(url, "http://test").pathname as StaticRoute,
  );
};
