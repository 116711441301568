import { observer, useLocalObservable } from "mobx-react-lite";
import React, { FunctionComponent, useEffect } from "react";
import { readAsDataURL } from ".";
import { MediaModel } from "./models";

export const Preview: FunctionComponent<
  React.PropsWithChildren<{ media: MediaModel }>
> = observer(function Preview({ media }) {
  const state = useLocalObservable(() => ({
    preview: "",
  }));

  useEffect(() => {
    // Local File
    if (media._file) {
      readAsDataURL(media._file).then((val) => (state.preview = val));
      return;
    }
    // Newly uploaded file with preview
    state.preview = media.urls?.preview ? media.urls.preview : "";
  }, [media]);

  return media.isImage ? (
    <img src={state.preview} alt={media.fileName} />
  ) : (
    <object data={state.preview} width="100%" height="850" />
  );
});
