import { Box, Stack } from "@chakra-ui/layout";
import { t, Trans } from "@lingui/macro";
import { AvatarUpload, FormRow, TextField } from "@src/components/ui-kit";
import { cannot } from "@src/utils/components/permissions";
import { fieldToInputProps } from "@src/utils/forms/inputHelpers";
import { useStore } from "@src/utils/hooks";
import { PositionSelect } from "@src/widgets/PositionSelect";
import { RoleSelect } from "@src/widgets/RoleSelect/RoleSelect";
import { TeamSelect } from "@src/widgets/TeamSelect";
import { observer } from "mobx-react-lite";

const USER_PLACEHOLDER_IMAGE =
  process.env.NODE_ENV === "test" ? "" : require("@src/assets/img/user.png");

export const PersonalSettings = observer(function PersonalSettings() {
  const { editUserModalStore } = useStore();

  return (
    <Stack
      align={{
        base: "center",
        md: "start",
      }}
      direction={{
        base: "column",
        md: "row",
      }}
      overflowY="auto"
      spacing="4"
    >
      <Box w="25%" mt="4">
        <AvatarUpload
          placeholderImage={USER_PLACEHOLDER_IMAGE}
          roundedFull
          imageSrc={
            typeof editUserModalStore.form.avatar.value === "string"
              ? editUserModalStore.form.avatar.value
              : ""
          }
          onChange={(val) => {
            editUserModalStore.form.avatar.onChange(val);
          }}
        />
      </Box>

      <Box
        w={{
          base: "full",
          md: "75%",
        }}
      >
        <FormRow title={<Trans>First name</Trans>}>
          <TextField
            isDisabled={cannot("user_updatePersonalData")}
            id="field-user-first-name"
            {...fieldToInputProps(editUserModalStore.form.first_name)}
            type="text"
          />
        </FormRow>
        <FormRow title={<Trans>Last name</Trans>}>
          <TextField
            isDisabled={cannot("user_updatePersonalData")}
            id="field-user-last-name"
            {...fieldToInputProps(editUserModalStore.form.last_name)}
            type="text"
          />
        </FormRow>
        <FormRow title={<Trans>Codename</Trans>}>
          <TextField
            isDisabled={cannot("user_updatePersonalData")}
            id="field-user-codename"
            {...fieldToInputProps(editUserModalStore.form.codename)}
            type="text"
          />
        </FormRow>
        <FormRow title={<Trans>E-mail</Trans>}>
          <TextField
            isDisabled={cannot("user_updatePersonalData")}
            id="field-user-email"
            placeholder={t`example@web.sk`}
            {...fieldToInputProps(editUserModalStore.form.email)}
            type="email"
          />
        </FormRow>
        <FormRow title={<Trans>Phone</Trans>}>
          <TextField
            isDisabled={cannot("user_updatePersonalData")}
            id="field-user-phone"
            placeholder={t`+421 111 222 333`}
            {...fieldToInputProps(editUserModalStore.form.phone)}
            type="tel"
          />
        </FormRow>
        <FormRow title={<Trans>Note</Trans>}>
          <TextField
            isDisabled={cannot("user_updatePersonalData")}
            id="field-user-note"
            {...fieldToInputProps(editUserModalStore.form.note)}
            type="text"
          />
        </FormRow>
        <FormRow title={<Trans>Team</Trans>}>
          <TeamSelect
            isDisabled={cannot("user_updateWorkData")}
            asPortal
            {...fieldToInputProps(editUserModalStore.form.team_id)}
          />
        </FormRow>
        <FormRow title={<Trans>Position</Trans>}>
          <PositionSelect
            isDisabled={cannot("user_updatePersonalData")}
            asPortal
            id="field-user-position"
            {...fieldToInputProps(editUserModalStore.form.default_work_type_id)}
          />
        </FormRow>
        <FormRow title={<Trans>Role</Trans>}>
          <RoleSelect
            isDisabled={cannot("user_updateAllfredData")}
            asPortal
            isMulti
            placeholder={t`Select roles`}
            {...fieldToInputProps(editUserModalStore.form.role_ids)}
          />
        </FormRow>
        <FormRow title={<Trans>Password</Trans>}>
          <TextField
            isDisabled={cannot("user_updateAllfredData")}
            id="field-user-password"
            {...fieldToInputProps(editUserModalStore.form.password)}
            type="password"
          />
        </FormRow>
      </Box>
    </Stack>
  );
});
