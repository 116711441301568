import { Box, BoxProps } from "@chakra-ui/react";
import * as React from "react";

const DisabledContext = React.createContext(false);

type DisableProps = Omit<BoxProps, "isDisabled"> & {
  as?: string;
  isDisabled: boolean;
  disabledProps?: any;
  disabledStyles?: React.CSSProperties;
  disabledOpacity?: number;
  children: React.ReactNode;
};

// Component inspired by https://github.com/kitze/react-disable/blob/master/src/index.tsx
export const Disable: React.FunctionComponent<DisableProps> = ({
  as = Box,
  children,
  isDisabled,
  disabledProps,
  disabledStyles,
  disabledOpacity = 0.4,
  ...props
}) => {
  const isParentDisabled = React.useContext(DisabledContext);
  const shouldDisable = isParentDisabled ? false : isDisabled;
  const disableEvent = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.persist();

      if (shouldDisable) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [shouldDisable],
  );

  return (
    <Box cursor={shouldDisable ? "not-allowed" : "default"}>
      <DisabledContext.Provider value={isDisabled}>
        {React.createElement(
          as,
          {
            sx: {
              transition: "all 100ms linear",
              ...(shouldDisable && {
                opacity: disabledOpacity,
                pointerEvents: "none",
                userSelect: "none",
                ...disabledStyles,
              }),
            },
            onClick: disableEvent,
            onKeyDown: disableEvent,
            ...(shouldDisable && {
              tabIndex: -1,
              "aria-hidden": "true",
              cursor: "not-allowed",
              ...disabledProps,
            }),
            ...props,
          },
          children,
        )}
      </DisabledContext.Provider>
    </Box>
  );
};
