import { t } from "@lingui/macro";
import {
  useGetPrioritiesOptionsQuery,
  useGetProjectOptionsLazyQuery,
} from "@src/__generated__/graphql";
import {
  useCreateTaskMutation,
  useGetStatusOptionsQuery,
} from "@src/__generated__/urql-graphql";
import { IOption } from "@src/components/ui-kit";
import { ModalTaskForm } from "@src/components/widgets/Modals/ModalTask";
import { ModalTask } from "@src/components/widgets/Modals/ModalTask/ModalTask";
import { useAutocompletePositionsFromOurWorkBudgetItem } from "@src/components/widgets/Modals/ModalTask/useAutocompletePositionsFromOurWorkBudgetItem";
import { trackEvent } from "@src/services/amplitude";
import { useStore } from "@src/utils/hooks";
import mapToOptions from "@src/utils/map-to-options";
import { FormMode } from "@src/utils/types";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";

export const ModalTaskCreate = observer(function ModalTaskCreate() {
  const { taskFormModalStore } = useStore();
  const { UIStore, workspaceStore } = useStore();
  const [{ data: statusesResponse }] = useGetStatusOptionsQuery({
    pause: !taskFormModalStore.modalState.isOpen,
  });
  const [projectOptions, setProjectOptions] = useState<IOption[]>([]);
  const [priorityOptions, setPriorityOptions] = useState<IOption[]>([]);
  const [form] = useState(() => new ModalTaskForm());
  const [{ fetching }, createTask] = useCreateTaskMutation();

  useEffect(() => {
    if (!workspaceStore.settings) return;
    const { default_task_priority_id, default_task_status_id } =
      workspaceStore.settings;

    if (!default_task_priority_id || !default_task_status_id) return;

    form.priority.onChange(default_task_priority_id.toString());
    form.status.onChange(default_task_status_id.toString());
  }, [workspaceStore.settings]);

  const statusOptions: IOption[] = useMemo(() => {
    if (!statusesResponse?.taskStatuses) return [];

    return statusesResponse.taskStatuses.map((status) => ({
      value: status.id,
      label: status.name,
    }));
  }, [statusesResponse]);

  const [fetchProjectOptions] = useGetProjectOptionsLazyQuery({
    onCompleted(data) {
      if (data.projectsSimpleMap) {
        setProjectOptions(mapToOptions.projects(data.projectsSimpleMap));
      }
    },
  });

  useGetPrioritiesOptionsQuery({
    skip: !taskFormModalStore.modalState.isOpen,
    onCompleted(data) {
      if (data.taskPriorities) {
        setPriorityOptions(
          mapToOptions.priorities.toOptions(data.taskPriorities),
        );
      }
    },
  });

  const { autocompletePositions } =
    useAutocompletePositionsFromOurWorkBudgetItem(form);

  const onSubmitWrapper = async (hasError: boolean) => {
    if (hasError) return false;
    const input = form.serialize();
    return createTask({ input }).then(({ data }) => {
      if (!data?.createTask) return false;

      taskFormModalStore.modalState.additionalData?.onSubmit?.(data.createTask);
      UIStore.toast({ title: t`Created` });
      trackEvent("task", "Created task");
      return true;
    });
  };

  useGetPrioritiesOptionsQuery({
    onCompleted(data) {
      if (data.taskPriorities) {
        setPriorityOptions(
          mapToOptions.priorities.toOptions(data.taskPriorities),
        );
      }
    },
  });

  useEffect(() => {
    if (!taskFormModalStore.modalState.isOpen) return;
    const { additionalData } = taskFormModalStore.modalState;
    if (!additionalData) return;

    fetchProjectOptions();
    if (additionalData.projectId) {
      form.projectId.onChange(additionalData.projectId);
    }

    if (additionalData.budgetItemId) {
      form.budgetItemId.onChange(additionalData.budgetItemId);
      autocompletePositions({
        variables: {
          our_work_budget_item_id: additionalData.budgetItemId,
        },
      });
    }

    if (additionalData.billable !== undefined) {
      form.billable.onChange(additionalData.billable ? "true" : "false");
    }

    if (additionalData.userId) {
      Array.from(form.positions.values())[0].$.userId.onChange(
        additionalData.userId,
      );
    }
  }, [
    taskFormModalStore.modalState.isOpen,
    taskFormModalStore.modalState.additionalData,
  ]);

  return (
    <ModalTask
      isOpen={taskFormModalStore.modalState.isOpen}
      onClose={taskFormModalStore.modalState.close}
      form={form}
      projectId={taskFormModalStore.modalState.additionalData?.projectId}
      projectOptions={projectOptions}
      priorityOptions={priorityOptions}
      statusOptions={statusOptions}
      mode={FormMode.CREATE}
      onSubmitLoading={fetching}
      onSubmit={onSubmitWrapper}
    />
  );
});
