const POLARIS_FIX_MULTIPLIER = 1.6;

export const spacing = {
  px: "1px",
  0.5: `${0.125 * POLARIS_FIX_MULTIPLIER}rem`,
  1: `${0.25 * POLARIS_FIX_MULTIPLIER}rem`,
  1.5: `${0.375 * POLARIS_FIX_MULTIPLIER}rem`,
  2: `${0.5 * POLARIS_FIX_MULTIPLIER}rem`,
  2.5: `${0.625 * POLARIS_FIX_MULTIPLIER}rem`,
  3: `${0.75 * POLARIS_FIX_MULTIPLIER}rem`,
  3.5: `${0.875 * POLARIS_FIX_MULTIPLIER}rem`,
  4: `${1 * POLARIS_FIX_MULTIPLIER}rem`,
  5: `${1.25 * POLARIS_FIX_MULTIPLIER}rem`,
  6: `${1.5 * POLARIS_FIX_MULTIPLIER}rem`,
  7: `${1.75 * POLARIS_FIX_MULTIPLIER}rem`,
  8: `${2 * POLARIS_FIX_MULTIPLIER}rem`,
  9: `${2.25 * POLARIS_FIX_MULTIPLIER}rem`,
  10: `${2.5 * POLARIS_FIX_MULTIPLIER}rem`,
  12: `${3 * POLARIS_FIX_MULTIPLIER}rem`,
  14: `${3.5 * POLARIS_FIX_MULTIPLIER}rem`,
  16: `${4 * POLARIS_FIX_MULTIPLIER}rem`,
  20: `${5 * POLARIS_FIX_MULTIPLIER}rem`,
  24: `${6 * POLARIS_FIX_MULTIPLIER}rem`,
  28: `${7 * POLARIS_FIX_MULTIPLIER}rem`,
  32: `${8 * POLARIS_FIX_MULTIPLIER}rem`,
  36: `${9 * POLARIS_FIX_MULTIPLIER}rem`,
  40: `${10 * POLARIS_FIX_MULTIPLIER}rem`,
  44: `${11 * POLARIS_FIX_MULTIPLIER}rem`,
  48: `${12 * POLARIS_FIX_MULTIPLIER}rem`,
  52: `${13 * POLARIS_FIX_MULTIPLIER}rem`,
  56: `${14 * POLARIS_FIX_MULTIPLIER}rem`,
  60: `${15 * POLARIS_FIX_MULTIPLIER}rem`,
  64: `${16 * POLARIS_FIX_MULTIPLIER}rem`,
  72: `${18 * POLARIS_FIX_MULTIPLIER}rem`,
  80: `${20 * POLARIS_FIX_MULTIPLIER}rem`,
  96: `${24 * POLARIS_FIX_MULTIPLIER}rem`,
};
