import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { useEffect } from "react";
import { usePushNotifications } from "./usePushNotifications";

const NOTIFICATION_PERMISSIONS_KEY = "notificationPermissionsKey";

export const PushNotifications = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      localStorage.setItem(NOTIFICATION_PERMISSIONS_KEY, "asked");
    },
  });

  const { requestPermission, subscriptionPending } = usePushNotifications({
    onSub: () => onClose(),
    onSubFail: () => onClose(),
  });

  useEffect(() => {
    localStorage.getItem(NOTIFICATION_PERMISSIONS_KEY) === null
      ? onOpen()
      : onClose();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <VStack mb="8" pt="6">
            <Avatar
              mb="3"
              bg="teal.300"
              icon={<Icon name="bell-03" w="6" h="6" color="teal.800" />}
              size="md"
            />
            <Heading pb="1" size="md">
              <Trans>Enable notifications</Trans>
            </Heading>
            <Text pb="4" textAlign="center">
              <Trans>
                Turn it on! Otherwise you will not be informed about your tasks
                at the right time.
              </Trans>
            </Text>
            <Alert rounded="lg" status="info">
              <AlertIcon />
              <AlertDescription>
                <Trans>
                  You may need to enable notifications for your browser in
                  computer settings.
                </Trans>
              </AlertDescription>
            </Alert>
          </VStack>
          <HStack spacing="3">
            <Button
              flex="1"
              isLoading={subscriptionPending}
              onClick={() => requestPermission()}
            >
              <Trans>Enable</Trans>
            </Button>
            <Button
              flex="1"
              colorScheme="grey"
              isLoading={subscriptionPending}
              onClick={() => onClose()}
              variant="outline"
            >
              <Trans>Cancel</Trans>
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
