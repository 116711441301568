import { Box } from "@chakra-ui/layout";
import { FC } from "react";
import { AUD, CZK, EUR, HUF, USD } from "./flags";

const CurrencyFlagsMap: Record<string, JSX.Element> = {
  AUD,
  CZK,
  EUR,
  HUF,
  USD,
};

interface CurrencyFlagProps {
  code: string;
}

const CurrencyFlag = ({ code }: CurrencyFlagProps) => {
  return CurrencyFlagsMap[code];
};

interface CurrencyFlagIconProps extends CurrencyFlagProps {
  rounded?: boolean;
  className?: string;
}

export const CurrencyFlagIcon: FC<
  React.PropsWithChildren<CurrencyFlagIconProps>
> = ({ code, rounded = true, className }) => (
  <Box
    className={className}
    borderRadius={rounded ? "50%" : undefined}
    overflow={rounded ? "hidden" : undefined}
  >
    {CurrencyFlag({ code })}
  </Box>
);
