import { BillingEntity } from "@src/__generated__/urql-graphql";

type ToOptionsParam = Pick<
  BillingEntity,
  "id" | "street" | "city" | "internal_name"
>[];

const toOptions = (billing: ToOptionsParam) =>
  billing.map((i) => ({
    label: `${i.internal_name}, ${i.street}, ${i.city}`,
    value: i.id,
  }));

export default { toOptions };
