import { User } from "@src/__generated__/urql-graphql";
import { upperFirst } from "lodash";

// Admin, CFO, Financier, Managing director, Team leader, Project manager, User

export function getHighestRole(roles: User["roles"]): User["roles"][0]["name"] {
  /* eslint-disable lingui/no-unlocalized-strings */
  const allRoles = [
    "Admin",
    "CFO",
    "Financier",
    "Managing director",
    "Team leader",
    "Project manager",
    "User",
  ];
  /* eslint-enable lingui/no-unlocalized-strings */
  const passedRoles = roles.map(({ name }) =>
    upperFirst(name.replace("-", " ")),
  );
  const roleCount = allRoles.length;
  let highestRole;

  for (let i = 0; i < roleCount; i++) {
    const role = allRoles[i];

    if (!passedRoles.includes(role)) continue;

    highestRole = role;
    break;
  }

  // eslint-disable-next-line lingui/no-unlocalized-strings
  return highestRole ?? "User";
}
