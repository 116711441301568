import { TextEditorRef } from "@src/components/ui-kit/TextEditor/TextEditor";
import { CommentModel } from "@src/components/widgets/Modals/ModalCommunication/models/CommentModel";
import { can } from "@src/utils/components/permissions";
import { BooleanState } from "@src/utils/mobx/states/BooleanState";
import { computed, makeObservable, observable } from "mobx";
import React from "react";

export class CommentStore {
  readonly COMMENT_ID_QUERY_KEY = "commentId";
  readOnly = new BooleanState(true);
  commentContainerRef = React.createRef<HTMLDivElement>();
  editorRef = React.createRef<TextEditorRef>();

  @observable.ref comment: CommentModel;

  constructor(comment: CommentModel) {
    makeObservable(this);
    this.comment = comment;
  }

  @computed get canEdit() {
    return (
      can("taskComment_update_all") ||
      can({
        do: "taskComment_update_own",
        eval: ({ id }) => id === this.comment.user.id,
      })
    );
  }

  @computed get canDelete() {
    return (
      can("taskComment_delete_all") ||
      can({
        do: "taskComment_delete_own",
        eval: ({ id }) => id === this.comment.user.id,
      })
    );
  }
}

export const CommentStoreCtx = React.createContext<CommentStore>(
  null as any as CommentStore,
);
