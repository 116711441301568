import { t } from "@lingui/macro";
import {
  CreateTimeTrackingItemMutationVariables,
  UpdateTimeTrackingItemMutationVariables,
} from "@src/__generated__/graphql";
import { toApiDate } from "@src/utils/dates";
import { required } from "@src/utils/forms/validators";
import { isTrackingEnabled } from "@src/utils/time-tracking";
import { TaskOption } from "@src/widgets/TaskSelect/TaskSelect";
import { FieldState, FormState, FormStateLazy } from "formstate";
import { makeObservable, observable } from "mobx";

export class EntryForm {
  private trackingAllowed = (date: Date) => {
    if (!this.timeTrackingSettings) return null;
    return isTrackingEnabled(this.timeTrackingSettings, date)
      ? null
      : t`Tracking for selected date isn't allowed`;
  };

  @observable.ref selectedTask:
    | Pick<TaskOption, "value" | "label">
    | undefined = undefined;
  @observable timeTrackingSettings:
    | TaskOption["timeTrackingSettings"]
    | undefined = undefined;
  id = new FieldState("");
  project_id = new FieldState("").validators(required);
  budget_item_id = new FieldState("").validators(required);
  task_id = new FieldState("").validators(required);
  time_tracking_work_type_id = new FieldState("").validators(required);
  tracked_for_date = new FieldState<Date>(new Date()).validators(
    required,
    this.trackingAllowed,
  );
  time = new FieldState<number | undefined>(undefined);
  notes = new FieldState("");
  capacity_allocation_item_id = new FieldState<string | undefined>(undefined);
  billable = new FieldState<boolean>(true);

  /** Proxy all fields to reset form */
  _form = new FormState([
    this.id,
    this.project_id,
    this.budget_item_id,
    this.task_id,
    this.time_tracking_work_type_id,
    this.tracked_for_date,
    this.time,
    this.notes,
    this.billable,
  ]);

  /** Proxy optional fields to validate form */
  _formLazy = new FormStateLazy(() => {
    return [
      this.project_id,
      this.time_tracking_work_type_id,
      this.budget_item_id,
      this.task_id,
      this.tracked_for_date,
      ...(this.id.value ? [this.tracked_for_date, this.time] : []),
    ];
  });

  constructor() {
    makeObservable(this);
  }

  validate() {
    return this._formLazy.validate();
  }

  reset() {
    this.selectedTask = undefined;
    this.timeTrackingSettings = undefined;
    this._form.reset();
  }

  serializeCreate(user_id: string): CreateTimeTrackingItemMutationVariables {
    return {
      user_id,
      task_id: this.task_id.value,
      time_tracking_work_type_id: this.time_tracking_work_type_id.value,
      tracked_time: this.time.value ?? 0,
      tracked_for_date: toApiDate(this.tracked_for_date.value),
      note: this.notes.value,
      capacity_allocation_item_id: this.capacity_allocation_item_id.value,
      billable: this.billable.$,
    };
  }

  serializeUpdate(user_id: string): UpdateTimeTrackingItemMutationVariables {
    const { tracked_time, ...rest } = this.serializeCreate(user_id);
    return { id: this.id.value, tracked_time: tracked_time!, ...rest };
  }
}
