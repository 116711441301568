import { t } from "@lingui/macro";
import {
  CapacityAllocation,
  CapacityAllocationItem,
  CapacityAllocationTypeEnum,
  DeleteCapacityAllocationDocument,
  DeleteCapacityAllocationItemDocument,
  DeleteCapacityAllocationItemMutation,
  DeleteCapacityAllocationItemMutationVariables,
  DeleteCapacityAllocationMutation,
  DeleteCapacityAllocationMutationVariables,
} from "@src/__generated__/urql-graphql";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { AffectAllocationOccurrences } from "@src/types/planning";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { DeleteAllocationModal } from "@src/widgets/DeleteAllocationModal";
import { FieldState } from "formstate";
import { makeObservable, observable } from "mobx";

type DeleteAllocationModalOptions = {
  type: CapacityAllocationTypeEnum;
  itemId: CapacityAllocationItem["id"];
  deleteFromDate: CapacityAllocationItem["date"];
  id: CapacityAllocation["id"];
  itemCount: number;
  affectOccurrences: AffectAllocationOccurrences;
  onSubmit?: (deleteFromDate?: CapacityAllocationItem["date"]) => void;
};

export class DeleteAllocationModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  readonly modalId = "deleteAllocationModal";

  @observable modalState = new DisclosureState<DeleteAllocationModalOptions>({
    onOpen: () => {
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <DeleteAllocationModal />,
      });
    },
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
      this.deleteAllItems.reset();
    },
  });

  deleteAllItems = new FieldState(true);

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  onDelete = async () => {
    return await (this.modalState.additionalData?.affectOccurrences ===
    "allOccurrences"
      ? this.deleteCapacityAllocation()
      : this.deleteCapacityAllocationItem());
  };

  private onDeleteFulfilled = (): void => {
    this.modalState.additionalData?.onSubmit?.(
      this.modalState.additionalData?.deleteFromDate,
    );
    this.modalState.close();
    this.appStore.UIStore.toast({
      title: t`Allocation successfully deleted`,
      status: "success",
    });
  };

  private deleteCapacityAllocation = async () => {
    if (!this.modalState.additionalData?.id) return;
    await client
      .mutation<
        DeleteCapacityAllocationMutation,
        DeleteCapacityAllocationMutationVariables
      >(DeleteCapacityAllocationDocument, {
        input: this.deleteAllItems.$
          ? { id: this.modalState.additionalData?.id }
          : {
              id: this.modalState.additionalData?.id,
              deleting_from_id: this.modalState.additionalData?.itemId,
            },
      })
      .toPromise()
      .then((value) => {
        if (!value.data?.deleteCapacityAllocation) return;
        this.onDeleteFulfilled();
      });
  };

  private deleteCapacityAllocationItem = async () => {
    if (!this.modalState.additionalData?.itemId) return;
    await client
      .mutation<
        DeleteCapacityAllocationItemMutation,
        DeleteCapacityAllocationItemMutationVariables
      >(DeleteCapacityAllocationItemDocument, {
        input: {
          id: this.modalState.additionalData?.itemId,
        },
      })
      .toPromise()
      .then((value) => {
        if (!value.data?.deleteCapacityAllocationItem) return;
        this.onDeleteFulfilled();
      });
  };
}
