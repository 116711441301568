import { FieldState, FormState } from "formstate";
import { makeObservable, observable } from "mobx";

export class ForgottenPasswordFormState {
  @observable state: "loading" | "ready" | "submitting" | "success" | "error" =
    "loading";
  @observable _token = "";
  readonly email = new FieldState<string>("");

  readonly form = new FormState({
    email: this.email,
  });

  constructor() {
    makeObservable(this);
  }
}
