/**
 * Code is copied from here: https://github.com/marcinkoziej/urql-serialize-scalars-exchange
 * All credits to the author.
 *
 * However, since the package isn't maintained from April 2022, in order to keep dependencies up to date,
 * I was forced to copy the code over to us.
 */

import { Exchange, Operation, OperationResult } from "@urql/core";
import { TypeNode } from "graphql";

import { map, pipe } from "wonka";

type TypeOrTypes = string | string[];

type ObjectFieldTypes = {
  [key: string]: { [key: string]: TypeOrTypes };
};

type OpTypes = {
  [key: string]: TypeOrTypes;
};
type ScalarLocations = {
  scalars: string[];
  inputObjectFieldTypes: ObjectFieldTypes;
  outputObjectFieldTypes: ObjectFieldTypes;
  operationMap: OpTypes;
};

type Serializer<T> = {
  serialize: (value: T) => string;
  deserialize: (value: string) => T;
};

interface ObjectWithTypename {
  __typename?: string;
}

// return the field types but when given an array (of interface implementations), return merged
// fields -> all possible field names with types.
// XXX we assume you have not defined two types with same field name but different type.
const allFieldTypes = (
  typesMap: ObjectFieldTypes,
  curType: string | string[],
) => {
  if (curType instanceof Array) {
    return curType
      .map((t) => typesMap[t])
      .reduce((a, x) => Object.assign(a, x), {});
  } else {
    return typesMap[curType] || {};
  }
};

const objectTypename = (data: any): string | undefined => {
  let p: ObjectWithTypename = data;
  if (p instanceof Array) {
    if (p.length > 0) p = p[0];
    else return;
  }
  if (p instanceof Object && p.__typename) return p.__typename;
  return;
};

const serializeTree = (
  data: any,
  serializeOrDeserialize: boolean,
  //variables: any,
  //varName: string,
  initialType: TypeOrTypes,
  typesMap: ObjectFieldTypes,
  serializers: Record<string, Serializer<any>>,
): any => {
  //walk data recursively
  const serialize = (ptr: any, curType: string | string[]): any => {
    // no data beyond that point.
    if (ptr === null || ptr === undefined) return ptr;

    if (typeof curType === "string" && curType in serializers) {
      // Leaf of scalar map tree - means we arrived at the place where scalar is, lets (de)serialize
      return serializeOrDeserialize
        ? serializers[curType].serialize(ptr)
        : serializers[curType].deserialize(ptr);
    } else {
      // nested element - for array, we do not traverse the tree deeper
      if (Array.isArray(ptr)) {
        return ptr.map((elem) => serialize(elem, curType));

        // object, we might have to fork here so call recursively on all the keys of cur tree node
      } else if (typeof ptr === "object") {
        const changed = { ...ptr };
        const fieldTypes = allFieldTypes(typesMap, curType);

        for (const [field, fieldValue] of Object.entries(ptr)) {
          if (fieldValue === null) continue;
          const fieldType = fieldTypes[field] || objectTypename(fieldValue);
          if (fieldType === undefined) continue;
          changed[field] = serialize(ptr[field], fieldType);
        }
        return changed;
      } else {
        // for anything else, just return it
        return ptr;
      }
    }
  };

  return serialize(data, initialType);
};

const unpackType = (varType: TypeNode): string => {
  if (varType.kind === "NamedType") return varType.name.value;
  if (varType.kind === "ListType" || varType.kind === "NonNullType")
    return unpackType(varType.type);
  throw new Error(`Unsupported variable type node: ${varType}`);
};

const createSerializeScalarsExchange =
  (
    scalarLocations: ScalarLocations,
    serializers: Record<string, Serializer<any>>,
  ): Exchange =>
  ({ forward }) => {
    const serializeArgs = (op: Operation): Operation => {
      if (op.kind !== "query" && op.kind !== "mutation") return op;

      for (const def of op.query.definitions) {
        if (def.kind === "OperationDefinition" && def.variableDefinitions) {
          for (const varDef of def.variableDefinitions) {
            const typeName = unpackType(varDef.type);
            const varName = varDef.variable.name.value;
            if (op.variables === undefined) {
              op.variables = {};
            }
            if (typeName in scalarLocations.inputObjectFieldTypes) {
              op.variables[varName] = serializeTree(
                op.variables[varName],
                true,
                typeName,
                scalarLocations.inputObjectFieldTypes,
                serializers,
              );
            }
            if (typeName in serializers) {
              op.variables[varName] = serializers[typeName].serialize(
                op.variables[varName],
              );
            }
          }
        }
      }

      return op;
    };

    const deserializeResult = (op: OperationResult): OperationResult => {
      if (
        op.data === null ||
        op.data === undefined ||
        !(op.operation.kind === "query" || op.operation.kind === "mutation")
      )
        return op;

      const dataCopy = { ...op.data };

      for (const [opName, opData] of Object.entries(op.data)) {
        if (opData === null) continue;

        const opType =
          scalarLocations.operationMap[opName] || objectTypename(opData);
        if (opType === undefined) continue;

        dataCopy[opName] = serializeTree(
          opData,
          false,
          opType,
          scalarLocations.outputObjectFieldTypes,
          serializers,
        );
      }

      op.data = dataCopy;
      return op;
    };

    return (ops$) =>
      pipe(ops$, map(serializeArgs), forward, map(deserializeResult));
  };

export default createSerializeScalarsExchange;
