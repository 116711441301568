import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightAddon,
  InputRightElement,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";

type TextFieldProps = Omit<InputProps, "onChange"> & {
  addonRight?: React.ReactNode;
  connectedRight?: React.ReactNode;
  error?: string;
  label?: string;
  onChange?: (value: string) => void;
};
export const TextField: FunctionComponent<TextFieldProps> = ({
  addonRight,
  connectedRight,
  error,
  label,
  onChange,
  ...props
}) => (
  <FormControl isInvalid={!!error}>
    {label && <FormLabel>{label}</FormLabel>}
    <InputGroup>
      <Input onChange={(event) => onChange?.(event.target.value)} {...props} />
      {connectedRight && (
        <InputRightElement>{connectedRight}</InputRightElement>
      )}
      {addonRight && <InputRightAddon>{addonRight}</InputRightAddon>}
    </InputGroup>
    <FormErrorMessage>{error}</FormErrorMessage>
  </FormControl>
);
