import { ApiDateFormat, ApiDateTimeFormat } from "@src/config/constants";
import { WorkspaceStore } from "@src/stores/WorkspaceStore/WorkspaceStore";
import { WeekDayIndexes } from "@src/utils/types";
import {
  addDays,
  eachDayOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isWeekend,
  startOfWeek,
  toDate,
} from "date-fns";

export const now = (): Date => new Date(Date.now());

export const MAX_JS_DATE = new Date(8640000000000000);

export const toApiDate = (date: Date | number): string => {
  if (typeof date === "string") return date;
  return format(date, ApiDateFormat);
};
export const toApiDateTime = (date: Date): string =>
  format(date, ApiDateTimeFormat);

/**
 * Compares just date, not time!
 * Hours,minutes,seconds,milliseconds are set to 0.
 *
 * @param date
 * @returns boolean
 */
export const isFutureDate = (date: Date): boolean => {
  return new Date(date).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
};

export const getWeekdays = (
  weekStartsOn: NonNullable<WorkspaceStore["settings"]>["startOfWeekNumber"],
) => {
  const now = new Date();
  const weekdays: Date[] = [];
  const start = startOfWeek(now, { weekStartsOn });
  const end = endOfWeek(now, { weekStartsOn });

  eachDayOfInterval({ start, end }).forEach((day) => {
    weekdays.push(day);
  });

  return weekdays as WeekDayIndexes<Date>;
};

export function countMonthlyDateAppearanceOfInterval({
  startDate,
  endDate,
  targetDate,
}: {
  startDate: Date;
  endDate: Date;
  targetDate: Date;
}): number {
  let count = 0;
  let currentDate = startDate;

  while (isAfter(addDays(endDate, 1), currentDate)) {
    if (currentDate.getDate() === targetDate.getDate()) {
      count++;
    }
    currentDate = addDays(currentDate, 1);
  }

  return count;
}

export function yearsCountOfInterval(start: Date, end: Date): number {
  return eachYearOfInterval({ start, end }).length;
}

export function weeksCountOfInterval(start: Date, end: Date): number {
  return eachWeekOfInterval({ start, end }).length;
}

export function daysCountOfIntervalWithoutWeekends(
  start: Date,
  end: Date,
): number {
  const startDate = toDate(start);
  const endDate = toDate(end);
  const endTime = endDate.getTime();

  // Return 0 if start date is after end date or if any date is `Invalid Date`
  if (!(startDate.getTime() <= endTime)) {
    return 0;
  }

  const dates = [];

  const currentDate = startDate;
  currentDate.setHours(0, 0, 0, 0);

  const step = 1;

  while (currentDate.getTime() <= endTime) {
    if (!isWeekend(currentDate)) {
      dates.push(toDate(currentDate));
    }
    currentDate.setDate(currentDate.getDate() + step);
    currentDate.setHours(0, 0, 0, 0);
  }

  return dates.length;
}

export function isAfterOrSame(
  date: Date | number,
  dateToCompare: Date | number,
) {
  return isSameDay(date, dateToCompare) || isAfter(date, dateToCompare);
}

export function isBeforeOrSame(
  date: Date | number,
  dateToCompare: Date | number,
) {
  return isSameDay(date, dateToCompare) || isBefore(date, dateToCompare);
}
