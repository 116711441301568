import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Box,
  Button,
  ButtonProps,
  HStack,
  HTMLChakraProps,
  IconButton,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import React, { ReactNode } from "react";

interface BannerProps
  extends Required<Pick<AlertProps, "status">>,
    Omit<HTMLChakraProps<"div">, "title"> {
  title: string | ReactNode;
  description?: string | ReactNode;
  customAction?: ReactNode;
  onDismissLoading?: boolean;
  variant?: "standalone" | "contained";
  primaryAction?: {
    title: string;
    onClick: () => void;
    loading?: boolean;
    leftIcon?: ButtonProps["leftIcon"];
    rightIcon?: ButtonProps["leftIcon"];
  };
  dismissAction?: {
    onClick: () => void;
    loading?: boolean;
  };
}

const statusColorMap: Record<NonNullable<AlertProps["status"]>, string> = {
  loading: "blue",
  info: "blue",
  success: "green",
  warning: "orange",
  error: "red",
};

export const Banner: React.VFC<BannerProps> = ({
  title,
  status,
  customAction,
  primaryAction,
  description,
  dismissAction,
  variant = "standalone",
  ...props
}) => {
  return (
    <Alert
      px="6"
      fontSize={{
        base: "sm",
        md: "md",
      }}
      borderWidth={variant === "standalone" ? "1px" : undefined}
      borderColor={
        variant === "standalone" ? `${statusColorMap[status]}.200` : undefined
      }
      rounded={variant === "standalone" ? "lg" : undefined}
      status={status}
      {...props}
    >
      <AlertIcon mr="5" />

      <HStack justifyContent="space-between" flex="1">
        <Box flex="1">
          <AlertTitle>{title}</AlertTitle>
          {!!description && (
            <AlertDescription display="block">{description}</AlertDescription>
          )}
        </Box>

        {customAction}
        {primaryAction && (
          <Button
            colorScheme={statusColorMap[status]}
            isLoading={primaryAction.loading}
            leftIcon={primaryAction.leftIcon}
            onClick={primaryAction.onClick}
            rightIcon={primaryAction.rightIcon}
          >
            {primaryAction.title}
          </Button>
        )}
        {dismissAction && (
          <IconButton
            bg={`${statusColorMap[status]}.200`}
            aria-label={t`close`}
            colorScheme={statusColorMap[status]}
            icon={<Icon name="x-close" color="#212B36" />}
            isLoading={dismissAction.loading}
            onClick={dismissAction.onClick}
            variant="subtle"
          />
        )}
      </HStack>
    </Alert>
  );
};
