import { SortOrder } from "@src/__generated__/urql-graphql";
import { action, computed, makeObservable, observable } from "mobx";
import { IQueryWhereParam, ISearchParamSerializable } from "../../types";

export class OrderBy<Columns>
  implements ISearchParamSerializable, IQueryWhereParam
{
  readonly searchParamKey = "orderBy";

  @observable.ref clauses: {
    column: Columns;
    order: SortOrder;
  }[] = [];

  @computed get asURLSearchParam() {
    return { [this.searchParamKey]: JSON.stringify(this.clauses) };
  }

  @computed get asWhereParam() {
    return this.clauses;
  }

  constructor(initial?: OrderBy<Columns>["clauses"]) {
    makeObservable(this);
    if (initial) this.clauses = initial;
  }

  @action
  fromURLSearchParam(src: any) {
    if (!src[this.searchParamKey]) return;
    try {
      this.clauses = JSON.parse(src[this.searchParamKey]);
    } catch (err) {
      console.log(err);
    }
  }

  @action
  setClauses(clauses: OrderBy<Columns>["clauses"]) {
    this.clauses = clauses;
  }

  @action.bound getOrderForColumn(column: Columns): SortOrder | undefined {
    const match = this.clauses.find((clause) => clause.column === column);

    return match?.order;
  }

  @action.bound addClause(clause: OrderBy<Columns>["clauses"][0]): void {
    if (this.getOrderForColumn(clause.column)) return;
    this.clauses.push(clause);
  }

  @action.bound updateClause(clause: {
    column: Columns;
    order: SortOrder | undefined;
  }): void {
    if (!clause.order) {
      this.setClauses(
        this.clauses.filter((item) => item.column !== clause.column),
      );
      return;
    }

    if (!this.getOrderForColumn(clause.column)) {
      // @ts-expect-error
      this.addClause(clause);
      return;
    }

    this.setClauses(
      // @ts-expect-error
      this.clauses.map((item) => {
        if (item.column === clause.column) {
          return clause;
        } else {
          return item;
        }
      }),
    );
  }
}
