import { t } from "@lingui/macro";
import {
  CreateUserInput,
  PlanningRangeInput,
  PlanningRangeTypeEnum,
  UpdateUserInput,
  UserProfileRateInput,
} from "@src/__generated__/graphql";
import { PRESET_COLORS } from "@src/components/ui-kit/ColorPicker/ColorPicker";
import { AppStore } from "@src/stores/AppStore";
import { MAX_JS_DATE, toApiDate } from "@src/utils/dates";
import { denominate } from "@src/utils/formatters";
import {
  email,
  minLength,
  mustBeBefore,
  password,
  required,
} from "@src/utils/forms/validators";
import { WeekDays } from "@src/utils/types";
import { addDays, areIntervalsOverlapping } from "date-fns";

import { FieldState, FormState } from "formstate";
import { uniqueId } from "lodash";
import { action, IObservableArray, observable } from "mobx";

type THourlyRateFrom = FormState<{
  id: FieldState<string>;
  valid_from: FieldState<Date | undefined>;
  valid_to: FieldState<Date | undefined>;
  rate: FieldState<number>;
}>;

type TPlanningRangeFrom = FormState<{
  id: FieldState<string>;
  type: FieldState<PlanningRangeTypeEnum>;
  valid_from: FieldState<Date | undefined>;
  valid_to: FieldState<Date | undefined>;
  utilising: FieldState<boolean>;
  active: FieldState<boolean>;
  weekly_capacities: FieldState<WeekDays<number>>;
  daily_capacity: FieldState<number>;
}>;

export class FormUser {
  appStore: AppStore;
  first_name = new FieldState("").validators(required);
  last_name = new FieldState("").validators(required);
  codename = new FieldState("").validators();
  email = new FieldState("").validators(required, email);
  phone = new FieldState("");
  note = new FieldState("");
  team_id = new FieldState("").validators(required);
  default_work_type_id = new FieldState("").validators(required);
  hourlyRates = new FormState<IObservableArray<THourlyRateFrom>>(
    observable.array([]),
  );
  planningRanges = new FormState<IObservableArray<TPlanningRangeFrom>>(
    observable.array([]),
  );
  plannable = new FieldState<boolean>(true);
  from_default_capacity = new FieldState<boolean>(false);
  hex_color = new FieldState<string>(PRESET_COLORS[0]).validators(required);
  role_ids = new FieldState<string[]>([]).validators(($) => {
    return required($.length > 0 ? " " : undefined);
  });
  formVariant: "create" | "edit";
  passwordValidator = ($: string) => {
    if (this.formVariant === "edit") {
      return undefined;
    } else {
      return required($);
    }
  };
  password = new FieldState("").validators(
    this.passwordValidator,
    minLength(8),
    password,
  );
  avatar = new FieldState<File | string | null | undefined>(undefined);
  internal_hourly_rate = new FieldState<number>(0);
  plannable_capacity = new FieldState<number>(0).validators(required);
  show_in_utilization = new FieldState(true);

  form = new FormState({
    first_name: this.first_name,
    last_name: this.last_name,
    avatar: this.avatar,
    codename: this.codename,
    email: this.email,
    phone: this.phone,
    note: this.note,
    team_id: this.team_id,
    default_work_type_id: this.default_work_type_id,
    role_ids: this.role_ids,
    password: this.password,
    plannable: this.plannable,
    hex_color: this.hex_color,
    plannable_capacity: this.plannable_capacity,
    show_in_utilization: this.show_in_utilization,
    internal_hourly_rate: this.internal_hourly_rate,
  });

  personalSettingsForm = new FormState({
    first_name: this.first_name,
    last_name: this.last_name,
    codename: this.codename,
    email: this.email,
    phone: this.phone,
    note: this.note,
    team_id: this.team_id,
    default_work_type_id: this.default_work_type_id,
    role_ids: this.role_ids,
    password: this.password,
  });

  planningAndUtilizationForm = new FormState({
    plannable: this.plannable,
    hex_color: this.hex_color,
    planningRanges: this.planningRanges,
  });

  serializeCreate(): Omit<CreateUserInput, "id"> {
    return {
      first_name: this.first_name.$,
      last_name: this.last_name.$,
      photo: this.avatar.$,
      ...(this.avatar.$ instanceof File && { photo: this.avatar.$ }),
      codename: this.codename.$,
      email: this.email.$,
      phone: this.phone.$,
      note: this.note.$,
      team_id: this.team_id.$,
      default_work_type_id: this.default_work_type_id.$,
      role_ids: this.role_ids.$,
      password: this.password.$,
      plannable: this.plannable.$,
      hex_color: this.hex_color.$,
      internal_hourly_rate: denominate(
        this.internal_hourly_rate.$,
        this.appStore.workspaceStore.settings?.currency.denomination!,
      ),
      plannable_capacity: this.plannable_capacity.$,
      show_in_utilization: this.show_in_utilization.$,
    };
  }

  serializePersonalSettings(): Omit<
    UpdateUserInput,
    "id" | "plannable" | "show_in_utilization" | "hex_color"
  > {
    return {
      first_name: this.first_name.$,
      last_name: this.last_name.$,
      ...(this.avatar.$ instanceof File && { photo: this.avatar.$ }),
      codename: this.codename.$,
      email: this.email.$,
      phone: this.phone.$,
      note: this.note.$,
      team_id: this.team_id.$,
      default_work_type_id: this.default_work_type_id.$,
      role_ids: this.role_ids.$,
      password: this.password.$,
    };
  }

  serializeHourlyRates(): { rates: UserProfileRateInput[] } {
    return {
      rates: this.hourlyRates.$.map((rate) => ({
        valid_from: toApiDate(rate.$.valid_from.value!),
        valid_to: !!rate.$.valid_to.value
          ? toApiDate(rate.$.valid_to.value)
          : null,
        rate: denominate(
          rate.$.rate.value,
          this.appStore.workspaceStore.settings?.currency.denomination!,
        ),
      })),
    };
  }

  serializePlanningSettings() {
    return {
      plannable: this.plannable.$,
      hex_color: this.hex_color.$,
    };
  }

  serializePlanningRanges(): { planningRanges: PlanningRangeInput[] } {
    return {
      planningRanges: this.planningRanges.$.map((range) => ({
        type: range.$.type.value,
        valid_from: toApiDate(range.$.valid_from.value!),
        valid_to: !!range.$.valid_to.value
          ? toApiDate(range.$.valid_to.value)
          : null,
        utilising: range.$.utilising.value,
        active: range.$.active.value,
        ...(range.$.type.value === PlanningRangeTypeEnum.Custom
          ? {
              weekly_capacities: range.$.weekly_capacities.value,
              daily_capacity: null,
            }
          : {
              daily_capacity: range.$.daily_capacity.value,
              weekly_capacities: null,
            }),
      })),
    };
  }

  constructor(formVariant: "create" | "edit", appStore: AppStore) {
    this.formVariant = formVariant;
    this.appStore = appStore;
    this.internal_hourly_rate = new FieldState(0);
  }

  @action addHourlyRate(
    addToIndex?: number,
    action: "split" | "add" = "add",
    defaultValues?: {
      id?: string;
      valid_from?: Date;
      valid_to?: Date;
      rate?: number;
    },
  ) {
    const index = addToIndex ?? this.hourlyRates.$.length;
    const prevRate = this.hourlyRates.$[index - 1];
    const _defaultValues = {
      id: defaultValues?.id ?? uniqueId("hourlyRate"),
      valid_from: defaultValues?.valid_from ?? undefined,
      valid_to: defaultValues?.valid_to ?? undefined,
      rate: defaultValues?.rate ?? 0,
    };

    if (action === "split" && prevRate) {
      _defaultValues.valid_to = prevRate.$.valid_to.value;
      prevRate.$.valid_to.onChange(undefined);
    }

    if (action === "add" && prevRate) {
      _defaultValues.valid_from = !!prevRate.$.valid_to.value
        ? addDays(prevRate.$.valid_to.value, 1)
        : undefined;
    }

    this.hourlyRates.$.splice(
      index,
      0,
      new FormState({
        id: new FieldState(_defaultValues.id),
        valid_from: new FieldState<Date | undefined>(
          _defaultValues.valid_from,
        ).validators(required),
        valid_to: new FieldState<Date | undefined>(_defaultValues.valid_to),
        rate: new FieldState(_defaultValues.rate).validators(required),
      }).validators(
        ({ id, valid_from, valid_to }) =>
          this.validateHourlyRateRange(
            id.value,
            valid_from.value,
            valid_to.value,
            this.hourlyRates,
          ),
        ({ valid_from, valid_to }) =>
          this.validFromMustBeBeforeValidTo(valid_from.$, valid_to.value),
      ),
    );
  }

  @action addPlanningRange(
    addToIndex?: number,
    action: "split" | "add" = "add",
    defaultValues?: {
      id?: string;
      type?: PlanningRangeTypeEnum;
      valid_from?: Date;
      valid_to?: Date;
      utilising?: boolean;
      active?: boolean;
      weekly_capacities?: WeekDays<number>;
      daily_capacity?: number;
    },
  ) {
    const index = addToIndex ?? this.planningRanges.$.length;
    const prevRate = this.planningRanges.$[index - 1];
    const _defaultValues = {
      id: defaultValues?.id ?? uniqueId("planningRange"),
      type: defaultValues?.type ?? PlanningRangeTypeEnum.Workweek,
      valid_from: defaultValues?.valid_from ?? undefined,
      valid_to: defaultValues?.valid_to ?? undefined,
      utilising: defaultValues?.utilising ?? true,
      active: defaultValues?.active ?? true,
      weekly_capacities: defaultValues?.weekly_capacities ?? {
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0,
      },
      daily_capacity: defaultValues?.daily_capacity ?? 0,
    };

    if (action === "split" && prevRate) {
      _defaultValues.valid_to = prevRate.$.valid_to.value;
      prevRate.$.valid_to.onChange(undefined);
    }

    if (action === "add" && prevRate) {
      _defaultValues.valid_from = !!prevRate.$.valid_to.value
        ? addDays(prevRate.$.valid_to.value, 1)
        : undefined;
    }

    this.planningRanges.$.splice(
      index,
      0,
      new FormState({
        id: new FieldState(_defaultValues.id),
        type: new FieldState(_defaultValues.type),
        valid_from: new FieldState<Date | undefined>(
          _defaultValues.valid_from,
        ).validators(required),
        valid_to: new FieldState<Date | undefined>(_defaultValues.valid_to),
        utilising: new FieldState(_defaultValues.utilising),
        active: new FieldState(_defaultValues.active),
        weekly_capacities: new FieldState(_defaultValues.weekly_capacities),
        daily_capacity: new FieldState(_defaultValues.daily_capacity),
      }).validators(
        ({ id, valid_from, valid_to }) =>
          this.validateHourlyRateRange(
            id.value,
            valid_from.value,
            valid_to.value,
            this.planningRanges,
          ),
        ({ valid_from, valid_to }) =>
          this.validFromMustBeBeforeValidTo(valid_from.$, valid_to.value),
      ),
    );
  }

  validateHourlyRateRange = (
    id: string,
    valid_from: Date | undefined,
    valid_to: Date | undefined,
    arrayInstance: FormState<IObservableArray<any>>,
  ) => {
    if (!valid_from) return;

    const newInterval = {
      start: valid_from,
      end: valid_to || MAX_JS_DATE,
    };

    const overlap = arrayInstance.$.some((rate) => {
      if (rate.$.id.$ === id) return false;

      const existingInterval = {
        start: rate.$.valid_from.value!,
        end: rate.$.valid_to.value || MAX_JS_DATE,
      };

      return areIntervalsOverlapping(newInterval, existingInterval);
    });

    return overlap ? t`Hourly rate ranges cannot overlap` : null;
  };

  validFromMustBeBeforeValidTo = (
    validFrom: Date | undefined,
    validTo: Date | undefined,
  ) => mustBeBefore(validTo, t`valid to`)(validFrom);

  @action removeHourlyRate(index: number) {
    this.hourlyRates.$.splice(index, 1);
  }

  @action removePlanningRange(index: number) {
    this.planningRanges.$.splice(index, 1);
  }

  @action reset() {
    this.form.reset();
    this.hourlyRates.$.clear();
    this.planningRanges.$.clear();
  }
}
