import { HStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { CurrentDate } from "../NavBar";

interface TitleProps {
  title: React.ReactNode;
  date: Date;
  weekMode?: boolean;
}

const Title = ({ title, date, weekMode }: TitleProps) => {
  return (
    <HStack align="baseline" justify="space-between">
      <div>{title}</div>
      <CurrentDate
        current={date}
        className="text-xl text-gray-600 uppercase"
        weekMode={weekMode}
      />
    </HStack>
  );
};

export default observer(Title);
