import { PaginatorInfo, SqlOperator } from "@src/__generated__/urql-graphql";
import { IOption } from "@src/components/ui-kit";
import { extractOptions } from "@src/components/ui-kit/Select/helpers";
import { deburr, filter, forEach, intersection, map } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { Query } from "nextjs-routes";
import { KeyboardEvent } from "react";
import { MultiColumnFilterTypeEnum } from "./factories";

export interface Option {
  label: string;
  value: string;

  options?: Option[];
}

interface MultiColumn {
  label: string;
  type: `${MultiColumnFilterTypeEnum}`;
}

type TOnChange = (value?: string[]) => void;
export type TFilterOptionsQueryArgs = {
  page: number;
  first: number;
  filters: {
    search: string;
  };
};
export type TFilterOptionsQuery = (
  filters: TFilterOptionsQueryArgs,
) => Promise<{
  options: IOption[];
  paginatorInfo: PaginatorInfo | undefined;
}>;

export class Filter<C> {
  column: C;

  title: string;
  tagTitle?: string;
  sectioned?: boolean;
  hasSelectAllOption?: boolean;
  includeInGql?: boolean;

  skipWhenValueEquals?: string;
  isMultiColumn?: boolean;
  multiColumn?: MultiColumn;
  dateRange = false;
  date = false;

  /**
   * If used, optionsQuery must be defined
   */
  isPaginated?: boolean;
  /**
   * Defaults to 5
   */
  perPage?: number;
  optionsQuery?: TFilterOptionsQuery;

  @observable.ref noOptionsMessage?: string;
  @observable private _value: string[] = [];
  @observable hidden = false;
  @observable isCollapsed = false;
  @observable operator: SqlOperator;
  @observable.ref options: Option[] = [];
  @observable bottomExtraContent?: React.ReactNode;
  @observable inputValue = "";

  private _onChange: TOnChange[] = [];
  private _mutateToGql?: <C>(value: string[], filter: Filter<C>) => any[];
  private _mutateToUrl?: <C>(value: string[], filter: Filter<C>) => string[];
  private _mutateFromUrl?: <C>(
    value: string | string[],
    filter: Filter<C>,
  ) => string | string[];

  constructor(
    src: Pick<
      Filter<C>,
      | "column"
      | "operator"
      | "options"
      | "tagTitle"
      | "optionsQuery"
      | "perPage"
    > & {
      title?: string;
      value?: string | string[];
      sectioned?: boolean;
      hasSelectAllOption?: boolean;
      dateRange?: boolean;
      date?: boolean;
      hidden?: boolean;
      isCollapsed?: boolean;
      skipWhenValueEquals?: string;
      isMultiColumn?: boolean;
      /**
       * If used, optionsQuery must be defined
       */
      isPaginated?: boolean;
      multiColumn?: MultiColumn;
      onChange?: TOnChange;
      mutateToGql?: Filter<C>["_mutateToGql"];
      mutateToUrl?: Filter<C>["_mutateToUrl"];
      mutateFromUrl?: Filter<C>["_mutateFromUrl"];
      includeInGql?: boolean;
      noOptionsMessage?: string;
      bottomExtraContent?: React.ReactNode;
    },
  ) {
    makeObservable(this);
    this.dateRange = src.dateRange ?? false;
    this.date = src.date ?? false;
    this.skipWhenValueEquals = src.skipWhenValueEquals;
    this.hidden = src.hidden ?? false;
    this.isCollapsed = src.isCollapsed ?? false;
    this.isMultiColumn = src.isMultiColumn ?? false;
    this.isPaginated = src.isPaginated ?? false;
    this.optionsQuery = src.optionsQuery;
    this.perPage = src.perPage ?? 5;
    this.multiColumn = src.multiColumn;
    this.column = src.column;
    this.sectioned = src.sectioned ?? false;
    this.hasSelectAllOption = src.hasSelectAllOption ?? false;
    this.operator = src.operator;
    this.options = src.options;
    this.title = src.title ?? "";
    this.tagTitle = src.tagTitle;
    if (src.value) this.setValue(src.value);
    if (src.onChange) this.registerOnChange(src.onChange);
    this._mutateToGql = src.mutateToGql;
    this._mutateToUrl = src.mutateToUrl;
    this._mutateFromUrl = src.mutateFromUrl;
    this.includeInGql =
      src.includeInGql === undefined ? true : src.includeInGql;
    this.noOptionsMessage = src.noOptionsMessage;
    this.bottomExtraContent = src.bottomExtraContent;
  }

  registerOnChange(func: TOnChange): void {
    this._onChange = [...this._onChange, func];
  }

  @computed get value(): string[] {
    return this._value;
  }

  @computed get isMultiSelect() {
    return this.operator === SqlOperator.In;
  }

  @computed get hasInnerOptions() {
    return this.options.some((option) => Array.isArray(option.options));
  }

  @computed get hasSelectedAllOptions() {
    const optionsCount = this.options.length;
    const selectedOptionsCount = this.selectedOptions.length;

    if (!this.hasInnerOptions) {
      return selectedOptionsCount === optionsCount;
    }
    let innerOptionsCount = 0;

    for (let i = 0; i < optionsCount; i++) {
      if (!Array.isArray(this.options[i].options)) continue;
      const count = this.options[i].options?.length;
      if (!count) continue;

      for (let j = 0; j < count; j++) {
        if (!this.options[i].options?.[j]) continue;
        innerOptionsCount++;
      }
    }

    return innerOptionsCount === selectedOptionsCount;
  }

  @computed get selectedOptions() {
    return extractOptions(this.options).filter((option) =>
      this.isOptionSelected(option),
    );
  }

  @computed get notSelectedOptions(): Option[] {
    const newOptions: Option[] = [];
    const optionsCount = this.options.length;

    if (this.hasInnerOptions) {
      for (let i = 0; i < optionsCount; i++) {
        const option = this.options[i];
        if (!option) continue;

        const innerOptions: Option[] = [];
        const innerOptionsCount = option.options?.length ?? 0;

        if (!innerOptionsCount) continue;

        for (let j = 0; j < innerOptionsCount; j++) {
          const innerOption = option.options?.[j];
          if (!innerOption) continue;
          if (this.isOptionSelected(innerOption)) continue;
          if (!this.isLabelMatching(innerOption)) continue;

          innerOptions.push(innerOption);
        }

        if (innerOptions.length === 0) continue;

        newOptions.push({
          value: option.value,
          label: option.label,
          options: innerOptions,
        });
      }
    } else {
      for (let i = 0; i < optionsCount; i++) {
        const option = this.options[i];
        if (!option) continue;
        if (this.isOptionSelected(option)) continue;
        if (!this.isLabelMatching(option)) continue;

        newOptions.push({
          value: option.value,
          label: option.label,
        });
      }
    }

    return newOptions;
  }

  @computed get gqlWhere() {
    let value = this.value;
    if (this._mutateToGql) {
      value = this._mutateToGql(this.value, this);
    }
    return this.where(value);
  }

  @computed get urlWhere() {
    let value = this.value;
    if (this._mutateToUrl) {
      value = this._mutateToUrl(this.value, this);
    }
    return this.where(value);
  }

  @action.bound setValue(
    value: string | string[],
    options?: { skipOnChange?: boolean; fromUrl?: boolean },
  ) {
    if (options?.fromUrl && this._mutateFromUrl) {
      value = this._mutateFromUrl(value, this);
    }

    if (Array.isArray(value)) {
      this._value = value;
    } else {
      this._value[0] = value;
    }

    if (!options?.skipOnChange) {
      this.triggerOnChanges();
    }
  }

  @action.bound selectAll() {
    this.clear();
    const optionsCount = this.options.length;

    if (this.hasInnerOptions) {
      for (const { options } of this.options) {
        const innerOptionsCount = options?.length ?? 0;

        for (let i = 0; i < innerOptionsCount; i++) {
          if (!options?.[i].value) continue;

          this._value.push(options[i].value);
        }
      }

      return;
    }

    for (let i = 0; i < optionsCount; i++) {
      this._value.push(this.options[i].value);
    }

    this.triggerOnChanges();
  }

  @action.bound handleSelectAll() {
    if (this.hasSelectedAllOptions) {
      this.clear();
      return;
    }

    this.selectAll();
  }

  @action.bound removeOptionFromValue(option: IOption) {
    this.setValue(this._value.filter((value) => value !== option.value));
  }

  /**
   * Clear all values which are not included in @param options
   *
   * @returns true when setValue has been triggered
   */
  @action.bound sanitizeAvailableValue(opt?: {
    skipOnChange?: boolean;
  }): boolean {
    const availableValues = this.options.map((i) => i.value);
    const currentValues = this.value;
    const sanitizedValues = intersection(availableValues, currentValues);

    if (sanitizedValues.length !== currentValues.length) {
      this.setValue(sanitizedValues, { skipOnChange: opt?.skipOnChange });
      return true;
    }

    return false;
  }

  @action.bound setOptions(options: Option[]) {
    this.options = options;
  }

  @action.bound clear(skipOnChange?: boolean) {
    this.setValue([], {
      skipOnChange: skipOnChange,
    });
  }

  @action.bound hide() {
    this.hidden = true;
  }

  @action setNoOptionsMessage(newMessage: string) {
    this.noOptionsMessage = newMessage;
  }

  @action.bound handleOptionClick(option: Option) {
    if (this.isMultiSelect) {
      this.value.push(option.value);
      this.triggerOnChanges();
    } else {
      this.setValue(option.value);
    }
    this.setInputValue("");
  }

  where(value: Filter<C>["value"]) {
    if (value.length === 0) return undefined;

    return {
      column: this.column,
      value:
        this.operator === SqlOperator.In ||
        this.operator === SqlOperator.Between ||
        this.operator === SqlOperator.NotBetween
          ? value
          : value[0],
      operator: this.operator,
    };
  }

  @action setInputValue(value: string) {
    this.inputValue = value;
  }

  triggerOnChanges() {
    this._onChange.forEach((func) => func(this.value));
  }

  isOptionSelected(option: IOption): boolean {
    return this._value.includes(option.value);
  }

  isLabelMatching(option: IOption): boolean {
    if (!this.inputValue.length) return true;
    const searchString = deburr(this.inputValue).toLowerCase();
    return deburr(option.label).toLowerCase().includes(searchString);
  }

  @action.bound handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (!this._value.length) return;
    if (event.key !== "Backspace") return;
    if (event.target instanceof HTMLInputElement && event.target.value.length) {
      return;
    }

    this._value.pop();
    this.triggerOnChanges();
  }
}

interface FilterAsParam<T> {
  column: T;
  value: string | string[];
  operator: SqlOperator;
}

export class Filters<T> {
  @observable filters: Filter<T>[] = [];
  @observable.ref onChange?: TOnChange;
  readonly searchParamKey = "where";

  /**
   * Indicates that filters has been already set from URL
   */
  private initializedFromUrl = false;

  /**
   * filters without UI - ie. used for clauses which are send with every request
   */
  @observable implicitFilters: FilterAsParam<T>[] = [];

  constructor(src: Filters<T>["filters"], opt?: { onChange?: TOnChange }) {
    makeObservable(this);
    this.filters = src;
    this.onChange = opt?.onChange;

    if (opt?.onChange) {
      this.filters.forEach((f) => f.registerOnChange(opt.onChange!));
    }

    this.reset = this.reset.bind(this);
  }

  @computed get visibleFilters() {
    return this.filters.filter(({ hidden }) => !hidden);
  }

  @computed get filtersByColumn() {
    return new Map(map(this.filters, (f) => [f.column, f]));
  }

  /* eslint-disable @cspell/spellchecker */
  //INFO: to iste co asWhereParam len sa tam filtruju tie filtre, co maju byt v URLcke
  //ale nemaju sa posielat do query
  //napr,. ked mam zakliknuty tab "all", tak taky filter neexistuje a nema sa poslat nic do query
  /* eslint-enable @cspell/spellchecker */
  @computed get asGraphQueryParam() {
    const filters = this.filters.filter((filter) => {
      if (filter.value.length === 0) return false;
      if (!filter.includeInGql) return false;
      if (
        typeof filter.skipWhenValueEquals !== undefined &&
        filter.skipWhenValueEquals === filter.value[0]
      ) {
        return false;
      }

      return true;
    });

    return {
      AND: [
        ...this.implicitFilters,
        ...(filter(
          map(
            filter(filters, (f) => !f.isMultiColumn),
            (f) => f.gqlWhere,
          ),
        ) as FilterAsParam<T>[]),
        {
          AND: [
            {
              OR: filter(
                map(
                  filters.filter((f) => f.isMultiColumn),
                  (f) => f.gqlWhere,
                ),
              ) as FilterAsParam<T>[],
            },
          ],
        },
      ],
    };
  }

  // rename to asParam
  @computed get asWhereParam() {
    return {
      AND: [
        ...(filter(
          map(
            filter(this.filters, (f) => !f.isMultiColumn),
            (f) => f.urlWhere,
          ),
        ) as FilterAsParam<T>[]),
        {
          AND: [
            {
              OR: filter(
                map(
                  this.filters.filter((f) => f.isMultiColumn),
                  (f) => f.urlWhere,
                ),
              ) as FilterAsParam<T>[],
            },
          ],
        },
      ],
    };
  }

  @computed get asURLSearchParam() {
    const filters = JSON.stringify(this.asWhereParam);

    return { [this.searchParamKey]: filters };
  }

  /**
   * @param exclude Exclude Filters by @property column
   */
  reset(exclude: T[] = [], skipOnChange?: boolean) {
    this.filters
      .filter((f) => !exclude.includes(f.column))
      .forEach((f) => f.setValue([], { skipOnChange }));
  }

  @action hide(filters: T[]) {
    if (!filters.length) {
      // hide all
      this.filters.forEach((f) => f.hide());
    } else {
      this.filtersByColumn.forEach(
        (f, column) => filters.includes(column) && f.hide(),
      );
    }
  }

  /**
   * @default opt.skipOnChange = true
   */
  @action fromURLSearchParam(
    src: Query,
    opt?: { omitColumn?: T[]; skipOnChange?: boolean },
  ) {
    if (this.initializedFromUrl) return;
    this.initializedFromUrl = true;

    const skipOnChange = Boolean(opt?.skipOnChange);
    const whereParam = src[this.searchParamKey];

    if (Array.isArray(whereParam) || !whereParam) return;

    let filters: unknown;
    try {
      filters = JSON.parse(whereParam);
    } catch (error) {
      console.warn("Filters failed to parse JSON from URL");

      return;
    }

    const getFilterAsParam = (
      filter: unknown,
    ): FilterAsParam<T> | undefined => {
      if (!(filter instanceof Object)) return undefined;

      // Validate column
      if (!("column" in filter) || typeof filter["column"] !== "string")
        return undefined;

      // Validate value
      if (
        !("value" in filter) ||
        !(
          typeof filter["value"] !== "string" || !Array.isArray(filter["value"])
        )
      ) {
        return undefined;
      }
      if (Array.isArray(filter["value"])) {
        for (const arrVal of filter["value"] as any[]) {
          if (typeof arrVal !== "string") return undefined;
        }
      }

      return filter as FilterAsParam<T>;
    };

    const getMultiColumnFilters = (filter: any): any[] | undefined => {
      return filter?.AND?.[0]?.OR;
    };

    const processFilter = (value: unknown) => {
      const valueAsFilter = getFilterAsParam(value);
      if (!valueAsFilter) return;

      if (opt?.omitColumn && opt.omitColumn.includes(valueAsFilter.column))
        return;

      const filter = this.filtersByColumn.get(valueAsFilter.column);
      if (!filter) return;

      filter.setValue(valueAsFilter.value, { fromUrl: true, skipOnChange });
    };

    if (
      filters instanceof Object &&
      "AND" in filters &&
      Array.isArray(filters["AND"])
    ) {
      forEach(filters["AND"], (f) => {
        const multiColumnFilters = getMultiColumnFilters(f);

        if (multiColumnFilters) {
          forEach(multiColumnFilters, (mf) => {
            processFilter(mf);
          });
        } else {
          processFilter(f);
        }
      });
    }
  }

  @action addImplicitFilter(
    column: T,
    value: string | string[],
    operator: SqlOperator = SqlOperator.Eq,
  ) {
    this.implicitFilters = [
      ...this.implicitFilters,
      {
        column,
        value,
        operator,
      },
    ];
  }

  clearAllVisibleFilters(skipOnChange?: boolean) {
    this.visibleFilters.forEach((filter) => filter.clear(skipOnChange));
  }
}
