import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Select,
  Switch,
} from "@chakra-ui/react";
import { DndContext } from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { t, Trans } from "@lingui/macro";
import { StartDayOfWeekEnum } from "@src/__generated__/urql-graphql";
import { DragHandle, FormRow } from "@src/components/ui-kit";
import { WORKING_TO_SMALLER_THAN_WORKING_FROM } from "@src/stores/forms/SettingsModalState/PlanningUtilizationState";
import {
  AllocationItemStyleEnum,
  PLANNING_SETTING_PROPERTIES,
  PlanningViewTypeEnum,
} from "@src/stores/models/WorkspaceSettings";
import { useStore } from "@src/utils/hooks";
import { Time } from "@src/utils/types";
import { observer } from "mobx-react-lite";
import { CSSProperties, Fragment, FunctionComponent, ReactNode } from "react";

type SortableItemProps = {
  id: string;
  children: ReactNode;
};
const SortableItem: FunctionComponent<SortableItemProps> = ({
  id,
  children,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
  });

  const containerStyle: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 2 : "initial",
  };

  return (
    <Box ref={setNodeRef} style={containerStyle} {...attributes}>
      <HStack align="center" mb="4">
        <IconButton
          colorScheme="grey"
          size="sm"
          variant="ghost"
          {...listeners}
          aria-label={t`Drag to reorder`}
          icon={<DragHandle />}
        />
        <Box flex="1">{children}</Box>
      </HStack>
    </Box>
  );
};

export const PlanningUtilization: FunctionComponent = observer(
  function PlanningUtilization() {
    const translateDay = {
      [StartDayOfWeekEnum.Monday]: <Trans>Monday</Trans>,
      [StartDayOfWeekEnum.Tuesday]: <Trans>Tuesday</Trans>,
      [StartDayOfWeekEnum.Wednesday]: <Trans>Wednesday</Trans>,
      [StartDayOfWeekEnum.Thursday]: <Trans>Thursday</Trans>,
      [StartDayOfWeekEnum.Friday]: <Trans>Friday</Trans>,
      [StartDayOfWeekEnum.Saturday]: <Trans>Saturday</Trans>,
      [StartDayOfWeekEnum.Sunday]: <Trans>Sunday</Trans>,
    };

    const {
      settingsModalStore: { planningUtilizationStore },
    } = useStore();

    return (
      <Fragment>
        <Heading as="h3" marginBlockEnd="3" marginBlockStart="6" size="md">
          <Trans>Resource planning</Trans>
        </Heading>
        <FormRow
          title={<Trans>Working hours</Trans>}
          description={
            <Trans>
              This setting defines the designated time range for work
              activities. When planning time-offs, these working hours are
              utilized to calculate the duration of absence.
            </Trans>
          }
        >
          <Flex align="center" gap="4">
            <Input
              name="working_from"
              onChange={(event) => {
                planningUtilizationStore.form.working_from.onChange(
                  event.target.value as Time,
                );
              }}
              type="time"
              value={planningUtilizationStore.form.working_from.value}
            />
            {" - "}
            <FormControl
              isInvalid={
                planningUtilizationStore.form.form.error ===
                WORKING_TO_SMALLER_THAN_WORKING_FROM
              }
            >
              <Input
                name="working_to"
                onChange={(event) => {
                  planningUtilizationStore.form.working_to.onChange(
                    event.target.value as Time,
                  );
                }}
                type="time"
                value={planningUtilizationStore.form.working_to.value}
              />
              <FormErrorMessage>
                <Trans>This time must be later than workday start time.</Trans>
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </FormRow>
        <FormRow title={<Trans>First day of week</Trans>}>
          <Select
            name="start_day_of_week"
            onChange={(event) =>
              planningUtilizationStore.form.start_day_of_week.onChange(
                event.target.value as StartDayOfWeekEnum,
              )
            }
            value={planningUtilizationStore.form.start_day_of_week.value}
          >
            <option value={StartDayOfWeekEnum.Monday}>
              {translateDay[StartDayOfWeekEnum.Monday]}
            </option>
            <option value={StartDayOfWeekEnum.Sunday}>
              {translateDay[StartDayOfWeekEnum.Sunday]}
            </option>
          </Select>
        </FormRow>
        <FormRow
          title={<Trans>Default daily capacity</Trans>}
          description={
            <Trans>Sets the default daily plannable capacity for users.</Trans>
          }
        >
          <FormControl
            isInvalid={
              planningUtilizationStore.form.default_user_plannable_capacity
                .hasError
            }
          >
            <InputGroup>
              <Input
                inputMode="numeric"
                max={24}
                min={0}
                name="default_user_plannable_capacity"
                onChange={(event) =>
                  planningUtilizationStore.form.default_user_plannable_capacity.onChange(
                    event.target.value,
                  )
                }
                type="number"
                value={
                  planningUtilizationStore.form.default_user_plannable_capacity
                    .value
                }
              />
              <InputRightAddon>
                <Trans>hours</Trans>
              </InputRightAddon>
            </InputGroup>
            <FormErrorMessage>
              <Trans>Minimum of 0 and maximum of 24 hours is allowed.</Trans>
            </FormErrorMessage>
          </FormControl>
        </FormRow>
        <FormRow
          title={<Trans>Default specific time planning</Trans>}
          description={
            <Trans>
              By default, the “specific time” option for planning capacity
              allocations is set.
            </Trans>
          }
        >
          <Switch
            isChecked={
              planningUtilizationStore.form.specific_time_in_planning_enabled
                .value
            }
            name="specific_time_in_planning_enabled"
            onChange={(event) =>
              planningUtilizationStore.form.specific_time_in_planning_enabled.onChange(
                event.target.checked,
              )
            }
          />
        </FormRow>
        <FormRow
          title={<Trans>Hide daily capacity summary</Trans>}
          description={
            <Trans>
              The summary row displaying the current team’s capacity will not be
              visible to users with the role of ‘User’.
            </Trans>
          }
        >
          <Switch
            isChecked={
              planningUtilizationStore.form.hide_planning_total_daily_capacity
                .value
            }
            name="hide_planning_total_daily_capacity"
            onChange={(event) =>
              planningUtilizationStore.form.hide_planning_total_daily_capacity.onChange(
                event.target.checked,
              )
            }
          />
        </FormRow>
        <FormRow
          title={<Trans>Automated user alphabetical ordering</Trans>}
          description={
            <Trans>
              Automatically sorts new users alphabetically in resource planning.
              Any manual changes to the arrangement will revert back to
              alphabetical upon exiting the resource planning interface.
            </Trans>
          }
        >
          <Switch
            isChecked={
              planningUtilizationStore.form.planning_alphabet_user_order.value
            }
            name="planning_alphabet_user_order"
            onChange={(event) =>
              planningUtilizationStore.form.planning_alphabet_user_order.onChange(
                event.target.checked,
              )
            }
          />
        </FormRow>
        <FormRow
          title={<Trans>Disable home office planning</Trans>}
          description={
            <Trans>
              Prevents users from planning home office in Resource planning.
            </Trans>
          }
        >
          <Switch
            isChecked={
              planningUtilizationStore.form.disable_home_office_in_planning
                .value
            }
            name="disable_home_office_in_planning"
            onChange={(event) =>
              planningUtilizationStore.form.disable_home_office_in_planning.onChange(
                event.target.checked,
              )
            }
          />
        </FormRow>
        <FormRow
          title={<Trans>Auto bottom sorting</Trans>}
          description={
            <Trans>
              When enabled, new capacity allocations without specific times will
              be automatically placed at the end of the list for a particular
              day.
            </Trans>
          }
        >
          <Switch
            isChecked={
              planningUtilizationStore.form
                .planning_sort_prioritize_time_specific_items.value
            }
            name="planning_sort_prioritize_time_specific_items"
            onChange={(event) =>
              planningUtilizationStore.form.planning_sort_prioritize_time_specific_items.onChange(
                event.target.checked,
              )
            }
          />
        </FormRow>

        <Heading as="h4" marginBlockEnd="3" marginBlockStart="6" size="sm">
          <Trans>Default planning view settings</Trans>
        </Heading>
        <FormRow title={<Trans>Card color</Trans>}>
          <Select
            name="allocation_item_style"
            onChange={(event) =>
              planningUtilizationStore.form.allocation_item_style.onChange(
                event.target.value as AllocationItemStyleEnum,
              )
            }
            value={planningUtilizationStore.form.allocation_item_style.value}
          >
            {Object.values(AllocationItemStyleEnum).map((value) => (
              <option key={value} value={value}>
                {
                  {
                    [AllocationItemStyleEnum.COLOR_FILL]: (
                      <Trans>Solid color</Trans>
                    ),
                    [AllocationItemStyleEnum.COLOR_SUBTLE]: (
                      <Trans>Light color</Trans>
                    ),
                    [AllocationItemStyleEnum.NO_COLOR]: <Trans>White</Trans>,
                  }[value]
                }
              </option>
            ))}
          </Select>
        </FormRow>
        <FormRow title={<Trans>Starting day</Trans>}>
          <Select
            name="view_type"
            onChange={(event) =>
              planningUtilizationStore.form.view_type.onChange(
                event.target.value as PlanningViewTypeEnum,
              )
            }
            value={planningUtilizationStore.form.view_type.value}
          >
            {Object.values(PlanningViewTypeEnum).map((value) => (
              <option key={value} value={value}>
                {
                  {
                    [PlanningViewTypeEnum.WEEK_START]:
                      translateDay[
                        planningUtilizationStore.form.start_day_of_week.$
                      ],
                    [PlanningViewTypeEnum.WEEK_FROM_NOW]: <Trans>Today</Trans>,
                  }[value]
                }
              </option>
            ))}
          </Select>
        </FormRow>
        <FormRow title={<Trans>Adjust allocation height by duration</Trans>}>
          <Switch
            isChecked={
              planningUtilizationStore.form.adjust_allocation_height_by_duration
                .value
            }
            name="adjust_allocation_height_by_duration"
            onChange={(event) =>
              planningUtilizationStore.form.adjust_allocation_height_by_duration.onChange(
                event.target.checked,
              )
            }
          />
        </FormRow>
        <Box>
          <DndContext
            onDragEnd={(event) => planningUtilizationStore.form.reorder(event)}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext
              strategy={verticalListSortingStrategy}
              items={planningUtilizationStore.form.text_order}
            >
              {planningUtilizationStore.form.text_order.map((item) => (
                <SortableItem key={item} id={item}>
                  <FormRow
                    m="0"
                    title={
                      {
                        [PLANNING_SETTING_PROPERTIES.SHOW_PROJECT]: (
                          <Trans>Show project</Trans>
                        ),
                        [PLANNING_SETTING_PROPERTIES.SHOW_TASK]: (
                          <Trans>Show task</Trans>
                        ),
                        [PLANNING_SETTING_PROPERTIES.SHOW_BUDGET_ITEM]: (
                          <Trans>Show budget item</Trans>
                        ),
                      }[item]
                    }
                    titleContainerStyle={{
                      width: "calc(30% - 2.5rem) !important",
                    }}
                    formContainerStyle={{
                      maxWidth: "calc(70% - 0.75rem + 2.5rem) !important",
                    }}
                  >
                    <Switch
                      isChecked={planningUtilizationStore.form[item].value}
                      name={item}
                      onChange={(event) =>
                        planningUtilizationStore.form[item].onChange(
                          event.target.checked,
                        )
                      }
                    />
                  </FormRow>
                </SortableItem>
              ))}
            </SortableContext>
          </DndContext>
        </Box>
        <FormRow title={<Trans>Show note</Trans>}>
          <Switch
            isChecked={planningUtilizationStore.form.show_note.value}
            name="show_note"
            onChange={(event) =>
              planningUtilizationStore.form.show_note.onChange(
                event.target.checked,
              )
            }
          />
        </FormRow>

        <Heading as="h3" marginBlockEnd="3" marginBlockStart="10" size="md">
          <Trans>Utilization</Trans>
        </Heading>
        <FormRow
          title={<Trans>Utilization thresholds</Trans>}
          description={
            <Trans>
              Defines the range for users utilization thresholds, indicating
              when the progress bar turns red (below threshold) and green
              (within threshold).
            </Trans>
          }
        >
          <RangeSlider
            // eslint-disable-next-line jsx-a11y/aria-proptypes
            aria-label={["min", "max"]}
            max={100}
            min={1}
            name={[
              "default_utilization_range_from",
              "default_utilization_range_to",
            ]}
            onChange={(values) => {
              planningUtilizationStore.form.default_utilization_range_from.onChange(
                values[0],
              );
              planningUtilizationStore.form.default_utilization_range_to.onChange(
                values[1],
              );
            }}
            value={[
              planningUtilizationStore.form.default_utilization_range_from
                .value,
              planningUtilizationStore.form.default_utilization_range_to.value,
            ]}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb pos="relative" index={0}>
              <Box pos="absolute" bottom="5">
                {
                  planningUtilizationStore.form.default_utilization_range_from
                    .value
                }
              </Box>
            </RangeSliderThumb>
            <RangeSliderThumb index={1}>
              <Box pos="absolute" bottom="5">
                {
                  planningUtilizationStore.form.default_utilization_range_to
                    .value
                }
              </Box>
            </RangeSliderThumb>
          </RangeSlider>
        </FormRow>
      </Fragment>
    );
  },
);
