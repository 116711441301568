import { WorkspaceSettingsQuery } from "@src/__generated__/urql-graphql";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ProjectsSettingsState } from "@src/stores/forms/SettingsModalState/ProjectsSettingsState";
import { action, makeObservable, observable } from "mobx";

export class ProjectsSettingsStore implements BaseStore {
  @observable canEditProjectCode = false;
  @observable hasMoreOurWorkBudgetCategories = false;

  constructor(
    public appStore: AppStore,
    public form: ProjectsSettingsState,
  ) {
    makeObservable(this);
  }

  @action.bound init(data: WorkspaceSettingsQuery) {
    this.canEditProjectCode = data.workspaceProjectSettings.project_count === 0;
    this.hasMoreOurWorkBudgetCategories =
      data.workspaceProjectSettings.our_work_budget_categories_count > 1;
  }
}
