import { Button, VStack } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { CalendarValues } from "@uselessdev/datepicker";
import {
  addDays,
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from "date-fns";
import { now } from "mobx-utils";
import { useMemo } from "react";

export type CalendarActionsProps = {
  setDates: (dates: CalendarValues) => void;
  customActions?: CalendarAction[];
};

export type CalendarAction = {
  title: string;
  getDates: () => CalendarValues;
};

export const CalendarActions = ({
  setDates,
  customActions,
}: CalendarActionsProps) => {
  const actionItems = useMemo(() => {
    if (!!customActions) return customActions;
    const today = new Date(now());

    return [
      {
        title: t`Next 7 days`,
        getDates: () => {
          return {
            start: today,
            end: addDays(today, 7),
          };
        },
      },
      {
        title: t`Next 14 days`,
        getDates: () => {
          return {
            start: today,
            end: addDays(today, 14),
          };
        },
      },
      {
        title: t`Next 30 days`,
        getDates: () => {
          return {
            start: today,
            end: addDays(today, 30),
          };
        },
      },
      {
        title: t`This month`,
        getDates: () => {
          return {
            start: startOfMonth(today),
            end: endOfMonth(today),
          };
        },
      },
      {
        title: t`Last month`,
        getDates: () => {
          return {
            start: startOfMonth(subMonths(today, 1)),
            end: endOfMonth(subMonths(today, 1)),
          };
        },
      },
      {
        title: t`This year`,
        getDates: () => {
          return {
            start: startOfYear(today),
            end: endOfYear(today),
          };
        },
      },
      {
        title: t`Last year`,
        getDates: () => {
          return {
            start: startOfYear(subYears(today, 1)),
            end: endOfYear(subYears(today, 1)),
          };
        },
      },
    ];
  }, [customActions]);

  return (
    <VStack
      align="stretch"
      px={{
        base: "1",
        md: "3",
      }}
      py="4"
      borderColor="grey.100"
      borderRightWidth="1px"
      spacing="2"
    >
      {actionItems.map((item) => (
        <Button
          key={item.title}
          justifyContent="start"
          colorScheme="grey"
          onClick={() => setDates(item.getDates())}
          size="xs"
          variant="ghost"
        >
          {item.title}
        </Button>
      ))}
    </VStack>
  );
};
