import { ErrorScreen } from "@components/ui-kit";
import { t } from "@lingui/macro";

export const NotFoundContractor = () => (
  <ErrorScreen
    title={t`I didn’t find the contractor you are looking for.`}
    link={{
      text: t`See all contractors`,
      href: "/contractors",
    }}
  />
);
