import { ErrorScreen } from "@components/ui-kit";
import { ContentContainer } from "@components/widgets/Layout/ContentContainer";
import { t } from "@lingui/macro";

const NotFound = () => (
  <ContentContainer>
    <div className="h-screen">
      <ErrorScreen
        title={t`I didn’t find the link you are trying to load.`}
        link={{
          text: t`See my homepage`,
          href: "/",
        }}
      />
    </div>
  </ContentContainer>
);

export { NotFound };
