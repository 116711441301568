import React, { Fragment, isValidElement, ReactNode } from "react";

interface WrapComponentProps<ChildrenType = ReactNode> {
  children: ChildrenType;
  if: boolean;
  with: (children: ChildrenType) => JSX.Element;
  elseWith?: (children: ChildrenType) => JSX.Element;
}

export const WrapComponent = function WrapComponent<T extends ReactNode>({
  if: condition,
  with: wrap,
  elseWith,
  children,
}: WrapComponentProps<T>) {
  return condition ? (
    isValidElement(children) ? (
      React.cloneElement(wrap(children))
    ) : (
      wrap(children)
    )
  ) : elseWith ? (
    isValidElement(children) ? (
      React.cloneElement(elseWith(children))
    ) : (
      elseWith(children)
    )
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Fragment>{children}</Fragment>
  );
};
