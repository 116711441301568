import {
  AttachmentFragment,
  useCreateAssignableFileMutation,
} from "@src/__generated__/urql-graphql";

export const useUpload = () => {
  const [_, upload] = useCreateAssignableFileMutation();

  return () => {
    const controller = new AbortController();
    const _upload = async (
      file: File,
    ): Promise<AttachmentFragment | undefined> => {
      const result = await upload(
        { file },
        {
          fetchOptions: {
            signal: controller.signal,
            credentials: "include",
          },
        },
      );
      if (!result.data?.createAssignableFile) return undefined;
      return result.data.createAssignableFile;
    };

    const cancel = () => controller.abort();

    return { upload: _upload, cancel };
  };
};
