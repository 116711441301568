import { Button } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import {
  useStartTimerMutation,
  useStopTimerMutation,
} from "@src/__generated__/graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import { trackEvent } from "@src/services/amplitude";
import { onError } from "@src/utils/apollo";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store";

interface PlayPauseButtonProps {
  /** timeTrackingItem.id */
  id: string;
  isRunning: boolean;
}

const PlayPauseButton = ({ id, isRunning }: PlayPauseButtonProps) => {
  const store = useStore();

  const [start, { loading: startLoading }] = useStartTimerMutation({
    variables: { time_tracking_item_id: id },
    onCompleted(data) {
      if (data?.startTimer) {
        store.setEntryHasRunningTimer(id, true);
        trackEvent("time-entry", "Started tracking");
      }
    },
    ...onError(),
  });

  const [stop, { loading: stopLoading }] = useStopTimerMutation({
    variables: { time_tracking_item_id: id },
    onCompleted(data) {
      if (data?.stopTimer) {
        store.setEntryHasRunningTimer(id, false);
        store.timeTrackingItemsRefetch?.();
        trackEvent("time-entry", "Stopped tracking");
      }
    },
    ...onError(),
  });

  return (
    <Button
      colorScheme="blue"
      isLoading={stopLoading || startLoading}
      leftIcon={
        <Icon
          color="blue.500"
          variant="solid"
          name={isRunning ? "pause-circle" : "play"}
        />
      }
      onClick={() => (isRunning ? stop() : start())}
      variant="subtle"
    >
      {isRunning ? t`Stop timer` : t`Start timer`}
    </Button>
  );
};

export default observer(PlayPauseButton);
