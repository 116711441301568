import { Box } from "@chakra-ui/react";
import { Link } from "@src/components/ui-kit";
import { observer } from "mobx-react-lite";
import { LinkProps } from "next/link";

export interface LinkTitleProps {
  href: LinkProps["href"];
  children: string;
}

const LinkTitle = ({ href, children }: LinkTitleProps) => (
  <Box pb="4">
    <Link href={href}>{children}</Link>
  </Box>
);

export default observer(LinkTitle);
