import { Box, Text } from "@chakra-ui/react";
import { Spinner } from "@src/components/ui-kit";
import { observer } from "mobx-react-lite";

interface UploadingProps {
  name: string;
}

const Uploading = ({ name }: UploadingProps) => {
  return (
    <Box w="125px">
      <Box h="100px">
        <Spinner />
      </Box>
      <Text mt="2" fontSize="2xs" fontWeight="semibold" noOfLines={1}>
        {name}
      </Text>
    </Box>
  );
};

export default observer(Uploading);
