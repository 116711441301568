import { t } from "@lingui/macro";
import {
  mustBeAfter,
  mustBeBefore,
  required,
} from "@src/utils/forms/validators";
import { FieldState, FormState } from "formstate";

export class Form {
  mustBeBeforeEnd = ($: Date | undefined) => {
    const result = mustBeBefore(this.end, t`Date To`)($);
    if (result === null) {
      this.end.setError("");
    }
    return result;
  };

  mustBeAfterStart = ($: Date | undefined) => {
    const result = mustBeAfter(this.start, t`Date From`)($);
    if (result === null) {
      this.start.setError("");
    }
    return result;
  };

  start: FieldState<Date | undefined>;
  end: FieldState<Date | undefined>;
  formState: FormState<FieldState<any>[]>;

  constructor(opt: { required: boolean }) {
    const startValidators = [];
    const endValidators = [];

    if (opt.required) {
      startValidators.push(required);
      endValidators.push(required);
    }
    startValidators.push(this.mustBeBeforeEnd);
    endValidators.push(this.mustBeAfterStart);

    this.start = new FieldState<Date | undefined>(undefined).validators(
      ...startValidators,
    );
    this.end = new FieldState<Date | undefined>(undefined).validators(
      ...endValidators,
    );
    this.formState = new FormState([this.start, this.end]);
  }

  get range() {
    const start = this.start.value;
    const end = this.end.value;

    if (start && end) return { start, end };

    return undefined;
  }
}
