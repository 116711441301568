import {
  Box,
  Stack,
  StackProps,
  SystemStyleObject,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Icon } from "@src/components/ui-kit/Icon";
import cx from "classnames";
import React, { ForwardRefRenderFunction, ReactNode, forwardRef } from "react";

interface FormRowProps extends Omit<StackProps, "title"> {
  title?: ReactNode;
  description?: ReactNode;

  titleClassName?: string;
  className?: string;
  style?: React.CSSProperties;
  children?: ReactNode;
  formContainerStyle?: SystemStyleObject | undefined;
  titleContainerStyle?: SystemStyleObject | undefined;
}

const FormRow: ForwardRefRenderFunction<HTMLDivElement, FormRowProps> = (
  {
    title,
    titleClassName,
    className,
    style,
    children,
    description,
    formContainerStyle,
    titleContainerStyle,
    ...props
  },
  ref,
) => (
  <Stack
    className={className}
    ref={ref}
    align="baseline"
    justify="space-between"
    direction={{
      base: "column",
      md: "row",
    }}
    mb="4"
    spacing="3"
    style={style}
    {...props}
  >
    <Box
      sx={titleContainerStyle}
      w={{
        base: "full",
        md: "30%",
      }}
    >
      <Text className={cx("text-2xl", titleClassName)}>
        {title}
        {description && (
          <Tooltip hasArrow label={description}>
            <span tabIndex={0}>
              <Icon name="info-circle" marginInlineStart="2" />
            </span>
          </Tooltip>
        )}
      </Text>
    </Box>
    <Box
      sx={formContainerStyle}
      flex="1"
      w={{
        base: "full",
        md: undefined,
      }}
      maxW={{
        base: "full",
        md: "calc(70% - 10px)",
      }}
    >
      {children}
    </Box>
  </Stack>
);

export default forwardRef<HTMLDivElement, FormRowProps>(FormRow);
