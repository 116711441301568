import { Box, Flex, Text } from "@chakra-ui/react";
import {
  ExpensesPopover,
  ExpensesProps,
  ExpensesToReceivePopover,
  ProfitEstimatePopover,
} from "@src/components/modules/projects/detail/ProgressBar/Expenses";
import { currency, HUNDRED_PERCENT } from "@src/utils/formatters";
import { observer } from "mobx-react-lite";
import { p } from ".";

const profitColor = "#A0ACB6";
const profitHoverColor = "#80909E";
const secondColor = "#FF0043";

const overlapStripedBg = `linear-gradient(135deg, ${profitColor} 25%, ${secondColor} 25%, ${secondColor} 50%, ${profitColor} 50%, ${profitColor} 75%, ${secondColor} 75%, ${secondColor} 100%)`;
const overlapHoverStripedBg = `linear-gradient(135deg, ${profitHoverColor} 25%, ${secondColor} 25%, ${secondColor} 50%, ${profitHoverColor} 50%, ${profitHoverColor} 75%, ${secondColor} 75%, ${secondColor} 100%)`;
// eslint-disable-next-line lingui/no-unlocalized-strings
const bgSize = "5.66px 5.66px";

export const ProgressBarExpensesReceived = observer<
  {
    variant?: "normal" | "big";
    isOpen?: boolean;
  } & Omit<ExpensesProps, "hidePopover">
>(function ProgressBarExpensesReceived({
  stats,
  showFinalCommission,
  showActualProfit,
  actualProfit,
  variant,
  isOpen,
  onSectionHover,
  isCompleted,
  section,
  isTimeAndMaterials,
}) {
  const {
    profit,
    profit_percent,
    planned_profit,
    planned_expenses,
    real_expenses,
    real_expenses_percent,
    real_commission,
    real_commission_relative,
  } = stats;
  const height = variant === "big" ? 8 : 2;
  const total_budget = planned_profit + planned_expenses;

  let green = 0;
  let overlap = 0;
  let gray = 0;
  let white = 0;

  if (real_expenses < total_budget) {
    if (real_expenses < planned_expenses) {
      white = (planned_profit / total_budget) * 100;
      green = (real_expenses / total_budget) * 100;
      gray = 100 - white - green;
    } else {
      green = (planned_expenses / total_budget) * 100;
      overlap = ((real_expenses - planned_expenses) / total_budget) * 100;
      white = 100 - overlap - green;
    }
  } else {
    green = (planned_expenses / total_budget) * 100;
    overlap = ((real_expenses - planned_expenses) / total_budget) * 100;
    white = 100 - overlap - green;
  }

  if ([total_budget, planned_expenses].includes(0)) {
    gray = 100;
    white = 0;
  }

  return (
    <Flex
      pos="relative"
      overflow="hidden"
      w="full"
      h={height}
      _hover={{ color: "black" }}
      role="group"
      rounded="2xl"
    >
      {!!green && (
        <ExpensesPopover
          received_expenses={real_expenses}
          received_expenses_percent={real_expenses_percent}
          actualProfit={actualProfit}
          showActualProfit={showActualProfit}
          showFinalCommission={showFinalCommission}
          isOpen={isOpen}
          real_commission={real_commission}
          real_commission_relative={real_commission_relative}
          isTimeAndMaterials={isTimeAndMaterials}
        >
          <Box
            flex={p(green)}
            h={height}
            bg={section === "received" ? "teal.600" : "teal.500"}
            onMouseEnter={() => {
              onSectionHover?.("received");
            }}
            onMouseLeave={() => {
              onSectionHover?.(undefined);
            }}
          />
        </ExpensesPopover>
      )}
      {!!gray && (
        <ExpensesToReceivePopover
          isTimeAndMaterials={isTimeAndMaterials}
          amount={planned_expenses - real_expenses}
          percent={HUNDRED_PERCENT - real_expenses_percent}
        >
          <Box
            flex={p(gray)}
            h={height}
            bg="gray.200"
            onMouseEnter={() => {
              onSectionHover?.("expected");
            }}
            onMouseLeave={() => {
              onSectionHover?.(undefined);
            }}
          />
        </ExpensesToReceivePopover>
      )}
      {!!overlap && (
        <Box
          flex={p(overlap)}
          style={{
            backgroundImage:
              section === "profit" ? overlapHoverStripedBg : overlapStripedBg,
            backgroundSize: bgSize,
          }}
        />
      )}
      {!!white && (
        <ProfitEstimatePopover
          planned_profit={planned_profit}
          total_budget={total_budget}
          isFinished={isCompleted}
          final_profit={profit}
          final_profit_percent={profit_percent}
        >
          <Box
            flex={p(white)}
            bg={
              section === "profit" || section === "final_profit"
                ? profitHoverColor
                : profitColor
            }
            onMouseEnter={() => {
              onSectionHover?.(isCompleted ? "final_profit" : "profit");
            }}
            onMouseLeave={() => {
              onSectionHover?.(undefined);
            }}
          />
        </ProfitEstimatePopover>
      )}
      {isTimeAndMaterials && (
        <Flex
          pos="absolute"
          zIndex="1"
          right="0"
          left="0"
          align="center"
          justify="center"
          h="full"
        >
          <Text
            px="2"
            fontWeight="medium"
            lineHeight="0"
            transition="color 150ms ease-in"
          >
            {currency.formatByWorkspace(real_expenses)}
          </Text>
        </Flex>
      )}
    </Flex>
  );
});
