import { deburr } from "lodash";
import { IGroupOption, IOption } from "./types";

// Custom search
// Default search is extended by search by group
export const filterOption = (
  { label, value }: { label: string; value: string },
  str: string,
  options: IOption[] | IGroupOption[],
) => {
  str = deburr(str.toLowerCase());

  // default search
  if (deburr(label.toLowerCase()).includes(str)) {
    return true;
  }

  // group search
  const matchedOptions = (options as any[]).filter(
    (opt) => "label" in opt && deburr(opt.label.toLowerCase()).includes(str),
  );

  for (const opt of matchedOptions) {
    if (
      Array.isArray(opt?.options) &&
      (opt.options as IOption[]).find(
        (o) => typeof o !== "string" && o.value === value,
      )
    ) {
      return true;
    }
  }

  return false;
};
