import { IGroupOption, IOption } from "./types";

export function extractOptions(options: IOption[] | IGroupOption[]): IOption[] {
  const innerOptions: IOption[] = [];
  for (const opt of options) {
    if ("options" in opt && Array.isArray(opt.options)) {
      // use recursion on nested options
      innerOptions.push(...extractOptions(opt.options));
    } else if ("value" in opt) {
      innerOptions.push(opt);
    }
  }
  return innerOptions;
}
