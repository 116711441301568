export const toOptions = <T extends { id: string }, K extends keyof T>(
  data: T[],
  labelKey: K | ((item: T) => string),
): {
  label: string;
  value: string;
}[] => {
  const getLabel =
    typeof labelKey === "function"
      ? labelKey
      : (item: T) => String(item[labelKey]);
  return data.map((i) => ({ label: getLabel(i), value: i.id }));
};
