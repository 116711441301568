import { Box, HStack, StyleProps } from "@chakra-ui/react";
import { FC } from "react";

type Props = {
  size?: "sm" | "md" | "lg";
  height?: StyleProps["h"];
  segments: Array<TProgressBarSegment>;
  onSegmentHover?: (name: string | undefined) => void;
};

export type TProgressBarSegment = {
  bg: StyleProps["bg"];
  /** A number between 0 and 10000 */
  percent: number;
  name?: string;
};

export const ProgressBar: FC<Props> = ({
  size = "md",
  segments,
  onSegmentHover,
  height,
}) => {
  const h = height ?? size === "md" ? "16px" : size === "sm" ? "8px" : "24px";

  return (
    <HStack
      justify="left"
      overflow="hidden"
      w="full"
      h={h}
      bg="grey.100"
      rounded="lg"
      spacing="0"
    >
      {segments.map(({ percent, bg, name }) => {
        const dividedPercent = percent * 0.01;

        return (
          <Box
            key={name}
            w={dividedPercent.toFixed(1) + "%"}
            h={h}
            bg={bg}
            onMouseEnter={() => {
              if (!name) return;
              onSegmentHover?.(name);
            }}
            onMouseLeave={() => {
              onSegmentHover?.(undefined);
            }}
          />
        );
      })}
    </HStack>
  );
};
