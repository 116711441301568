import { Attachment } from "@src/components/ui-kit/TextEditor/extensions/UploadAttachmentPlugin";
import { now } from "@src/utils/dates";
import { isEqual } from "date-fns";

type TextEditorDraft = {
  value: string;
  attachments: Attachment[];
  date: string;
};

const DRAFT_PREFIX = "text-editor-draft";

function geTextEditorDraft(key: string): TextEditorDraft | undefined {
  try {
    const draft = localStorage.getItem(key);

    if (!draft) return;

    const parsed: TextEditorDraft | undefined = JSON.parse(draft);

    return parsed;
  } catch (e) {
    console.error(e);
    return;
  }
}

/**
 * Clears editor draft values older than 24 hours
 */
export function clearEditorDrafts() {
  const draftKeys = Object.keys(localStorage);

  draftKeys.forEach((key) => {
    if (key.split("-")?.[0] !== DRAFT_PREFIX) return;

    const draftValue = geTextEditorDraft(key);

    if (!draftValue?.date) return;

    const date = new Date(draftValue.date);
    const today = new Date(now().toDateString());

    if (isEqual(today, date)) return;

    localStorage.removeItem(key);
  });
}

export class TextEditorDraftStorage {
  draftKey: string;

  constructor(draftKey: string) {
    this.draftKey = `${DRAFT_PREFIX}-${draftKey}`;
  }

  /**
   * @param value value to draft storage
   */
  public set(value: string, attachments: Attachment[]) {
    const draft: TextEditorDraft = {
      value,
      attachments,
      date: new Date().toDateString(),
    };

    const stringified = JSON.stringify(draft);

    localStorage.setItem(this.draftKey, stringified);
  }

  /**
   * @returns draft value or undefined
   */
  public get(): TextEditorDraft | undefined {
    return geTextEditorDraft(this.draftKey);
  }

  /**
   * Clears cache
   */
  public clear() {
    localStorage.removeItem(this.draftKey);
  }
}
