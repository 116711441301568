import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import {
  EmojiPicker,
  EmojiPickerProps,
} from "@src/components/ui-kit/EmojiPicker";
import { FC, ReactNode } from "react";

interface EmojiPopoverProps {
  trigger: ReactNode;
  onEmojiSelect: EmojiPickerProps["onEmojiSelect"];
}

export const EmojiPopover: FC<EmojiPopoverProps> = ({
  trigger,
  onEmojiSelect,
}) => {
  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        {/* Wrap with `div` element to allow trigger to be wrapped in Tooltip component */}
        <div>{trigger}</div>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <EmojiPicker {...{ onEmojiSelect }} />
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
