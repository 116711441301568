import { Project } from "@src/__generated__/urql-graphql";

type toProjectOptionsParams = {
  projects: Pick<Project, "id" | "code" | "title">[];
  modifier: (option: ProjectOption) => ProjectOption;
};

type ProjectOption = {
  label: string;
  value: Project["id"];
};

export const toProjectOptions = (
  projects: toProjectOptionsParams["projects"],
  modifier: toProjectOptionsParams["modifier"] = (option) => option,
): ProjectOption[] =>
  projects.map((p) =>
    modifier({
      label: `${p.code} ${p.title}`,
      value: p.id,
    }),
  );
