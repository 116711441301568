import { User } from "@src/__generated__/urql-graphql";

type ToOptionsParam = Pick<User, "id" | "full_name" | "codename">[];

const toOptions = (projectManagers: ToOptionsParam) =>
  projectManagers.map((i) => ({
    label: `${i.full_name} (${i.codename})`,
    value: i.id,
  }));

export default { toOptions };
