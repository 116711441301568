import { Text } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { formatDuration } from "@src/utils/formatters";
import { sumBy } from "lodash";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Store } from "../../store";

interface WeekTotalProps {
  items: Store["summaryPerDay"];
}

const WeekTotal = ({ items }: WeekTotalProps) => {
  const total = useMemo(
    () => formatDuration(sumBy(items, "tracked_time"), { noPadStart: true }),
    [items],
  );

  return (
    <div className="TimeSheet__SummaryItem self-stretch flex w-full items-center justify-center bg-skyLighter px-6">
      <p className="text-inkLighter text-2xl mr-2">{t`Week total:`}</p>
      <Text fontSize="sm" fontWeight="medium">
        {total}
      </Text>
    </div>
  );
};

export default observer(WeekTotal);
