import { Button } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import {
  useCreateTimeTrackingItemMutation,
  useStartTimerMutation,
  useUpdateTimeTrackingItemMutation,
} from "@src/__generated__/graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import { trackEvent } from "@src/services/amplitude";
import { onError } from "@src/utils/apollo";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { EntryForm } from "../form";

interface StartSaveButtonProps {
  form: EntryForm;
  onStart: () => void;
  onSave: () => void;
}

const StartSaveButton = ({ form, onStart, onSave }: StartSaveButtonProps) => {
  const { authStore } = useStore();
  const userId = authStore.user?.id!;

  const startAfterCreate = !form.editMode && !form.time.$;

  const [create, { loading: createLoading }] =
    useCreateTimeTrackingItemMutation({
      onCompleted({ createTimeTrackingItem }) {
        startAfterCreate
          ? start({
              variables: { time_tracking_item_id: createTimeTrackingItem.id },
            })
          : onSave();
      },
      ...onError(),
    });

  const [update, { loading: updateLoading }] =
    useUpdateTimeTrackingItemMutation({
      onCompleted() {
        onSave();
      },
      ...onError(),
    });

  const [start, { loading: startLoading }] = useStartTimerMutation({
    onCompleted() {
      onStart();
    },
    ...onError(),
  });

  const _onSubmit = async () => {
    const { hasError } = await form.validate();

    if (hasError) {
      return;
    }

    if (form.editMode) {
      update({ variables: form.serializeUpdate(userId) });
    } else {
      create({ variables: form.serializeCreate(userId) });
    }

    trackEvent(
      "time-entry",
      startAfterCreate ? "Started tracking" : "Updated entry",
    );
  };

  const loading = createLoading || updateLoading || startLoading;

  return (
    <Button
      isLoading={loading}
      leftIcon={
        startAfterCreate ? (
          <Icon
            name="play"
            variant="solid"
            style={{ transform: "scale(0.8)" }}
          />
        ) : undefined
      }
      onClick={_onSubmit}
    >
      {startAfterCreate ? t`Start timer` : t`Save entry`}
    </Button>
  );
};

export default observer(StartSaveButton);
