import { WeekTimeReportQuery } from "@src/__generated__/graphql";
import { addDays, differenceInCalendarDays } from "date-fns";
import { FieldState } from "formstate";
import { computed, makeObservable } from "mobx";

type WeeklyTimeReportRow = WeekTimeReportQuery["weekTimeReport"]["rows"][0];

export class WeekRow {
  task: WeeklyTimeReportRow["task"];
  timeTrackingWorkType: WeeklyTimeReportRow["timeTrackingWorkType"];

  // Sorted by dayOfWeek 0(Mon)-6(Sun)
  days: Day[] = [];

  constructor(src: WeeklyTimeReportRow, startOfWeek: Date) {
    makeObservable(this);
    this.task = src.task;
    this.timeTrackingWorkType = src.timeTrackingWorkType;

    let i = 0;
    while (i < 7) {
      const daySrc = src.trackedTime[i];

      if (daySrc) {
        const dayOfWeek = differenceInCalendarDays(
          new Date(daySrc.date),
          startOfWeek,
        );
        this.days[dayOfWeek] = new Day(
          new Date(daySrc.date),
          daySrc.tracked_time,
        );
      }

      if (!this.days[i]) {
        this.days[i] = new Day(addDays(startOfWeek, i), 0);
      }

      i += 1;
    }
  }

  get id() {
    return this.task.id;
  }

  @computed get totalTrackedTime() {
    return this.days.reduce((r, i) => (i.trackedTime.value ?? 0) + r, 0);
  }
}

class Day {
  date: Date;
  trackedTime: FieldState<number | undefined>;

  constructor(date: Date, trackedTime: number) {
    this.date = date;
    this.trackedTime = new FieldState<number | undefined>(trackedTime);
  }
}
