import { formatDate } from "@components/ui-kit";
import { Trans } from "@lingui/macro";
import { useStore } from "@src/utils/hooks";
import cs from "classnames";
import { endOfWeek, format, isThisWeek, startOfWeek } from "date-fns";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";

interface CurrentDateProps {
  current: Date;
  weekMode?: boolean;
  className?: string;
}

const CurrentDate = ({ current, weekMode, className }: CurrentDateProps) => {
  const { workspaceStore } = useStore();
  const weekStartsOn = computed(
    () => workspaceStore.settings?.startOfWeekNumber,
  ).get();
  const _startOfWeek = startOfWeek(current, { weekStartsOn });
  const _endOfWeek = endOfWeek(current, { weekStartsOn });
  const _isThisWeek = isThisWeek(current);

  return (
    <div className={cs(className || "text-3xl")}>
      {weekMode ? (
        <React.Fragment>
          {_isThisWeek && (
            <React.Fragment>
              <Trans>This week:</Trans>{" "}
            </React.Fragment>
          )}
          {`${format(_startOfWeek, "d MMMM")} - ${format(_endOfWeek, "d MMMM y")}`}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {formatDate(current)} ({format(current, "EEEE")})
        </React.Fragment>
      )}
    </div>
  );
};

export default observer(CurrentDate);
