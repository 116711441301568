import { Badge, Center } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { FC } from "react";

type TProps = {
  billable: boolean | undefined;
};

export const BillableBadge: FC<TProps> = ({ billable }) => {
  return billable ? (
    <Badge fontWeight="medium" colorScheme="green">
      <Trans>Billable</Trans>
    </Badge>
  ) : (
    <Badge display="inline-flex" fontWeight="medium" colorScheme="grey">
      <Center>
        <Icon name="non-billable" />
      </Center>
      <Trans>Non-Billable</Trans>
    </Badge>
  );
};
