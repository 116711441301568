import { scalarLocations } from "@src/__generated__/scalarLocations";
import { toApiDate, toApiDateTime } from "@src/utils/dates";
import {
  convertTimeToTimeWithSeconds,
  convertTimeWithSecondsToTime,
} from "@src/utils/time";
import { Time, TimeWithSeconds } from "@src/utils/types";
import createSerializeScalarsExchange from "./urql-serialize-scalars-exchange";

export const serializeScalarsExchange = createSerializeScalarsExchange(
  scalarLocations,
  {
    Time: {
      deserialize: (value) => {
        return convertTimeWithSecondsToTime(value as TimeWithSeconds);
      },
      serialize: (value) =>
        !!value ? convertTimeToTimeWithSeconds(value as Time) : value,
    },
    Date: {
      deserialize: (value) => new Date(value),
      serialize: (value) => toApiDate(value),
    },
    DateTime: {
      deserialize: (value) => (!!value ? new Date(value) : value),
      serialize: (value) => (!!value ? toApiDateTime(value) : value),
    },
  },
);
