import cx from "classnames";
import { observer } from "mobx-react-lite";
import s from "./ProgressBar.module.css";

type TColors =
  | "red"
  | "purple"
  | "white"
  | "green"
  | "dottedGreen"
  | "gray"
  | "dottedGray"
  | "darkGray"
  | "darkGrayDotted";

interface ProgressBarProps {
  /** Default: green */
  color?: TColors;
  bgColor?: string;
  progress: number;
  show100?: boolean;
}

const ProgressBar = ({
  color = "green",
  bgColor = "bg-sky",
  show100,
  progress,
}: ProgressBarProps) => (
  <div className={cx(s.box, bgColor)} style={{ width: "100%" }}>
    <div
      className={cx(
        color ? s[color] : s.green,
        "flex justify-center text-white",
      )}
      style={{ width: `${progress}%`, lineHeight: "10px" }}
    >
      {progress! === 100 && show100 && <div>100%</div>}
    </div>
  </div>
);

export default observer(ProgressBar);
