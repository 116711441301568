import { Box, Heading, HStack, Stack, Text } from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { UtilizationReportDataSource } from "@src/__generated__/urql-graphql";
import { DataTable } from "@src/components/ui-kit";
import {
  ProgressBar,
  TProgressBarSegment,
} from "@src/components/ui-kit/NewProgressBar";
import { HUNDRED_PERCENT } from "@src/utils/formatters";
import { useStore } from "@src/utils/hooks";
import { formatSecondsToHours } from "@src/utils/time";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { FC } from "react";

type Props = {
  totalTime: number | undefined;
  allocatedTime: number | undefined;
  trackedTime: number | undefined;
  taskBudgetedTime: number | undefined;
  combinedTime: number | undefined;
};

export const TrackedAllocatedStatCard: FC<Props> = observer(
  function TrackedAllocatedStatCard({
    allocatedTime = 0,
    trackedTime = 0,
    taskBudgetedTime = 0,
    totalTime = 0,
    combinedTime = 0,
  }) {
    const { utilizationReportStore: store } = useStore();

    const cardName = computed(() => {
      let name = "";

      switch (store.activeDataSourceOption?.value) {
        case UtilizationReportDataSource.Combined:
          name = t`Tracked & Allocated`;
          break;
        case UtilizationReportDataSource.TimeTracking:
          name = t`Tracked`;
          break;
        case UtilizationReportDataSource.Planning:
          name = t`Allocated`;
          break;
        case UtilizationReportDataSource.TaskPositions:
          name = t`Task budgeted`;
          break;
      }

      return name;
    }).get();

    const trackedTotal = computed(() => {
      let total = 0;
      switch (store.activeDataSourceOption?.value) {
        case UtilizationReportDataSource.Combined:
          total = combinedTime;
          break;
        case UtilizationReportDataSource.TimeTracking:
          total = trackedTime;
          break;
        case UtilizationReportDataSource.Planning:
          total = allocatedTime;
          break;
        case UtilizationReportDataSource.TaskPositions:
          total = taskBudgetedTime;
          break;
      }

      return total;
    }).get();

    const trackedPercent = computed(() => {
      const tracked =
        store.activeDataSourceOption?.value ===
        UtilizationReportDataSource.TaskPositions
          ? taskBudgetedTime
          : trackedTime;
      return (tracked / totalTime) * 100;
    }).get();

    const allocatedPercent = computed(() => {
      return (allocatedTime / totalTime) * 100;
    }).get();

    const segments: Array<TProgressBarSegment> = computed(() => {
      const segments: Array<TProgressBarSegment> = [];
      const totalPercent = (trackedPercent + allocatedPercent) * 100;

      segments.push({
        bg: "teal.500",
        percent: trackedPercent * 100,
      });

      segments.push({
        bg: "#C4A1FF",
        percent: allocatedPercent * 100,
      });

      if (totalPercent > HUNDRED_PERCENT) {
        const diff = totalPercent - HUNDRED_PERCENT;
        segments.push({
          bg: "repeating-linear-gradient(-65deg, #FFB2B7, #DD3943 2px, #FFB2B7 4px)",
          percent: diff,
        });
      }
      return segments;
    }).get();

    return (
      <DataTable.StatCard>
        <Stack>
          <HStack justify="space-between" mb="2">
            <Heading size="sm">{cardName}</Heading>
            <Heading size="sm">{formatSecondsToHours(trackedTotal, 1)}</Heading>
          </HStack>
          <ProgressBar segments={segments} />

          {store.activeDataSourceOption?.value ===
          UtilizationReportDataSource.Combined ? (
            <HStack justify="space-between">
              <HStack>
                <Text color="grey.600" fontWeight="medium" size="sm">
                  <Trans>Tracked</Trans>
                </Text>
                <Text size="sm">{formatSecondsToHours(trackedTime, 1)}</Text>
              </HStack>
              <HStack>
                <Text color="grey.600" fontWeight="medium" size="sm">
                  <Trans>Allocated</Trans>
                </Text>
                <Text size="sm">{formatSecondsToHours(allocatedTime, 1)}</Text>
              </HStack>
            </HStack>
          ) : (
            <Box minH="6" />
          )}
        </Stack>
      </DataTable.StatCard>
    );
  },
);
