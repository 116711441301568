import { ErrorScreen } from "@components/ui-kit";
import { t } from "@lingui/macro";

export const NotFoundClient = () => (
  <ErrorScreen
    title={t`I didn’t find the client you are looking for.`}
    link={{
      text: t`See all clients`,
      href: "/brands",
    }}
  />
);
