import Color from "color";

const whiteColor = Color.rgb(255, 255, 255);

export const calculateBackgroundColor = (hex: string) => {
  const color = Color(hex);
  return Color.rgb(
    color.red() * 0.1 + whiteColor.red() * 0.9,
    color.green() * 0.1 + whiteColor.green() * 0.9,
    color.blue() * 0.1 + whiteColor.blue() * 0.9,
  )
    .saturate(2)
    .hex();
};
