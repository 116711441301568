const NON_ALPHANUMERIC = /[^a-z\d]/gi;
const EVERY_FOUR_CHARS = /(.{4})(?!$)/g;

export function formatIBAN(iban: string): string {
  return iban
    .replace(" ", "")
    .replace(NON_ALPHANUMERIC, "")
    .toUpperCase()
    .replace(EVERY_FOUR_CHARS, "$1" + " ");
}
