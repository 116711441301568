import {
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import {
  GroupBase,
  MultiValue,
  OnChangeValue,
  CreatableSelect as ReactCreatableSelect,
  Select as ReactSelect,
  Props as ReactSelectProps,
  SelectInstance,
  SetValueAction,
  SingleValue,
  chakraComponents,
} from "chakra-react-select";
import {
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { CustomControl, CustomMenuList } from "./components";
import { filterOption } from "./filterOption";
import { extractOptions } from "./helpers";
import { IGroupOption, IOption } from "./types";

const OPTION_PREFIX = "select-value-";

type SelectInternalProps = Omit<ReactSelectProps, "onChange"> & {
  options: IOption[] | IGroupOption[];
  topExtraContent?: ReactNode;
  bottomExtraContent?: ReactNode;
  prefix?: ReactNode;
  isCreatable?: boolean;
  asPortal?: boolean;
  onChange?: (val: any) => void;
};

export type SelectProps = SelectInternalProps & {
  error?: string;
  label?: string;
  menuMinWidth?: BoxProps["minW"];
  ignoreFilterOptions?: boolean;
};

export interface SelectRef {
  onMenuClose: () => void | undefined;
  setValue: (val: unknown, action: SetValueAction, option?: unknown) => void;
  focus: () => void;
}

export const SelectInternal = forwardRef<SelectRef, SelectProps>(
  function SelectInternal(
    {
      options,
      value,
      onChange,
      placeholder,
      topExtraContent,
      bottomExtraContent,
      components,
      prefix,
      isDisabled,
      isLoading,
      isClearable = false,
      isCreatable,
      isSearchable = true,
      isMulti = false,
      asPortal,
      menuMinWidth = "auto",
      ignoreFilterOptions,
      ...props
    },
    ref,
  ) {
    const Component = isCreatable ? ReactCreatableSelect : ReactSelect;
    const selectRef = useRef<SelectInstance>(null);
    useImperativeHandle(ref, () => ({
      onMenuClose() {
        selectRef.current?.onMenuClose();
      },
      blur() {
        selectRef.current?.blur();
      },
      focus() {
        selectRef.current?.focus();
      },
      setValue(value, action, option) {
        selectRef.current?.setValue(value, action, option);
      },
    }));

    const [selected, setSelected] =
      useState<OnChangeValue<IOption, typeof isMulti>>();

    function parseValues(data: OnChangeValue<IOption, typeof isMulti>): any[] {
      const selectedOptions: IOption[] = Array.isArray(data)
        ? data
        : data
          ? [data]
          : [];
      return selectedOptions.map((i) => i.value);
    }

    const optionsMap = useMemo(() => {
      const memOptions = Array.isArray(selected) ? selected : [selected];
      const availableOptions = selected
        ? extractOptions([...memOptions, ...options])
        : extractOptions(options);
      return new Map(availableOptions.map((i) => [i.value, i]));
    }, [options]);

    useEffect(() => {
      const _values: string[] = Array.isArray(value)
        ? value
        : value
          ? [value]
          : [];
      const _selected = _values.flatMap((v) => {
        const opt = optionsMap.get(v);
        return opt ? [opt] : [];
      });
      setSelected(_selected);
    }, [value, optionsMap]);

    const setSelectedOnSelectChange = useCallback(
      (data: SingleValue<IOption> | MultiValue<IOption>) => {
        setSelected(data);
      },
      [setSelected],
    );

    const handleMenuOpen = () => {
      props.onMenuOpen?.();
      // Time outing because it takes a while for menu to mount
      setTimeout(() => {
        if (!selectRef.current?.menuListRef) return;
        if (!value) return;

        const selectedOption = document.getElementById(
          `${OPTION_PREFIX}${value}`,
        );
        if (!selectedOption) return;

        selectRef.current?.menuListRef?.scrollTo({
          top: selectedOption.offsetTop - 10,
        });
      }, 100);
    };

    const memoizedComponents = useMemo(
      () => ({
        IndicatorSeparator: () => null,
        ...((topExtraContent || bottomExtraContent) && {
          MenuList: (props: any) =>
            CustomMenuList(props, topExtraContent, bottomExtraContent),
        }),
        Control: (props: any) => CustomControl(props, { prefix }),
        ...components,
        Option: (props: any) => (
          <chakraComponents.Option {...props}>
            <div id={`${OPTION_PREFIX}${props?.data?.value ?? ""}`}>
              {props.children}
            </div>
          </chakraComponents.Option>
        ),
      }),
      [prefix, topExtraContent, components],
    );

    return (
      <Component<IOption, boolean, GroupBase<IOption>>
        {...props}
        // @ts-expect-error
        ref={selectRef}
        filterOption={
          ignoreFilterOptions
            ? () => true
            : ({ label, value }, str) =>
                filterOption({ label, value }, str, options)
        }
        options={options}
        // @ts-expect-error
        value={
          isCreatable
            ? selected || value
              ? { label: value, value }
              : []
            : selected
        }
        isLoading={isLoading}
        onChange={(data) => {
          setSelectedOnSelectChange(data);
          const selectedValues = parseValues(data);
          onChange?.(isMulti ? selectedValues : selectedValues[0]);
        }}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        onMenuOpen={handleMenuOpen}
        menuPlacement="auto"
        chakraStyles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            shadow: "lg",
          }),
          control: (provided) => ({
            ...provided,
            minH: 9,
            h: "auto",
          }),
          menuList: (provided) => ({
            ...provided,
            minW: menuMinWidth,
            borderRadius: "0",
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            fontSize: "xs",
          }),
          multiValueRemove: (provided) => ({
            ...provided,
            ":hover": {
              color: "red.500",
            },
          }),
          multiValue: (provided) => ({
            ...provided,
            h: "6",
            bg: "grey.100",
            mr: 1,
          }),
          option: (provided, { data }) => ({
            ...provided,
            fontSize: "sm",
            overflow: "none",
            h: "auto",
            minH: "10",
            ...((data.value === "select-all" ||
              data.value === "unselect-all") && {
              color: "#0B74A1",
              textDecoration: "underline",
              cursor: "pointer",
              fontWeight: 500,
            }),
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            background: "transparent",
            color: "grey.500",
            p: 0,
            w: "30px",
          }),
          valueContainer: (provided) => ({
            ...provided,
            pl: 2,
            pr: 0,
          }),
        }}
        isMulti={isMulti}
        // @ts-expect-error
        components={memoizedComponents}
        formatGroupLabel={(data) => data.label}
        isOptionDisabled={(option) => !!option.disabled}
        menuPortalTarget={!!asPortal ? document.body : undefined}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
    );
  },
);

const Select = forwardRef<SelectRef, SelectProps>(function Select(
  { error, label, ...props },
  ref,
) {
  return (
    <FormControl w="full" isInvalid={!!error}>
      {label && <FormLabel>{label}</FormLabel>}
      <SelectInternal ref={ref} {...props} />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
});

export default Select;
