import { WeeklyTimeReportRowDay } from "@src/__generated__/urql-graphql";
import { TimeInput } from "@src/components/ui-kit";
import { FieldState } from "formstate";
import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";

const TIMEOUT = 2 * 1000; // 2sec

interface WeekEntryTimeInputProps {
  trackedTime: FieldState<number | undefined>;
  onDebouncedChange: (
    trackedTime: WeeklyTimeReportRowDay["tracked_time"],
  ) => void;
}

const WeekEntryTimeInput = ({
  trackedTime,
  onDebouncedChange,
}: WeekEntryTimeInputProps) => {
  const debouncedChange = useMemo(
    () => debounce(onDebouncedChange, TIMEOUT),
    [],
  );

  useEffect(() => () => debouncedChange.cancel(), []);

  const handleChange = (val?: number) => {
    trackedTime.onChange(val);
    debouncedChange(val ?? 0);
  };

  return (
    <div className="flex m-4 px-4">
      <TimeInput value={trackedTime.value} onChange={handleChange} />
    </div>
  );
};

export default observer(WeekEntryTimeInput);
