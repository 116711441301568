import { Scalars } from "@src/__generated__/urql-graphql";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { MovedAllocationPayload } from "@src/types/planning";
import { required } from "@src/utils/forms/validators";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { SelectWorkTypeIfUserNotInTaskModal } from "@src/widgets/SelectWorkTypeIfUserNotInTaskModal";
import { FieldState } from "formstate";
import { action, makeObservable, observable } from "mobx";

interface SelectWorkTypeIfUserNotInTaskOptions {
  payload: MovedAllocationPayload;
  onSubmit: (workTypeId: Scalars["ID"]["output"]) => Promise<void>;
  onCancel: () => void;
}
export class SelectWorkTypeIfUserNotInTaskModalStore
  implements BaseStore, ModalStore
{
  appStore: AppStore;

  readonly modalId = "selectWorkTypeIfUserNotInTaskModal";

  @observable loading = false;
  @observable workTypeId = new FieldState<string | undefined>(undefined)
    .validators(required)
    .enableAutoValidation();

  @observable modalState =
    new DisclosureState<SelectWorkTypeIfUserNotInTaskOptions>({
      onOpen: () => {
        this.appStore.UIStore.dialogs.openModal({
          id: this.modalId,
          content: <SelectWorkTypeIfUserNotInTaskModal />,
        });
      },
      onClose: () => {
        this.appStore.UIStore.dialogs.closeModal(this.modalId);
        this.workTypeId.reset();
      },
    });

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @action.bound async onSubmit() {
    this.loading = true;
    const { hasError } = await this.workTypeId.validate();

    if (hasError || !this.workTypeId.$) {
      this.loading = false;
      return;
    }

    await this.modalState?.additionalData?.onSubmit?.(this.workTypeId.$);

    this.loading = false;
    this.modalState.onClose();
  }
}
