import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle((props) => {
  const { colorScheme: c } = props;

  return {
    tablist: {
      borderBottomWidth: "1px !important",
    },
    tab: {
      h: 14,
      fontSize: "sm",
      color: "grey.600",
      fontWeight: "semibold",
      position: "relative",
      border: "none !important",
      _hover: {
        color: `${c}.800`,
        _before: {
          content: '""',
          zIndex: -1,
          position: "absolute",
          top: "20%",
          bottom: "20%",
          left: 0,
          right: 0,
          w: "full",
          bg: `${c}.50`,
          rounded: "md",
        },
      },
      _selected: {
        border: "none",
        color: `${c}.800`,
        _after: {
          content: '""',
          position: "absolute",
          bottom: "1px",
          left: 0,
          right: 0,
          w: "full",
          h: "4px",
          bg: `${c}.500`,
          borderTopRadius: "6px",
        },
      },
    },
  };
});

const defaultProps = {
  size: "sm",
  colorScheme: "teal",
};

export const tabsTheme = defineMultiStyleConfig({ baseStyle, defaultProps });
