import { Button, HStack, Text } from "@chakra-ui/react";
import { Plural, Trans } from "@lingui/macro";
import { observer } from "mobx-react-lite";

interface SelectionRowProps {
  colSpan: number;
  selectedIds: string[];
  allIds: string[];
  onSelect?: (ids: string[]) => void;
}

const SelectionRow = ({
  colSpan,
  selectedIds,
  allIds,
  onSelect,
}: SelectionRowProps) => {
  const onSelectAll = () => onSelect?.(allIds);
  const onCancel = () => onSelect?.([]);

  if (!selectedIds.length) {
    return null;
  }

  const allIdsAreSelected = selectedIds.length === allIds.length;

  return (
    <tr>
      <td colSpan={colSpan}>
        <HStack
          justifyContent="center"
          m="2"
          px="3"
          py="2"
          bg="blue.50"
          rounded="lg"
          spacing="2"
        >
          <Text fontWeight="bold">
            {allIdsAreSelected ? (
              <Trans>
                All {allIds.length > 1 && allIds.length} items are selected.
              </Trans>
            ) : (
              <Plural
                value={selectedIds.length}
                one={
                  <Trans>
                    <strong>#</strong> item is selected.
                  </Trans>
                }
                other={
                  <Trans>
                    <strong>#</strong> items are selected.
                  </Trans>
                }
              />
            )}
          </Text>

          {allIdsAreSelected || (
            <Button onClick={onSelectAll} size="sm" variant="outline">
              <Plural
                value={allIds.length}
                one="Select all items"
                other="Select # items"
              />
            </Button>
          )}

          <Button onClick={onCancel} size="sm" variant="outline">
            <Trans>Cancel selection</Trans>
          </Button>
        </HStack>
      </td>
    </tr>
  );
};

export default observer(SelectionRow);
