import { Text } from "@chakra-ui/layout";
import { PropsWithChildren } from "react";

export const SummaryItemSubText = ({ children }: PropsWithChildren) => {
  return (
    <Text color="gray.500" fontSize="md" wordBreak="keep-all" mt="0.5">
      {children}
    </Text>
  );
};
