import { ComponentSingleStyleConfig } from "@chakra-ui/react";

export const CalendarDay: ComponentSingleStyleConfig = {
  baseStyle: {
    rounded: "base",
    bgColor: "transparent",
    color: "grey.700",

    _hover: {
      bgColor: "grey.100",
    },

    _disabled: {
      color: "grey.200",

      _hover: {
        cursor: "not-allowed",
        bgColor: "transparent",
      },
    },
  },

  sizes: {
    sm: {
      h: 8,
    },
  },

  variants: {
    selected: {
      bgColor: "blue.500",
      color: "white",

      _hover: {
        bgColor: "blue.600",
      },

      _disabled: {
        _hover: {
          bgColor: "blue.600",
        },
      },
    },

    range: {
      rounded: "none",
      bgColor: "blue.200",
      color: "white",

      _hover: {
        bgColor: "blue.100",
      },

      _disabled: {
        _hover: {
          bgColor: "blue.300",
        },
      },
    },

    outside: {
      color: "grey.300",
    },

    today: {
      color: "blue.400",
      bgColor: "white",

      _hover: {
        color: "black",
        bgColor: "blue.200",
      },
    },
  },

  defaultProps: {
    size: "sm",
  },
};
