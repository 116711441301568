export const EUR = (
  <svg
    viewBox="0 0 40 40"
    height="20"
    width="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
      <path d="M40 0H0v40h40V0z" fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <path d="M40 0H0v40h40V0z" fill="#0053A7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10.607l-1.015.537.194-1.137-.82-.806 1.134-.166L20 8l.507 1.035 1.135.166-.821.806.194 1.137L20 10.607zm0 20.856L18.985 32l.194-1.137-.82-.806 1.134-.166.507-1.035.507 1.035 1.135.166-.821.806.194 1.137L20 31.463zm10.358-10.428l-1.015.537.194-1.137-.82-.806 1.134-.166.507-1.035.508 1.035 1.134.166-.82.805.193 1.138-1.015-.537zm-20.716 0l-1.015.537.194-1.137L8 19.628l1.134-.166.508-1.035.507 1.035 1.135.166-.821.805.194 1.138-1.015-.537zm5.179-9.124l-1.015.537L14 11.31l-.82-.806 1.134-.165.507-1.036.507 1.035 1.135.166-.821.806.194 1.138-1.015-.537zm10.358 0l-1.015.537.194-1.138-.82-.806 1.134-.165.507-1.036.507 1.035 1.135.166-.821.806.194 1.138-1.015-.537zm0 18.248l-1.015.538.194-1.138-.82-.806 1.134-.166.507-1.035.507 1.035 1.135.166-.821.806.194 1.137-1.015-.537zm-10.358 0l-1.015.538.194-1.138-.82-.806 1.134-.166.507-1.035.507 1.035 1.135.166-.821.806.194 1.137-1.015-.537zm-3.884-3.91l-1.015.537.194-1.138-.821-.805 1.134-.166.508-1.035.507 1.035 1.134.166-.82.805.193 1.138-1.014-.537zm18.126 0l-1.014.537.193-1.138-.82-.805 1.134-.166.507-1.035.508 1.035 1.134.166-.82.805.193 1.138-1.015-.537zM10.937 15.82l-1.015.537.194-1.138-.821-.805 1.134-.166.508-1.035.507 1.035 1.134.166-.82.806.193 1.137-1.014-.537zm18.126 0l-1.014.537.193-1.138-.82-.805 1.134-.166.507-1.035.508 1.035 1.134.166-.82.806.193 1.137-1.015-.537z"
        fill="#FFF400"
      />
    </g>
  </svg>
);
