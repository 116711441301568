import { API_BASE_URL } from "@src/config/constants";
import { FunctionComponent, useEffect, useRef } from "react";

export interface EmojiPickerProps {
  onEmojiSelect?: (emoji: { id: string; native: string }) => void;
}

export const EmojiPicker: FunctionComponent<EmojiPickerProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    import("emoji-mart").then((EmojiMart) => {
      new EmojiMart.Picker({
        ...props,
        ref,
        data: async () => {
          const response = await fetch(`${API_BASE_URL}/emoji-data`, {
            credentials: "include",
          });
          return response.json();
        },
      });
    });
  }, []);

  return <div ref={ref}></div>;
};
