import { Contact } from "@src/__generated__/urql-graphql";
import { DeepPick } from "ts-essentials";

type toContactOptionsParams = {
  users: {
    first_name?: Contact["first_name"];
    last_name?: Contact["last_name"];
    id: Contact["id"];
    image?: DeepPick<Contact["image"], { urls: { thumbnail: never } }>;
  }[];
  modifier: (option: ContactOption) => ContactOption;
};

type ContactOption = {
  image: NonNullable<Contact["image"]>["urls"]["thumbnail"];
  label: string;
  value: Contact["id"];
};

export const toContactOptions = (
  contacts: toContactOptionsParams["users"],
  modifier: toContactOptionsParams["modifier"] = (option) => option,
): ContactOption[] =>
  contacts.map((i) =>
    modifier({
      label: `${i.first_name} ${i.last_name}`,
      value: i.id,
      image: i.image?.urls.thumbnail,
    }),
  );
