import { Brand, Client } from "@src/__generated__/urql-graphql";

type ToOptionsParam = (Pick<Client, "id" | "name"> & {
  brands: Pick<Brand, "id" | "name">[];
})[];

const fromClients = (clients: ToOptionsParam) =>
  clients
    .filter((c) => c.brands.length)
    .flatMap((c) =>
      c.brands.map((b) => ({
        label: b.name,
        value: b.id,
      })),
    );

export default { fromClients };
