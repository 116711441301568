import { Flex, HStack, Stack } from "@chakra-ui/react";
import { Icon, IconProps } from "@src/components/ui-kit/Icon";
import { observer } from "mobx-react-lite";
import React, { FC, ReactNode } from "react";

interface CardHeaderProps {
  icon?: IconProps["name"];
  title?: ReactNode;
  action?: ReactNode;
}

const CardHeader: FC<React.PropsWithChildren<CardHeaderProps>> = ({
  icon,
  title,
  action,
  children,
}) => (
  <header className="p-8">
    <Stack
      align="center"
      justify="space-between"
      flexDir={{
        base: "column",
        md: "row",
      }}
      w="full"
      spacing={{
        base: "3",
        md: undefined,
      }}
    >
      <HStack>
        {!!icon && (
          <Flex
            align="center"
            justify="center"
            w="8"
            h="8"
            p="5"
            color="white"
            bg="#47C1BF"
            borderRadius="50%"
            marginInlineEnd="3"
          >
            <Icon name={icon} />
          </Flex>
        )}
        <span className="text-3xl">{title}</span>
      </HStack>
      {!!action && <span>{action}</span>}
    </Stack>
    {children}
  </header>
);

export default observer(CardHeader);
