import {
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import {
  useDeleteProjectCategoryMutation,
  useGetProjectCategoriesQuery,
  useUpdateProjectCategoryMutation,
} from "@src/__generated__/urql-graphql";
import { cannot } from "@src/utils/components/permissions";
import { useStore } from "@src/utils/hooks";
import { computed, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FC, Fragment, useEffect, useState } from "react";
import { Icon } from "../Icon";
import { ModalConfirm } from "../Modals";
import { IOption } from "../Select";

export const ProjectCategoryEditModal: FC = observer(
  function ProjectCategoryEditModal() {
    const {
      projectCategoryModalStore: store,
      projectCategorySelectStore: selectStore,
    } = useStore();
    const [idToDelete, setIdToDelete] = useState<string>();
    const [{ fetching: updatingCategory }, updateCategory] =
      useUpdateProjectCategoryMutation();
    const [{ fetching: deletingCategory }, deleteCategory] =
      useDeleteProjectCategoryMutation();
    const [{ fetching: fetchingCategories, data }, fetchCategories] =
      useGetProjectCategoriesQuery({
        pause: true,
      });

    useEffect(() => {
      if (selectStore.categories.length || cannot("projectCategory_query"))
        return;

      selectStore.categories = [];
      fetchCategories();
    }, []);

    useEffect(() => {
      if (!data?.projectCategories) return;
      runInAction(() => {
        selectStore.categories = data.projectCategories.map((category) => ({
          label: category.name,
          value: category.id,
        }));
      });
    }, [data?.projectCategories]);

    const animatedProps = computed(() => {
      return {
        opacity: store.isSelectedCategory ? "1" : "0",
        transitionDuration: "300ms, 200ms, 100ms",
        transitionProperty: "flex, opacity, width",
      };
    }).get();

    const handleCategoryUpdate = async () => {
      const { hasError } = await store.editForm.validate();
      if (hasError || !store.editForm.$.name.$ || !store.editForm.$.id.$)
        return;

      const { error } = await updateCategory({
        id: store.editForm.$.id.$,
        name: store.editForm.$.name.$,
      });

      if (error) return;

      store.updateCategory();
    };

    const handleCategoryDelete = async (id: IOption["value"]) => {
      const { error } = await deleteCategory({ id });

      if (error) return;

      store.deleteCategory(id);
      setIdToDelete(undefined);
    };

    return (
      <Fragment>
        <Modal isOpen onClose={store.modalState.onClose} trapFocus={false}>
          <ModalOverlay />
          <ModalContent minW="600px">
            <ModalHeader>
              <Trans>Edit categories</Trans>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HStack w="full" spacing="4">
                <VStack
                  alignItems="flex-start"
                  flex="1"
                  overflowX="hidden"
                  overflowY="scroll"
                  h="300px"
                >
                  {fetchingCategories ? (
                    <Center>
                      <Spinner />
                    </Center>
                  ) : (
                    selectStore.categories.map((category) => (
                      <HStack
                        key={category.value}
                        justifyContent="space-between"
                        w="full"
                      >
                        <Button
                          leftIcon={<Icon name="edit-02" />}
                          onClick={() => {
                            store.selectCategory(category);
                          }}
                          variant="link"
                        >
                          {category.label}
                        </Button>
                        <IconButton
                          aria-label={t`Delete category`}
                          colorScheme="grey"
                          icon={<Icon name="trash-03" />}
                          isDisabled={cannot("projectCategory_delete")}
                          onClick={() => {
                            setIdToDelete(category.value);
                          }}
                          variant="ghost"
                        />
                      </HStack>
                    ))
                  )}
                </VStack>
                <Divider sx={animatedProps} h="250px" orientation="vertical" />
                <VStack
                  sx={animatedProps}
                  flex={store.isSelectedCategory ? "1" : "0"}
                  w={store.isSelectedCategory ? undefined : "0"}
                  spacing="2"
                >
                  <FormControl
                    isInvalid={
                      store.isSelectedCategory && store.editForm.$.name.hasError
                    }
                  >
                    <FormLabel>
                      <Trans>New name</Trans>
                    </FormLabel>
                    <Input
                      onChange={({ target }) => {
                        store.editForm.$.name.onChange(target.value);
                      }}
                      value={store.editForm.$.name.value}
                    />
                    <FormErrorMessage>
                      {store.editForm.$.name.error}
                    </FormErrorMessage>
                  </FormControl>
                  <HStack justifyContent="flex-end" w="full" spacing="2">
                    <Button
                      colorScheme="grey"
                      isLoading={updatingCategory}
                      onClick={store.clearCategory}
                      variant="outline"
                    >
                      <Trans>Cancel</Trans>
                    </Button>
                    <Button
                      isLoading={updatingCategory}
                      onClick={handleCategoryUpdate}
                    >
                      <Trans>Save</Trans>
                    </Button>
                  </HStack>
                </VStack>
              </HStack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="grey"
                onClick={() => {
                  store.modalState.onClose();
                  store.clearCategory();
                }}
                variant="outline"
              >
                <Trans>Close</Trans>
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <ModalConfirm
          isOpen={!!idToDelete}
          isLoading={deletingCategory}
          confirmBtnTitle={t`Delete`}
          destructive
          onClose={() => {
            setIdToDelete(undefined);
          }}
          closeOnConfirm={false}
          onConfirm={() => {
            if (!idToDelete) return;
            handleCategoryDelete(idToDelete);
          }}
        />
      </Fragment>
    );
  },
);
