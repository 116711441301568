import { t } from "@lingui/macro";
import {
  SqlOperator,
  UtilizationReportDataSource,
  UtilizationReportGroupBy,
  UtilizationReportStatsQuery,
  UtilizationReportWhereColumn,
} from "@src/__generated__/urql-graphql";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { toApiDate } from "@src/utils/dates";
import { TableState } from "@src/utils/mobx/states/TableState";
import { DateRange } from "@src/utils/types";
import { endOfMonth, startOfMonth } from "date-fns";
import { action, computed, makeObservable, observable } from "mobx";
import Router from "next/router";

export class UtilizationReportStore implements BaseStore {
  appStore: AppStore;
  @observable.ref dateRange: DateRange = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  };
  @observable dataSource: UtilizationReportDataSource =
    UtilizationReportDataSource.Combined;
  @observable groupBy?: UtilizationReportGroupBy = undefined;
  @observable tableStatsData:
    | UtilizationReportStatsQuery["utilizationReportSummary"]
    | undefined = undefined;

  filters = new Filters<UtilizationReportWhereColumn>([
    new Filter({
      column: UtilizationReportWhereColumn.TeamId,
      operator: SqlOperator.In,
      title: t`Team`,
      options: [],
    }),
    new Filter({
      column: UtilizationReportWhereColumn.WorkTypeId,
      operator: SqlOperator.In,
      title: t`Position`,
      options: [],
    }),
    new Filter({
      column: UtilizationReportWhereColumn.UserId,
      operator: SqlOperator.In,
      title: t`People`,
      options: [],
    }),
  ]);

  // TODO: Get rid of TableState class, just adds complexity without any benefits
  table = new TableState({
    key: "utilization-report",
    filters: this.filters,
  });

  // TODO: Get rid of TableState class, just adds complexity without any benefits
  tableByGroups = new TableState({
    key: "utilization-report-grouped",
    filters: this.filters,
  });

  readonly groupByOptions = [
    { label: t`Team`, value: UtilizationReportGroupBy.Team },
    { label: t`Position`, value: UtilizationReportGroupBy.WorkType },
    { label: t`Month`, value: UtilizationReportGroupBy.Month },
    { label: t`Week`, value: UtilizationReportGroupBy.Week },
  ];
  readonly dataSourceOptions = [
    {
      label: t`Tracked & Allocated Hours`,
      value: UtilizationReportDataSource.Combined,
    },
    // { label: t`Tracked Hours`, value: UtilizationReportDataSource.TimeTracking },
    // { label: t`Allocated Hours`, value: UtilizationReportDataSource.Planning },
    {
      label: t`Task budgeted hours`,
      value: UtilizationReportDataSource.TaskPositions,
    },
  ];

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @computed get activeDataSourceOption() {
    return this.dataSourceOptions.find(
      ({ value }) => value === this.dataSource,
    );
  }
  @computed get activeGroupByOption() {
    return this.groupByOptions.find(({ value }) => value === this.groupBy);
  }

  @action.bound updateUrl() {
    if (!!this.groupBy) {
      Router.replace({
        query: {
          where: this.tableByGroups.where.asURLSearchParam.where,
          orderBy: this.tableByGroups.orderBy.asURLSearchParam.orderBy,
          from: toApiDate(this.dateRange.start),
          to: toApiDate(this.dateRange.end),
          dataSource: this.dataSource,
          groupBy: this.groupBy,
          page: this.tableByGroups.pagination.asURLSearchParam.page,
          first: this.tableByGroups.pagination.asURLSearchParam.first,
        },
      });
    } else {
      Router.replace({
        query: {
          where: this.table.where.asURLSearchParam.where,
          orderBy: this.table.orderBy.asURLSearchParam.orderBy,
          from: toApiDate(this.dateRange.start),
          to: toApiDate(this.dateRange.end),
          dataSource: this.dataSource,
          groupBy: undefined,
          page: this.table.pagination.asURLSearchParam.page,
          first: this.table.pagination.asURLSearchParam.first,
        },
      });
    }
  }
}
