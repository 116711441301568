import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import {
  Form,
  LoadingOverlay,
  RemoveButton,
  Select,
  TimeInput,
} from "@src/components/ui-kit";
import { BillableButton } from "@src/components/ui-kit/BillableButton";
import { CancelButton } from "@src/components/ui-kit/Button/CancelButton";
import { TextEditor } from "@src/components/ui-kit/TextEditor/TextEditor";
import {
  fieldToInputProps,
  fieldToSelectProps,
} from "@src/utils/forms/inputHelpers";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

export const CompleteCapacityAllocationModal = observer(
  function CompleteCapacityAllocationModal() {
    const { completeCapacityAllocationModalStore: store } = useStore();
    const [isSubmitting, setIsSubmitting] = useBoolean(false);
    const mode = store.modalState.additionalData?.mode ?? "complete";
    const optionsItem = store.modalState.additionalData?.item;

    const onSubmit = async () => {
      setIsSubmitting.on();
      await store.completeAllocation();
      setIsSubmitting.off();
    };

    return (
      <Modal
        isOpen
        onClose={store.modalState.onClose}
        size="3xl"
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <Form onSubmit={onSubmit}>
            <ModalHeader>
              {mode === "complete" ? t`Assignment complete` : t`Track time`}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <LoadingOverlay
                isLoading={store.isLoading}
                minH="100px"
                position="absolute"
                transparent={false}
              >
                <Text mb="6" fontWeight="semibold">
                  <Text display="inline" color="grey.500">
                    {`${optionsItem?.project_code} / ${optionsItem?.budget_item_title} / `}
                  </Text>
                  {optionsItem?.task_name}
                </Text>

                {!!store.existingTimeTrackingItems.$.size && (
                  <Stack mb="10" spacing="3">
                    {Array.from(
                      store.existingTimeTrackingItems.$.entries(),
                    ).map(([id, form]) => {
                      return (
                        <HStack key={id} align="start" spacing="2">
                          <BillableButton field={form.$.billable} />
                          <FormControl
                            w="24"
                            isInvalid={!!form.$.tracked_time.hasError}
                          >
                            <TimeInput
                              h="9"
                              w="24"
                              fontSize="xl"
                              autoFocus
                              mode24
                              onChange={(value) =>
                                form.$.tracked_time.onChange(value)
                              }
                              value={form.$.tracked_time.value}
                            />
                            <FormErrorMessage>
                              {form.$.tracked_time.error}
                            </FormErrorMessage>
                          </FormControl>
                          <Box flex="1">
                            <Select
                              placeholder={t`Select position`}
                              {...fieldToSelectProps(
                                form.$.time_tracking_work_type_id,
                                store.timeTrackingWorkTypeOptions,
                              )}
                            />
                          </Box>
                          <FormControl
                            flex="1"
                            isInvalid={!!form.$.note.hasError}
                          >
                            <TextEditor
                              attachments={[]}
                              placeholder={t`Notes`}
                              overflow="hidden"
                              borderWidth="thin"
                              h="9"
                              maxH="9"
                              px="2"
                              initialValue={form.$.note.value}
                              borderColor="grey.200"
                              borderRadius="md"
                              transition="border-color 150ms ease-in"
                              _focusWithin={{
                                borderColor: "purple.500",
                              }}
                              {...fieldToInputProps(form.$.note)}
                            />
                            <FormErrorMessage>
                              {form.$.note.error}
                            </FormErrorMessage>
                          </FormControl>
                          <RemoveButton
                            onClick={() =>
                              store.removeExistingTimeTrackingItem(id)
                            }
                          />
                        </HStack>
                      );
                    })}
                  </Stack>
                )}

                <HStack align="start" spacing="2">
                  <BillableButton
                    field={store.newTimeTrackingItem.$.billable}
                  />
                  <FormControl
                    w="24"
                    isInvalid={
                      !!store.newTimeTrackingItem.$.tracked_time.hasError
                    }
                  >
                    <TimeInput
                      h="9"
                      w="24"
                      fontSize="xl"
                      autoFocus
                      mode24
                      onChange={(value) =>
                        store.newTimeTrackingItem.$.tracked_time.onChange(value)
                      }
                      value={store.newTimeTrackingItem.$.tracked_time.value}
                    />
                    <FormErrorMessage>
                      {store.newTimeTrackingItem.error}
                    </FormErrorMessage>
                  </FormControl>
                  <Box flex="1">
                    <Select
                      placeholder={t`Select position`}
                      {...fieldToSelectProps(
                        store.newTimeTrackingItem.$.time_tracking_work_type_id,
                        store.timeTrackingWorkTypeOptions,
                      )}
                    />
                  </Box>
                  <FormControl
                    flex="1"
                    isInvalid={!!store.newTimeTrackingItem.$.note.hasError}
                  >
                    <TextEditor
                      attachments={[]}
                      placeholder={t`Notes`}
                      overflow="hidden"
                      borderWidth="thin"
                      h="9"
                      maxH="9"
                      px="2"
                      initialValue={store.newTimeTrackingItem.$.note.value}
                      borderColor="grey.200"
                      borderRadius="md"
                      transition="border-color 150ms ease-in"
                      _focusWithin={{
                        borderColor: "purple.500",
                      }}
                      {...fieldToInputProps(store.newTimeTrackingItem.$.note)}
                    />
                    <FormErrorMessage>
                      {store.newTimeTrackingItem.$.note.error}
                    </FormErrorMessage>
                  </FormControl>
                  <RemoveButton
                    onClick={() => {
                      // hidden button just to make the layout look good
                    }}
                    visibility="hidden"
                  />
                </HStack>
                <TimeInput
                  mt="3"
                  h="9"
                  w="24"
                  isReadOnly
                  variant="plain"
                  fontSize="xl"
                  autoFocus
                  mode24
                  onChange={() => {}}
                  value={store.trackedTimeSummary}
                />
              </LoadingOverlay>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup spacing="2">
                <CancelButton onClick={store.modalState.onClose} />
                <Button
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  type="submit"
                >
                  <Trans>Save</Trans>
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    );
  },
);
