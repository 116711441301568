import cs from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";

interface LabeledItemProps {
  label: string;

  /** Default: 'center' */
  verticalAlign?: "start" | "center" | "end";

  className?: string;
}

const LabeledItem: FC<React.PropsWithChildren<LabeledItemProps>> = ({
  label,
  verticalAlign = "center",
  className,
  children,
}) => (
  <div
    className={cs(
      `flex items-${verticalAlign} leading-10 flex-wrap`,
      className,
    )}
  >
    <span className="flex-shrink-0 font-semibold mr-3">{label}:</span>
    {children}
  </div>
);

export default observer(LabeledItem);
