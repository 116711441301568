import { AssignableFile } from "@src/__generated__/urql-graphql";
import { API_BASE_URL } from "@src/config/constants";

export function downloadAttachments(
  files: { public_id: AssignableFile["public_id"] }[],
): void {
  const queryParams = files
    .map((file, index) => `ids[${index}]=${encodeURIComponent(file.public_id)}`)
    .join("&");

  window.open(
    `${API_BASE_URL}/media/downloadMediaBulk?${queryParams}`,
    "_blank",
  );
}
