import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";

export const RemoveButton = (
  props: Omit<IconButtonProps, "aria-label" | "icon">,
) => {
  return (
    <IconButton
      alignSelf="start"
      aria-label={t`Delete`}
      colorScheme="grey"
      icon={<Icon name="trash-03" w="6" h="6" />}
      variant="ghost"
      {...props}
    />
  );
};
