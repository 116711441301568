import { Button, ButtonProps, forwardRef } from "@chakra-ui/react";
import { Icon } from "@src/components/ui-kit/Icon";
import { FunctionComponent } from "react";

export const DropdownButton: FunctionComponent<ButtonProps> = forwardRef(
  (props, ref) => {
    return (
      <Button
        colorScheme="grey"
        rightIcon={<Icon name="chevron-down" />}
        variant="outline"
        {...props}
        ref={ref}
      />
    );
  },
);
