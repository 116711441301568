import { t } from "@lingui/macro";
import {
  Task,
  User,
  useAllocationItemsSelectOptionsQuery,
} from "@src/__generated__/urql-graphql";
import { IOption, Select, SelectProps } from "@src/components/ui-kit";
import { formatDuration } from "@src/utils/formatters";
import { FunctionComponent, useEffect, useState } from "react";

type AllocationItemsSelectProps = Omit<SelectProps, "options"> & {
  options?: SelectProps["options"];
  userId: User["id"];
  taskId?: Task["id"];
  date: Date;
};

export const AllocationItemsSelect: FunctionComponent<
  AllocationItemsSelectProps
> = ({ options: passedOptions, userId, taskId, date, ...props }) => {
  const [options, setOptions] = useState<IOption[]>([]);
  const [{ data, fetching }, fetchOption] =
    useAllocationItemsSelectOptionsQuery({
      variables: { user_id: userId, date, task_id: taskId },
      pause: true,
    });

  useEffect(() => {
    if (!userId || !date) return;

    fetchOption({
      variables: { user_id: userId, date, task_id: taskId },
    });
  }, [userId, date, taskId]);

  useEffect(() => {
    if (!data?.capacityAllocationItemList) return;

    setOptions(
      data.capacityAllocationItemList.map((item) => {
        const duration = item?.from_time
          ? `${item.from_time} - ${item.to_time}`
          : formatDuration(item.total_time);

        return {
          label: `${item.capacityAllocation.task?.name} (${duration}) ${
            item.note ? `- ${item.note}` : ""
          }`,
          value: item.id,
        };
      }),
    );
  }, [data]);

  return (
    <Select
      isLoading={fetching}
      placeholder={t`Select capacity allocation`}
      options={passedOptions ?? options}
      {...props}
    />
  );
};
