import { t } from "@lingui/macro";
import { captureEvent } from "@sentry/nextjs";
import { appStore } from "@src/stores/AppStore";
import Router from "next/router";
import { route } from "nextjs-routes";
import { mapExchange } from "urql";

export const errorExchange = mapExchange({
  onError: async (error) => {
    if (error.response?.status === 401) {
      await Router.push({
        pathname: route({ pathname: "/login" }),
        query: { redirect_to: location.href },
      });
      return;
    }

    if (error.graphQLErrors?.length) {
      error.graphQLErrors.forEach((i) => {
        const messages = parseValidationMessages(i.extensions);

        if (messages.length) {
          messages.forEach((j) =>
            appStore.UIStore.toast({ title: j, status: "error" }),
          );
        } else {
          appStore.UIStore.toast({ title: i.message, status: "error" });
        }
      });
    } else if (error.networkError) {
      appStore.UIStore.toast({
        title: error.networkError.message,
        status: "error",
      });
    } else {
      appStore.UIStore.toast({
        title: t`Something went wrong unexpectedly. Please try again.`,
        status: "error",
      });
    }

    captureEvent({
      message: "GraphQL ERROR: " + error.message,
      extra: { error },
    });

    console.error("urql error:", error);
  },
});

const parseValidationMessages = (
  extensions: { [key: string]: any } | undefined,
): string[] => {
  let messages: string[] = [];

  if (
    extensions &&
    "validation" in extensions &&
    typeof extensions.validation === "object"
  ) {
    for (const arrayOfMsg of Object.values(extensions.validation)) {
      if (Array.isArray(arrayOfMsg)) {
        messages = messages.concat(arrayOfMsg);
      }
    }
  }

  return messages;
};
