import { API_URL } from "@src/config/constants";
import { errorExchange } from "@src/services/client/error";
import { serializeScalarsExchange } from "@src/services/client/scalarsExchange";
import { devtoolsExchange } from "@urql/devtools";
import { cacheExchange, createClient, fetchExchange } from "urql";

/**
 * @deprecated Use Apollo client instead
 */
export const client = createClient({
  url: API_URL,
  exchanges: [
    devtoolsExchange,
    serializeScalarsExchange,
    cacheExchange,
    errorExchange,
    fetchExchange,
  ],
  requestPolicy: "network-only",
  fetchOptions: {
    credentials: "include",
  },
});
