import { User } from "@src/__generated__/urql-graphql";
import { DeepPick } from "ts-essentials";

export type toUserOptionsParams = {
  users: {
    id: User["id"];
    full_name: User["full_name"];
    codename?: User["codename"];
    image?: DeepPick<User["image"], { urls: { thumbnail: never } }>;
  }[];
  modifier: (option: UserOption) => UserOption;
};

export type UserOption = {
  image: NonNullable<User["image"]>["urls"]["thumbnail"];
  label: string;
  value: User["id"];
};

export const toUserOptions = (
  users: toUserOptionsParams["users"],
  modifier: toUserOptionsParams["modifier"] = (option) => option,
): UserOption[] =>
  users.map((i) =>
    modifier({
      image: i.image?.urls?.thumbnail,
      label: i.full_name + (!!i.codename ? ` (${i.codename})` : ""),
      value: i.id,
    }),
  );
