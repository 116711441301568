import { t } from "@lingui/macro";
import { usePositionSelectOptionsQuery } from "@src/__generated__/urql-graphql";
import { Select, SelectProps, SelectRef } from "@src/components/ui-kit";
import mapToOptions from "@src/utils/map-to-options";
import {
  WorkTypeOption,
  toWorkTypeOptionsParams,
} from "@src/utils/map-to-options/workTypes";
import { forwardRef, useEffect, useState } from "react";

type PositionSelectProps = Omit<SelectProps, "options"> & {
  optionModifier?: toWorkTypeOptionsParams["modifier"];
  options?: SelectProps["options"];
};

export const PositionSelect = forwardRef<SelectRef, PositionSelectProps>(
  function PositionSelect(
    { optionModifier = (option) => option, options: passedOptions, ...props },
    ref,
  ) {
    const [options, setOptions] = useState<WorkTypeOption[]>([]);
    const [{ data, fetching }] = usePositionSelectOptionsQuery({
      pause: !!passedOptions,
    });

    useEffect(() => {
      if (!data?.timeTrackingWorkTypes) return;

      setOptions(
        mapToOptions.workTypes(data.timeTrackingWorkTypes, optionModifier),
      );
    }, [data]);

    return (
      <Select
        ref={ref}
        isLoading={fetching}
        placeholder={t`Select position`}
        options={passedOptions ?? options}
        {...props}
      />
    );
  },
);
