export * from "./MediaList";
export * from "./models";

export const readAsDataURL = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result as string);
    fileReader.onerror = () => reject();
    fileReader.readAsDataURL(file);
  });
};
