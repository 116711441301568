import {
  AssignableFile,
  PdfFileFragment,
} from "@src/__generated__/urql-graphql";
import { uniqueId } from "lodash";

export class MediaModel {
  id: AssignableFile["public_id"];

  _file?: File;

  fileName: AssignableFile["filename"];
  name: AssignableFile["name"];
  fileSize: AssignableFile["size"];
  mimeType: AssignableFile["mime_type"];

  isImage: boolean;
  isPdf: boolean;

  urls?: {
    preview: AssignableFile["urls"]["viewable"];
    download: AssignableFile["urls"]["original"];
  };

  constructor(src: File | PdfFileFragment) {
    this.id =
      src instanceof File && (src as any).webkitRelativePath
        ? (src as any).webkitRelativePath
        : "public_id" in src && src.public_id
          ? src.public_id
          : uniqueId();

    this._file = src instanceof File ? src : undefined;

    this.fileName = "filename" in src ? src.filename : src.name;
    this.name = src.name;
    this.fileSize = src.size.toString();
    this.mimeType = "type" in src ? src.type : src.mime_type;

    this.isImage = ["image/jpeg", "image/png"].includes(this.mimeType!);
    this.isPdf = "application/pdf" === this.mimeType;

    this.urls =
      src instanceof File
        ? undefined
        : {
            preview: src.urls.viewable ? src.urls.viewable : src.urls.original,
            download: src.urls.original,
          };
  }

  get isFile(): boolean {
    return this._file !== undefined;
  }

  get isMedia(): boolean {
    return this.isFile === false;
  }
}
