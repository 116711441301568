import { format, isMatch } from "date-fns";

export * from "./DatePicker";
export * from "./MultiMonthCalendar";

export const formatDate = (date?: Date) =>
  date ? format(date, "dd.MM.yyyy") : "";

function getSeparator(string: string): string | undefined {
  return string.includes(".")
    ? "."
    : string.includes("-")
      ? "-"
      : string.includes("/")
        ? "/"
        : undefined;
}

function validateDate(dateString: string): boolean {
  const separator = getSeparator(dateString);

  if (!separator) return false;

  return isMatch(dateString, "dd" + separator + "MM" + separator + "yyyy");
}

export function getDateFromEUFormat(dateString: string): Date | undefined {
  const isValid = validateDate(dateString);

  if (!isValid) return undefined;

  const separator = getSeparator(dateString);

  if (!separator) return undefined;

  const split = dateString.split(separator).map(Number);

  if (!split.length) return undefined;

  // - 1 because it takes month as index
  return new Date(Date.UTC(split[2], split[1] - 1, split[0]));
}
