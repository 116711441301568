import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Text,
  useCheckboxGroup,
  Wrap,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import {
  CapacityAllocationRepetitionTypeEnum,
  CapacityAllocationTypeEnum,
  RepetitionSettingsDaysEnum,
} from "@src/__generated__/urql-graphql";
import { FormRow, NumberInput } from "@src/components/ui-kit";
import { CheckboxCard } from "@src/components/ui-kit/CheckboxCard/CheckboxCard";
import { getWeekdays } from "@src/utils/dates";
import { useStore } from "@src/utils/hooks";
import { format } from "date-fns";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useMemo } from "react";
import { TooltipDisabledForCurrentItemEdit } from "./AllocationModal";

export const RepetitionDays: FC = observer(function RepetitionDays() {
  const { allocationModalStore: store, workspaceStore } = useStore();
  const { repetition_days, repetition, type } = store.form;
  const formattedDays = useMemo(() => {
    return getWeekdays(workspaceStore.settings?.startOfWeekNumber ?? 0).map(
      (day) => ({
        label: day.toLocaleString("en-us", { weekday: "short" }),
        value: format(
          day,
          "EEEE",
        ).toUpperCase() as keyof typeof RepetitionSettingsDaysEnum,
      }),
    );
  }, [workspaceStore.settings?.startOfWeekNumber]);

  const { getCheckboxProps } = useCheckboxGroup({
    value: toJS(repetition_days.value as string[] | null) ?? undefined,
    onChange: (value: RepetitionSettingsDaysEnum[]) => {
      repetition_days.value = value;
    },
  });

  return (
    <TooltipDisabledForCurrentItemEdit>
      <FormRow title={t`Recurrence`} w="full">
        <HStack align="start" justify="space-between" spacing="4">
          {repetition.$ === CapacityAllocationRepetitionTypeEnum.Weekly && (
            <FormControl isInvalid={repetition_days.hasError}>
              <FormLabel>
                <Trans>On</Trans>
              </FormLabel>
              <Wrap>
                {formattedDays.map(({ label, value }) => (
                  <CheckboxCard
                    key={label}
                    {...getCheckboxProps({ value })}
                    isDisabled={store.editingCurrentItem}
                  >
                    {label}
                  </CheckboxCard>
                ))}
              </Wrap>
              <FormErrorMessage>{repetition_days.error}</FormErrorMessage>
            </FormControl>
          )}
          {(type.$ === CapacityAllocationTypeEnum.TimeOff ||
            type.$ === CapacityAllocationTypeEnum.HomeOffice) && (
            <RepetitionInput />
          )}
        </HStack>
      </FormRow>
    </TooltipDisabledForCurrentItemEdit>
  );
});

export const RepetitionInput: FC = observer(function RepetitionInput() {
  const { allocationModalStore: store } = useStore();
  const { repetition_every, repetition } = store.form;

  const handleEvery = useCallback((newEvery: string) => {
    repetition_every.value = Number(newEvery);
  }, []);

  const everyText = useMemo(() => {
    switch (repetition.$) {
      case CapacityAllocationRepetitionTypeEnum.Daily:
        return t`day(s)`;

      case CapacityAllocationRepetitionTypeEnum.Weekly:
        return t`week(s)`;

      case CapacityAllocationRepetitionTypeEnum.Monthly:
        return t`month(s)`;

      case CapacityAllocationRepetitionTypeEnum.Yearly:
        return t`year(s)`;

      default:
        return;
    }
  }, [repetition.$]);

  return (
    <TooltipDisabledForCurrentItemEdit>
      <NumberInput
        onChange={handleEvery}
        disabled={store.editingCurrentItem}
        label={t`Every`}
        value={repetition_every.value.toString()}
        error={repetition_every.error}
        suffix={
          repetition.$ !== CapacityAllocationRepetitionTypeEnum.Custom && (
            <Text>{everyText}</Text>
          )
        }
        inputStyle={{ pr: "20", w: "13rem" }}
        suffixStyle={{ w: "20" }}
      />
    </TooltipDisabledForCurrentItemEdit>
  );
});
