import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Radio,
  Switch,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { DndContext } from "@dnd-kit/core";
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Trans, t } from "@lingui/macro";
import { ColorPicker, DragHandle, ModalConfirm } from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import {
  TaskPriorityState,
  TaskStatusState,
} from "@src/stores/forms/SettingsModalState/TasksSettingsState";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { Fragment, FunctionComponent, useState } from "react";

type TaskStatusProps = {
  field: TaskStatusState;
  onDelete: () => void;
};
const TaskStatusRow: FunctionComponent<TaskStatusProps> = observer(
  function TaskStatusRow({ field, onDelete }) {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isSorting,
    } = useSortable({
      id: field.internalId,
    });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: "default",
    };

    return (
      <Tr ref={setNodeRef} style={style} {...attributes}>
        <Td>
          <Flex as="label" align="center" justify="center">
            <Radio
              isChecked={field.$.default.value}
              name="default"
              onChange={() => field.setDefault()}
            />
          </Flex>
        </Td>
        <Td>
          <Flex as="label" align="center" justify="center">
            <Radio
              isChecked={field.$.done.value}
              name="done"
              onChange={() => field.setDone()}
            />
          </Flex>
        </Td>
        <Td>
          <FormControl isInvalid={field.$.name.hasError}>
            <Input
              minW="130"
              color={field.$.foreground_color.value}
              bgColor={field.$.background_color.value}
              onChange={(event) => field.$.name.onChange(event.target.value)}
              type="text"
              value={field.$.name.value}
            />
            <FormErrorMessage>
              <Trans>Status name is required</Trans>
            </FormErrorMessage>
          </FormControl>
        </Td>
        <Td>
          <ColorPicker
            color={field.$.foreground_color.value}
            onChange={(color) => field.$.foreground_color.onChange(color)}
            title={t`Text color`}
          />
          <ColorPicker
            color={field.$.background_color.value}
            onChange={(color) => field.$.background_color.onChange(color)}
            title={t`Background color`}
          />
        </Td>
        <Td>
          <Flex align="center" justify="center" gap="3">
            <IconButton
              aria-label={t`Delete this task status`}
              icon={<Icon name="trash-03" />}
              onClick={() => {
                onDelete();
              }}
              variant="ghost"
            />
            <DragHandle
              {...listeners}
              cursor={isSorting ? "grabbing" : "grab"}
            />
          </Flex>
        </Td>
      </Tr>
    );
  },
);

type TaskPriorityProps = {
  field: TaskPriorityState;
  onDelete: () => void;
};
const TaskPriorityRow: FunctionComponent<TaskPriorityProps> = observer(
  function TaskStatusRow({ field, onDelete }) {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isSorting,
    } = useSortable({
      id: field.internalId,
    });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: "default",
    };

    return (
      <Tr ref={setNodeRef} style={style} {...attributes}>
        <Td>
          <Flex as="label" align="center" justify="center">
            <Radio
              isChecked={field.$.default.value}
              name="default"
              onChange={() => field.setDefault()}
            />
          </Flex>
        </Td>
        <Td>
          <FormControl isInvalid={field.$.name.hasError}>
            <Input
              minW="130"
              onChange={(event) => field.$.name.onChange(event.target.value)}
              type="text"
              value={field.$.name.value}
            />
            <FormErrorMessage>
              <Trans>Priority name is required</Trans>
            </FormErrorMessage>
          </FormControl>
        </Td>
        <Td>
          <Flex align="center" justify="center" gap="3">
            <IconButton
              aria-label={t`Delete this task status`}
              icon={<Icon name="trash-03" />}
              onClick={() => {
                onDelete();
              }}
              variant="ghost"
            />
            <DragHandle
              {...listeners}
              cursor={isSorting ? "grabbing" : "grab"}
            />
          </Flex>
        </Td>
      </Tr>
    );
  },
);

export const Tasks: FunctionComponent = observer(function Tasks() {
  const {
    settingsModalStore: { tasksStore },
  } = useStore();
  const [isStatusDeleteModalOpen, setStatusIsDeleteModalOpen] = useState(false);
  const [taskStatusToDelete, setTaskStatusToDelete] =
    useState<TaskStatusState["internalId"]>("");
  const [isPriorityDeleteModalOpen, setPriorityIsDeleteModalOpen] =
    useState(false);
  const [taskPriorityToDelete, setTaskPriorityToDelete] =
    useState<TaskPriorityState["internalId"]>("");

  return (
    <Fragment>
      <DndContext
        onDragEnd={(event) => tasksStore.form.reorder("statuses", event)}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      >
        <SortableContext
          items={tasksStore.form.form.$.statuses.$.map(
            ({ internalId }) => internalId,
          )}
          strategy={verticalListSortingStrategy}
        >
          <TableContainer>
            <Table size="sm" variant="simple">
              <TableCaption paddingInlineStart="0" placement="top">
                <Heading as="h3" textAlign="left" size="md">
                  <Trans>Task statuses</Trans>
                </Heading>
              </TableCaption>
              <Thead>
                <Tr>
                  <Th textAlign="center">
                    <Tooltip
                      hasArrow
                      label={t`Default status will be used when creating new task.`}
                    >
                      <Box
                        as="span"
                        tabIndex={0}
                        textDecor="underline"
                        textDecorationStyle="dotted"
                        textUnderlineOffset="2px"
                      >
                        <Trans>Default</Trans>
                      </Box>
                    </Tooltip>
                  </Th>
                  <Th textAlign="center">
                    <Tooltip
                      hasArrow
                      label={t`Done status performs additional work in background when assigned.`}
                    >
                      <Box
                        as="span"
                        tabIndex={0}
                        textDecor="underline"
                        textDecorationStyle="dotted"
                        textUnderlineOffset="2px"
                      >
                        <Trans>Done</Trans>
                      </Box>
                    </Tooltip>
                  </Th>
                  <Th w="99%">
                    <Trans>Name</Trans>
                  </Th>
                  <Th>
                    <Trans>Colors</Trans>
                  </Th>
                  <Th textAlign="center">
                    <Trans>Actions</Trans>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {tasksStore.form.form.$.statuses.$.map((status) => (
                  <TaskStatusRow
                    key={status.internalId}
                    field={status}
                    onDelete={() => {
                      setTaskStatusToDelete(status.internalId);
                      setStatusIsDeleteModalOpen(true);
                    }}
                  />
                ))}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th colSpan={5}>
                    <Flex justify="end" paddingBlock="3">
                      <Button
                        leftIcon={<Icon name="plus" />}
                        onClick={() => tasksStore.form.addTaskStatus()}
                        variant="outline"
                      >
                        <Trans>Add new status</Trans>
                      </Button>
                    </Flex>
                  </Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>

          <ModalConfirm
            heading={t`Confirm removal of task status`}
            onClose={() => setStatusIsDeleteModalOpen(false)}
            isOpen={isStatusDeleteModalOpen}
            onConfirm={() => {
              tasksStore.form.removeTaskStatus(taskStatusToDelete);
            }}
          >
            <Trans>
              Removal of this task status will keep currently assigned tasks
              untouched, but you will not be able to assign new tasks to this
              status anymore. Do you want to remove this task status?
            </Trans>
          </ModalConfirm>
        </SortableContext>
      </DndContext>

      <DndContext
        onDragEnd={(event) => tasksStore.form.reorder("priorities", event)}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      >
        <SortableContext
          items={tasksStore.form.form.$.priorities.$.map(
            ({ internalId }) => internalId,
          )}
          strategy={verticalListSortingStrategy}
        >
          <TableContainer>
            <Table size="sm" variant="simple">
              <TableCaption paddingInlineStart="0" placement="top">
                <Heading as="h3" textAlign="left" size="md">
                  <Trans>Task priorities</Trans>
                </Heading>
              </TableCaption>
              <Thead>
                <Tr>
                  <Th textAlign="center">
                    <Trans>Default</Trans>
                  </Th>
                  <Th w="99%">
                    <Trans>Name</Trans>
                  </Th>
                  <Th textAlign="center">
                    <Trans>Actions</Trans>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {tasksStore.form.form.$.priorities.$.map((priority) => (
                  <TaskPriorityRow
                    key={priority.internalId}
                    field={priority}
                    onDelete={() => {
                      setTaskPriorityToDelete(priority.internalId);
                      setPriorityIsDeleteModalOpen(true);
                    }}
                  />
                ))}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th colSpan={5}>
                    <Flex justify="end" paddingBlock="3">
                      <Button
                        leftIcon={<Icon name="plus" />}
                        onClick={() => tasksStore.form.addTaskPriority()}
                        variant="outline"
                      >
                        <Trans>Add new priority</Trans>
                      </Button>
                    </Flex>
                  </Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>

          <ModalConfirm
            heading={t`Confirm removal of task priority`}
            onClose={() => setPriorityIsDeleteModalOpen(false)}
            isOpen={isPriorityDeleteModalOpen}
            onConfirm={() => {
              tasksStore.form.removeTaskPriority(taskPriorityToDelete);
            }}
          >
            <Trans>
              Removal of this task priority will keep currently assigned tasks
              untouched, but you will not be able to assign new tasks to this
              priority anymore. Do you want to remove this task priority?
            </Trans>
          </ModalConfirm>
        </SortableContext>
      </DndContext>

      <Box marginBlockStart="4" paddingBlock="2">
        <Heading as="h2" marginBlockEnd="2" size="md">
          <Trans>Show done tasks</Trans>
        </Heading>
        <FormControl
          alignItems="center"
          justifyContent="space-between"
          display="flex"
          borderBlock="1px solid #e2e8f0"
          paddingBlock="2"
        >
          <FormLabel mb="0" htmlFor="show-done">
            <Trans>
              Enabling this will show done tasks in project detail by default.
            </Trans>
          </FormLabel>
          <Switch
            id="show-done"
            isChecked={tasksStore.form.form.$.show_done.value}
            onChange={(event) =>
              tasksStore.form.form.$.show_done.onChange(event.target.checked)
            }
          />
        </FormControl>
      </Box>
    </Fragment>
  );
});
