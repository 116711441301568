import {
  RunningTimerQuery,
  RunningTimerQueryVariables,
} from "@src/__generated__/graphql";
import {
  TaskStatsDocument,
  TaskStatsQuery,
  TaskStatsQueryVariables,
  TimeTrackingEntryFragment,
} from "@src/__generated__/urql-graphql";
import { client } from "@src/services/client";
import { AppStore, appStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { TRefetch } from "@src/utils/apolloHelpers";
import { secondsToHours } from "date-fns";
import { action, computed, makeObservable, observable } from "mobx";

export class TimeTrackingStore implements BaseStore {
  appStore: AppStore;
  @observable runningEntry?: TimeTrackingEntryFragment = undefined;
  refetch?: TRefetch<RunningTimerQueryVariables, RunningTimerQuery>;

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @computed get isTimerRunning(): boolean {
    return Boolean(this.runningEntry);
  }

  @computed get runningHours(): number {
    return secondsToHours(
      this.runningEntry?.tracked_time_with_running_timer ?? 0,
    );
  }

  @computed get timerRunningForTooLong(): boolean {
    return this.runningHours >= 12;
  }

  @action.bound setRunningEntry(entry: RunningTimerQuery) {
    const affectedEntry = !entry.runningTimer?.timeTrackingItem
      ? this.runningEntry
      : undefined;
    this.runningEntry = entry.runningTimer?.timeTrackingItem
      ? {
          ...entry.runningTimer.timeTrackingItem,
          tracked_for_date: new Date(
            entry.runningTimer.timeTrackingItem.tracked_for_date,
          ),
        }
      : undefined;

    if (!affectedEntry) return;
    this.stopRunningEntryInPlanning(affectedEntry);
  }

  async updateTrackedTimeInListing(taskId: string) {
    if (!taskId) return;
    const { data, error } = await client.query<
      TaskStatsQuery,
      TaskStatsQueryVariables
    >(TaskStatsDocument, {
      id: taskId,
    });

    if (error || !data?.task?.stats) return;
    this.appStore.tasksListingStore.updateTask({
      id: taskId,
      stats: data.task.stats,
    });
  }

  stopRunningEntryInPlanning(entry: TimeTrackingEntryFragment) {
    if (!entry.user?.id) return;
    if (!entry.capacityAllocationItem?.id) return;

    const allocationItemInWeekStore =
      appStore.resourcePlanningWeekStore.findUserItem({
        id: entry.capacityAllocationItem.id,
        date: entry.tracked_for_date,
        userId: entry.user.id,
      });

    const allocationItemInDayStore =
      appStore.resourcePlanningDayStore.findUserItem({
        id: entry.capacityAllocationItem.id,
        date: entry.tracked_for_date,
        userId: entry.user.id,
      });

    if (allocationItemInDayStore) {
      allocationItemInDayStore.setHasRunningTimer(false);
      appStore.resourcePlanningDayStore.fetchDailyView({
        backgroundUpdate: true,
      });
    }
    if (allocationItemInWeekStore) {
      allocationItemInWeekStore.setHasRunningTimer(false);
      appStore.resourcePlanningWeekStore.fetchDailyView({
        backgroundUpdate: true,
      });
    }
  }

  trackingForAllocation(allocationId: string): boolean {
    if (!this.isTimerRunning) return false;
    if (!this.runningEntry?.capacityAllocationItem?.id) return false;
    return this.runningEntry.capacityAllocationItem.id === allocationId;
  }

  trackingForTask(taskId: string): boolean {
    if (!this.isTimerRunning) return false;
    if (!this.runningEntry?.task?.id) return false;
    return this.runningEntry.task.id === taskId;
  }
}
