import { useAvailableTaskPositionsHoursByOurWorkBudgetLazyQuery } from "@src/__generated__/graphql";
import { ModalTaskForm } from "@src/components/widgets/Modals/ModalTask";

export function useAutocompletePositionsFromOurWorkBudgetItem(
  form: ModalTaskForm,
) {
  const [getAvailableTaskPositionsHoursByOurWorkBudget] =
    useAvailableTaskPositionsHoursByOurWorkBudgetLazyQuery({
      onCompleted(data) {
        if (
          data.availableTaskPositionsHoursByOurWorkBudget &&
          !!data.availableTaskPositionsHoursByOurWorkBudget.length
        ) {
          const positions =
            data.availableTaskPositionsHoursByOurWorkBudget.filter(
              (item) => item.planned_time !== 0,
            );

          if (positions.length) {
            form.positions.clear();
            positions.map((item) => {
              form.addPosition(
                item.planned_time,
                item.time_tracking_work_type_id,
              );
            });
          }
        }
      },
    });

  return {
    autocompletePositions: getAvailableTaskPositionsHoursByOurWorkBudget,
  };
}
