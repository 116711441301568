import { t } from "@lingui/macro";
import { DateRange as Range } from "@src/utils/types";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { InputDatePicker } from "../InputDatePicker";
import { Form } from "./Form";

interface InputRangeDatePickerProps {
  selected?: Range;
  onSelect: (value: Range) => void;

  requiredFields?: boolean;

  onError?: (hasError: boolean) => void;
  usePortal?: boolean;
}

const InputRangeDatePicker = ({
  selected,
  onSelect,
  requiredFields = false,
  onError,
  usePortal,
}: InputRangeDatePickerProps) => {
  const form = useMemo(
    () => new Form({ required: requiredFields }),
    [requiredFields],
  );

  useEffect(() => {
    if (selected) {
      initForm(selected);
    }
  }, [selected?.start, selected?.end]);

  const initForm = async (value: Range) => {
    form.start.onChange(value.start);
    form.end.onChange(value.end);

    const { hasError } = await form.formState.validate();

    onError?.(hasError);
  };

  const onSelectFrom = async (value: Date | undefined) => {
    form.start.onChange(value);

    const { hasError } = await form.start.validate();
    const range = form.range;
    if (!hasError && !form.end.hasError && range) {
      onError?.(false);
      onSelect(range);
    } else {
      onError?.(true);
    }
  };

  const onSelectTo = async (value: Date | undefined) => {
    form.end.onChange(value);

    const { hasError } = await form.end.validate();
    const range = form.range;
    if (!hasError && !form.start.hasError && range) {
      onError?.(false);
      onSelect(range);
    } else {
      onError?.(true);
    }
  };

  return (
    <div>
      <InputDatePicker
        mb="5"
        label={t`Date From`}
        usePortal={!!usePortal}
        disableDatesAfter={form.end.value}
        selected={form.start.value}
        error={form.start.error}
        onChange={(range) => onSelectFrom(range?.start)}
      />

      <InputDatePicker
        usePortal={!!usePortal}
        label={t`Date To`}
        disableDatesBefore={form.start.value}
        selected={form.end.value}
        error={form.end.error}
        onChange={(range) => onSelectTo(range?.end)}
      />
    </div>
  );
};

export default observer(InputRangeDatePicker);
