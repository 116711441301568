import { ErrorScreen } from "@components/ui-kit";
import { t } from "@lingui/macro";

export const NotFoundOutgoingInvoice = () => (
  <ErrorScreen
    title={t`I didn’t find the outgoing invoice you are looking for.`}
    link={{
      text: t`See all outgoing invoices`,
      href: "/invoices/outgoing-invoices",
    }}
  />
);
