import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { TasksSettingsState } from "@src/stores/forms/SettingsModalState/TasksSettingsState";

export class TasksSettingsStore implements BaseStore {
  constructor(
    public appStore: AppStore,
    public form: TasksSettingsState,
  ) {}
}
