import {
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { AssignableFileUrlPair } from "@src/__generated__/urql-graphql";
import { Icon } from "@src/components/ui-kit/Icon";

type ImageModalProps = Omit<ModalProps, "children"> & {
  title: string;
  url: NonNullable<AssignableFileUrlPair["viewable"]>;
  originalUrl: NonNullable<AssignableFileUrlPair["original"]>;
};

export const ImageModal = ({
  url,
  title,
  originalUrl,
  ...props
}: ImageModalProps) => {
  return (
    <Modal {...props} size="4xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}{" "}
          <IconButton
            pos="absolute"
            ml="4"
            aria-label={t`download`}
            colorScheme="grey"
            icon={<Icon name="download-02" w="5" h="5" color="grey.500" />}
            onClick={() => {
              window.open(originalUrl, "_blank");
            }}
            variant="outline"
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Image w="full" h="full" objectFit="contain" alt={title} src={url} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
