import { Button } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import {
  TimeTrackingItem,
  useStopTimerMutation,
} from "@src/__generated__/graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import { onError } from "@src/utils/apollo";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

interface StopButtonProps {
  timeTrackingItem: Pick<TimeTrackingItem, "id">;
  onStop: () => void;
}

const StopButton = ({ timeTrackingItem, onStop }: StopButtonProps) => {
  const { timeTrackingStore } = useStore();

  const [stop, { loading }] = useStopTimerMutation({
    variables: { time_tracking_item_id: timeTrackingItem.id },
    onCompleted(data) {
      if (data?.stopTimer) {
        onStop();
        timeTrackingStore.refetch?.();
      }
    },
    ...onError(),
  });

  return (
    <Button
      isLoading={loading}
      leftIcon={<Icon name="pause-circle" variant="solid" />}
      onClick={() => stop()}
    >
      {t`Stop timer`}
    </Button>
  );
};

export default observer(StopButton);
