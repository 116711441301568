import { useStore } from "@src/utils/hooks";
import { useScreenType } from "@src/utils/hooks/useIsMobile";
import Script from "next/script";
import { Fragment } from "react";

// eslint-disable-next-line @cspell/spellchecker
const intercomAppId = "j4pskzxj";

export const IntercomWidget = () => {
  const { authStore, workspaceStore } = useStore();
  const { isMobile } = useScreenType();

  if (!authStore.user || isMobile || !workspaceStore.settings) return null;

  /* eslint-disable lingui/no-unlocalized-strings */
  return (
    <Fragment>
      <Script id="intercom-chat-setup">
        {`
          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "${intercomAppId}",
            name: "${authStore.user.full_name}", // Full name
            user_id: "${authStore.user.id}-${
              workspaceStore.settings.title
            }", // a UUID for your user
            email: "${authStore.user.email}", // the email for your user
            company: {
              company_id: "${workspaceStore.settings.title}",
              name: "${workspaceStore.settings.title}",
              created_at: 0,
              monthly_spend: 0,
              size: 0,
              website: "Website",
              industry: "Industry"
            },
            "Roles": "${authStore.user.roles.map(({ name }) => name).join(", ")}",
            "Position": "${authStore.user.profile.defaultWorktype.title}",
          };
        `}
      </Script>
      <Script id="intercom-chat-code">
        {`
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/j4pskzxj';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `}
      </Script>
    </Fragment>
  );
  /* eslint-enable lingui/no-unlocalized-strings */
};
