import { UnionToIntersection } from "ts-essentials";

export interface ISearchParamSerializable {
  asURLSearchParam: {
    [key in string]: string;
  };

  fromURLSearchParam: (src: any) => void;
}

export interface IQueryWhereParam {
  asWhereParam: unknown;
}

class AssertionError extends Error {
  constructor(message?: string) {
    super(message); // 'Error' breaks prototype chain here
    this.name = "AssertionError";
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

export function assert(condition: any, msg?: string): asserts condition {
  if (!condition) {
    throw new AssertionError(msg);
  }
}

export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new AssertionError(
      `Expected 'val' to be defined, but received ${val}`,
    );
  }
}

export function omitEmpty<T extends { value: any }>(arg: T) {
  if (arg.value == null || arg.value.length === 0) return undefined;
  return arg;
}

export interface Option<Label, Value> {
  label: Label;
  value: Value;
}

export enum FormMode {
  CREATE = "create",
  EDIT = "edit",
  DUPLICATE = "duplicate",
  MOVE = "move",
}

export type DateRange = {
  start: Date;
  end: Date;
};

export type WeekDayIndexes<T> = [T, T, T, T, T, T, T];
export type WeekDays<T> = {
  monday: T;
  tuesday: T;
  wednesday: T;
  thursday: T;
  friday: T;
  saturday: T;
  sunday: T;
};

/**
 * Prettify type to get beautiful readout of all of the members of intersection.
 */
export type Prettify<T> = { [K in keyof T]: T[K] } & {};

type HH =
  | `${"0" | "1"}${"0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9"}`
  | `2${"0" | "1" | "2" | "3"}`;
type MM = `${"0" | "1" | "2" | "3" | "4" | "5"}${
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"}`;

type SS = `${"0" | "1" | "2" | "3" | "4" | "5"}${
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"}`;

export type Time = `${HH}:${MM}`;

export type TimeWithSeconds = `${HH}:${MM}:${SS}`;

export type MathSign = -1 | 1 | 0;

export type CSSUnits = "px" | "rem" | "em" | "%" | "vw" | "vw";
export type CSSUnitValue = `${number}${CSSUnits}`;

export type IsUnion<T> = [T] extends [UnionToIntersection<T>] ? false : true;

type Function = (...args: any) => any;

/**
 * Make all function return types in T optional
 */
export type OptionFunctionReturnType<T extends { [key: string]: Function }> = {
  [P in keyof T]: (...params: Parameters<T[P]>) => ReturnType<T[P]> | undefined;
};
