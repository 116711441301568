import { AttachmentNodeView } from "@src/components/ui-kit/TextEditor/extensions/AttachmentNodeView";
import {
  Attachment,
  OnUpload,
  UploadAttachmentPlugin,
} from "@src/components/ui-kit/TextEditor/extensions/UploadAttachmentPlugin";
import { Node, mergeAttributes, nodeInputRule } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    attachment: {
      setAttachment: (options: AttachmentWithLoading) => ReturnType;
      getAttachmentIds: () => Attachment["id"][];
      syncAttachments: () => void;
    };
  }
}

interface AttachmentWithLoading extends Attachment {
  loading: boolean;
}

const FILE_INPUT_REGEX = /!\[(.+|:?)\]\((\S+)(?:\s+["'](\S+)["'])?\)/;

interface ImageOptions {
  HTMLAttributes: Record<string, any>;
}

export interface AttachmentStorage {
  attachments: Attachment[];
}

export const AttachmentNode = (uploadFn: OnUpload) => {
  return Node.create<ImageOptions, AttachmentStorage>({
    name: "attachment",

    addOptions() {
      return {
        HTMLAttributes: {},
      };
    },

    addNodeView() {
      return ReactNodeViewRenderer(AttachmentNodeView);
    },

    atom: true,

    group: "block",

    showGapCursor: true,
    defining: true,
    draggable: true,

    // @ts-expect-error
    addCommands() {
      return {
        setAttachment:
          ({
            id,
            mime_type,
            filename,
            viewable,
            original,
            thumbnail,
            loading,
          }: AttachmentWithLoading) =>
          ({ state, dispatch }) => {
            const { selection } = state;
            const position = selection.$head
              ? selection.$head.pos
              : selection.$to.pos;

            this.storage.attachments.push({
              id,
              mime_type,
              filename,
              viewable,
              original,
              thumbnail,
            });
            const node = this.type.create({ id, loading });
            const transaction = state.tr.insert(position, node);
            return dispatch?.(transaction);
          },
        getAttachmentIds: () => () => {
          return this.storage.attachments.map((attachment) => attachment.id);
        },
        syncAttachments: () => () => {
          // sync attachments storage with editor content (attachments can be removed with selection)
          const attachmentIDs: string[] = [];
          const parser = new DOMParser();
          const htmlString = this.editor.getHTML();
          const htmlDocument = parser.parseFromString(htmlString, "text/html");
          const attachments = htmlDocument.querySelectorAll(
            `[data-type="${this.name}"]`,
          );
          attachments.forEach((attachmentTag) => {
            const attachmentID = attachmentTag.getAttribute("data-id");
            if (attachmentID) {
              attachmentIDs.push(attachmentID);
            }
          });

          this.storage.attachments = this.storage.attachments.filter(({ id }) =>
            attachmentIDs.includes(id),
          );
        },
      };
    },

    addStorage() {
      return {
        attachments: [],
      };
    },

    onUpdate() {
      this.editor.commands.syncAttachments();
    },

    addAttributes() {
      return {
        loading: {
          default: false,
        },
        id: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-id"),
          renderHTML: (attributes) => {
            if (!attributes.id) return {};
            return {
              "data-id": attributes.id,
            };
          },
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: `span[data-type="${this.name}"]`,
        },
      ];
    },

    renderHTML({ HTMLAttributes }) {
      return [
        "span",
        mergeAttributes(
          { "data-type": this.name },
          this.options.HTMLAttributes,
          HTMLAttributes,
        ),
      ];
    },

    addInputRules() {
      return [
        nodeInputRule({
          find: FILE_INPUT_REGEX,
          type: this.type,
          getAttributes: (match) => {
            const [, id, loading] = match;
            return {
              id,
              loading,
            };
          },
        }),
      ];
    },
    addProseMirrorPlugins() {
      return [UploadAttachmentPlugin(uploadFn, this.editor)];
    },
  });
};
