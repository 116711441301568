import { TextEditorProps } from "@src/components/ui-kit/TextEditor/TextEditor";
import { HyperlinkModal } from "@src/components/ui-kit/TextEditor/toolbar/HyperlinkButton";
import { ToolbarMenu } from "@src/components/ui-kit/TextEditor/toolbar/ToolbarMenu";
import {
  ToolbarStore,
  ToolbarStoreContext,
} from "@src/components/ui-kit/TextEditor/toolbar/ToolbarStore";
import { Editor } from "@tiptap/core";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";

interface ToolbarProps {
  editor: Editor;
  showToolbar: TextEditorProps["showToolbar"];
  isEditable: TextEditorProps["isEditable"];
}

export const Toolbar: FC<ToolbarProps> = observer(function Toolbar({
  editor,
  showToolbar,
  isEditable,
}) {
  const [toolbarStore] = useState(() => new ToolbarStore(editor));

  return (
    <ToolbarStoreContext.Provider value={toolbarStore}>
      {isEditable && showToolbar && <ToolbarMenu editor={editor} />}
      <HyperlinkModal />
    </ToolbarStoreContext.Provider>
  );
});
