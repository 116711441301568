import { IOption } from "@components/ui-kit";
import { ModalTaskDependenciesQuery } from "@src/__generated__/graphql";
import mapToOptions from "@src/utils/map-to-options";
import { useMemo } from "react";

export const useDependenciesOptions = (
  data: ModalTaskDependenciesQuery | undefined,
) => {
  return useMemo(() => {
    const _options = {
      budgetItemOptions: [] as IOption[],
      workTypeOptions: [] as IOption[],
    };

    if (!data) return _options;

    _options.budgetItemOptions = mapToOptions.budgetItems(
      data.projectForTask?.ourWork ?? [],
    );
    _options.workTypeOptions = mapToOptions.toOptions(
      data.projectForTask?.availableTimeTrackingWorkTypes ?? [],
      "title",
    );

    return _options;
  }, [data]);
};
