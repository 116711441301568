import { Currency } from "@src/__generated__/urql-graphql";
import { appStore } from "@src/stores/AppStore";
import { NumberModel } from "../models";
import { nominate } from "./helpers";

type TValue = number | string;

interface TOptions {
  czkNoFractionDigits?: boolean;
  fractionDigits?: number;
}

/**
 * minimumFractionDigits is set in this order:
 *   - options.minimumFractionDigits
 *   - options.czkNoFractionDigits
 *   - denominator
 *   - undefined
 */
const money = (currency: string, denominator?: number, options?: TOptions) => {
  let minimumFractionDigits: number | undefined;

  if (options?.fractionDigits !== undefined) {
    minimumFractionDigits = options.fractionDigits;
  } else if (options?.czkNoFractionDigits && currency.toLowerCase() === "czk") {
    minimumFractionDigits = 0;
  } else if (denominator !== undefined) {
    minimumFractionDigits = denominator;
  }

  return new Intl.NumberFormat(
    appStore.workspaceStore.settings?.locale ?? "sk-SK",
    {
      style: "currency",
      currency,
      minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    },
  );
};

const format = (
  value: TValue,
  currencyCode: string | Currency,
  denominator: number,
  options?: TOptions,
): string => {
  if (typeof currencyCode !== "string") {
    currencyCode = currencyCode.code;
  }

  return money(currencyCode, denominator, options).format(
    nominate(+value, denominator),
  );
};

const formatByCurrency = (
  value: TValue,
  currency: Pick<Currency, "code" | "denomination">,
  options?: TOptions,
): string => {
  return money(currency.code, currency.denomination, options).format(
    nominate(+value, currency.denomination),
  );
};

const formatByNumberModel = (
  value: NumberModel,
  currency: string,
  options?: TOptions,
): string => {
  return money(currency, value.denominator, options).format(
    nominate(value.denominatedValue, value.denominator),
  );
};

const formatByWorkspace = (
  value: number | string | NumberModel,
  options?: TOptions,
): string => {
  if (!appStore.workspaceStore.settings) {
    return "";
  }

  if (value instanceof NumberModel) {
    value = value.value;
  } else if (typeof value === "string") {
    value = +value;
  }

  const { code, denomination: denominator } =
    appStore.workspaceStore.settings.currency;

  return money(code, denominator, options).format(nominate(value, denominator));
};

const formatNominal = (
  value: number | string | NumberModel,
  currency: string,
  options?: TOptions,
): string => {
  if (value instanceof NumberModel) {
    value = value.value;
  } else if (typeof value === "string") {
    value = +value;
  }

  return money(currency, undefined, options).format(value);
};

export const currency = {
  format,
  formatByCurrency,
  formatByNumberModel,
  formatByWorkspace,
  formatNominal,
};
