import { HStack, Text, Tooltip } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import { formatDuration } from "@src/utils/formatters";
import { observer } from "mobx-react-lite";

interface DurationProps {
  duration: number;
  autoStopped?: boolean;
  billable: boolean;
}

const Duration = ({ duration, autoStopped, billable }: DurationProps) => {
  return (
    <WrapComponent
      if={autoStopped ?? false}
      with={(children) => (
        <Tooltip
          label={<Trans>This item has been automatically stopped.</Trans>}
          placement="top"
          shouldWrapChildren
        >
          {children}
        </Tooltip>
      )}
    >
      <HStack>
        {autoStopped && <Icon name="alert-triangle" color="red.500" />}
        {!billable && (
          <Tooltip
            label={<Trans>Non-billable</Trans>}
            placement="top"
            shouldWrapChildren
          >
            <Icon name="non-billable" />
          </Tooltip>
        )}
        <Text color="grey.700" fontSize="xl" fontWeight="semibold">
          {formatDuration(duration, { noPadStart: true })}
        </Text>
      </HStack>
    </WrapComponent>
  );
};

export default observer(Duration);
