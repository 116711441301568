import { BoxProps, Center, Circle, Tooltip } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { t } from "@lingui/macro";
import { observer } from "mobx-react-lite";

interface RoundToggleButtonProps extends Omit<BoxProps, "onToggle"> {
  isActive: boolean;
  onToggle: (val: boolean) => void;
  loading?: boolean;
}

const RoundToggleButton = ({
  isActive,
  onToggle,
  loading,
  ...props
}: RoundToggleButtonProps) => {
  return (
    <Tooltip label={isActive ? t`Mark as unread` : t`Mark as read`}>
      <Center
        p="1"
        {...props}
        onClick={(e) => {
          e.preventDefault?.();
          e.stopPropagation?.();
          onToggle(!isActive);
        }}
        role="group"
      >
        {loading ? (
          <Spinner size="sm" />
        ) : (
          <Circle
            bg={isActive ? "grey.100" : "red.500"}
            _groupHover={{
              bg: isActive ? "grey.200" : "red.600",
            }}
            transition="all 0.2s ease-in-out"
            size="4"
          />
        )}
      </Center>
    </Tooltip>
  );
};

export default observer(RoundToggleButton);
