/* eslint-disable lingui/no-unlocalized-strings */
const borders = {
  none: 0,
  "1px": "1px solid",
  "2px": "2px solid",
  "4px": "4px solid",
  "8px": "8px solid",
};

export default borders;
