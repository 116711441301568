import { Button, ButtonGroup, HStack, IconButton } from "@chakra-ui/react";
import { DatePicker } from "@components/ui-kit";
import { t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { DateRange as Range } from "@src/utils/types";
import { observer } from "mobx-react-lite";
import { DateStepper, useStore } from "../../store";
import CurrentDate from "./CurrentDate";

interface DateFilterProps {
  stepper: DateStepper;
}

const DateFilter = ({ stepper }: DateFilterProps) => {
  const store = useStore();

  const handleDateChange = (val: Range | undefined) => {
    if (val) {
      stepper.setCurrent(val.start);
    }
  };

  const handlePrevious = () => {
    if (store.weekMode) {
      stepper.prevWeek();
    } else {
      stepper.prevDay();
    }
  };

  const handleNext = () => {
    if (store.weekMode) {
      stepper.nextWeek();
    } else {
      stepper.nextDay();
    }
  };

  return (
    <HStack spacing="3">
      <ButtonGroup colorScheme="grey" isAttached variant="outline">
        <IconButton
          mr="-px"
          aria-label={t`prev`}
          icon={<Icon name="chevron-left" />}
          onClick={handlePrevious}
        />
        <IconButton
          aria-label={t`next`}
          icon={<Icon name="chevron-right" />}
          onClick={handleNext}
        />
      </ButtonGroup>

      <DatePicker
        trigger={
          <IconButton
            aria-label={t`choose date`}
            colorScheme="grey"
            icon={<Icon name="calendar" w="5" h="5" color="grey.500" />}
            variant="outline"
          />
        }
        onChange={handleDateChange}
        selected={stepper.current}
      />

      <ButtonGroup colorScheme="grey" isAttached variant="outline">
        <Button
          mr="-px"
          bg={!store.weekMode ? "grey.50" : "initial"}
          onClick={() => store.toggleWeekMode(false)}
        >
          {t`Day`}
        </Button>
        <Button
          bg={store.weekMode ? "grey.50" : "initial"}
          onClick={() => store.toggleWeekMode(true)}
        >
          {t`Week`}
        </Button>
      </ButtonGroup>

      <CurrentDate current={store.stepper.current} weekMode={store.weekMode} />

      {!store.weekMode && !store.stepper.isCurrentToday && (
        <Button
          fontWeight="normal"
          onClick={() => stepper.setToday()}
          variant="ghost"
        >
          {t`Return to Today`}
        </Button>
      )}
      {store.weekMode && !store.stepper.isCurrentWeek && (
        <Button
          fontWeight="normal"
          onClick={() => stepper.setThisWeek()}
          variant="ghost"
        >
          {t`Return to this week`}
        </Button>
      )}
    </HStack>
  );
};

export default observer(DateFilter);
