import { withProse } from "@nikolovlazar/chakra-ui-prose";

export const remoteHtmlStyles = withProse({
  baseStyle: {
    ".ProseMirror": {
      mt: 0,
      "> * + *": {
        marginTop: 0,
      },
      p: {
        my: 2,
      },
      "p.is-editor-empty:first-of-type::before": {
        content: "attr(data-placeholder)",
        color: "gray.500",
        float: "left",
        pointerEvents: "none",
        height: 0,
      },
      "&:focus": {
        outline: "none",
      },
    },
    "p, a, span, li": {
      fontSize: "sm",
    },
    a: {
      color: "blue.500",
    },
    ".mention": {
      bg: "blue.50",
      color: "blue.600",
      fontWeight: "medium",
      p: 1,
      rounded: "md",
    },
    ".mention.my-mention": {
      bg: "#FCEBDA",
      color: "#B44807",
    },
    'ul[data-type="taskList"]': {
      listStyle: "none",
      padding: 0,

      p: {
        margin: 0,
      },

      "input[type=checkbox]": {
        accentColor: "#3A7995",
      },

      'li[data-checked="true"] > div > p': {
        color: "grey.500",
        textDecoration: "line-through",
      },

      li: {
        display: "flex",
        alignItems: "baseline",
        my: 1,

        "> label": {
          flex: "0 0 auto",
          marginRight: "0.5rem",
          userSelect: "none",
        },

        "> div": {
          flex: "1 1 auto",
        },
      },
    },
    pre: {
      background: "#0d0d0d",
      borderRadius: "0.5rem",
      color: "#fff",
      fontFamily: "JetBrainsMono, monospace",
      padding: "0.75rem 1rem",
      code: {
        background: "none",
        color: "inherit",
        padding: 0,
      },
    },
    ol: {
      listStyle: "decimal",
    },
    "ol > li::marker": {
      color: "grey.500",
    },
    iframe: {
      border: "8px solid #000",
      borderRadius: "4px",
      minWidth: "200px",
      maxW: "600px",
      maxH: "400px",
      minHeight: "200px",
      display: "block",
      outline: "0px solid transparent",
    },

    "div[data-youtube-video]": {
      cursor: "move",
      paddingRight: "24px",
    },
    ".node-attachment": {
      display: "inline-flex",
    },
  },
});
