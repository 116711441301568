import { HStack, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { sumBy } from "lodash";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Store } from "../../store";
import Duration from "./Duration";

interface TotalDayDurationProps {
  items: Store["entries"];
}

const TotalDayDuration = ({ items }: TotalDayDurationProps) => {
  const total = useMemo(() => sumBy(items, "tracked_time"), [items]);

  return (
    <HStack align="baseline" spacing="3">
      <Text color="grey.500">
        <Trans>Day total:</Trans>
      </Text>
      <Duration duration={total} billable />
    </HStack>
  );
};

export default observer(TotalDayDuration);
