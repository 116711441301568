import {
  Button,
  Grid,
  HStack,
  StyleProps,
  SystemStyleObject,
  Text,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { TaskType } from "@src/components/modules/resource-planning/tasks/listing/TasksListingStore";
import { TASK_ID_QUERY_KEY } from "@src/components/widgets/Modals/ModalCommunication/CommunicationModalHeader";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Icon } from "../Icon";
import { TaskStatusBadge } from "../TaskStatusBadge";

export type TTaskItemBaseProps = {
  task: TaskType | undefined;
  title: string;
  titleOpacity?: StyleProps["opacity"];
  wrapperStyle?: SystemStyleObject;
  onClick?: (task: TaskType) => void;
  content: (task: TaskType) => React.ReactNode;
  extraContent?: (task: TaskType) => React.ReactNode;
};

export const TaskItemBase: FC<TTaskItemBaseProps> = observer(
  function TaskItemBase({
    task,
    wrapperStyle,
    onClick,
    content,
    extraContent,
    title,
    titleOpacity,
  }) {
    const { UIStore } = useStore();
    const { onCopy } = useClipboard(
      `${location.origin}/notifications?${TASK_ID_QUERY_KEY}=${task?.id ?? ""}`,
    );

    const handleCopyId = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      event.preventDefault();
      event.stopPropagation();
      onCopy();
      UIStore.toast({
        title: t`Link copied to clipboard`,
        status: "info",
        isClosable: true,
        duration: 3000,
      });
    };

    const badgeWidth = wrapperStyle?.gridTemplateColumns
      ? Object.fromEntries(
          Object.entries(wrapperStyle.gridTemplateColumns).map(
            ([key, value]) => [key, value.split(" ")[0]],
          ),
        )
      : "9rem";

    if (!task) return null;
    return (
      <Grid
        sx={wrapperStyle}
        alignItems="center"
        gap="4"
        overflow="hidden"
        w="full"
        h="42px"
        px="2.5"
        py="1.5"
        fontWeight="medium"
        bg="white"
        shadow="md"
        cursor="pointer"
        onClick={() => onClick?.(task)}
        rounded="md"
      >
        <TaskStatusBadge
          badgeWidth={badgeWidth}
          background_color={task.status.background_color}
          name={task.status.name}
          foreground_color={task.status.foreground_color}
        />

        <Tooltip label={t`Copy link to task`}>
          <Button onClick={handleCopyId} size="sm" variant="link">
            {task.id}
          </Button>
        </Tooltip>

        <HStack spacing="2">
          <Tooltip label={title}>
            <Text
              color="inherit"
              fontWeight="medium"
              opacity={titleOpacity}
              textOverflow="ellipsis"
              noOfLines={1}
            >
              {title}
            </Text>
          </Tooltip>
          {!task.billable && (
            <Tooltip label={t`Non-billable`} shouldWrapChildren>
              <Icon name="non-billable" />
            </Tooltip>
          )}
        </HStack>

        {content(task)}

        <HStack justify="end">
          <Text
            color={
              Boolean(task.unreadCommentsCount) && task.commentCount > 0
                ? "white"
                : "grey.600"
            }
            fontSize="xs"
            fontWeight="bold"
            lineHeight="6"
            textAlign="center"
          >
            {task.commentCount}
          </Text>
          <Icon
            name="message-text-square-01"
            w="5"
            h="5"
            color={
              Boolean(task.unreadCommentsCount) && task.commentCount > 0
                ? "red.500"
                : "grey.400"
            }
          />
        </HStack>

        {extraContent?.(task)}
      </Grid>
    );
  },
);
