import { Button, ButtonProps, forwardRef } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { FunctionComponent } from "react";

export const ExportButton: FunctionComponent<Omit<ButtonProps, "children">> =
  forwardRef((props, ref) => {
    return (
      <Button
        colorScheme="grey"
        leftIcon={<Icon name="download-02" />}
        variant="outline"
        {...props}
        ref={ref}
      >
        <Trans>Export</Trans>
      </Button>
    );
  });
