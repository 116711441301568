import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import {
  WorkspaceTaskPriority,
  WorkspaceTaskSettings,
  WorkspaceTaskStatus,
} from "@src/__generated__/urql-graphql";
import { calculateBackgroundColor } from "@src/utils/colors";
import { TToFieldStates } from "@src/utils/forms/ts-utils";
import { required } from "@src/utils/forms/validators";
import { FieldState, FormState } from "formstate";
import { reaction } from "mobx";
import { v4 as uuidV4 } from "uuid";

type TaskStatusStateProps = Omit<WorkspaceTaskStatus, "id" | "order">;
export class TaskStatusState extends FormState<
  TToFieldStates<TaskStatusStateProps>
> {
  id: WorkspaceTaskStatus["id"];
  internalId: string;

  onDefaultChange: () => void;
  onDoneChange: () => void;

  constructor(
    onDefaultChange: typeof TaskStatusState.prototype.onDefaultChange,
    onDoneChange: typeof TaskStatusState.prototype.onDoneChange,
    id?: WorkspaceTaskStatus["id"],
    fields: TaskStatusStateProps = {
      background_color: "#FFFFFF",
      default: false,
      done: false,
      foreground_color: "#000000",
      name: "",
    },
  ) {
    super({
      background_color: new FieldState(fields.background_color),
      default: new FieldState(fields.default),
      done: new FieldState(fields.done),
      foreground_color: new FieldState(fields.foreground_color),
      name: new FieldState(fields.name).validators(required),
    });
    this.id = id;
    this.internalId = uuidV4();
    this.onDefaultChange = onDefaultChange;
    this.onDoneChange = onDoneChange;

    reaction(
      () => this.$.background_color.value,
      () => {
        this.$.background_color.value = calculateBackgroundColor(
          this.$.foreground_color.value,
        );
      },
    );
  }

  setDefault() {
    this.onDefaultChange();
    this.$.default.value = true;
  }

  setDone() {
    this.onDoneChange();
    this.$.done.value = true;
  }
}

type TaskPriorityStateProps = Omit<WorkspaceTaskPriority, "id" | "order">;
export class TaskPriorityState extends FormState<
  TToFieldStates<TaskPriorityStateProps>
> {
  id: WorkspaceTaskPriority["id"];
  internalId: string;

  onDefaultChange: () => void;

  constructor(
    onDefaultChange: typeof TaskPriorityState.prototype.onDefaultChange,
    id?: WorkspaceTaskPriority["id"],
    fields: TaskPriorityStateProps = {
      default: false,
      name: "",
    },
  ) {
    super({
      default: new FieldState(fields.default),
      name: new FieldState(fields.name).validators(required),
    });
    this.id = id;
    this.internalId = uuidV4();
    this.onDefaultChange = onDefaultChange;
  }

  setDefault() {
    this.onDefaultChange();
    this.$.default.value = true;
  }
}

export class TasksSettingsState {
  readonly form = new FormState({
    statuses: new FormState<TaskStatusState[]>([]),
    priorities: new FormState<TaskPriorityState[]>([]),
    show_done: new FieldState<WorkspaceTaskSettings["show_done_tasks"]>(false),
  });

  init(settings: WorkspaceTaskSettings) {
    this.form.$.statuses = new FormState(
      settings.task_statuses.map(
        (status) =>
          new TaskStatusState(
            this.resetStatusField("default"),
            this.resetStatusField("done"),
            status.id,
            status,
          ),
      ),
    );
    this.form.$.priorities = new FormState(
      settings.task_priorities.map(
        (priority) =>
          new TaskPriorityState(
            this.resetPriorityDefaultField(),
            priority.id,
            priority,
          ),
      ),
    );
    this.form.$.show_done.value = settings.show_done_tasks;
  }

  resetStatusField(type: "default" | "done") {
    return () => {
      const index = this.form.$.statuses.$.findIndex(
        (status) => status.$[type].value,
      );
      this.form.$.statuses.$[index].$[type].value = false;
    };
  }

  resetPriorityDefaultField() {
    return () => {
      const index = this.form.$.priorities.$.findIndex(
        (priority) => priority.$.default.value,
      );
      this.form.$.priorities.$[index].$.default.value = false;
    };
  }

  reorder(type: "statuses" | "priorities", { active, over }: DragEndEvent) {
    if (!active.id || !over?.id || active.id === over.id) return;

    const oldIndex = this.form.$[type].$.findIndex(
      (item) => item.internalId === active.id,
    );
    const newIndex = this.form.$[type].$.findIndex(
      (item) => item.internalId === over.id,
    );

    if (type === "statuses") {
      this.form.$.statuses = new FormState(
        arrayMove(this.form.$.statuses.$, oldIndex, newIndex),
      );
    } else {
      this.form.$.priorities = new FormState(
        arrayMove(this.form.$.priorities.$, oldIndex, newIndex),
      );
    }
  }

  addTaskStatus() {
    this.form.$.statuses.$.push(
      new TaskStatusState(
        this.resetStatusField("default"),
        this.resetStatusField("done"),
      ),
    );
  }

  removeTaskStatus(internalId: TaskStatusState["internalId"]) {
    this.form.$.statuses = new FormState(
      this.form.$.statuses.$.filter(
        (status) => status.internalId !== internalId,
      ),
    );
    if (!this.form.$.statuses.$.find((status) => status.$.default.value)) {
      this.form.$.statuses.$[0].$.default.value = true;
    }
    if (!this.form.$.statuses.$.find((status) => status.$.done.value)) {
      this.form.$.statuses.$[this.form.$.statuses.$.length - 1].$.done.value =
        true;
    }
  }

  addTaskPriority() {
    this.form.$.priorities.$.push(
      new TaskPriorityState(this.resetPriorityDefaultField()),
    );
  }

  removeTaskPriority(internalId: TaskPriorityState["internalId"]) {
    this.form.$.priorities = new FormState(
      this.form.$.priorities.$.filter(
        (priority) => priority.internalId !== internalId,
      ),
    );
    if (
      !this.form.$.priorities.$.find((priority) => priority.$.default.value)
    ) {
      this.form.$.priorities.$[0].$.default.value = true;
    }
  }
}
