import { logout } from "@src/helpers/logout";
import { useStore } from "@src/utils/hooks";
import React, { FC, useEffect } from "react";

export const DevTools: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const appStore = useStore();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      // @ts-expect-error
      globalThis.__devTools__ = {
        globalStore: appStore,
        logout,
      };
    }
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <React.Fragment>{children}</React.Fragment>;
};
