import {
  Flex,
  Heading,
  HeadingProps,
  Skeleton,
  Td,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { SUMMARY_BOX_BG } from "@src/components/ui-kit/DataTable/SummaryBox";
import { Icon } from "@src/components/ui-kit/Icon";
import { observer } from "mobx-react-lite";
import React, { FC, ReactNode } from "react";
import s from "./SummaryItem.module.css";

interface SummaryItemProps {
  label: string;
  isLoaded?: boolean;
  labelTooltip?: ReactNode;
  coloredValue?: number;
  defaultColor?: boolean;
  alignment?: "left" | "right";
}

const SummaryItem: FC<React.PropsWithChildren<SummaryItemProps>> = ({
  label,
  labelTooltip,
  coloredValue,
  children,
  isLoaded,
  alignment = "left",
}) => (
  <Td
    className={s.SummaryItem}
    align={alignment}
    justifyContent="start"
    verticalAlign="0"
    p="4"
    bg={SUMMARY_BOX_BG}
    rounded="lg"
  >
    <Flex>
      <Text w="full" mb="0.5" color="grey.500" textAlign={alignment}>
        {label}
      </Text>
      {!!labelTooltip && (
        <Tooltip label={labelTooltip}>
          <div className="ml-2">
            &nbsp;
            <Icon
              variant="solid"
              name="info-circle"
              color="#919EAB"
              style={{ transform: "scale(0.8)" }}
            />
          </div>
        </Tooltip>
      )}
    </Flex>

    <Skeleton isLoaded={isLoaded === undefined ? true : isLoaded}>
      <Heading
        color={color(coloredValue)}
        fontSize={{ base: "sm", xl: "md" }}
        textAlign={alignment}
      >
        {children}
      </Heading>
    </Skeleton>
  </Td>
);

const color = (value?: number): HeadingProps["color"] => {
  if (value && value < 0) return "red.500";
  if (value && value > 0) return "green.500";
  return "initial";
};

export default observer(SummaryItem);
