import { HStack } from "@chakra-ui/react";
import { TimeTrackingEntryFragment } from "@src/__generated__/urql-graphql";
import { TimeTrackingLabel } from "@src/components/modules/time-tracking/TimeSheet/components/Entries/TimeTrackingLabel";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import Duration from "./Duration";
import { TimeTrackingItemMenuActions } from "./TimeTrackingItemMenuActions";

interface EntryProps {
  value: TimeTrackingEntryFragment;
  running?: boolean;
}

const Entry = ({ value: e, running }: EntryProps) => {
  const { timeTrackingPopoverStore: store } = useStore();

  return (
    <HStack justify="end">
      {e.task.ourWorkBudgetItem && (
        <TimeTrackingLabel
          py="3"
          mr="auto"
          project={e.task.ourWorkBudgetItem.project}
          brandName={e.task.ourWorkBudgetItem.project.brand.name}
          budgetItemTitle={e.task.ourWorkBudgetItem.title}
          taskName={e.task.name}
          taskId={e.task.id}
          workTypeTitle={e.timeTrackingWorkType.title}
        />
      )}
      <Duration
        value={e.tracked_time_with_running_timer}
        highlighted={running}
        billable={e.billable}
      />
      {!running && (
        <TimeTrackingItemMenuActions
          value={e}
          onStart={(data) => {
            if (data?.startTimer) {
              store.refetchEntries();
              store.togglePopover();
              store.form.reset();
            }
          }}
          onDelete={() => {
            store.refetchEntries();
          }}
          onEdit={() => {
            const { form } = store;
            form.reset();
            form.id.onChange(e.id);
            form.notes.onChange(e.note ?? "");
            form.project_id.onChange(e.project.id);
            form.task_id.onChange(e.task.id);
            form.time_tracking_work_type_id.onChange(e.timeTrackingWorkType.id);
            form.time.onChange(e.tracked_time);
            form.tracked_for_date.onChange(new Date(e.tracked_for_date));
            form.billable.onChange(e.billable);
            if (!e.task.ourWorkBudgetItem) return;
            form.budget_item_id.onChange(e.task.ourWorkBudgetItem.id);
          }}
        />
      )}
    </HStack>
  );
};

export default observer(Entry);
