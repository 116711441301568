import {
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
} from "@chakra-ui/react";
import Color, { hsl } from "color";

import React, { FunctionComponent } from "react";

export interface AvatarProps
  extends Omit<
    ChakraAvatarProps,
    "src" | "bg" | "color" | "bgColor" | "colorScheme"
  > {
  src?: string | null;
  colorScheme?: ChakraAvatarProps["bg"];
}

const DEFAULT_TEXT_COLOR = Color("white");
export const LOW_CONTRAST_RATIO = 2;

export const Avatar: FunctionComponent<
  React.PropsWithChildren<AvatarProps>
> = ({ src, colorScheme, ...props }) => {
  /**
   * Make BG white when src to support transparent images
   */
  const bg = src ? "white" : colorScheme ?? "teal.500";
  const textColor = !!colorScheme
    ? Color(colorScheme).contrast(DEFAULT_TEXT_COLOR) > LOW_CONTRAST_RATIO
      ? "white"
      : hsl(Color(colorScheme).hue(), 0, 15).hex()
    : "white";

  return (
    <ChakraAvatar color={textColor} bg={bg} src={src ?? undefined} {...props} />
  );
};
