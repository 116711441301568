import {
  commonQueryVariables,
  commonSearchParams,
} from "@src/utils/apolloHelpers";
import { Filters } from "@src/utils/components/filters/models";
import { OrderBy } from "@src/utils/components/sorting/OrderBy";
import {
  PaginationOptions,
  PaginationState,
} from "@src/utils/mobx/states/PaginationState";
import { computed, makeObservable, observable } from "mobx";

type GetOrderByColumnFromVariables<TVariables extends { [prop: string]: any }> =
  Exclude<NonNullable<TVariables["orderBy"]>, Array<any>>["column"];

type GetWhereColumnFromVariables<TVariables extends { [prop: string]: any }> =
  NonNullable<TVariables["where"]>["column"];

type TableStateOptions<TVariables extends { [prop: string]: any }> = {
  key: string;
  filters: Filters<GetWhereColumnFromVariables<TVariables>>;
  pagination?: PaginationOptions;
  orderBy?: OrderBy<GetOrderByColumnFromVariables<TVariables>>["clauses"];
};

export class TableState<TVariables extends { [prop: string]: any }, TData> {
  readonly key: string;
  @observable data: TData[] = [];

  where: Filters<GetWhereColumnFromVariables<TVariables>>;
  pagination: PaginationState;
  orderBy: OrderBy<GetOrderByColumnFromVariables<TVariables>>;

  @observable searchTerm = "";

  @observable isFetching = false;

  constructor(options: TableStateOptions<TVariables>) {
    makeObservable(this);
    this.key = options.key;
    this.where = options.filters;
    this.pagination = new PaginationState(options.key, options.pagination);
    this.orderBy = new OrderBy<GetOrderByColumnFromVariables<TVariables>>(
      options.orderBy,
    );
  }

  @computed get searchParams() {
    return commonSearchParams(this);
  }

  @computed get queryParams() {
    return commonQueryVariables(this);
  }
}
