import { Box } from "@chakra-ui/react";
import { CSSUnits } from "@src/utils/types";
import React, { FC } from "react";

const OFFSET = {
  Navbar: "52px",
  ContentMargin: "9rem",
  ContentMarginWithoutBottom: "4rem",
};

interface FullScreenContainerProps {
  heightOffset?: `${string}${CSSUnits}`;
  noBottomMargin?: boolean;
}

/**
 * This should be used as child of <MainLayout />
 */
const FullScreenContainer: FC<
  React.PropsWithChildren<FullScreenContainerProps>
> = ({ heightOffset, noBottomMargin, children }) => (
  <Box
    w="full"
    style={{
      height: `calc(100vh - ${OFFSET.Navbar} - ${
        noBottomMargin
          ? OFFSET.ContentMarginWithoutBottom
          : OFFSET.ContentMargin
      } - ${heightOffset || "0px"})`,
    }}
  >
    {children}
  </Box>
);

export default FullScreenContainer;
