import { HStack, Text, TextProps } from "@chakra-ui/react";
import { Icon } from "@src/components/ui-kit/Icon";
import { IconNames } from "@src/utils/types/iconNames";
import React, { FunctionComponent } from "react";

type SectionHeading = {
  children: React.ReactNode;
  iconName?: IconNames;
} & TextProps;

export const SectionHeading: FunctionComponent<SectionHeading> = ({
  children,
  iconName,
  ...props
}) => {
  return (
    <HStack>
      {iconName && <Icon color="grey.400" name={iconName} />}
      <Text color="grey.500" fontSize="xs" fontWeight="medium" {...props}>
        {children}
      </Text>
    </HStack>
  );
};
