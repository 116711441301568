import { Box, BoxProps } from "@chakra-ui/react";
import { FunctionComponent, PropsWithChildren } from "react";

interface CardProps extends BoxProps {
  sectioned?: boolean;
}

export const Card: FunctionComponent<PropsWithChildren<CardProps>> & {
  Section: typeof Section;
  Subsection: typeof Subsection;
} = ({ sectioned, children, ...props }: PropsWithChildren<CardProps>) => {
  const content = sectioned ? <Section>{children}</Section> : children;

  return (
    <Box
      bg="white"
      borderWidth="1px"
      borderColor="gray.200"
      shadow="sm"
      rounded="xl"
      {...props}
    >
      {content}
    </Box>
  );
};

const Section: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Box flex="1" p="6">
      {children}
    </Box>
  );
};

const Subsection: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  return <Section>{children}</Section>;
};

Card.Section = Section;
Card.Subsection = Subsection;
