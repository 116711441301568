import { action, computed, makeObservable, observable, reaction } from "mobx";
import {
  PropSchema,
  deserialize,
  optional,
  primitive,
  serializable,
} from "serializr";

const asNumber: Pick<PropSchema, "afterDeserialize"> = {
  afterDeserialize(cb, _err, raw) {
    if (raw === "") return cb(undefined, undefined);

    const val = Number(raw);

    if (isNaN(val)) {
      console.warn(`SearchParamsDTO: Invalid value`, raw);
      return cb(undefined, undefined);
    }

    cb(undefined, val);
  },
};

const PER_PAGE_OPTIONS = [10, 20, 50] as const;

class SearchParamsDTO {
  @serializable(optional(primitive(asNumber)))
  first?: number;

  @serializable(optional(primitive(asNumber)))
  page?: number;

  constructor() {}
}

export type PaginationOptions = {
  perPage?: number;
  perPageOptions?: number[];
  onChangePagination?: PaginationState["onChangePagination"];
};

export class PaginationState {
  readonly key: string;
  @observable count?: number;
  @observable currentPage = 1;
  @observable firstItem?: number | null;
  @observable lastItem?: number | null;
  @observable lastPage?: number | null;
  @observable perPage: number = PER_PAGE_OPTIONS[0];
  defaultPerPage: number = PER_PAGE_OPTIONS[0];
  @observable total?: number | null;

  /**
   * Triggered by:
   *  @method setPerPage
   *  @method setPage
   *  @method resetPage
   *  @method inc
   *  @method dec
   */
  private onChangePagination?: (arg: PaginationState) => void;

  readonly perPageOptions: readonly number[] = PER_PAGE_OPTIONS;

  constructor(key: string, opts?: PaginationOptions) {
    makeObservable(this);
    this.key = key;
    if (opts?.perPageOptions) this.perPageOptions = opts.perPageOptions;
    this.onChangePagination = opts?.onChangePagination;
    this.defaultPerPage = opts?.perPage ?? this.perPageOptions[0];
    this.persistPerPageToLocalStorage();
  }

  @action.bound
  setPerPage(val: number) {
    this.perPage = val;
    this.onChangePagination?.(this);
  }

  @action.bound
  setPage(val: number) {
    this.currentPage = val;
    this.onChangePagination?.(this);
  }

  @action.bound
  resetPage() {
    this.currentPage = 1;
    this.onChangePagination?.(this);
  }

  @action.bound
  inc() {
    this.currentPage += 1;
    this.onChangePagination?.(this);
  }

  @action.bound
  dec() {
    this.currentPage -= 1;
    this.onChangePagination?.(this);
  }

  @action setFromPaginatorInfo = (
    src: Pick<
      PaginationState,
      | "currentPage"
      | "perPage"
      | "total"
      | "firstItem"
      | "lastItem"
      | "lastPage"
      | "count"
    >,
  ) => {
    if (!src) return;
    this.currentPage = src.currentPage;
    this.perPage = src.perPage;
    this.total = src.total;
    this.firstItem = src.firstItem;
    this.lastItem = src.lastItem;
    this.lastPage = src.lastPage;
    this.count = src.count;
  };

  @action
  fromURLSearchParam(src: unknown) {
    const perPage = Number(
      localStorage.getItem(this.persistKey) ?? this.defaultPerPage,
    );
    const { first, page } = deserialize(SearchParamsDTO, src);
    this.setPage(page ?? 1);
    this.setPerPage(first ?? perPage);
  }

  persistPerPageToLocalStorage() {
    try {
      this.perPage = Number(
        localStorage.getItem(this.persistKey) ?? this.defaultPerPage,
      );
      reaction(
        () => this.perPage,
        (perPage) => {
          localStorage.setItem(this.persistKey, String(perPage));
        },
      );
    } catch (e) {}
  }

  @computed get persistKey() {
    return `perPage-${this.key}`;
  }

  @computed get hasPrevPage() {
    return this.currentPage > 1;
  }

  @computed get hasNextPage() {
    return this.currentPage < (this.lastPage ?? 0);
  }

  @computed get asParams() {
    return {
      page: this.currentPage,
      first: this.perPage,
    };
  }

  @computed get asURLSearchParam() {
    return {
      page: String(this.currentPage),
      first: String(this.perPage),
    };
  }
}
