import { Box, Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { p } from ".";
import s from "./ProgressBar.module.css";
import { ouWorkSpendWidth } from "./width";

export const ProgressBarOurWorkSpend = observer<{
  spent_budget: number;
  total_budget: number;
  credit: number;
  variant?: "normal" | "big";
  textOnHover?: string;
  isTimeAndMaterials: boolean;
}>(function ProgressBarOurWorkSpend({
  spent_budget,
  total_budget,
  credit,
  variant,
  textOnHover,
  isTimeAndMaterials,
}) {
  let width = isTimeAndMaterials
    ? 100
    : ouWorkSpendWidth(credit, total_budget, spent_budget);

  let green = 0;
  let red = 0;
  let gray = 0;

  if (spent_budget <= total_budget) {
    green = (spent_budget / total_budget) * 100;
    gray = 100 - green;
  } else if (spent_budget > total_budget) {
    green = (total_budget / spent_budget) * 100;
    red = 100 - green;
  }

  if ([spent_budget, total_budget].includes(0)) {
    gray = 100;
  }

  if (total_budget === 0) {
    width = 100;
  }

  if (isTimeAndMaterials) {
    green = 100;
    gray = 0;
    red = 0;
  }

  const leftPos = red
    ? undefined
    : gray === 0 || green < gray
      ? p(green)
      : undefined;
  const rightPos = red ? "2" : green > gray ? p(gray) : undefined;

  return (
    <Flex
      pos="relative"
      overflow="hidden"
      w={p(width)}
      h={variant === "big" ? 8 : 2}
      _hover={{ color: "black" }}
      rounded="2xl"
    >
      {!!green && <Box flex={p(green)} bg="teal.500" />}
      {!!gray && <Box flex={p(gray)} bg="grey.100" />}
      {textOnHover && (
        <Flex
          pos="absolute"
          zIndex="1"
          right={isTimeAndMaterials ? 0 : rightPos}
          left={isTimeAndMaterials ? 0 : leftPos}
          align="center"
          justify="center"
          h="full"
        >
          <Text
            px="2"
            fontWeight="medium"
            lineHeight="0"
            transition="color 150ms ease-in"
          >
            {textOnHover}
          </Text>
        </Flex>
      )}
      {!!red && <Box pos="relative" w={p(red)} bg="red.500" />}
    </Flex>
  );
});

export const ProgressBarOurWorkSpendPercent: FunctionComponent<{
  progress: number;
}> = observer(function ProgressBarOurWorkSpendPercent({ progress }) {
  const width = 100;
  let green = 0,
    gray = 0,
    red = 0;

  if (progress <= width) {
    // partial/full
    green = progress;
    gray = width - green;
  } else {
    // exceeded
    green = (width / progress) * 100;
    red = width - green;
  }

  return (
    <div>
      <div className={s.box} style={{ width: p(width) }}>
        {!!green && (
          <div className={s.green} style={{ width: p(green) }}>
            {/* {p(green)} */}
          </div>
        )}
        {!!gray && (
          <div className={s.gray} style={{ width: p(gray) }}>
            {/* {p(gray)} */}
          </div>
        )}
        {!!red && (
          <div className={s.red} style={{ width: p(red) }}>
            {/* {p(red)} */}
          </div>
        )}
      </div>
    </div>
  );
});
