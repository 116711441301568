import { FieldState } from "formstate";
import { denominate, nominate } from "../formatters";

/**
 * @deprecated
 * use NumberProperty or MoneyProperty instead
 */
export class NumberModel {
  readonly denominatedValue: number;
  readonly denominator: number;

  static fromDenomValue(
    value: number | string,
    denominator: number,
  ): NumberModel {
    value = typeof value === "string" ? Number(value) : value;
    return new NumberModel(value, denominator);
  }

  static fromValue(value: number | string, denominator: number): NumberModel {
    value = typeof value === "string" ? Number(value) : value;
    return new NumberModel(denominate(value, denominator), denominator);
  }

  constructor(denominatedValue: number, denominator: number) {
    this.denominatedValue = denominatedValue;
    this.denominator = denominator;
  }

  get value(): number {
    return nominate(this.denominatedValue, this.denominator);
  }

  /** FieldState<NumberModel> helper */
  static setFieldState(
    field: FieldState<NumberModel>,
    value: number | string,
  ): void {
    field.onChange(field.value.setValue(value));
  }

  setDenomValue(val: number | string): NumberModel {
    return NumberModel.fromDenomValue(val, this.denominator);
  }

  setValue(val: number | string): NumberModel {
    return NumberModel.fromValue(val, this.denominator);
  }

  setDenominator(denominator: number): NumberModel {
    return NumberModel.fromDenomValue(this.denominatedValue, denominator);
  }
}
