import { Text, TextProps } from "@chakra-ui/react";
import { FunctionComponent } from "react";

// https://fonts.google.com/knowledge/glossary/dashes
export const Dash: FunctionComponent<TextProps> = (props) => {
  return (
    <Text fontSize="inherit" fontWeight="inherit" {...props}>
      &nbsp; &ndash; &nbsp;
    </Text>
  );
};
