import { Flex, Heading, HStack, Stack, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { UtilizationReportDataSource } from "@src/__generated__/urql-graphql";
import { DataTable } from "@src/components/ui-kit";
import { ProgressBar } from "@src/components/ui-kit/NewProgressBar";
import { HUNDRED_PERCENT, percentFormatter } from "@src/utils/formatters";
import { useStore } from "@src/utils/hooks";
import { formatSecondsToHours } from "@src/utils/time";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { UtilizationStatCardProps } from "./Utilization";

type Props = Omit<UtilizationStatCardProps, "month"> & {
  timeOffTime: number | undefined;
  trackedTimeBillable: number | undefined;
  combinedTimeBillable: number | undefined;
  allocatedTimeBillable: number | undefined;
  budgetedTimeBillable: number | undefined;
};

export const BillableUtilizationStatCard: FC<Props> = observer(
  function BillableUtilizationStatCard({
    trackedTime = 0,
    combinedTime = 0,
    allocatedTime = 0,
    totalTime = 0,
    budgetedTime = 0,
    timeOffTime = 0,
    utilizationRange = { from: 80, to: 100 },
    trackedTimeBillable = 0,
    combinedTimeBillable = 0,
    allocatedTimeBillable = 0,
    budgetedTimeBillable = 0,
    percent = 0,
  }) {
    const { utilizationReportStore: store } = useStore();

    const billable = computed(() => {
      let total = 0;

      switch (store.activeDataSourceOption?.value) {
        case UtilizationReportDataSource.TimeTracking:
          total = trackedTimeBillable;
          break;
        case UtilizationReportDataSource.Planning:
          total = allocatedTimeBillable;
          break;
        case UtilizationReportDataSource.Combined:
          total = combinedTimeBillable;
          break;
        case UtilizationReportDataSource.TaskPositions:
          total = budgetedTimeBillable;
          break;
      }

      return total;
    }).get();

    const nonBillable = computed(() => {
      let total = 0;

      switch (store.activeDataSourceOption?.value) {
        case UtilizationReportDataSource.TimeTracking:
          total = trackedTime - trackedTimeBillable;
          break;
        case UtilizationReportDataSource.Planning:
          total = allocatedTime - allocatedTimeBillable;
          break;
        case UtilizationReportDataSource.Combined:
          total = combinedTime - combinedTimeBillable;
          break;
        case UtilizationReportDataSource.TaskPositions:
          total = budgetedTime - budgetedTimeBillable;
          break;
      }
      return total;
    }).get();

    const nonBillablePercent = computed(() => {
      return (timeOffTime / totalTime) * HUNDRED_PERCENT;
    }).get();

    const billableColor = computed(() => {
      const dividedPercent = percent * 100;
      if (dividedPercent > utilizationRange.to) return "red.500";
      if (dividedPercent < utilizationRange.from) return "red.500";
      return "teal.500";
    }).get();

    return (
      <DataTable.StatCard>
        <Stack>
          <HStack justify="space-between" mb="2">
            <Heading size="sm">
              <Trans>Billable Utilization</Trans>
            </Heading>
            <Heading size="sm">
              {percentFormatter().format(percent * 0.01)}
            </Heading>
          </HStack>
          <ProgressBar
            segments={[
              {
                percent: percent * 100,
                bg: billableColor,
              },
              {
                percent: nonBillablePercent,
                bg: "repeating-linear-gradient(-65deg, #586876, #515f6c 2px, #586876 4px)",
              },
            ]}
          />
          <HStack justify="space-between">
            <Flex>
              <Text color="grey.600" size="sm">
                <Trans>Billable</Trans>
              </Text>
              &nbsp;
              <Text fontWeight="medium" size="sm">
                {formatSecondsToHours(billable, 1)}
              </Text>
            </Flex>
            <Flex>
              <Text color="grey.600" size="sm">
                <Trans>Non-Billable</Trans>
              </Text>
              &nbsp;
              <Text fontWeight="medium" size="sm">
                {formatSecondsToHours(nonBillable, 1)}
              </Text>
            </Flex>
          </HStack>
        </Stack>
      </DataTable.StatCard>
    );
  },
);
