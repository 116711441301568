import { RefObject, useEffect } from "react";

interface InsideClickProps {
  ref: RefObject<HTMLElement>;
  handler: () => void;
}

export const useInsideClick = ({ ref, handler }: InsideClickProps) => {
  useEffect(() => {
    const listener = ({ target }: MouseEvent | TouchEvent) => {
      if (!ref.current) return;
      if (!(target instanceof HTMLElement)) return;
      if (!ref.current?.contains(target)) return;

      handler();
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
