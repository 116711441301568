import { Button } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { observer } from "mobx-react-lite";

interface NewRowButtonProps {
  onClick: () => void;
  disabled: boolean;
}

const NewRowButton = ({ onClick, disabled }: NewRowButtonProps) => {
  return (
    <Button
      disabled={disabled}
      leftIcon={<Icon name="plus" />}
      onClick={onClick}
    >{t`New row`}</Button>
  );
};

export default observer(NewRowButton);
