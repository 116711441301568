import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import { Spinner } from "@src/components/ui-kit/Spinner";
import { observer } from "mobx-react-lite";
import React, { FocusEvent, useRef } from "react";

type Option = {
  label: React.ReactNode;
  value: string;
};

interface AutocompleteFieldProps {
  autoCompleteOptions: Option[];
  onChange: (value: string) => void;
  onSelect: (value: string) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement, Element>) => void;
  charCount: number;
  value: string;
  label: string;
  loading: boolean;
  error?: string;
}

const AutocompleteField = ({
  onBlur,
  autoCompleteOptions,
  value,
  onChange,
  label,
  charCount,
  onSelect,
  loading,
  error,
}: AutocompleteFieldProps) => {
  const initialRef = useRef(null);
  const popoverContentRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    if (e.target.value.length >= charCount) {
      onOpen();
    } else {
      onClose();
    }
  };

  const handleSelect = (value: string) => {
    onSelect(value);
    onClose();
  };

  useOutsideClick({
    ref: popoverContentRef,
    handler: onClose,
    enabled: isOpen,
  });

  return (
    <Popover
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <Box ref={initialRef}>
          <FormControl isInvalid={!!error}>
            {label && <FormLabel htmlFor="email">{label}</FormLabel>}
            <Input
              isInvalid={!!error}
              onBlur={onBlur}
              onChange={handleChange}
              value={value}
            />
            {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
          </FormControl>
        </Box>
      </PopoverTrigger>

      <PopoverContent
        ref={popoverContentRef}
        w="max-content"
        p={0}
        color="black"
        border="none"
        shadow="xl"
        _focus={{ boxShadow: "none" }}
        outline="none"
        rounded="lg"
      >
        {loading && (
          <Center w="full" minW="48" p="2">
            <Spinner size="sm" />
          </Center>
        )}
        <VStack align="start" spacing="0">
          {autoCompleteOptions.map((option) => (
            <Box
              key={option.value}
              w="full"
              _hover={{
                bg: "gray.100",
                cursor: "pointer",
              }}
              onClick={() => handleSelect(option.value)}
              paddingBlock="2"
              paddingInline="4"
            >
              {option.label}
            </Box>
          ))}
        </VStack>
      </PopoverContent>
    </Popover>
  );
};

export default observer(AutocompleteField);
