import { IconButton } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";

interface CollapseRowButtonProps {
  open?: boolean;
  onClick: (newState: boolean) => void;
}

const CollapseRowButton = ({ open, onClick }: CollapseRowButtonProps) => (
  <IconButton
    aria-label={t`toggle sub row`}
    onClick={() => onClick(!open)}
    size="sm"
    variant="ghost"
  >
    <Icon name={open ? "chevron-up" : "chevron-down"} w="5" h="5" />
  </IconButton>
);

export default CollapseRowButton;
