import DOMPurify from "dompurify";

// default uri regexp copied from DOMPurify and enriched by allowed smb protocol (e.g. used by triad workspace)
const ALLOWED_URI_REGEXP =
  /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|cid|xmpp|smb|xxx):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i;

export function makeHTMLSafe(html: string): string {
  return DOMPurify.sanitize(html, {
    USE_PROFILES: { html: true },
    ALLOWED_URI_REGEXP,
  });
}
