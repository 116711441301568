import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Radio,
  Select,
  Skeleton,
  Switch,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Plural, Trans, t } from "@lingui/macro";
import { Avatar, ModalConfirm } from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { useStore } from "@src/utils/hooks";
import { range } from "lodash";
import { observer } from "mobx-react-lite";
import { Fragment, FunctionComponent } from "react";

export const UserManagement: FunctionComponent = observer(
  function UserManagement() {
    const {
      settingsModalStore: { userManagementStore },
    } = useStore();

    return (
      <Fragment>
        <TableContainer>
          <Table size="sm" variant="simple">
            <TableCaption paddingInlineStart="0" placement="top">
              <Heading as="h3" textAlign="left" size="md">
                <Trans>Teams</Trans>
              </Heading>
            </TableCaption>
            <Thead>
              <Tr>
                <Th textAlign="center">
                  <Trans>Default</Trans>
                </Th>
                <Th w="99%">
                  <Trans>Name</Trans>
                </Th>
                <Th textAlign="center">
                  <Trans>Actions</Trans>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {userManagementStore.form.form.$.teams.$.map((team) => (
                <Tr key={team.internalId}>
                  <Td>
                    <Flex as="label" align="center" justify="center">
                      <Radio
                        isChecked={team.$.is_default.value}
                        name="default"
                        onChange={(event) => {
                          if (event.target.checked) {
                            userManagementStore.form.makeDefault(team);
                          }
                        }}
                      />
                    </Flex>
                  </Td>
                  <Td>
                    <FormControl isInvalid={team.$.name.hasError}>
                      <Input
                        onChange={(event) =>
                          team.$.name.onChange(event.target.value)
                        }
                        type="text"
                        value={team.$.name.value}
                      />
                      <FormErrorMessage>
                        <Trans>Team name is required</Trans>
                      </FormErrorMessage>
                      {team.$.copy_users_from_team_id?.$ && (
                        <FormHelperText gap="1" display="flex">
                          <Icon name="info-circle" />
                          <Text>
                            <Trans>
                              Users from{" "}
                              <strong>{team.copy_users_from_team_name}</strong>{" "}
                              will be reassigned to this team once you update
                              the settings.
                            </Trans>
                          </Text>
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Td>
                  <Td>
                    <Flex align="center" justify="center" gap="3">
                      <Tooltip
                        hasArrow
                        label={
                          userManagementStore.form.form.$.teams.$.length === 1
                            ? t`You cannot remove the last team`
                            : team.$.copy_users_from_team_id?.$
                              ? t`You cannot delete a team that is set to replace a previously deleted team until you update these settings`
                              : team.user_count > 0 &&
                                  userManagementStore.form.form.$.teams.$.filter(
                                    (t) => t !== team,
                                  ).filter(
                                    (t) =>
                                      t.$.copy_users_from_team_id?.$ ===
                                      undefined,
                                  ).length === 0
                                ? t`There is no free team to reassign this team members to at the moment. Update the settings first and you will be able to delete this team.`
                                : t`Remove this team`
                        }
                      >
                        <IconButton
                          aria-label={t`Delete this team`}
                          icon={<Icon name="trash-03" />}
                          isDisabled={
                            userManagementStore.form.form.$.teams.$.length ===
                              1 ||
                            (team.user_count > 0 &&
                              userManagementStore.form.form.$.teams.$.filter(
                                (t) => t !== team,
                              ).filter(
                                (t) =>
                                  t.$.copy_users_from_team_id?.$ === undefined,
                              ).length === 0) ||
                            team.$.copy_users_from_team_id?.$ !== undefined
                          }
                          onClick={() =>
                            userManagementStore.form.requestTeamRemoval(team)
                          }
                          variant="ghost"
                        />
                      </Tooltip>
                      <ModalConfirm
                        heading={t`Confirm removal of team`}
                        onClose={() =>
                          userManagementStore.form.cleanUpTeamRemoval()
                        }
                        isOpen={userManagementStore.form.teamToDelete === team}
                        onConfirm={() =>
                          userManagementStore.form.removeTeam(team)
                        }
                      >
                        {team.user_count === 0 ? (
                          <Trans>
                            This team doesn't contain any users, you can safely
                            delete it. Do you want to proceed?
                          </Trans>
                        ) : (
                          <Fragment>
                            <Text>
                              <Plural
                                value={team.user_count}
                                one="This team contains # user"
                                other="This team contains # users"
                              />
                              {userManagementStore.teamMembersFetchState ===
                              "error"
                                ? "."
                                : ":"}
                            </Text>
                            {userManagementStore.teamMembersFetchState !==
                              "error" && (
                              <Wrap marginBlock="4" spacing="2">
                                {userManagementStore.teamMembersFetchState ===
                                  "loading" &&
                                  range(team.user_count).map((index) => (
                                    <WrapItem
                                      key={index}
                                      alignItems="center"
                                      display="flex"
                                      p="1"
                                      border="1px"
                                      borderStyle="solid"
                                      borderColor="grey.100"
                                      borderRadius="full"
                                      paddingInlineEnd="3"
                                    >
                                      <Avatar
                                        size="xs"
                                        colorScheme="#e2e8f0"
                                        name={"A A"}
                                      />
                                      <Text
                                        ml="1"
                                        fontSize="xs"
                                        fontWeight="medium"
                                      >
                                        <Skeleton w="80px" h="18px" />
                                      </Text>
                                    </WrapItem>
                                  ))}
                                {userManagementStore.teamMembersFetchState ===
                                  "success" &&
                                  userManagementStore.teamMembers.map(
                                    (user) => (
                                      <WrapItem
                                        key={user.id}
                                        alignItems="center"
                                        display="flex"
                                        p="1"
                                        border="1px"
                                        borderStyle="solid"
                                        borderColor="grey.100"
                                        borderRadius="full"
                                        paddingInlineEnd="3"
                                      >
                                        <Avatar
                                          size="xs"
                                          colorScheme={user.profile?.hex_color}
                                          name={user.full_name}
                                          src={user.image?.urls.thumbnail}
                                        />
                                        <Text
                                          ml="1"
                                          fontSize="xs"
                                          fontWeight="medium"
                                        >
                                          {user.full_name}
                                        </Text>
                                      </WrapItem>
                                    ),
                                  )}
                              </Wrap>
                            )}
                            <Text>
                              <Trans>
                                You can delete a team with members, but they
                                need to be reassigned to different team. Please
                                choose a new team for{" "}
                                <strong>{team.$.name.$}</strong> members:
                              </Trans>
                            </Text>
                            <Select
                              marginBlockStart="2"
                              onChange={(event) =>
                                userManagementStore.form.setNewTeamForUsers(
                                  event.target.value,
                                )
                              }
                            >
                              {userManagementStore.form.form.$.teams.$.filter(
                                (t) =>
                                  t !== team &&
                                  t.$.copy_users_from_team_id?.$ === undefined,
                              ).map((team) => (
                                <option key={team.id} value={team.internalId}>
                                  {team.$.name.$}
                                </option>
                              ))}
                            </Select>
                            <Text paddingBlockStart="4">
                              <Trans>
                                Do you really want to delete team{" "}
                                <strong>{team.$.name.$}</strong> and move all
                                its users to team{" "}
                                <strong>
                                  {userManagementStore.form.newTeam?.$.name.$}
                                </strong>
                                ?
                              </Trans>
                            </Text>
                          </Fragment>
                        )}
                      </ModalConfirm>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th colSpan={3}>
                  <Flex justify="end" paddingBlock="3">
                    <Button
                      leftIcon={<Icon name="plus" />}
                      onClick={() => userManagementStore.form.addNewTeam()}
                      variant="outline"
                    >
                      <Trans>Add new team</Trans>
                    </Button>
                  </Flex>
                </Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>

        <Box marginBlockStart="4" paddingBlock="2">
          <Heading as="h2" marginBlockEnd="2" size="md">
            <Trans>Require two-factor authentication</Trans>
          </Heading>
          <FormControl
            alignItems="center"
            justifyContent="space-between"
            display="flex"
            borderBlock="1px solid #e2e8f0"
            paddingBlock="2"
          >
            <FormLabel mb="0" htmlFor="two-factor-auth">
              <Trans>
                This will force all users to use two-factor authentication for
                improved security.
              </Trans>
            </FormLabel>
            <Switch
              id="two-factor-auth"
              isChecked={
                userManagementStore.form.form.$
                  .require_two_factor_authentication.value
              }
              onChange={(event) =>
                userManagementStore.form.form.$.require_two_factor_authentication.onChange(
                  event.target.checked,
                )
              }
              paddingInlineEnd="4"
            />
          </FormControl>
        </Box>
      </Fragment>
    );
  },
);
