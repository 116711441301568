import { WorkspaceSettingsQuery } from "@src/__generated__/urql-graphql";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { NotificationSettingsState } from "@src/stores/forms/SettingsModalState/NotificationSettingsState";
import { makeObservable, observable } from "mobx";

export class NotificationSettingsStore implements BaseStore {
  @observable.ref roles: string[] = [];

  constructor(
    public appStore: AppStore,
    public form: NotificationSettingsState,
  ) {
    makeObservable(this);
  }

  init(data: WorkspaceSettingsQuery) {
    this.roles = data.roles.map(({ name }) => name);
  }
}
