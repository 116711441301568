import { Box, Button, HStack, Text, Tooltip } from "@chakra-ui/react";
import { Avatar } from "@components/ui-kit";
import { Trans, t } from "@lingui/macro";
import {
  TaskComment,
  useUpdateTaskCommentMutation,
} from "@src/__generated__/graphql";
import { TextEditor } from "@src/components/ui-kit/TextEditor/TextEditor";
import { UserTooltip } from "@src/components/ui-kit/UserTooltip";
import { CommentReactions } from "@src/components/widgets/Modals/ModalCommunication/components/comment/CommentReactions";
import {
  CommentStore,
  CommentStoreCtx,
} from "@src/components/widgets/Modals/ModalCommunication/components/comment/CommentStore";
import { FloatingCommentActionsMenu } from "@src/components/widgets/Modals/ModalCommunication/components/comment/FloatingCommentActionsMenu";
import { CommentModel } from "@src/components/widgets/Modals/ModalCommunication/models/CommentModel";
import { onError } from "@src/utils/apollo";
import { useStore } from "@src/utils/hooks";
import { format, formatDistanceToNow } from "date-fns";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import React, { forwardRef, useEffect, useRef, useState } from "react";

export interface CommentProps {
  taskId?: string;
  comment: CommentModel;
  onUpdated: (originalCommentId: string, taskComment: CommentModel) => void;
  onDeleted: (id: string) => void;
}

function useHasCommentIdInUri(
  queryKey: string,
  onHasCommentId: (commentId: TaskComment["id"]) => void,
) {
  const router = useRouter();

  useEffect(() => {
    if (router.query && router.query[queryKey]) {
      onHasCommentId(router.query[queryKey] as TaskComment["id"]);
    }
  }, [router]);
}

const Comment = forwardRef<HTMLDivElement, CommentProps>(function Comment(
  {
    comment,
    onUpdated,
    onDeleted,
    // INFO: In case some client will want this back
    // taskId,
  },
  ref,
) {
  const { taskDetailModalStore } = useStore();
  const [store] = useState(() => new CommentStore(comment));
  const [value, setValue] = useState("");
  const router = useRouter();
  const mentionedUserIds = useRef<string[]>([]);
  const mentionedTeamIds = useRef<string[]>([]);
  const mentionedAssignees = useRef(false);
  const attachmentIds = useRef<string[]>([]);

  useHasCommentIdInUri(store.COMMENT_ID_QUERY_KEY, () => {
    store.commentContainerRef.current?.scrollIntoView();
  });

  const [update, { loading }] = useUpdateTaskCommentMutation({
    ...onError(),
    onCompleted(data) {
      store.editorRef.current?.clearDraft();
      if (data.updateTaskComment) {
        store.readOnly.on();
        onUpdated(store.comment.id, new CommentModel(data.updateTaskComment));
      }
      taskDetailModalStore.commentChanged = false;
    },
  });

  const onCancel = () => {
    store.editorRef.current?.setContent(store.comment.body);
    store.readOnly.on();
    store.editorRef.current?.clearDraft();
    taskDetailModalStore.commentChanged = false;
  };

  const onSave = () => {
    update({
      variables: {
        input: {
          id: store.comment.id,
          body: value,
          files: attachmentIds.current,
          mentioned_user_ids: mentionedUserIds.current,
          mentioned_team_ids: mentionedTeamIds.current,
          mentioned_assignees: mentionedAssignees.current,
        },
      },
    });
  };

  return (
    <CommentStoreCtx.Provider value={store}>
      <Box ref={store.commentContainerRef}>
        <Box ref={ref} px="8" py="3" role="group">
          <HStack align="end">
            <UserTooltip user={store.comment.user}>
              <Avatar
                name={store.comment.user.full_name}
                src={store.comment.user.image?.urls.thumbnail}
                colorScheme={store.comment.user.profile?.hex_color}
              />
            </UserTooltip>
            <Box
              flex="1"
              // force max width to break links - calc(container width - avatar tooltip width + some spacing)
              maxW="calc(100% - 37px)"
              ml="2"
            >
              <Box
                px="3"
                pt="2"
                pb="3"
                bg={
                  store.readOnly.value
                    ? router.query[store.COMMENT_ID_QUERY_KEY] ===
                      store.comment.id
                      ? "yellow.100"
                      : "grey.50"
                    : "transparent"
                }
                border={store.readOnly.value ? "none" : "1px solid"}
                borderColor="grey.100"
                rounded="xl"
              >
                {store.readOnly.value && (
                  <HStack mb="2">
                    <Text flex="1" fontSize="xs" fontWeight="medium">
                      {store.comment.user.full_name}
                      {store.readOnly.value && !!store.comment.timestamp && (
                        <Text
                          display="inline"
                          ml="2"
                          color="grey.500"
                          fontSize="xs"
                          fontWeight="medium"
                        >
                          <Tooltip
                            label={format(
                              new Date(store.comment.timestamp),
                              "MMM d, y, HH:mm",
                            )}
                          >
                            <span>
                              {formatDistanceToNow(
                                new Date(store.comment.timestamp),
                                {
                                  addSuffix: true,
                                },
                              )}
                            </span>
                          </Tooltip>
                          {store.comment.has_edits && (
                            <React.Fragment>
                              <span>{" • "}</span>
                              <span className="italic">{t`Edited`}</span>
                            </React.Fragment>
                          )}
                        </Text>
                      )}
                    </Text>
                    <FloatingCommentActionsMenu onDeleted={onDeleted} />
                  </HStack>
                )}
                <TextEditor
                  showToolbar
                  ref={store.editorRef}
                  // INFO: In case some client will want this back
                  // enableDrafts
                  // draftKey={getTaskDetailDraftKey({
                  //   taskId,
                  //   editorName: 'comment-edit',
                  //   tabId: TaskTabIdEnum.Communication,
                  //   commentId: comment.id,
                  // })}
                  isEditable={!store.readOnly.value}
                  initialValue={store.comment.body}
                  attachments={store.comment.files.map((file) => ({
                    id: file.public_id,
                    filename: file.filename,
                    mime_type: file.mime_type,
                    viewable: file.urls.viewable!,
                    original: file.urls.original!,
                    thumbnail: file.urls.thumbnail!,
                  }))}
                  onChange={(
                    value,
                    {
                      mentioned_user_ids,
                      attachment_ids,
                      mentioned_team_ids,
                      mentioned_assignees,
                    },
                  ) => {
                    if (value) {
                      setValue(value.toString());
                    }
                    mentionedUserIds.current = mentioned_user_ids;
                    mentionedTeamIds.current = mentioned_team_ids;
                    mentionedAssignees.current = mentioned_assignees;
                    attachmentIds.current = attachment_ids;
                  }}
                />
                {!!store.comment.reactions?.length && store.readOnly.value && (
                  <CommentReactions />
                )}
              </Box>

              {!store.readOnly.value && (
                <HStack justify="end" mt="5" spacing="3">
                  <Button
                    colorScheme="grey"
                    onClick={onCancel}
                    variant="outline"
                  >
                    <Trans>Cancel</Trans>
                  </Button>
                  <Button isLoading={loading} onClick={onSave}>
                    <Trans>Save</Trans>
                  </Button>
                </HStack>
              )}
            </Box>
          </HStack>
        </Box>
      </Box>
    </CommentStoreCtx.Provider>
  );
});

export default observer<CommentProps, HTMLDivElement>(Comment);
