import { action, makeObservable, observable, when } from "mobx";

export class DisclosureState<AdditionalDataType extends Object> {
  @observable isOpen: boolean;
  @observable additionalData?: AdditionalDataType;

  onOpenCallback?: (additionalData?: AdditionalDataType) => void;
  onCloseCallback?: () => void;

  constructor({
    isOpen,
    onOpen,
    onClose,
  }: {
    onOpen?: (additionalData?: AdditionalDataType) => void;
    onClose?: () => void;
    isOpen?: boolean;
  } = {}) {
    makeObservable(this);
    this.onOpenCallback = onOpen;
    this.onCloseCallback = onClose;
    this.isOpen = isOpen || false;
    when(
      () => this.isOpen === false,
      () => {
        this.additionalData = undefined;
      },
    );
  }

  @action.bound onOpen(additionalData?: AdditionalDataType): void {
    this.additionalData = additionalData;
    this.onOpenCallback?.(additionalData);
    this.isOpen = true;
  }

  @action.bound onClose(): void {
    this.onCloseCallback?.();
    this.isOpen = false;
  }

  @action.bound open(): void {
    this.isOpen = true;
  }

  @action.bound close(): void {
    this.isOpen = false;
  }

  @action.bound onToggle(additionalData?: AdditionalDataType): void {
    this.additionalData = additionalData;
    this.isOpen = !this.isOpen;
  }
}
