import { Box, HStack, StackProps } from "@chakra-ui/react";
import {
  OverflowMenu,
  OverflowMenuProps,
} from "@src/components/ui-kit/CollapsiblePanel/OverflowMenu";
import { last } from "lodash";
import {
  Children,
  ReactElement,
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export type CollapsiblePanelItem<T> = ReactElement<
  T & { targetId: string; isCollapsed?: boolean },
  any
>;

type CollapsiblePanelProps<T> = StackProps & {
  children: CollapsiblePanelItem<T>[];
  dropdownMenu: ReactElement<OverflowMenuProps<T>, typeof OverflowMenu>;
};

type ContextType<T> = {
  items: CollapsiblePanelItem<T>[];
  collapsedItems: CollapsiblePanelItem<T>[];
  visibleItems: CollapsiblePanelItem<T>[];
  visibilityMap: Record<string, boolean>;
};

const CollapsiblePanelContext = createContext<ContextType<unknown>>({
  items: [],
  collapsedItems: [],
  visibleItems: [],
  visibilityMap: {},
});

export function useCollapsiblePanel<T>(): ContextType<T> {
  const context = useContext(CollapsiblePanelContext) as ContextType<T>;
  if (!context) {
    throw new Error(
      'useCollapsiblePanel returned is "undefined". Seems you forgot to wrap the components in "<CollapsiblePanel />"',
    );
  }
  return context;
}

const DATA_TARGET_ID = "data-targetid";
const DATA_IS_COLLAPSED = "data-is-collapsed";

export const CollapsiblePanel = function CollapsiblePanel<T>({
  children,
  dropdownMenu,
  ...props
}: CollapsiblePanelProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const overflowTriggerRef = useRef<HTMLDivElement>(null);
  const [visibilityMap, setVisibilityMap] = useState<Record<string, boolean>>(
    {},
  );

  const { items, collapsedItems, visibleItems } = useMemo(() => {
    const _items: CollapsiblePanelItem<T>[] = children;
    const _collapsedItems: CollapsiblePanelItem<T>[] = [];
    const _visibleItems: CollapsiblePanelItem<T>[] = [];

    Children.forEach(children, (child) => {
      if (visibilityMap[child.props["targetId"]]) {
        _visibleItems.push(child);
      } else {
        _collapsedItems.push(child);
      }
    });
    return {
      items: _items,
      collapsedItems: _collapsedItems,
      visibleItems: _visibleItems,
    };
  }, [children, visibilityMap]);

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const updatedEntries: Record<string, boolean> = {};

    entries.forEach((entry) => {
      const targetid = entry.target.getAttribute(DATA_TARGET_ID);
      const isCollapsed = entry.target.getAttribute(DATA_IS_COLLAPSED);

      if (!targetid) return;
      if (isCollapsed === "true") {
        updatedEntries[targetid] = false;
        return;
      }

      updatedEntries[targetid] = entry.isIntersecting;
    });

    setVisibilityMap((prev) => ({
      ...prev,
      ...updatedEntries,
    }));
  };

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: containerRef.current,
      threshold: 1,
    });

    Array.from(containerRef.current?.children || []).forEach((item) => {
      if (!item.getAttribute(DATA_TARGET_ID)) return;
      observer.observe(item);
    });

    if (overflowTriggerRef.current) {
      observer.observe(overflowTriggerRef.current);
    }

    return () => observer.disconnect();
  }, [containerRef, overflowTriggerRef, children]);

  return (
    <CollapsiblePanelContext.Provider
      value={{ items, collapsedItems, visibilityMap, visibleItems }}
    >
      <HStack
        ref={containerRef}
        {...props}
        overflow="hidden"
        h="full"
        spacing="0"
      >
        {items.map((item) => {
          const isVisible = visibilityMap[item.props.targetId];

          return (
            <Box
              key={item.key}
              h="full"
              {...{ [DATA_TARGET_ID]: item.props["targetId"] }}
              {...{ [DATA_IS_COLLAPSED]: item.props["isCollapsed"] }}
              style={{
                visibility: isVisible ? "visible" : "hidden",
                order: isVisible ? 0 : 100,
                pointerEvents: isVisible ? undefined : "none",
                opacity: isVisible ? 1 : 0,
              }}
            >
              {item}
            </Box>
          );
        })}
        <Box
          ref={overflowTriggerRef}
          {...{ [DATA_TARGET_ID]: last(visibleItems)?.props.targetId }}
          order={99}
          h="full"
        >
          {dropdownMenu}
        </Box>
      </HStack>
    </CollapsiblePanelContext.Provider>
  );
};
