import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { FinancialSettingsState } from "@src/stores/forms/SettingsModalState/FinancialSettingsState";

export class FinancialSettingsStore implements BaseStore {
  constructor(
    public appStore: AppStore,
    public form: FinancialSettingsState,
  ) {}
}
