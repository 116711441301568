import type { SystemStyleFunction } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  return {
    transitionProperty: "common",
    transitionDuration: "fast",
    transitionTimingFunction: "ease-out",
    cursor: "pointer",
    textDecoration: "none",
    outline: "none",
    color: `${c}.600`,
    _hover: {
      textDecoration: "underline",
    },
    _focus: {
      boxShadow: "outline",
    },
  };
};

const defaultProps = {
  colorScheme: "blue",
};

export default {
  baseStyle,
  defaultProps,
};
