import { StyleProps } from "@chakra-ui/react";
import cs from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";

interface ThProps {
  type: "th";
  sortable?: boolean;
}

interface TdProps {
  type: "td";
  sortable?: undefined;
}

export type CellProps = {
  width?: string | number;
  backgroundColor?: StyleProps["backgroundColor"];
  align?: "left" | "center" | "right";
  padding?: StyleProps["padding"];

  /** Pass 0 to render nothing */
  colSpan?: number;

  className?: string;
};

const Cell: FC<React.PropsWithChildren<CellProps & (ThProps | TdProps)>> = ({
  type,
  sortable,
  width,
  backgroundColor,
  align,
  colSpan,
  className,
  children,
  padding,
}) =>
  colSpan === 0
    ? null
    : React.createElement(
        type,
        {
          colSpan,
          className: cs(
            className,
            "Polaris-DataTable__Cell",
            {
              "Polaris-DataTable__Cell--header": type === "th",
              "Polaris-DataTable__Cell--sortable": sortable === true,
              "Polaris-DataTable__Cell--sorted": sortable === true,
            },
            getAlignClassName(align),
          ),
          style: { width, backgroundColor, padding },
        },
        children,
      );

const getAlignClassName = (val?: string) => {
  switch (val) {
    case "center":
      return "text-center";
    case "right":
      return "text-right";
    default:
      return "text-left";
  }
};

export default observer(Cell);
