const colors = {
  transparent: "transparent",
  black: "#000",
  white: "#fff",
  blue: {
    "50": "#f1f8fa",
    "100": "#D3E6EE",
    "200": "#A7CDDD",
    "300": "#7BB3CC",
    "400": "#4F9ABA",
    "500": "#3A7995",
    "600": "#2E6076",
    "700": "#224858",
    "800": "#17303B",
    "900": "#0B181D",
  },
  grey: {
    "50": "#EEF0F2",
    "100": "#DFE3E7",
    "200": "#BFC7CE",
    "300": "#A0ACB6",
    "400": "#80909E",
    "500": "#637381",
    "600": "#505D68",
    "700": "#3C454E",
    "800": "#282E34",
    "900": "#14171A",
  },
  red: {
    "50": "#FCEBE8",
    "100": "#FAD7D1",
    "200": "#F5AFA3",
    "300": "#F08875",
    "400": "#EB6047",
    "500": "#E33719",
    "600": "#B82D14",
    "700": "#8A220F",
    "800": "#5C160A",
    "900": "#2E0B05",
  },
  green: {
    "50": "#EEF9EC",
    "100": "#DDF2D9",
    "200": "#B7E5AE",
    "300": "#95D888",
    "400": "#70CA5D",
    "500": "#50B83C",
    "600": "#409230",
    "700": "#317024",
    "800": "#204918",
    "900": "#11260D",
  },
  yellow: {
    "50": "#FFFAE5",
    "100": "#FFF5C7",
    "200": "#FFEB94",
    "300": "#FFE15C",
    "400": "#FFD724",
    "500": "#EEC200",
    "600": "#BD9A00",
    "700": "#8F7500",
    "800": "#614F00",
    "900": "#2E2500",
  },
  teal: {
    "50": "#ECF9F8",
    "100": "#D9F2F2",
    "200": "#B7E7E6",
    "300": "#90DAD9",
    "400": "#6ACDCB",
    "500": "#47C1BF",
    "600": "#359C9B",
    "700": "#287675",
    "800": "#1B504F",
    "900": "#0D2626",
  },
  jade: {
    "50": "#f0fdf9",
    "100": "#cdfaee",
    "200": "#9af5dc",
    "300": "#60e8c9",
    "400": "#2fd2b1",
    "500": "#15aa8f",
    "600": "#0f927d",
    "700": "#117466",
    "800": "#125d53",
    "900": "#144d45",
    "950": "#052e2a",
  },
  purple: {
    "50": "#F0F1FA",
    "100": "#DDE0F3",
    "200": "#BFC5E8",
    "300": "#9EA6DC",
    "400": "#7C87D0",
    "500": "#5C6AC4",
    "600": "#3D4BA8",
    "700": "#2E397F",
    "800": "#1F2756",
    "900": "#0F1229",
  },
  riptide: {
    "50": "#f1fcf9",
    "100": "#d0f7f1",
    "200": "#a0efe3",
    "300": "#6de0d3",
    "400": "#3ac7bb",
    "500": "#21aba2",
    "600": "#178a84",
    "700": "#176e6b",
    "800": "#175857",
    "900": "#184948",
    "950": "#072b2c",
  },
  violet: {
    "50": "#eef1ff",
    "100": "#dfe4ff",
    "200": "#c6ccff",
    "300": "#a3acfe",
    "400": "#7e80fb",
    "500": "#675ff5",
    "600": "#6853eb",
    "700": "#4d34ce",
    "800": "#3e2da6",
    "900": "#362c83",
    "950": "#211a4c",
  },
};

export default colors;
