import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Switch,
  Tooltip,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { FormRow, Select } from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { useStore } from "@src/utils/hooks";
import { upperFirst } from "lodash";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

export const Notifications: FunctionComponent = observer(
  function Notifications() {
    const {
      settingsModalStore: { notificationsStore },
    } = useStore();

    return (
      <Accordion allowToggle paddingBlock="6">
        {notificationsStore.form.notifications.map((notification, index) => {
          const notificationHasOptions =
            notification.to_roles || notification.shouldShowThresholdInput;
          const notificationHasError =
            notificationsStore.form.form.$[index].hasError;
          return (
            <AccordionItem key={notification.id}>
              <h4>
                <AccordionButton
                  gap="2"
                  cursor={!notificationHasOptions ? "default" : undefined}
                >
                  <Box
                    as="span"
                    flex="1"
                    color={notificationHasError ? "red.500" : undefined}
                    textAlign="left"
                  >
                    {notification.name}{" "}
                    <Tooltip hasArrow label={notification.description}>
                      <span tabIndex={0}>
                        <Icon name="help-circle" />
                      </span>
                    </Tooltip>{" "}
                    {notificationHasError ? (
                      <Tooltip
                        hasArrow
                        label={t`This section contains errors that need to be fixed in order to update the settings.`}
                      >
                        <span tabIndex={0}>
                          <Icon name="alert-triangle" color="red.500" />
                        </span>
                      </Tooltip>
                    ) : undefined}
                  </Box>
                  <FormControl
                    alignItems="center"
                    display="flex"
                    w="max-content"
                  >
                    <Switch
                      defaultChecked={notification.$.database_enabled.value}
                      id="in-app-switch"
                      onChange={(e) =>
                        notification.$.database_enabled.onChange(
                          e.target.checked,
                        )
                      }
                    />
                    <FormLabel
                      mb="0"
                      htmlFor="in-app-switch"
                      marginInlineStart="2"
                    >
                      <Trans>In app</Trans>
                    </FormLabel>
                  </FormControl>
                  <FormControl
                    alignItems="center"
                    display="flex"
                    w="max-content"
                  >
                    <Switch
                      defaultChecked={notification.$.mail_enabled.value}
                      id="email-switch"
                      onChange={(e) =>
                        notification.$.mail_enabled.onChange(e.target.checked)
                      }
                    />
                    <FormLabel
                      mb="0"
                      htmlFor="email-switch"
                      marginInlineStart="2"
                    >
                      <Trans>Email</Trans>
                    </FormLabel>
                  </FormControl>
                  {notificationHasOptions ? <AccordionIcon /> : <Box w="5" />}
                </AccordionButton>
              </h4>
              <AccordionPanel p={!notificationHasOptions ? "0" : undefined}>
                {notification.to_roles && (
                  <FormRow title={<Trans>Roles to notify</Trans>}>
                    <Select
                      asPortal
                      id="roles-to-notify"
                      label={""}
                      isMulti
                      options={notificationsStore.roles.map((role) => ({
                        label: getRoleLabel(role),
                        value: role,
                      }))}
                      placeholder={t`Select roles that will be notified`}
                      value={notification.$.roles_to_notify.value}
                      onChange={(value) =>
                        notification.$.roles_to_notify.onChange(value)
                      }
                    />
                  </FormRow>
                )}

                {notification.shouldShowThresholdInput && (
                  <FormRow title={<Trans>Threshold value</Trans>}>
                    <FormControl isInvalid={notification.$.threshold.hasError}>
                      <InputGroup>
                        <Input
                          defaultValue={notification.$.threshold.value ?? ""}
                          id="threshold"
                          inputMode="numeric"
                          min="1"
                          onChange={(e) => {
                            notification.$.threshold.onChange(e.target.value);
                            notification.validate();
                          }}
                          type="number"
                        />
                        <InputRightAddon>
                          {notification.thresholdUnit}
                        </InputRightAddon>
                      </InputGroup>
                      <FormErrorMessage>
                        {notification.$.threshold.error}
                      </FormErrorMessage>
                    </FormControl>
                  </FormRow>
                )}
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    );
  },
);

/**
 *
 * @returns Role label specific for notification settings. DO NOT REUSE, because `project-manager` and `team-leader` use different translations than usual.
 */
const getRoleLabel = (name: string) => {
  switch (name) {
    case "admin":
      return t`Admin`;
    case "cfo":
      return t`CFO`;
    case "financier":
      return t`Financier`;
    case "managing-director":
      return t`Managing Director`;
    case "project-manager":
      return t`Project PM`;
    case "team-leader":
      return t`Project TL`;
    case "user":
      return t`User`;
    default:
      return upperFirst(name.toLowerCase().replaceAll("-", " "));
  }
};
