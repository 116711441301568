import { action, makeObservable, observable } from "mobx";

export class ValueState<ValueType> {
  @observable value: ValueType;

  constructor(defaultValue: ValueType) {
    makeObservable(this);
    this.value = defaultValue;
  }

  @action.bound set(value: ValueType): void {
    this.value = value;
  }
}
