import { Box } from "@chakra-ui/react";
import { Card } from "@src/components/ui-kit";
import { StatCard } from "@src/components/ui-kit/DataTable/StatCard";
import { Stats } from "@src/components/ui-kit/DataTable/Stats";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import Body from "./Body";
import s from "./DataTable.module.css";
import Filter from "./Filter";
import NavBar from "./NavBar";
import SummaryBox from "./SummaryBox";
import SummaryItem from "./SummaryItem";
import { SummaryItemSubText } from "./SummaryItemSubText";

const DataTable: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Card
    overflowX={{
      base: "scroll",
      md: "unset",
    }}
    maxW={{
      base: "full",
      md: "unset",
    }}
  >
    <Box
      w={{
        base: "fit-content",
        md: "full",
      }}
    >
      <div className={s.DataTable}>{children}</div>
    </Box>
  </Card>
);

const DataTableObservable = observer(DataTable) as FC<
  React.PropsWithChildren<unknown>
> & {
  NavBar: typeof NavBar;
  Filter: typeof Filter;
  SummaryBox: typeof SummaryBox;
  SummaryItem: typeof SummaryItem;
  SummaryItemSubText: typeof SummaryItemSubText;
  Body: typeof Body;
  Stats: typeof Stats;
  StatCard: typeof StatCard;
};

DataTableObservable.NavBar = NavBar;
DataTableObservable.Filter = Filter;
DataTableObservable.SummaryBox = SummaryBox;
DataTableObservable.SummaryItem = SummaryItem;
DataTableObservable.SummaryItemSubText = SummaryItemSubText;
DataTableObservable.Body = Body;
DataTableObservable.Stats = Stats;
DataTableObservable.StatCard = StatCard;

export default DataTableObservable;
