import { Button, ButtonProps } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { FunctionComponent } from "react";

export const CancelButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <Button colorScheme="grey" variant="outline" {...props}>
      <Trans>Cancel</Trans>
    </Button>
  );
};
