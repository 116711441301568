import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { PlanningUtilizationState } from "@src/stores/forms/SettingsModalState/PlanningUtilizationState";

export class PlanningUtilizationSettingsStore implements BaseStore {
  constructor(
    public appStore: AppStore,
    public form: PlanningUtilizationState,
  ) {}
}
