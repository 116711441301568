import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { AssignableFileUrlPair } from "@src/__generated__/urql-graphql";
import { Icon } from "@src/components/ui-kit/Icon";

type VideoModalProps = Omit<ModalProps, "children"> & {
  title: string;
  url: NonNullable<AssignableFileUrlPair["viewable"]>;
  originalUrl: NonNullable<AssignableFileUrlPair["original"]>;
};

export const VideoModal = ({
  url,
  originalUrl,
  title,
  ...props
}: VideoModalProps) => {
  return (
    <Modal {...props} size="4xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title}
          <IconButton
            pos="absolute"
            ml="4"
            aria-label={t`download`}
            colorScheme="grey"
            icon={<Icon name="download-02" w="5" h="5" color="grey.500" />}
            onClick={() => {
              window.open(originalUrl, "_blank");
            }}
            variant="outline"
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <video controls width="100%">
            <source src={url} type="video/mp4" />
            <Text>
              <Trans>
                Sorry, your browser doesn't support embedded videos. Here is a
                <a href={url} target="__blank" referrerPolicy="no-referrer">
                  link to the video
                </a>{" "}
                instead.
              </Trans>
            </Text>
          </video>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
