import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { TimeTrackingEntryFragment } from "@src/__generated__/urql-graphql";
import { ModalEntry } from "@src/components/modules/time-tracking/TimeSheet/components";
import { TimeTrackingLabel } from "@src/components/modules/time-tracking/TimeSheet/components/Entries/TimeTrackingLabel";
import { LoadingOverlay } from "@src/components/ui-kit";
import Duration from "@src/components/widgets/TimeTrackingPopover/components/Entry/Duration";
import { TimeTrackingItemMenuActions } from "@src/components/widgets/TimeTrackingPopover/components/Entry/TimeTrackingItemMenuActions";
import { toApiDate } from "@src/utils/dates";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { Fragment, FunctionComponent } from "react";

export const TrackedForDayModal = observer(function TrackedForDayModal() {
  const { trackedForDayModalStore: store } = useStore();

  return (
    <Modal
      /**
       * set `blockScrollOnMount` to `false` to enable scroll in nested modals
       */
      blockScrollOnMount={false}
      isCentered
      isOpen
      onClose={store.modalState.onClose}
      scrollBehavior="inside"
      size="3xl"
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Total tracked time</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <LoadingOverlay
            isLoading={store.isLoading}
            minH="200px"
            position="absolute"
            transparent={false}
          >
            <Stack h="full" dir="vertical">
              {!store.timeTrackingItems.length && (
                <Text>
                  <Trans>Nothing has been tracked yet</Trans>
                </Text>
              )}
              {store.timeTrackingItems.map((item) => (
                <TimeTrackingEntry key={item.id} item={item} />
              ))}
            </Stack>
          </LoadingOverlay>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

const TimeTrackingEntry: FunctionComponent<{
  item: TimeTrackingEntryFragment;
}> = observer(function TimeTrackingEntry({ item }) {
  const {
    trackedForDayModalStore: store,
    timeTrackingStore,
    resourcePlanningDayStore,
    resourcePlanningWeekStore,
  } = useStore();
  const editModal = useDisclosure();

  return (
    <HStack justify="end">
      {item.task.ourWorkBudgetItem && (
        <TimeTrackingLabel
          py="3"
          mr="auto"
          project={item.task.ourWorkBudgetItem.project}
          brandName={item.task.ourWorkBudgetItem.project.brand.name}
          budgetItemTitle={item.task.ourWorkBudgetItem.title}
          taskName={item.task.name}
          taskId={item.task.id}
          workTypeTitle={item.timeTrackingWorkType.title}
          isFromPlanning={!!item.capacityAllocationItem?.id}
        />
      )}
      <Duration
        value={item.tracked_time}
        highlighted={item.id === timeTrackingStore.runningEntry?.id}
      />
      {item.id !== timeTrackingStore.runningEntry?.id && (
        <Fragment>
          <TimeTrackingItemMenuActions
            value={item}
            onEdit={editModal.onOpen}
            withStart={false}
            onDelete={() => {
              store.timeTrackingItems = store.timeTrackingItems.filter(
                (i) => i.id !== item.id,
              );
            }}
          />
          <ModalEntry
            {...editModal}
            item={{
              ...item,
              tracked_for_date: toApiDate(item.tracked_for_date),
            }}
            userId={store.modalState.additionalData!.userId!}
            withAllocationSelect
            redirectOnClose={false}
            onSaveCompleted={() => {
              resourcePlanningDayStore.syncData();
              resourcePlanningWeekStore.syncData();
              store.fetchTimeTrackingItems({
                userId: store.modalState.additionalData!.userId,
                date: store.modalState.additionalData!.date,
              });
            }}
          />
        </Fragment>
      )}
    </HStack>
  );
});
