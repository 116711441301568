import { Heading, HStack, Stack, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { DataTable } from "@src/components/ui-kit";
import { UtilizationProgressBar } from "@src/components/ui-kit/ProgressBar/UtilizationProgressBar";
import { formatSecondsToHours } from "@src/utils/time";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { FC } from "react";

type Props = {
  totalTime: number | undefined;
  availableTime: number | undefined;
  timeOff: number | undefined;
};

export const TotalCapacityStatCard: FC<Props> = observer(
  function TotalCapacityStatCard({
    totalTime = 0,
    availableTime = 0,
    timeOff = 0,
  }) {
    const progressPercent = computed(() => {
      return ((availableTime ?? 0) / (totalTime ?? 0)) * 100;
    }).get();

    return (
      <DataTable.StatCard>
        <Stack>
          <HStack justify="space-between" mb="2">
            <Heading size="sm">
              <Trans>Total Capacity</Trans>
            </Heading>
            <Heading size="sm">{formatSecondsToHours(totalTime, 1)}</Heading>
          </HStack>
          <UtilizationProgressBar percent={progressPercent} />
          <HStack justify="space-between">
            <HStack>
              <Text color="grey.600" fontWeight="medium" size="sm">
                <Trans>Available</Trans>
              </Text>
              <Text>{formatSecondsToHours(availableTime, 1)}</Text>
            </HStack>
            <HStack>
              <Text color="grey.600" fontWeight="medium" size="sm">
                <Trans>Time offs</Trans>
              </Text>
              <Text size="sm">{formatSecondsToHours(timeOff, 1)}</Text>
            </HStack>
          </HStack>
        </Stack>
      </DataTable.StatCard>
    );
  },
);
