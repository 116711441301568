import { Box, BoxProps, HStack } from "@chakra-ui/react";
import { FC, PropsWithChildren, ReactElement } from "react";

export const UncollapseOnHover: FC<
  PropsWithChildren<{
    dataComponentName: string;
    width: BoxProps["w"];
    hiddenComponent: ReactElement;
    color?: BoxProps["color"];
  }>
> = ({
  children,
  dataComponentName,
  width,
  hiddenComponent,
  color = "blue.500",
}) => {
  return (
    <HStack data-component-name={dataComponentName} spacing="0">
      {children}
      <Box
        sx={{
          [`[data-component-name="${dataComponentName}"]:hover &`]: {
            w: width,
            opacity: "1",
            ml: "1",
          },
        }}
        w="0px"
        color={color}
        opacity="0"
        transition="all"
        transitionDuration="200ms"
      >
        {hiddenComponent}
      </Box>
    </HStack>
  );
};
