import { createStandaloneToast, UseToastOptions } from "@chakra-ui/react";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { theme } from "@src/theme";
import { CustomToast } from "./CustomToast";
import { DialogsStore } from "./DialogsStore";

const { toast: chakraToast } = createStandaloneToast({ theme });

export class UIStore implements BaseStore {
  appStore: AppStore;
  onBackButtonClick?: () => Promise<void>;
  dialogs = new DialogsStore();

  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  toast(options: UseToastOptions) {
    chakraToast({
      render: (props) => (
        <CustomToast
          isClosable={options.isClosable}
          onClose={props.onClose}
          title={options.title}
          description={options.description}
          status={options.status}
        />
      ),
    });
  }
}
