import { action, computed, makeObservable, observable } from "mobx";
import { denominate, nominate, percentFormatter } from "../formatters";

/**
 * @deprecated
 * use PercentProperty instead
 */
export class PercentModel {
  private static _denomination = 2;
  @observable private _rawValue: number;

  constructor(rawValue: number) {
    makeObservable(this);
    this._rawValue = rawValue;
  }

  static fromRawValue(rawValue: number | string) {
    return new PercentModel(
      typeof rawValue === "string" ? Number(rawValue) : rawValue,
    );
  }

  static fromValue(value: number | string) {
    return new PercentModel(
      denominate(
        typeof value === "string" ? +value : value,
        PercentModel._denomination,
      ),
    );
  }

  get denomination() {
    return PercentModel._denomination;
  }

  @computed get value() {
    return nominate(this._rawValue, PercentModel._denomination);
  }

  @computed get rawValue() {
    return this._rawValue;
  }

  @action
  setRawValue(val: number | string) {
    if (typeof val === "string") this._rawValue = Number(val);
    else this._rawValue = val;
    return this;
  }

  @action
  setValue(val: number | string) {
    if (typeof val === "string")
      this._rawValue = denominate(Number(val), PercentModel._denomination);
    else this._rawValue = denominate(val, PercentModel._denomination);
    return this;
  }

  format() {
    return percentFormatter().format(this.value / 100);
  }

  percentOf(amount: number): number {
    return PercentModel.percentOf(amount, this.value);
  }

  static percentOf(amount: number, percent: number): number {
    return Math.round((amount * percent) / 100);
  }
}
