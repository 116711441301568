import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { ToolbarStoreContext } from "@src/components/ui-kit/TextEditor/toolbar/ToolbarStore";
import { Editor } from "@tiptap/core";
import { FieldState } from "formstate";
import { observer } from "mobx-react-lite";
import {
  FormEvent,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

export interface HyperlinkModalRef {
  open: () => void;
  close: () => void;
  isOpen: boolean;
}
export const HyperlinkButton: FunctionComponent<{ editor: Editor }> = ({
  editor,
}) => {
  const store = useContext(ToolbarStoreContext);

  return (
    <IconButton
      bg={editor.isActive("link") ? "grey.50" : "initial"}
      aria-label={t`link`}
      colorScheme="grey"
      icon={<Icon name="link-03" w="4" h="4" />}
      onClick={() => store.hyperlinkModalRef.current?.open()}
      size="sm"
      variant="ghost"
    />
  );
};

export const HyperlinkModal = observer(function HyperlinkModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const store = useContext(ToolbarStoreContext);
  const [text] = useState(new FieldState(""));
  const [link] = useState(new FieldState(""));
  useImperativeHandle(store.hyperlinkModalRef, () => ({
    open: onOpen,
    close: onClose,
    isOpen,
  }));

  useEffect(() => {
    const previousLink = store.editor.getAttributes("link").href as
      | string
      | undefined;
    const { view, state } = store.editor;
    const { from, to } = view.state.selection;
    const previousText = state.doc.textBetween(from, to, "");

    // let url = window.prompt('URL', previousUrl)

    link.onChange(previousLink || "");
    text.onChange(previousText);
  }, [isOpen]);

  const setLinkAction = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      // empty
      if (link.value === "") {
        store.editor.chain().focus().extendMarkRange("link").unsetLink().run();
        onClose();
        return;
      }

      // force https
      if (!/^https?:\/\//.test(link.value)) {
        link.onChange(`http://${link.value}`);
      }

      // update link
      store.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: link.value })
        .command(({ tr, view }) => {
          const { from, to } = view.state.selection;
          tr.insertText(text.value, from, to);
          return true;
        })
        .run();
      onClose();
    },
    [store.editor],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={setLinkAction}>
          <ModalHeader>
            <Trans>Add link</Trans>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <FormControl mb="4">
              <FormLabel>
                <Trans>Text</Trans>
              </FormLabel>
              <Input
                onChange={(e) => text.onChange(e.target.value)}
                value={text.value}
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                <Trans>Link</Trans>
              </FormLabel>
              <Input
                onChange={(e) => link.onChange(e.target.value)}
                value={link.value}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="grey"
              onClick={() => {
                onClose();
                store.editor.commands.focus("end");
              }}
              variant="outline"
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button ml={3} disabled={text.value === ""} type="submit">
              <Trans>Save</Trans>
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
});
