import { Time, TimeWithSeconds } from "@src/utils/types";
import {
  secondsToHours as secondsToHoursFns,
  secondsToMinutes,
} from "date-fns";

export function secondsToHoursWithMinutes(seconds: number): string {
  const positiveSeconds = Math.abs(seconds);
  const hours = String(secondsToHoursFns(positiveSeconds)).padStart(2, "0");
  const minutes = String(secondsToMinutes(positiveSeconds % 3600)).padStart(
    2,
    "0",
  );
  return `${hours}:${minutes}`;
}

export function convertTimeWithSecondsToTime(time: TimeWithSeconds): Time {
  return time.slice(0, 5) as Time;
}

export function convertTimeToTimeWithSeconds(time: Time): TimeWithSeconds {
  const seconds = time === "23:59" ? ":59" : ":00";
  return (time + seconds) as TimeWithSeconds;
}

export function formatSecondsToHours(seconds: number, decimals = 0) {
  return (
    (decimals === 0
      ? Math.floor(secondsToHours(seconds))
      : secondsToHours(seconds).toFixed(decimals)) + "h"
  );
}

export function secondsToHours(seconds: number | undefined | null): number {
  if (!seconds) return 0;
  return seconds / 3600;
}

export function secondsToTime(seconds: number | undefined): Time | undefined {
  if (seconds === undefined) return undefined;
  const hours = Math.floor(Math.abs(seconds) / 3600);
  const hoursWithPrefix = hours < 10 ? `0${hours}` : hours;
  const minutes = Math.floor((Math.abs(seconds) - hours * 3600) / 60);
  return `${Math.sign(seconds) === -1 ? "-" : ""}${hoursWithPrefix}:${String(minutes).padStart(2, "0")}` as Time;
}

export function timeToSeconds(time: Time | undefined): number | undefined {
  if (!time) return undefined;
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 3600 + minutes * 60;
}

export function isTimeBiggerThan(time1: Time, time2: Time) {
  // We get advantage of the fact that numbers are sorted correctly in ASCII table.
  return time1 > time2;
}
