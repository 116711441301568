import { Avatar, AvatarProps } from "@src/components/ui-kit/Avatar";
import brandPlaceholder from "./img/brand.png";

type BrandLogoProps = Omit<AvatarProps, "icon">;

const BrandLogo = (props: BrandLogoProps) => (
  <Avatar icon={<img src={brandPlaceholder} alt="brand" />} {...props} />
);

export default BrandLogo;
