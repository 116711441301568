export const HUF = (
  <svg
    viewBox="0 0 40 40"
    height="20"
    width="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0v13h40V0z" fill="#CD2A3E" />
    <path d="M40 13H0v14h40V13z" fill="#fff" />
    <path d="M40 27H0v13h40V27z" fill="#436F4D" />
  </svg>
);
