import { t } from "@lingui/macro";
import {
  SequenceTypesEnum,
  WorkspaceBankAccount,
  WorkspaceCompanySetting,
  WorkspaceSequence,
  WorkspaceSettingsQuery,
} from "@src/__generated__/urql-graphql";
import { TToFieldStates } from "@src/utils/forms/ts-utils";
import { FieldState, FormState } from "formstate";
import { action, autorun, makeObservable, observable } from "mobx";
import { v4 as uuidV4 } from "uuid";

type TWorkspaceCompany =
  WorkspaceSettingsQuery["workspaceCompaniesSettings"][0];

export const REQUIRED_SEQUENCE_TYPES_ERROR = "REQUIRED_SEQUENCE_TYPES_ERROR";
const required = (value?: string | null) => !value && t`This field is required`;

type BankAccountStateProps = Omit<WorkspaceBankAccount, "deleted" | "id">;
class BankAccountState extends FormState<
  TToFieldStates<BankAccountStateProps>
> {
  id?: WorkspaceBankAccount["id"];
  internalId: string;
  constructor(
    id?: WorkspaceBankAccount["id"],
    fields: BankAccountStateProps = {
      account_holder_name: "",
      bank_account: "",
      bank_code: "",
      bank_name: "",
      bic: "",
      iban: "",
    },
  ) {
    super({
      account_holder_name: new FieldState(fields.account_holder_name),
      bank_account: new FieldState(fields.bank_account),
      bank_code: new FieldState(fields.bank_code),
      bank_name: new FieldState(fields.bank_name),
      bic: new FieldState(fields.bic).validators(required),
      iban: new FieldState(fields.iban).validators(required),
    });
    this.id = id;
    this.internalId = uuidV4();
  }
}

type SequenceStateProps = Omit<WorkspaceSequence, "id" | "next_number"> & {
  next_number: string;
};
class SequenceState extends FormState<TToFieldStates<SequenceStateProps>> {
  id?: WorkspaceSequence["id"];
  internalId: string;
  constructor(
    id?: WorkspaceSequence["id"],
    fields: SequenceStateProps = {
      format: "",
      next_number: "",
      types: [],
    },
  ) {
    super({
      format: new FieldState<WorkspaceSequence["format"]>(
        fields.format,
      ).validators(($) => !$.includes("#") && t`Format must include "#"`),
      next_number: new FieldState<string>(fields.next_number).validators(
        required,
      ),
      types: new FieldState<WorkspaceSequence["types"]>(fields.types),
    });
    this.id = id;
    this.internalId = uuidV4();
  }
}

class CompanyState {
  // static properties
  id?: string;
  internalId: string;

  // dynamic properties
  @observable bankAccountToDelete: BankAccountState | undefined = undefined;
  @observable sequenceToDelete: SequenceState | undefined = undefined;

  // form properties
  readonly bank_accounts = new FormState({
    bank_accounts: new FormState<BankAccountState[]>([new BankAccountState()]),
  });
  readonly sequences = new FormState({
    sequences: new FormState<SequenceState[]>([new SequenceState()]),
  }).validators(($) => {
    const requiredSequenceTypes = [
      SequenceTypesEnum.CreditNote,
      SequenceTypesEnum.ExpenseAll,
      SequenceTypesEnum.OutgoingInvoice,
      SequenceTypesEnum.Proforma,
      SequenceTypesEnum.ProformaPayment,
    ];
    const usedSequenceTypes = $.sequences.$.reduce((acc, sequence) => {
      return acc.concat(sequence.$.types.$);
    }, [] as SequenceTypesEnum[]);

    const unusedRequiredTypes = requiredSequenceTypes.filter(
      (type) => !usedSequenceTypes.includes(type),
    );
    return unusedRequiredTypes.length > 0 && REQUIRED_SEQUENCE_TYPES_ERROR;
  });
  //readonly pdfSettings = new FormState({
  //  footer: new FieldState<TWorkspaceCompany["pdfSettings"]["footer"]>(""),
  //  text_on_invoice: new FieldState<
  //    TWorkspaceCompany["pdfSettings"]["text_on_invoice"]
  //  >(""),
  //  show_contact_person: new FieldState<
  //    TWorkspaceCompany["pdfSettings"]["show_contact_person"]
  //  >(false),
  //  show_project_codes: new FieldState<
  //    TWorkspaceCompany["pdfSettings"]["show_project_codes"]
  //  >(false),
  //  show_full_bank_account_information: new FieldState<
  //    TWorkspaceCompany["pdfSettings"]["show_full_bank_account_information"]
  //  >(false),
  //  show_amount_in_words: new FieldState<
  //    TWorkspaceCompany["pdfSettings"]["show_amount_in_words"]
  //  >(false),
  //  show_variable_symbol: new FieldState<
  //    TWorkspaceCompany["pdfSettings"]["show_variable_symbol"]
  //  >(false),
  //  show_tax_no: new FieldState<
  //    TWorkspaceCompany["pdfSettings"]["show_tax_no"]
  //  >(false),
  //  show_payment_type: new FieldState<
  //    TWorkspaceCompany["pdfSettings"]["show_payment_type"]
  //  >(false),
  //  show_date_of_supply: new FieldState<
  //    TWorkspaceCompany["pdfSettings"]["show_date_of_supply"]
  //  >(false),
  //  logo: new FieldState<
  //    TWorkspaceCompany["pdfSettings"]["logo_assignable_file"]
  //  >(undefined),
  //  stamp: new FieldState<
  //    TWorkspaceCompany["pdfSettings"]["stamp_assignable_file"]
  //  >(undefined),
  //});
  city = new FieldState<WorkspaceCompanySetting["city"]>("");
  country_id = new FieldState<WorkspaceCompanySetting["country_id"]>("");
  deleted = new FieldState<WorkspaceCompanySetting["deleted"]>(false);
  internal_name = new FieldState<WorkspaceCompanySetting["internal_name"]>(
    "",
  ).validators(required);
  is_default = new FieldState<WorkspaceCompanySetting["is_default"]>(false);
  name = new FieldState<WorkspaceCompanySetting["name"]>("").validators(
    required,
  );
  registration_no = new FieldState<WorkspaceCompanySetting["registration_no"]>(
    "",
  );
  street = new FieldState<WorkspaceCompanySetting["street"]>("");
  tax_no = new FieldState<WorkspaceCompanySetting["tax_no"]>("");
  vat_no = new FieldState<WorkspaceCompanySetting["vat_no"]>("");
  zip = new FieldState<WorkspaceCompanySetting["zip"]>("");

  form = new FormState({
    bank_accounts: this.bank_accounts,
    sequences: this.sequences,
    // pdfSettings: this.pdfSettings,
    city: this.city,
    country_id: this.country_id,
    deleted: this.deleted,
    internal_name: this.internal_name,
    is_default: this.is_default,
    name: this.name,
    registration_no: this.registration_no,
    street: this.street,
    tax_no: this.tax_no,
    vat_no: this.vat_no,
    zip: this.zip,
  });

  // callbacks
  onDefaultChange: () => void;

  constructor(
    onDefaultChange: typeof CompanyState.prototype.onDefaultChange,
    company?: TWorkspaceCompany,
  ) {
    makeObservable(this);
    this.onDefaultChange = onDefaultChange;
    this.internalId = uuidV4();

    if (company) {
      this.bank_accounts.$.bank_accounts = new FormState(
        company.bank_accounts
          .filter((bankAccount) => !bankAccount.deleted)
          .map(
            (bankAccount) =>
              new BankAccountState(bankAccount.id, {
                account_holder_name: bankAccount.account_holder_name,
                bank_account: bankAccount.bank_account,
                bank_code: bankAccount.bank_code,
                bank_name: bankAccount.bank_name,
                bic: bankAccount.bic,
                iban: bankAccount.iban,
              }),
          ),
      );
      this.sequences.$.sequences = new FormState(
        company.sequences.map(
          (sequence) =>
            new SequenceState(sequence.id, {
              format: sequence.format,
              next_number: sequence.next_number.toString(),
              types: sequence.types,
            }),
        ),
      );

      // this.pdfSettings.$.footer.value = company.pdfSettings.footer;
      // this.pdfSettings.$.text_on_invoice.value =
      //   company.pdfSettings.text_on_invoice;
      // this.pdfSettings.$.show_contact_person.value =
      //   company.pdfSettings.show_contact_person;
      // this.pdfSettings.$.show_project_codes.value =
      //   company.pdfSettings.show_project_codes;
      // this.pdfSettings.$.show_full_bank_account_information.value =
      //   company.pdfSettings.show_full_bank_account_information;
      // this.pdfSettings.$.show_amount_in_words.value =
      //   company.pdfSettings.show_amount_in_words;
      // this.pdfSettings.$.show_variable_symbol.value =
      //   company.pdfSettings.show_variable_symbol;
      // this.pdfSettings.$.show_tax_no.value = company.pdfSettings.show_tax_no;
      // this.pdfSettings.$.show_payment_type.value =
      //   company.pdfSettings.show_payment_type;
      // this.pdfSettings.$.show_date_of_supply.value =
      //   company.pdfSettings.show_date_of_supply;
      // this.pdfSettings.$.logo.value = company.pdfSettings.logo_assignable_file;
      // this.pdfSettings.$.stamp.value =
      //   company.pdfSettings.stamp_assignable_file;

      this.city.value = company.city;
      this.country_id.value = company.country_id;
      this.deleted.value = company.deleted;
      this.id = company.id;
      this.internal_name.value = company.internal_name;
      this.is_default.value = company.is_default;
      this.name.value = company.name;
      this.registration_no.value = company.registration_no;
      this.street.value = company.street;
      this.tax_no.value = company.tax_no ?? "";
      this.vat_no.value = company.vat_no ?? "";
      this.zip.value = company.zip;
    }
  }

  getAvailableSequenceTypes(sequence: SequenceState) {
    const allSequenceTypes = Object.values(SequenceTypesEnum);
    const usedSequenceTypes = this.sequences.$.sequences.$.filter(
      (s) => s !== sequence,
    ).reduce((acc, sequence) => {
      return acc.concat(sequence.$.types.$);
    }, [] as SequenceTypesEnum[]);

    return allSequenceTypes.filter((type) => !usedSequenceTypes.includes(type));
  }

  addBankAccount() {
    this.bank_accounts.$.bank_accounts.$.push(new BankAccountState());
  }

  @action.bound requestBankAccountRemoval(bankAccount: BankAccountState) {
    this.bankAccountToDelete = bankAccount;
  }

  @action.bound removeBankAccount(bankAccount: BankAccountState) {
    this.bank_accounts.$.bank_accounts = new FormState(
      this.bank_accounts.$.bank_accounts.$.filter(
        (account) => account !== bankAccount,
      ),
    );
    this.bankAccountToDelete = undefined;
  }

  addSequence() {
    this.sequences.$.sequences.$.push(new SequenceState());
  }

  @action.bound requestSequenceRemoval(sequence: SequenceState) {
    this.sequenceToDelete = sequence;
  }

  @action.bound removeSequence(sequence: SequenceState) {
    this.sequences.$.sequences = new FormState(
      this.sequences.$.sequences.$.filter((s) => s !== sequence),
    );
    this.sequenceToDelete = undefined;
  }

  setDefault() {
    this.onDefaultChange();
    this.is_default.value = true;
  }
}

export class CompaniesSettingsState {
  @observable companies: CompanyState[] = [];
  readonly form = new FormState({
    companies: new FormState<(typeof CompanyState.prototype.form)[]>([]),
  });

  constructor() {
    makeObservable(this);
    autorun(() => {
      this.form.$.companies = new FormState(
        this.companies.map((company) => company.form),
      );
    });
  }

  init(settings: WorkspaceSettingsQuery["workspaceCompaniesSettings"]) {
    this.companies = settings.map(
      (company) => new CompanyState(this.resetDefaultField.bind(this), company),
    );
    this.form.$.companies = new FormState(
      this.companies.map((company) => company.form),
    );
  }

  resetDefaultField() {
    const company = this.companies.find((company) => company.is_default.value);
    if (company) company.is_default.value = false;
  }

  @action.bound addCompany() {
    this.companies.push(new CompanyState(this.resetDefaultField.bind(this)));
  }
}
