import { HStack, StackProps } from "@chakra-ui/react";
import { FC, ReactElement } from "react";

type StatsProps = {
  children?: ReactElement[];
} & StackProps;

export const Stats: FC<StatsProps> = ({ children, ...props }) => (
  <HStack p="4" spacing="3" {...props}>
    {children}
  </HStack>
);
