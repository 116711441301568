import { TaskPriority } from "@src/__generated__/urql-graphql";

type ToOptionsParam = Pick<TaskPriority, "id" | "name">[];

const toOptions = (priorities: ToOptionsParam) =>
  priorities.map((i) => ({
    label: i.name,
    value: i.id,
  }));

export default { toOptions };
