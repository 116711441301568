import { useRouter } from "next/router";
import { useEffect } from "react";

export function useRecordPrevUrl() {
  const router = useRouter();

  useEffect(() => storePathValues, [router.asPath]);

  function storePathValues() {
    const storage = globalThis?.sessionStorage;
    const pathname = globalThis.location.pathname;
    if (!storage || !pathname) return;
    const prevPath = storage.getItem("currentPath");

    if (pathname !== prevPath) {
      storage.setItem("prevPath", prevPath ?? "");
      storage.setItem("currentPath", pathname);
    }
  }
}
