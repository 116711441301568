import cs from "classnames";
import React, { FC } from "react";

interface ColoredAmountProps {
  value: number;
  className?: string;
  /** @default 0 */
  toCompare?: number;
}

const ColoredAmount: FC<React.PropsWithChildren<ColoredAmountProps>> = ({
  value,
  className,
  toCompare = 0,
  children,
}) => (
  <span
    className={cs(className, {
      "text-red": value < toCompare,
      "text-green": value > toCompare,
    })}
  >
    {children}
  </span>
);

export { ColoredAmount };
