import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { Link } from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import {
  TextEditor,
  TextEditorRef,
} from "@src/components/ui-kit/TextEditor/TextEditor";
import { useSetEditorContent } from "@src/components/ui-kit/TextEditor/hooks/useSetContent";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { FC, useRef } from "react";

export const SendInvoiceModal: FC = observer(function SendInvoiceModal() {
  const { sendInvoiceModalStore: store } = useStore();
  const additionalData = store.modalState.additionalData;
  const editorRef = useRef<TextEditorRef>(null);

  useSetEditorContent({
    ref: editorRef.current,
    note: store.form.$.content.value.replaceAll("<br/>", "<p></p>"),
  });

  return (
    <Modal
      isOpen
      onClose={store.modalState.onClose}
      scrollBehavior="inside"
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        minW={{
          base: "full",
          md: "500px",
        }}
      >
        <ModalHeader>
          <Trans>Send mail</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {store.fetching ? (
            <Center h="300px">
              <Spinner color="teal.500" size="lg" />
            </Center>
          ) : (
            <VStack align="flex-start" spacing="4">
              <FormControl isInvalid={store.form.$.recipients.hasError}>
                <FormLabel>
                  <Trans>Recipients</Trans>
                </FormLabel>
                <Textarea
                  resize="vertical"
                  onChange={({ target }) => {
                    store.form.$.recipients.onChange(target.value);
                  }}
                  value={store.form.$.recipients.value}
                />
                <HStack align="center" mt="2">
                  <Icon name="info-circle" />
                  <FormHelperText>
                    <Trans>
                      If you use multiple emails, please separate them with ", "
                    </Trans>
                  </FormHelperText>
                </HStack>
                <FormErrorMessage>
                  {store.form.$.recipients.error}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={store.form.$.subject.hasError}>
                <FormLabel>
                  <Trans>Subject</Trans>
                </FormLabel>
                <Input
                  onChange={({ target }) => {
                    store.form.$.subject.onChange(target.value);
                  }}
                  value={store.form.$.subject.value}
                />
                <FormErrorMessage>
                  {store.form.$.subject.error}
                </FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>
                  <Trans>Content</Trans>
                </FormLabel>
                <TextEditor
                  ref={editorRef}
                  attachments={[]}
                  isEditable
                  initialValue={store.form.$.content.value}
                  onChange={(val) => {
                    store.form.$.content.onChange(val?.toString() ?? "");
                  }}
                  borderWidth="thin"
                  px="3"
                  borderColor="grey.200"
                  borderRadius="base"
                  transition="border-color 150ms ease-in"
                  _focusWithin={{
                    borderColor: "purple.500",
                  }}
                />
              </FormControl>
              {additionalData?.preview_url && additionalData.number && (
                <Box>
                  <FormLabel>
                    <Trans>Attachment</Trans>
                  </FormLabel>
                  <Link href={additionalData.preview_url} isExternal>
                    {additionalData.type === "outgoing" ? (
                      <Trans>Outgoing</Trans>
                    ) : (
                      <Trans>Proforma</Trans>
                    )}
                    &nbsp;
                    <Trans>invoice {additionalData.number}.pdf</Trans>
                  </Link>
                </Box>
              )}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            mr="2"
            colorScheme="grey"
            isLoading={store.sendingInvoice}
            onClick={store.modalState.onClose}
            variant="outline"
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            isDisabled={store.form.hasError}
            isLoading={store.sendingInvoice}
            onClick={store.sendEmail}
          >
            <Trans>Send</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
