import { ColorProps } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { TimeTrackingPopoverItemsQuery } from "@src/__generated__/graphql";
import { TimeTrackingEntryFragment } from "@src/__generated__/urql-graphql";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { groupBy, keys } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { EntryForm } from "../components/widgets/TimeTrackingPopover/form";

export class TimeTrackingPopoverStore implements BaseStore {
  appStore: AppStore;
  @observable popoverActive = false;

  form: EntryForm = new EntryForm();

  @observable.ref groupedEntries: [string, TimeTrackingEntryFragment[]][] = [];
  refetchEntries: () => void = () => {};

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @computed get bg(): ColorProps["color"] {
    return this.appStore.timeTrackingStore.runningHours >= 24
      ? "red.100"
      : this.appStore.timeTrackingStore.runningHours >= 12
        ? "orange.100"
        : "blue.100";
  }

  @computed get iconBg(): ColorProps["color"] {
    return this.appStore.timeTrackingStore.runningHours >= 24
      ? "red.200"
      : this.appStore.timeTrackingStore.runningHours >= 12
        ? "orange.200"
        : "blue.200";
  }

  @computed get activatorColor(): ColorProps["color"] {
    return this.appStore.timeTrackingStore.runningHours >= 24
      ? "red.800"
      : this.appStore.timeTrackingStore.runningHours >= 12
        ? "orange.800"
        : this.appStore.timeTrackingStore.runningEntry
          ? "blue.700"
          : "blue.600";
  }

  @computed get longRunningText(): string {
    return this.appStore.timeTrackingStore.runningHours >= 24
      ? t`Check your time because timer ran through the night.`
      : this.appStore.timeTrackingStore.runningHours >= 12
        ? t`Are you sure that this time entry is correct? It is more than 12 hours.`
        : "";
  }

  @action.bound
  togglePopover(value?: boolean) {
    this.popoverActive = value ?? !this.popoverActive;
  }

  @action
  setEntries(src: TimeTrackingPopoverItemsQuery) {
    const timeTrackingItems = src.timeTrackingItems.map((i) => ({
      ...i,
      tracked_for_date: new Date(i.tracked_for_date),
    }));

    const groups = groupBy(timeTrackingItems, "tracked_for_date");
    this.groupedEntries = keys(groups)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
      .map((i) => [i, groups[i]]);

    this.appStore.timeTrackingStore.runningEntry = src.runningTimer
      ?.timeTrackingItem
      ? {
          ...src.runningTimer.timeTrackingItem,
          tracked_for_date: new Date(
            src.runningTimer.timeTrackingItem.tracked_for_date,
          ),
        }
      : undefined;
  }
}
