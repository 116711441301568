import { Brand, Client } from "@src/__generated__/urql-graphql";

type ToOptionsParam = (Pick<Client, "id" | "name"> & {
  brands: Pick<Brand, "id" | "name">[];
})[];

const toOptions = (clients: ToOptionsParam) =>
  clients
    .filter((i) => i.brands.length)
    .map((i) => ({
      label: i.name,
      value: i.id,
      options: i.brands.map((k) => ({
        label: k.name,
        value: k.id,
      })),
    }));

export default { toOptions };
