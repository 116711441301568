import { Theme } from "@chakra-ui/react";
import { theme } from "@src/theme";
import { FunctionComponent } from "react";

interface EmojiProps {
  id: string;
  size?: `${keyof Theme["space"]}`;
}

export const Emoji: FunctionComponent<EmojiProps> = ({ id, size = "3" }) => {
  return <em-emoji id={id} size={theme["space"][size]} />;
};
