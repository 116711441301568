import {
  Box,
  BoxProps,
  Center,
  forwardRef,
  Spinner,
  SpinnerProps,
} from "@chakra-ui/react";
import { FC, ReactNode, Ref } from "react";

type LoadingOverlayProps = {
  isLoading: boolean;
  children?: ReactNode;
  position?: "fixed" | "absolute";
  transparent?: boolean;
  ref?: Ref<HTMLDivElement>;
  size?: SpinnerProps["size"];
  thickness?: SpinnerProps["thickness"];
} & BoxProps;

export const LoadingOverlay: FC<LoadingOverlayProps> = forwardRef(
  (
    {
      children,
      isLoading,
      position = "fixed",
      transparent = true,
      size = "xl",
      thickness = "3px",
      ...props
    },
    ref,
  ) => (
    <Box pos="relative" {...props} ref={ref}>
      <Center
        pos={position}
        zIndex="30"
        top="0"
        right="0"
        bottom="0"
        left="0"
        flex="1"
        w="full"
        h="full"
        bg={transparent ? "whiteAlpha.600" : "white"}
        visibility={isLoading ? "visible" : "hidden"}
      >
        <Spinner color="teal.500" size={size} thickness={thickness} />
      </Center>
      {children}
    </Box>
  ),
);
