import {
  Box,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import {
  useDeleteTaskCommentMutation,
  useToggleTaskCommentReactionMutation,
} from "@src/__generated__/urql-graphql";
import { Emoji } from "@src/components/ui-kit/Emoji";
import { EmojiPopover } from "@src/components/ui-kit/EmojiPopover";
import { Icon } from "@src/components/ui-kit/Icon";
import { TASK_ID_QUERY_KEY } from "@src/components/widgets/Modals/ModalCommunication/CommunicationModalHeader";
import { CommentProps } from "@src/components/widgets/Modals/ModalCommunication/components/comment/Comment";
import { CommentStoreCtx } from "@src/components/widgets/Modals/ModalCommunication/components/comment/CommentStore";
import { trackEvent } from "@src/services/amplitude";
import { downloadAttachments } from "@src/utils/downloadAttachments";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect } from "react";

const COMMON_REACTIONS = ["+1", "heart", "tada", "smile", "white_check_mark"];

interface FloatingCommentActionsMenuProps {
  onDeleted: CommentProps["onDeleted"];
}

export const FloatingCommentActionsMenu: FC<FloatingCommentActionsMenuProps> =
  observer(function FloatingCommentActionsMenu({ onDeleted }) {
    const store = useContext(CommentStoreCtx);
    const { taskDetailModalStore } = useStore();
    const toast = useToast();
    const { onCopy } = useClipboard(
      `${location.origin}/notifications?${TASK_ID_QUERY_KEY}=${taskDetailModalStore.taskId.value}&${store.COMMENT_ID_QUERY_KEY}=${store.comment.id}`,
    );

    const [_, toggleCommentReaction] = useToggleTaskCommentReactionMutation();

    const [removeResponse, remove] = useDeleteTaskCommentMutation();

    useEffect(() => {
      if (removeResponse.data?.deleteTaskComment) {
        onDeleted(removeResponse.data.deleteTaskComment.id);
      }
    }, [removeResponse]);

    const onDelete = async () => {
      await remove({ id: store.comment.id });
      trackEvent("task", "Deleted task comment");
    };

    const copyLinkToComment = () => {
      onCopy();
      toast({
        title: t`Link to comment copied to clipboard`,
        status: "info",
        isClosable: true,
        duration: 3000,
      });
    };

    const toggleReaction = (emoji_id: string) => {
      toggleCommentReaction({
        input: {
          task_comment_id: store.comment.id,
          emoji_id: emoji_id,
        },
      }).then(store.comment.setReactionsFromServer);
    };

    const downloadAllAttachments = () => {
      downloadAttachments(store.comment.files);
    };

    return (
      <Box pos="relative">
        <ButtonGroup
          bg="white"
          borderWidth="1px"
          borderColor="grey.200"
          _groupHover={{ visibility: "visible" }}
          visibility="hidden"
          colorScheme="grey"
          rounded="md"
          size="sm"
          spacing="0"
          variant="ghost"
        >
          {COMMON_REACTIONS.map((emoji_id) => (
            <IconButton
              key={emoji_id}
              aria-label={t`add reaction`}
              icon={<Emoji id={emoji_id} />}
              onClick={() => {
                toggleReaction(emoji_id);
              }}
            />
          ))}
          <EmojiPopover
            onEmojiSelect={({ id }) => toggleReaction(id)}
            trigger={
              <Tooltip
                label={t`Find another reaction`}
                placement="top"
                shouldWrapChildren
              >
                <IconButton
                  aria-label={t`add reaction`}
                  colorScheme="grey"
                  icon={<Icon name="face-happy" />}
                  size="sm"
                  variant="ghost"
                />
              </Tooltip>
            }
          />
          <Tooltip label={t`Copy link to comment`} placement="top">
            <IconButton
              aria-label={t`copy link to comment`}
              icon={<Icon name="link-03" />}
              onClick={copyLinkToComment}
            />
          </Tooltip>
          {store.canEdit || store.canDelete ? (
            <Menu>
              <Tooltip label={t`More actions`} placement="top">
                <MenuButton
                  as={IconButton}
                  icon={<Icon name="dots-horizontal" w="5" h="5" />}
                />
              </Tooltip>
              <MenuList>
                <MenuItem
                  icon={<Icon name="edit-05" w="5" h="5" color="grey.500" />}
                  isDisabled={!store.canEdit}
                  onClick={() => {
                    store.readOnly.off();
                    store.editorRef.current?.focus();
                    taskDetailModalStore.commentChanged = true;
                  }}
                >
                  <Trans>Edit</Trans>
                </MenuItem>
                <MenuItem
                  icon={
                    <Icon name="download-02" w="5" h="5" color="grey.500" />
                  }
                  onClick={downloadAllAttachments}
                >
                  <Trans>Download all attachments</Trans>
                </MenuItem>
                <MenuItem
                  icon={<Icon name="trash-03" w="5" h="5" color="grey.500" />}
                  isDisabled={!store.canDelete}
                  onClick={onDelete}
                >
                  <Trans>Delete</Trans>
                </MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <IconButton
              aria-label={t` Download all comment attachments`}
              icon={<Icon name="download-02" w="5" h="5" color="grey.500" />}
              onClick={downloadAllAttachments}
            />
          )}
        </ButtonGroup>
      </Box>
    );
  });
