import { Team } from "@src/__generated__/urql-graphql";

export type toTeamOptionsParams = {
  teams: Pick<Team, "id" | "name">[];
  modifier: (option: TeamOption) => TeamOption;
};

export type TeamOption = {
  label: Team["name"];
  value: Team["id"];
};

export const toTeamOptions = (
  teams: toTeamOptionsParams["teams"],
  modifier: toTeamOptionsParams["modifier"] = (option) => option,
): TeamOption[] =>
  teams.map(({ name, id }) =>
    modifier({
      label: name,
      value: id,
    }),
  );
