export const AUD = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    width="20"
    viewBox="0 0 512 512"
  >
    <g strokeWidth="1pt">
      <path fill="#006" d="M0 0h512v512H0z" />
      <path
        fill="#fff"
        d="M0 0v27.95L307.037 250h38.647v-27.95L38.647 0H0zm345.684 0v27.95L38.647 250H0v-27.95L307.037 0h38.647z"
      />
      <path
        fill="#fff"
        d="M144.035 0v250h57.614V0h-57.615zM0 83.333v83.333h345.684V83.333H0z"
      />
      <path
        fill="#c00"
        d="M0 100v50h345.684v-50H0zM155.558 0v250h34.568V0h-34.568zM0 250l115.228-83.334h25.765L25.765 250H0zM0 0l115.228 83.333H89.463L0 18.633V0zm204.69 83.333L319.92 0h25.764L230.456 83.333H204.69zM345.685 250l-115.228-83.334h25.765l89.464 64.7V250z"
      />
      <path
        fillRule="evenodd"
        fill="#fff"
        d="M54.886 368.64l40.665 15.4 13.32-41.403 13.32 41.402 40.666-15.4-24.06 36.223 37.395 22.197-43.326 3.767 5.97 43.082-29.963-31.528-29.96 31.527 5.968-43.083-43.327-3.767 37.397-22.197M403.807 476.413l-18.823 1.638 2.597 18.712-13.02-13.692-13.02 13.692 2.598-18.71-18.823-1.64 16.247-9.645-10.458-15.736 17.675 6.693 5.78-17.99 5.783 17.99 17.675-6.692-10.458 15.737m16.247-270.353l-18.823 1.638 2.597 18.71-13.02-13.69-13.02 13.69 2.598-18.71-18.823-1.638 16.247-9.645-10.458-15.737 17.675 6.69 5.78-17.985 5.783 17.986 17.675-6.69-10.458 15.736m-88.752 123.393l-18.823 1.638 2.597 18.71-13.02-13.69-13.02 13.69 2.598-18.71-18.823-1.638 16.247-9.646-10.458-15.736 17.675 6.692 5.783-17.99 5.78 17.99 17.676-6.692-10.46 15.735m214.57-18.352l-18.83 1.638 2.59 18.71-13.02-13.69-13.02 13.69 2.59-18.71-18.795-1.638 16.275-9.646-10.48-15.736 17.69 6.692 5.74-17.99 5.81 17.99 17.64-6.692-10.43 15.736m-64.33 82.494l-10.29 6.377 2.892-11.752-9.247-7.805 12.074-.89 4.57-11.206 4.57 11.207 12.076.89-9.247 7.804 2.89 11.753"
      />
    </g>
  </svg>
);
