import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { MultiMonthCalendar } from "@src/components/ui-kit/datePicker";
import { CalendarPicker } from "@src/components/ui-kit/datePicker/CalendarPicker";
import { now } from "@src/utils/dates";
import { Calendar } from "@uselessdev/datepicker";
import { toDate } from "date-fns";
import { observer, useLocalObservable } from "mobx-react-lite";
import { ReactNode } from "react";

interface ModalDatePickerProps extends UseModalProps {
  title: string | ReactNode;
  allowRange?: boolean;
  onConfirm: (range: {
    start: Date | undefined;
    end: Date | undefined;
  }) => void;
  confirmLabel?: string;
}

const ModalDatePicker = ({
  title,
  allowRange,
  isOpen,
  onClose,
  onConfirm,
  confirmLabel,
}: ModalDatePickerProps) => {
  const state = useLocalObservable(() => ({
    selected: {
      start: now() as Date | undefined,
      end: now() as Date | undefined,
    },
    onChange(value: Parameters<Calendar["onSelectDate"]>[0]) {
      if (typeof value === "number" || value instanceof Date) {
        this.selected = {
          start: toDate(value),
          end: undefined,
        };
        return;
      }
      this.selected = {
        start: value.start ? toDate(value.start) : undefined,
        end: value.end ? toDate(value.end) : undefined,
      };
    },
    datePicker: {
      month: now().getMonth(),
      year: now().getFullYear(),
    },
    setDatePicker(month: number, year: number) {
      this.datePicker.month = month;
      this.datePicker.year = year;
    },
    get confirmLabel() {
      return confirmLabel ?? t`Confirm`;
    },
  }));

  function onActionHandler() {
    onConfirm(state.selected);
    onClose();
  }

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => onClose()}
      size="2xl"
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Flex justify="center">
            {allowRange ? (
              <MultiMonthCalendar
                hideCalendarActions
                value={state.selected}
                onSelectDate={state.onChange}
              />
            ) : (
              <CalendarPicker
                value={state.selected}
                onSelectDate={state.onChange}
              />
            )}
          </Flex>
        </ModalBody>
        <ModalFooter gap="2">
          <Button onClick={() => onClose()} variant="outline">
            <Trans>Close</Trans>
          </Button>
          <Button onClick={() => onActionHandler()}>
            {state.confirmLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default observer(ModalDatePicker);
