import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const outline = defineStyle(({ theme }) => ({
  borderColor: "grey.200",
  _hover: {
    borderColor: "grey.300",
  },
  _focus: {
    zIndex: 1,
    borderColor: "purple.500",
    boxShadow: `0 0 0 1px ${theme["colors"]["purple"]["500"]}`,
  },
}));

const md = defineStyle({
  fontSize: "sm",
});

export const textareaTheme = defineStyleConfig({
  variants: { outline },
  sizes: { md },
});
