import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  SystemStyleObject,
} from "@chakra-ui/react";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";

const isNumber = (val: string): boolean => {
  return !isNaN(parseFloat(val));
};

const validateMaxValue = (val: string, maxValue?: number): boolean => {
  if (maxValue === undefined) return true;
  return +val <= maxValue;
};

const validateMinValue = (val: string, minValue?: number): boolean => {
  if (minValue === undefined) return true;
  return +val >= minValue;
};

const validateInnerValue = (N: number) => {
  let regex: RegExp;

  if (N === 0) {
    // eslint-disable-next-line prefer-regex-literals, regexp/prefer-d
    regex = new RegExp(`^[+-]?[0-9]+$`);
  } else {
    regex = new RegExp(
      `^(([+-]?[0-9]+[,.]?[0-9]{0,${N}})|([+-]?[,.][0-9]{0,${N}})|([+-]{1}))$`,
    );
  }

  return (val: string): boolean => {
    if (val === "") return true;
    return regex.test(val);
  };
};

const parsePastedString = (val: string, decimalDigits: number): string => {
  val = val.replace(/\s/g, ""); // Remove whitespaces
  const parsed = parseFloat(val);

  if (!isNaN(parsed)) return parsed.toFixed(decimalDigits);

  return "";
};

const DEFAULT_VALUE = "0";
const MIN_DECIMAL_DIGITS = 0;
const MAX_DECIMAL_DIGITS = 20;

interface NumberInputProps {
  id?: string;
  label?: string;
  placeholder?: string;
  value: string;
  minValue?: number;
  maxValue?: number;
  onChange: (val: string) => void;
  error?: string;
  decimalDigits?: number;
  currency?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  readOnly?: boolean;
  connectedRight?: React.ReactNode;
  prefixStyles?: SystemStyleObject;
  inputStyle?: SystemStyleObject;
  suffixStyle?: SystemStyleObject;
}

const NumberInput = (props: NumberInputProps) => {
  const {
    id,
    label,
    placeholder,
    value,
    maxValue,
    minValue,
    onChange,
    error,
    currency,
    prefix,
    suffix,
    onBlur,
    onFocus,
    disabled,
    readOnly,
    connectedRight,
    prefixStyles,
    inputStyle,
    suffixStyle,
  } = props;

  const { workspaceStore } = useStore();

  const [zIndex, setZIndex] = useState(0);

  let { decimalDigits = MAX_DECIMAL_DIGITS } = props;
  if (decimalDigits < MIN_DECIMAL_DIGITS) {
    decimalDigits = MIN_DECIMAL_DIGITS;
  } else if (decimalDigits > MAX_DECIMAL_DIGITS) {
    decimalDigits = MAX_DECIMAL_DIGITS;
  }

  const [innerValue, setInnerValue] = useState<string>(value);

  useEffect(() => {
    if (Number(innerValue) !== Number(value)) {
      setInnerValue(value);
    }
  }, [value]);

  const displayValue = useMemo<string>(() => {
    if (currency) {
      const parsed = parseFloat(innerValue);
      if (!isNaN(parsed)) {
        return parsed.toLocaleString("sk", {
          maximumFractionDigits: MAX_DECIMAL_DIGITS,
        });
      }
    }
    return innerValue;
  }, [innerValue]);

  const [usedComma, setUsedComma] = useState(false);
  const [focused, setFocused] = useState(false);
  const [pasted, setPasted] = useState(false);

  useLayoutEffect(() => {
    if (!focused) setUsedComma(true);
  }, [focused]);

  const innerOnChange = (_value: React.ChangeEvent<HTMLInputElement>) => {
    let value = _value.target.value;
    const validate = validateInnerValue(decimalDigits);

    // Float number separator always has to leave as a dot, not as a comma
    if (/,/.test(value)) {
      value = value.replace(/,/, ".");
      setUsedComma(true);
    } else {
      setUsedComma(false);
    }

    if (pasted) {
      setPasted(false);
      value = parsePastedString(value, decimalDigits);
    }

    // String is valid but not all cases
    // can be converted to number.
    // Single signs (+-,.) cannot be converted to number
    // but must be allowed in input
    if (validate(value)) {
      // String is valid number a has to be validate
      // with number validators.
      if (isNumber(value)) {
        if (
          validateMinValue(value, minValue) &&
          validateMaxValue(value, maxValue)
        ) {
          setInnerValue(value);
          onChange(value);
        }
      } else {
        setInnerValue(value);
      }
    }
  };

  const innerOnBlur = () => {
    setZIndex(0);
    setFocused(false);
    if (!isNumber(innerValue)) {
      onChange(DEFAULT_VALUE);
      setInnerValue(DEFAULT_VALUE);
    }
    onBlur?.();
  };

  const innerOnFocus = () => {
    setZIndex(2);
    setFocused(true);
    onFocus?.();
  };

  let _value = focused ? innerValue : displayValue;

  // Keep separator used by user
  // If it's dot view dot
  // If it's comma view comma
  if (usedComma) {
    _value = _value.replace(/\./, ",");
  }

  // If both suffix and currency are used
  // suffix is picked
  const _suffix =
    suffix ||
    (!currency
      ? undefined
      : workspaceStore.settings
        ? workspaceStore.settings.currency.code
        : "EUR");

  return (
    <div onPaste={() => setPasted(true)}>
      <FormControl isInvalid={!!error}>
        {label && <FormLabel>{label}</FormLabel>}
        <InputGroup>
          {connectedRight && <InputLeftAddon>{connectedRight}</InputLeftAddon>}
          {prefix && (
            <InputLeftElement sx={prefixStyles} h="9" pl="2">
              {prefix}
            </InputLeftElement>
          )}
          <Input
            sx={inputStyle}
            autoComplete="off"
            id={id}
            isDisabled={disabled || readOnly}
            isInvalid={!!error}
            onBlur={innerOnBlur}
            onChange={innerOnChange}
            onFocus={innerOnFocus}
            placeholder={placeholder}
            type="text"
            value={_value}
          />
          {_suffix && (
            <InputRightElement
              sx={suffixStyle}
              zIndex={zIndex}
              h="9"
              pr="2"
              /**
               * Workaround for conflict zIndex with MenuList
               * @see https://github.com/chakra-ui/chakra-ui/issues/5742
               */
            >
              {_suffix}
            </InputRightElement>
          )}
        </InputGroup>
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    </div>
  );
};

export default observer(NumberInput);
