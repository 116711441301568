import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  closeButton: {
    position: "absolute",
    top: 2,
    color: "grey.500",
  },
  body: {
    px: 6,
    py: 6,
  },
  header: {
    px: 6,
    py: 4,
    fontSize: "xl",
    fontWeight: "semibold",
    borderColor: "grey.100",
    borderBottomWidth: "1px",
  },
  footer: {
    px: 6,
    py: 4,
    borderColor: "grey.100",
    borderTopWidth: "1px",
  },
  dialog: {
    borderRadius: "xl",
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: "md",
  },
});
