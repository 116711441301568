import { ErrorScreen } from "@components/ui-kit";
import { ContentContainer } from "@components/widgets/Layout/ContentContainer";
import { t } from "@lingui/macro";

const UnexpectedError = () => (
  <ContentContainer>
    <div className="h-screen">
      <ErrorScreen
        title={t`I have noticed something. Do not worry I have already reported this issue to the right place. Until it will be fixed, try to reload the homepage.`}
        link={{
          text: t`See my homepage`,
          href: "/",
        }}
      />
    </div>
  </ContentContainer>
);

export { UnexpectedError };
