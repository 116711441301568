import { TimeOffType } from "@src/__generated__/urql-graphql";

export type toTimeOffTypeOptionsParams = {
  timeOffTypes: Pick<TimeOffType, "id" | "name">[];
  modifier: (option: TimeOffTypeOption) => TimeOffTypeOption;
};

export type TimeOffTypeOption = {
  label: TimeOffType["name"];
  value: TimeOffType["id"];
};

export const toTimeOffTypeOptions = (
  timeOffTypes: toTimeOffTypeOptionsParams["timeOffTypes"],
  modifier: toTimeOffTypeOptionsParams["modifier"] = (option) => option,
): TimeOffTypeOption[] =>
  timeOffTypes.map((i) =>
    modifier({
      label: i.name,
      value: i.id,
    }),
  );
