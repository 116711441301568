import { FieldState, FormState } from "formstate";
import { makeObservable, observable } from "mobx";

export class AuthTwoFactorChallengeState {
  @observable _token = "";
  @observable redirect_to = "";
  readonly code = new FieldState<string>("");

  readonly form = new FormState({
    code: this.code,
  });

  constructor() {
    makeObservable(this);
  }
}
