import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { required } from "@src/utils/forms/validators";
import { FieldState, FormState } from "formstate";
import { makeObservable, observable } from "mobx";
import { IOption } from "../Select";

export class ProjectCategorySelectStore implements BaseStore {
  appStore: AppStore;
  categoryIds: FieldState<string[]> = new FieldState<string[]>([]).validators(
    required,
  );
  @observable categories: IOption[] = [];
  @observable categoryForm = new FormState({
    name: new FieldState("").validators(required),
  });

  constructor(appStore: AppStore) {
    makeObservable(this);

    this.appStore = appStore;
  }
}
