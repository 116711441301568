import { Heading, HStack, Stack, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { UtilizationReportDataSource } from "@src/__generated__/urql-graphql";
import { DataTable } from "@src/components/ui-kit";
import {
  ProgressBar,
  TProgressBarSegment,
} from "@src/components/ui-kit/NewProgressBar";
import {
  HUNDRED_PERCENT,
  percent as percentFormatter,
} from "@src/utils/formatters";
import { useStore } from "@src/utils/hooks";
import { formatSecondsToHours } from "@src/utils/time";
import { differenceInMonths } from "date-fns";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { now } from "mobx-utils";
import { FC } from "react";

export type UtilizationStatCardProps = {
  trackedTime: number | undefined;
  allocatedTime: number | undefined;
  combinedTime: number | undefined;
  budgetedTime: number | undefined;
  totalTime: number | undefined;
  month: Date;
  utilizationRange: { from: number; to: number } | undefined;
  percent: number | undefined;
};

export const UtilizationStatCard: FC<UtilizationStatCardProps> = observer(
  function UtilizationStatCard({
    trackedTime = 0,
    combinedTime = 0,
    allocatedTime = 0,
    totalTime = 0,
    budgetedTime = 0,
    month,
    utilizationRange = { from: 80, to: 100 },
    percent = 0,
  }) {
    const { utilizationReportStore: store } = useStore();

    const isDateInPast = computed(() => {
      return differenceInMonths(month, now()) < 0;
    }).get();

    const billable = computed(() => {
      let total = 0;

      switch (store.activeDataSourceOption?.value) {
        case UtilizationReportDataSource.TimeTracking:
          total = trackedTime;
          break;
        case UtilizationReportDataSource.Planning:
          total = allocatedTime;
          break;
        case UtilizationReportDataSource.Combined:
          total = combinedTime;
          break;
        case UtilizationReportDataSource.TaskPositions:
          total = budgetedTime;
          break;
      }

      return total;
    }).get();

    const segments: Array<TProgressBarSegment> = computed(() => {
      const segments: Array<TProgressBarSegment> = [];
      const fixedPercent = percent * 100;

      if (fixedPercent > HUNDRED_PERCENT) {
        const diff = fixedPercent - HUNDRED_PERCENT;
        segments.push({ bg: "red.500", percent: fixedPercent - diff });
        segments.push({
          bg: "repeating-linear-gradient(-65deg, #FFB2B7, #DD3943 2px, #FFB2B7 4px)",
          percent: diff,
        });
      } else {
        const dividedPercent = fixedPercent * 0.01;
        segments.push({
          bg:
            dividedPercent > utilizationRange.from &&
            dividedPercent < utilizationRange.to
              ? "teal.500"
              : "red.500",
          percent: fixedPercent,
        });
      }

      return segments;
    }).get();

    const unusedTime = computed(() => {
      return totalTime - billable;
    }).get();

    return (
      <DataTable.StatCard>
        <Stack>
          <HStack justify="space-between" mb="2">
            <Heading size="sm">
              <Trans>Utilization</Trans>
            </Heading>
            <Heading size="sm">
              {percentFormatter.format(percent * 100)}
            </Heading>
          </HStack>
          <ProgressBar segments={segments} />
          <HStack>
            <Text color="grey.600" fontWeight="medium" size="sm">
              {isDateInPast ? (
                <Trans>Unused hours:</Trans>
              ) : (
                <Trans>Left to allocate:</Trans>
              )}
            </Text>
            <Text size="sm">{formatSecondsToHours(unusedTime, 1)}</Text>
          </HStack>
        </Stack>
      </DataTable.StatCard>
    );
  },
);
