import * as amplitude from "@amplitude/analytics-browser";
import { appStore } from "@src/stores/AppStore";
import { getHighestRole } from "@src/utils/roles";
import { capitalize } from "lodash";

type TAmplitudeEventType = "page-load" | "login" | "time-entry" | "task";

const amplitudeKey = process.env.AMPLITUDE_API_KEY;
let amplitudeInitialized = false;
let amplitudeIdentified = false;

/**
 * @param type
 * @param description - optional text to further describe event
 */
export const trackEvent = (type: TAmplitudeEventType, description?: string) => {
  if (!amplitudeKey) return;
  if (!amplitudeInitialized) {
    amplitude.init(amplitudeKey);
    amplitudeInitialized = true;
  }
  if (!amplitudeIdentified) {
    try {
      if (appStore.authStore.user) {
        const identify = new amplitude.Identify();
        const { full_name, roles, email } = appStore.authStore.user;
        const workspaceTitle = appStore.workspaceStore.settings?.title;

        identify.set("user_name", full_name);
        identify.set("email", email);
        identify.set("role", getHighestRole(roles));
        workspaceTitle && identify.set("workspace", workspaceTitle);

        amplitude.identify(identify);
        amplitudeIdentified = true;
      }
    } catch (e) {
      console.error(e);
    }
  }

  const eventName = `[${capitalize(type.replace("-", " "))}]${description ? " " + description : ""}`;
  amplitude.track(eventName);
};
