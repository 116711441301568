import { Box, BoxProps, StyleProps } from "@chakra-ui/react";
import { TaskStatus } from "@src/__generated__/urql-graphql";
import { FunctionComponent } from "react";

type TaskStatusBadgeProps = Pick<
  TaskStatus,
  "name" | "background_color" | "foreground_color"
> &
  BoxProps & {
    badgeWidth?: StyleProps["width"];
  };

export const TaskStatusBadge: FunctionComponent<TaskStatusBadgeProps> = ({
  background_color,
  foreground_color,
  name,
  badgeWidth,
  ...props
}) => {
  return (
    <Box w={badgeWidth ?? "max-content"}>
      <Box
        overflow="hidden"
        w={badgeWidth ?? "max-content"}
        maxW="max-content"
        px="2"
        py="1"
        color={foreground_color}
        fontFamily="heading"
        fontSize="xs"
        fontWeight="medium"
        bg={background_color}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        rounded="base"
        {...props}
      >
        {name}
      </Box>
    </Box>
  );
};
