// TODO: move these functions somewhere else
import { Trans, t } from "@lingui/macro";
export const now = (): Date => new Date(Date.now());
export const range = (date?: Date) => ({
  start: date ?? now(),
  end: date ?? now(),
});

import {
  Box,
  Button,
  Center,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { Icon } from "@src/components/ui-kit/Icon";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import { useStore } from "@src/utils/hooks";
import { DateRange as Range } from "@src/utils/types";
import {
  Calendar,
  CalendarControls,
  CalendarDate,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarValues,
  CalendarWeek,
} from "@uselessdev/datepicker";
import { observer } from "mobx-react-lite";
import { ReactNode, useRef } from "react";

interface DatePickerProps {
  selected?: Date | CalendarValues | undefined;
  onChange: (val: Range | undefined) => void;
  disableDatesBefore?: Date;
  disableDatesAfter?: Date;
  weekDateSelection?: boolean;
  trigger: ReactNode;
  usePortal?: boolean;
  isClearable?: boolean;
  clearButtonLabel?: string;
}

export const DatePicker = observer(function DatePicker({
  onChange,
  selected,
  disableDatesBefore,
  disableDatesAfter,
  weekDateSelection,
  trigger,
  usePortal,
  isClearable,
  clearButtonLabel,
}: DatePickerProps) {
  const { workspaceStore } = useStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const calendarRef = useRef(null);

  const handleDateSelect = (value: CalendarValues | CalendarDate) => {
    if (value instanceof Date) {
      // TODO: rewrite old Range format to single Date
      const oldValueFormat: Range = { start: value, end: value };
      onChange(oldValueFormat);
      onClose();
    } else {
      onChange(value as Range);
      onClose();
    }
  };

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });

  return (
    <div
      // Event.stopPropagation() to prevent closing table filter dropdown on date select
      onClick={(e) => e.stopPropagation()}
    >
      <Popover
        initialFocusRef={initialRef}
        isLazy
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <Box ref={initialRef} onClick={onOpen}>
            {trigger}
          </Box>
        </PopoverTrigger>

        <WrapComponent
          if={!!usePortal}
          with={(children) => <Portal>{children}</Portal>}
        >
          <PopoverContent
            ref={calendarRef}
            w="min-content"
            p={0}
            color="black"
            border="none"
            shadow="xl"
            _focus={{ boxShadow: "none" }}
            outline="none"
            rounded="xl"
          >
            <Calendar
              value={
                selected instanceof Date || typeof selected === undefined
                  ? { start: selected as Date | undefined }
                  : (selected as CalendarValues)
              }
              onSelectDate={handleDateSelect}
              weekDateSelection={weekDateSelection}
              singleDateSelection={!weekDateSelection}
              highlightToday
              weekStartsOn={workspaceStore.settings?.startOfWeekNumber}
              allowOutsideDays
              disableFutureDates={disableDatesAfter}
              disablePastDates={disableDatesBefore}
            >
              <PopoverBody p={0}>
                <CalendarControls>
                  <CalendarPrevButton
                    as={(props) => (
                      <IconButton
                        h="7"
                        aria-label={t`Prev month`}
                        colorScheme="grey"
                        icon={<Icon name="arrow-narrow-left" w="5" h="5" />}
                        variant="subtle"
                        {...props}
                      />
                    )}
                  />
                  <CalendarNextButton
                    as={(props) => (
                      <IconButton
                        h="7"
                        aria-label={t`Next month`}
                        colorScheme="grey"
                        icon={<Icon name="arrow-narrow-right" w="5" h="5" />}
                        variant="subtle"
                        {...props}
                      />
                    )}
                  />
                </CalendarControls>

                <CalendarMonths>
                  <CalendarMonth>
                    <CalendarMonthName />
                    <CalendarWeek />
                    <CalendarDays />
                  </CalendarMonth>
                </CalendarMonths>
                {isClearable && (
                  <Center mb="4">
                    <Button
                      onClick={() => {
                        onChange(undefined);
                      }}
                      variant="outline"
                    >
                      {clearButtonLabel ?? <Trans>Clear</Trans>}
                    </Button>
                  </Center>
                )}
              </PopoverBody>
            </Calendar>
          </PopoverContent>
        </WrapComponent>
      </Popover>
    </div>
  );
});
