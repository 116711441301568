import { CommentFragment } from "@src/__generated__/graphql";
import {
  Exact,
  ToggleTaskCommentReactionInput,
  ToggleTaskCommentReactionMutation,
} from "@src/__generated__/urql-graphql";
import { groupBy } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { OperationResult } from "urql";

export class CommentModel {
  id: CommentFragment["id"];
  user: CommentFragment["user"];
  @observable.ref reactions: CommentFragment["reactions"];
  body: CommentFragment["body"];
  timestamp: CommentFragment["timestamp"];
  has_edits: CommentFragment["has_edits"];
  files: CommentFragment["files"];

  constructor(src: CommentFragment) {
    makeObservable(this);
    this.id = src.id;
    this.user = src.user;
    this.reactions = src.reactions;
    this.body = src.body;
    this.timestamp = src.timestamp;
    this.has_edits = src.has_edits;
    this.files = src.files;
  }

  @computed get reactionsByEmoji(): Record<
    string,
    CommentFragment["reactions"]
  > {
    return groupBy(this.reactions, "emoji_id");
  }

  @action.bound setReactionsFromServer(
    response: OperationResult<
      ToggleTaskCommentReactionMutation,
      Exact<{ input: ToggleTaskCommentReactionInput }>
    >,
  ): void {
    if (response.data?.toggleTaskCommentReaction) {
      this.reactions = response.data.toggleTaskCommentReaction;
    }
  }
}
