import { ErrorScreen } from "@components/ui-kit";
import { t } from "@lingui/macro";

export const NotFoundExpense = () => (
  <ErrorScreen
    title={t`I didn’t find the expense you are looking for.`}
    link={{
      text: t`See all expenses`,
      href: "/expenses",
    }}
  />
);
