import {
  As,
  Menu,
  MenuButton,
  MenuItemProps,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { runIfFn } from "@chakra-ui/utils";
import {
  CollapsiblePanelItem,
  useCollapsiblePanel,
} from "@src/components/ui-kit/CollapsiblePanel/CollapsiblePanel";
import { ReactElement, useMemo } from "react";
export type OverflowMenuProps<T> = {
  children: (props: {
    isOpen: boolean;
    collapsedItems: CollapsiblePanelItem<T>[];
  }) => ReactElement<MenuItemProps>[];
  menuButton: As;
};

export const OverflowMenu = function OverflowMenu<T>({
  children,
  menuButton,
}: OverflowMenuProps<T>) {
  const { visibilityMap, collapsedItems } = useCollapsiblePanel<T>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const shouldShowMenu = useMemo(
    () => Object.values(visibilityMap).some((v) => v === false),
    [visibilityMap],
  );

  if (!shouldShowMenu) {
    return null;
  }

  return (
    <Menu
      closeOnSelect={false}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      strategy="fixed"
    >
      <MenuButton as={menuButton} />
      <MenuList>{runIfFn(children, { isOpen, collapsedItems })}</MenuList>
    </Menu>
  );
};
