import { HStack, StackProps } from "@chakra-ui/react";
import cs from "classnames";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { BackButton } from "./BackButton";
import Item from "./Item";

interface NavBarProps extends StackProps {
  fullWidth?: boolean;

  /**
   * Height of container
   *
   * 'default' = h-24
   * Default value: 'default'
   */
  size?: "default" | null;

  className?: string;
  style?: React.CSSProperties;
}

const NavBar: FunctionComponent<PropsWithChildren<NavBarProps>> & {
  Item: typeof Item;
  BackButton: typeof BackButton;
} = ({
  fullWidth,
  size = "default",
  className,
  style,
  children,
  ...props
}: PropsWithChildren<NavBarProps>) => (
  <HStack
    className={cs(
      {
        "h-24": size === "default",
      },
      className,
    )}
    w={fullWidth ? "full" : "auto"}
    spacing={2}
    style={style}
    {...props}
  >
    {children}
  </HStack>
);

NavBar.Item = Item;
NavBar.BackButton = BackButton;

export { NavBar };
