import { t } from "@lingui/macro";

export const AppName = "Allfred";

export const ApiDateFormat = "yyyy-MM-dd";
export const ApiDateTimeFormat = "yyyy-MM-dd HH:mm:ss";

export const getMonths = () => [
  t`January`,
  t`February`,
  t`March`,
  t`April`,
  t`May`,
  t`June`,
  t`July`,
  t`August`,
  t`September`,
  t`October`,
  t`November`,
  t`December`,
];

const [subdomain, domainName, tld] =
  typeof location !== "undefined"
    ? location.hostname.replace("-local", "").split(".")
    : [];

export const API_BASE_URL = `https://${subdomain}-api.${domainName}.${tld}`;
export const BUDGETOVAC_BASE_URL = `https://${subdomain}-budgetovac.${domainName}.${tld}/`;
export const BUDGETOVAC_API_URL = `https://${subdomain}-budgetovac-api.${domainName}.${tld}/api`;
const PLANOVAC_BASE_URL = `https://${subdomain}-planovac.${domainName}.${tld}/`;

export const API_URL = `${API_BASE_URL}/api`;
export const planovacUrl = PLANOVAC_BASE_URL;
