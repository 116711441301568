import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBoolean,
  UseModalProps,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { DateRange as Range } from "@src/utils/types";
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect, useState } from "react";
import { InputDatePicker } from "../../InputDatePicker";
import { InputRangeDatePicker } from "../../InputRangeDatePicker";

interface ModalInputDatePickerProps extends UseModalProps {
  title?: ReactNode;
  confirmLabel?: string;
  onConfirm: (value: Range) => Promise<unknown>;
  allowRange?: boolean;
}

const ModalInputDatePicker = ({
  title,
  confirmLabel = t`Confirm`,
  onConfirm,
  allowRange,
  isOpen,
  onClose,
}: ModalInputDatePickerProps) => {
  const [isLoading, setLoading] = useBoolean(false);
  const [range, setRange] = useState<Range>();

  useEffect(() => {
    if (isOpen) return;
    setRange(undefined);
  }, [isOpen]);

  const _onConfirm = async () => {
    if (!range) return;
    setLoading.on();
    await onConfirm(range);
    setLoading.off();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {allowRange ? (
            <InputRangeDatePicker requiredFields onSelect={setRange} />
          ) : (
            <InputDatePicker onChange={setRange} />
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup spacing="3">
            <Button colorScheme="grey" onClick={onClose} variant="outline">
              <Trans>Close</Trans>
            </Button>
            <Button
              isDisabled={!range}
              isLoading={isLoading}
              onClick={_onConfirm}
            >
              {confirmLabel}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default observer(ModalInputDatePicker);
