import {
  Badge,
  Divider,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { ExpenseBudgetItemStats } from "@src/__generated__/urql-graphql";
import { ProgressBarExpensesReceived } from "@src/components/ui-kit";
import { HUNDRED_PERCENT, currency, percent } from "@src/utils/formatters";
import { observer } from "mobx-react-lite";
import { FC, Fragment, PropsWithChildren, ReactNode } from "react";

type TExpenseStats = Pick<
  ExpenseBudgetItemStats,
  | "credit"
  | "real_expenses"
  | "real_expenses_percent"
  | "real_commission"
  | "real_commission_relative"
  | "planned_expenses"
  | "planned_commission"
  | "planned_commission_relative_computed"
  | "planned_profit"
  | "total_budget"
> & {
  profit?: number;
  profit_percent?: number;
};

export interface ExpensesProps {
  stats: TExpenseStats;
  showFinalCommission?: boolean;
  isCompleted?: boolean;
  hidePopover?: boolean;
  showActualProfit?: boolean;
  actualProfit?: number;
  section?: ExpenseBarSection;
  isTimeAndMaterials?: boolean;
  onSectionHover?: (section: ExpenseBarSection) => void;
}

export type ExpenseBarSection =
  | "received"
  | "expected"
  | "profit"
  | "final_profit"
  | undefined;

const PopoverRow: FC<PropsWithChildren<{ title: string }>> = ({
  children,
  title,
}) => {
  return (
    <VStack alignItems="flex-start" px="6">
      <Text fontWeight="medium">{title}</Text>
      <HStack mt="1" spacing="2">
        {children}
      </HStack>
    </VStack>
  );
};

const DarkPopover: FC<
  PropsWithChildren<{ isOpen?: boolean; trigger: ReactNode }>
> = ({ isOpen, children, trigger }) => {
  return (
    <Popover
      closeDelay={150}
      gutter={24}
      isOpen={isOpen}
      openDelay={150}
      trigger="hover"
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <Portal>
        <PopoverContent borderColor="none">
          <PopoverBody
            px="0"
            py="5"
            color="whiteAlpha.900"
            fontSize="sm"
            fontWeight="medium"
            bg="gray.700"
            rounded="md"
          >
            {children}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export const ExpensesPopover: FC<
  PropsWithChildren<{
    isOpen?: boolean;
    received_expenses: number;
    received_expenses_percent: number;
    real_commission: number;
    real_commission_relative: string;
  }> &
    Pick<
      ExpensesProps,
      | "showFinalCommission"
      | "actualProfit"
      | "showActualProfit"
      | "isTimeAndMaterials"
    >
> = ({
  children,
  isOpen,
  actualProfit,
  showActualProfit,
  showFinalCommission,
  received_expenses,
  received_expenses_percent,
  real_commission,
  real_commission_relative,
  isTimeAndMaterials,
}) => {
  return (
    <DarkPopover trigger={children} isOpen={isOpen}>
      <VStack align="start" spacing="4">
        <PopoverRow
          title={
            isTimeAndMaterials
              ? t`Received expenses`
              : t`Received expenses (vs Buy price)`
          }
        >
          <Text fontWeight="bold">
            {currency.formatByWorkspace(received_expenses)}
          </Text>
          {!isTimeAndMaterials && (
            <Badge bg="teal.300">
              {percent.format(received_expenses_percent)}
            </Badge>
          )}
        </PopoverRow>
      </VStack>
      {/* !project?.is_pro_bono && ( */}
      <Fragment>
        {showFinalCommission && (
          <Fragment>
            <Divider
              h="1"
              my="4"
              borderWidth="1"
              borderStyle="dashed"
              borderColor="gray.500"
              orientation="horizontal"
            />

            <PopoverRow title={t`Profit (Expenses)`}>
              <Text fontWeight="semibold">
                {currency.formatByWorkspace(real_commission)}
              </Text>
              <Badge>{percent.format(real_commission_relative)}</Badge>
            </PopoverRow>
          </Fragment>
        )}
        {showActualProfit && (
          <Fragment>
            <Divider
              h="1"
              my="4"
              borderWidth="1"
              borderStyle="dashed"
              borderColor="gray.500"
              orientation="horizontal"
            />
            <PopoverRow title={t`Actual profit (Expenses)`}>
              <Text fontWeight="semibold">
                {currency.formatByWorkspace(actualProfit ?? 0)}
              </Text>
            </PopoverRow>
          </Fragment>
        )}
      </Fragment>
      {/* )} */}
    </DarkPopover>
  );
};

export const ExpensesToReceivePopover: FC<
  PropsWithChildren<{
    amount: number;
    percent: number;
    isTimeAndMaterials?: boolean;
  }>
> = ({ children, amount, percent: percentAmount, isTimeAndMaterials }) => {
  return (
    <DarkPopover trigger={children}>
      <PopoverRow
        title={
          isTimeAndMaterials
            ? t`Expenses to receive`
            : t`Expenses to receive (vs Buy price)`
        }
      >
        <Text fontWeight="bold">
          {currency.formatByWorkspace(amount > 0 ? amount : 0)}
        </Text>
        {!isTimeAndMaterials && <Badge>{percent.format(percentAmount)}</Badge>}
      </PopoverRow>
    </DarkPopover>
  );
};

export const ProfitEstimatePopover: FC<
  PropsWithChildren<{
    planned_profit: number;
    total_budget: number;
    isOpen?: boolean;
    isFinished?: boolean;
    final_profit?: number;
    final_profit_percent?: number;
  }>
> = ({
  children,
  planned_profit,
  total_budget,
  isOpen,
  isFinished,
  final_profit,
  final_profit_percent,
}) => {
  return (
    <DarkPopover trigger={children} isOpen={isOpen}>
      {isFinished && !!final_profit && !!final_profit_percent ? (
        <PopoverRow title={t`Final profit`}>
          <Text fontWeight="bold">
            {currency.formatByWorkspace(final_profit)}
          </Text>
          <Badge>{percent.format(final_profit_percent)}</Badge>
        </PopoverRow>
      ) : (
        <PopoverRow title={t`Profit estimate (vs Total Price)`}>
          <Text fontWeight="bold">
            {currency.formatByWorkspace(planned_profit)}
          </Text>
          <Badge>
            {percent.format((planned_profit / total_budget) * HUNDRED_PERCENT)}
          </Badge>
        </PopoverRow>
      )}
    </DarkPopover>
  );
};

const Expenses: FC<ExpensesProps> = ({
  stats,
  hidePopover,
  showFinalCommission,
  showActualProfit,
  actualProfit,
  section,
  onSectionHover,
  isCompleted,
  isTimeAndMaterials,
}) => {
  return (
    <ProgressBarExpensesReceived
      variant="big"
      stats={stats}
      actualProfit={actualProfit}
      section={section}
      onSectionHover={onSectionHover}
      showActualProfit={showActualProfit}
      showFinalCommission={showFinalCommission}
      isCompleted={isCompleted}
      isOpen={hidePopover ? !hidePopover : undefined}
      isTimeAndMaterials={isTimeAndMaterials}
    />
  );
};

export default observer(Expenses);
