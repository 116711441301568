import { Button } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { useAddRowToWeekTimeReportMutation } from "@src/__generated__/graphql";
import { onError } from "@src/utils/apollo";
import { observer } from "mobx-react-lite";
import { EntryForm } from "../../form";

interface SaveRowButtonProps {
  userId: string;
  form: EntryForm;
  onSave: () => void;
}

const SaveRowButton = ({ userId, form, onSave }: SaveRowButtonProps) => {
  const [addNewRow, { loading }] = useAddRowToWeekTimeReportMutation({
    onCompleted() {
      onSave();
    },
    ...onError(),
  });

  const onClick = async () => {
    const { hasError } = await form.validate();

    if (hasError) {
      return;
    }

    addNewRow({ variables: form.serializeCreate(userId) });
  };

  return (
    <Button isLoading={loading} onClick={onClick}>
      {t`Save row`}
    </Button>
  );
};

export default observer(SaveRowButton);
