import { Box, Button, HStack, useDisclosure } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { fieldToSelectProps } from "@src/utils/forms/inputHelpers";
import { UserSelect } from "@src/widgets/UserSelect";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store";
import ModalEntry from "../ModalEntry";
import DateFilter from "./DateFilter";
import NewRowButton from "./NewRowButton";

const NavBar = () => {
  const store = useStore();

  const dayEntryModal = useDisclosure();
  const weekEntryModal = useDisclosure();

  return (
    <HStack
      justify="space-between"
      mb="4"
      px="4"
      py="3"
      borderColor="grey.100"
      borderBottomWidth="1px"
    >
      <DateFilter stepper={store.stepper} />
      <HStack spacing="3">
        {store.canReadUsers && (
          <Box minW="230px">
            <UserSelect
              {...fieldToSelectProps(store.userId, store.userOptions)}
              isLoading={store.usersLoading}
            />
          </Box>
        )}
        {store.weekMode ? (
          <NewRowButton
            disabled={!store.canCreateEntry}
            onClick={weekEntryModal.onOpen}
          />
        ) : (
          <Button
            disabled={!store.canCreateEntry}
            leftIcon={<Icon name="plus" />}
            onClick={dayEntryModal.onOpen}
          >
            <Trans>New entry</Trans>
          </Button>
        )}
      </HStack>

      <ModalEntry
        {...dayEntryModal}
        forDate={store.stepper.current}
        userId={store.currentUser?.id!}
        onSaveCompleted={store.timeTrackingItemsRefetch}
      />

      <ModalEntry
        {...weekEntryModal}
        forDate={store.stepper.current}
        userId={store.currentUser?.id!}
        onSaveCompleted={store.weekTimeReportRefetch}
        newWeekEntryMode
      />
    </HStack>
  );
};

export default observer(NavBar);
