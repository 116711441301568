import borders from "./borders";
import colors from "./colors";
import radii from "./radius";
import sizes from "./sizes";
import { spacing } from "./spacing";
import typography from "./typography";

const foundations = {
  radii,
  colors,
  ...typography,
  sizes,
  space: spacing,
  borders,
  breakpoints: {
    "1.5xl": "82em",
  },
};

export default foundations;
