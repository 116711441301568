import { Flex, useDisclosure } from "@chakra-ui/react";
import {
  TimeTrackingItem,
  useTimeTrackingItemQuery,
} from "@src/__generated__/urql-graphql";
import { toApiDate } from "@src/utils/dates";
import cs from "classnames";
import { isSameDay } from "date-fns";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { Store, useStore } from "../store";
import {
  DayEntry,
  EmptyEntries,
  LoadingEntries,
  TotalDayDuration,
} from "./Entries";
import s from "./Entries/DayEntry.module.css";
import ModalEntry from "./ModalEntry";
import { DayItem, WeekTotal } from "./SummaryPerDay";

export const TIME_TRACKING_ID_QUERY_KEY = "timeTrackingId";

const DayMode = () => {
  const store = useStore();
  const { query } = useRouter();
  const editEntryModal = useDisclosure();
  const [editItem, setEditItem] = useState<Store["entries"][0]>();

  useEffect(() => {
    if (!query) return;
    const timeTrackingId = query[
      TIME_TRACKING_ID_QUERY_KEY
    ] as TimeTrackingItem["id"];

    if (!timeTrackingId) return;
    if (!timeTrackingId?.length) return;

    fetchTimeTrackingItem();
  }, [query]);

  const [{ data, fetching }, fetchTimeTrackingItem] = useTimeTrackingItemQuery({
    pause: true,
    variables: {
      id: query[TIME_TRACKING_ID_QUERY_KEY] as TimeTrackingItem["id"],
    },
  });

  useEffect(() => {
    if (!data) return;
    const { tracked_for_date } = data.timeTrackingItem;

    store.stepper.setCurrent(new Date(tracked_for_date));
    setEditItem({
      ...data.timeTrackingItem,
      tracked_for_date: toApiDate(tracked_for_date),
    });
    editEntryModal.onOpen();
  }, [data]);

  return (
    <React.Fragment>
      <Flex mb="8" px="4">
        {store.summaryPerDay.map((i) => (
          <DayItem
            key={i.date.toLocaleString()}
            item={i}
            onSelect={store.stepper.setCurrent}
            selected={isSameDay(store.stepper.current, new Date(i.date))}
            minWidth="12rem"
          />
        ))}
        <WeekTotal items={store.summaryPerDay} />
      </Flex>
      {((store.timeTrackingItemsLoading || fetching) && <LoadingEntries />) || (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment>
          {!store.currentEntries.length ? (
            <EmptyEntries />
          ) : (
            <React.Fragment>
              <div className="divide-y divide-gray-200">
                {store.currentEntries.map((i) => (
                  <DayEntry
                    key={i.id}
                    item={i}
                    onEdit={() => {
                      setEditItem(i);
                      editEntryModal.onOpen();
                    }}
                  />
                ))}
              </div>
              <div className={cs(s.EntryItemWrapper, "py-10 border-t")}>
                <div />
                <TotalDayDuration items={store.currentEntries} />
                <div />
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <ModalEntry
        item={editItem}
        userId={store.currentUser?.id!}
        onSaveCompleted={store.timeTrackingItemsRefetch}
        {...editEntryModal}
      />
    </React.Fragment>
  );
};

export default observer(DayMode);
