import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Square,
  useDisclosure,
} from "@chakra-ui/react";
import { FC } from "react";
import { HexColorPicker } from "react-colorful";

type ColorPickerProps = {
  onChange: (color: string) => void;
  isDisabled?: boolean;
  color: string;
  title?: string;
};

export const PRESET_COLORS = [
  "#a3252b",
  "#d13338",
  "#f5630b",
  "#fde101",
  "#8d552c",
  "#02cb69",
  "#0e7b0f",
  "#038186",
  "#0098bb",
  "#0077d3",
  "#0026b3",
  "#5b2d90",
  "#b045c1",
  "#be0176",
  "#69797d",
  "#6ab6f1",
];

export const ColorPicker: FC<ColorPickerProps> = ({
  onChange,
  color,
  isDisabled,
  title,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      onOpen={() => {
        if (isDisabled) return;
        onOpen();
      }}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <Box
          as="button"
          w="16"
          h="8"
          bg={color}
          borderWidth="2px"
          borderColor="grey.100"
          pointerEvents={isDisabled ? "none" : "auto"}
          cursor={isDisabled ? "not-allowed" : "pointer"}
          rounded="md"
          title={title}
        />
      </PopoverTrigger>
      <PopoverContent maxW="240">
        <PopoverBody>
          <SimpleGrid
            mb="3"
            columns={Math.round(PRESET_COLORS.length / 3)}
            spacing="2"
          >
            {PRESET_COLORS.map((presetColor) => (
              <Square
                key={presetColor}
                as="button"
                bg={presetColor}
                onClick={() => onChange(presetColor)}
                rounded="md"
                size="6"
              />
            ))}
          </SimpleGrid>

          <HexColorPicker
            style={{ width: "100%" }}
            color={color}
            onChange={onChange}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
