import { Box, BoxProps } from "@chakra-ui/react";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import { Attachment } from "@src/components/ui-kit/TextEditor/extensions/UploadAttachmentPlugin";
import {
  UseTextEditorProps,
  useTextEditor,
} from "@src/components/ui-kit/TextEditor/hooks/useTextEditor";
import { Toolbar } from "@src/components/ui-kit/TextEditor/toolbar/Toolbar";
import { OptionFunctionReturnType } from "@src/utils/types";
import { Editor } from "@tiptap/core";
import { EditorContent } from "@tiptap/react";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

export const EMPTY_EDITOR_LENGTH = 7;

export type TextEditorProps = {
  showToolbar?: boolean;
  attachments: Attachment[];
} & UseTextEditorProps &
  Omit<BoxProps, "onChange" | "onBlur" | "onFocus">;

export type TextEditorRef = OptionFunctionReturnType<
  Pick<Editor["commands"], "focus" | "setContent">
> &
  Partial<Pick<Editor, "isFocused" | "isEmpty">> & {
    getContent: () => string | undefined;
    getAttachments: () => Attachment[];
    clear: () => void;
    clearDraft: () => void;
  };

export const TextEditor = forwardRef<TextEditorRef, TextEditorProps>(
  function TextEditor(
    {
      showToolbar,
      placeholder,
      autofocus = false,
      outputFormat,
      initialValue,
      attachments,
      isDisabled = false,
      isEditable,
      onInit,
      onChange,
      onFocus,
      onBlur,
      enableDrafts,
      draftKey,
      ...boxProps
    },
    ref,
  ) {
    const editorRef: React.MutableRefObject<Editor | null> = useRef(null);
    const { editor, draftStorage } = useTextEditor({
      placeholder,
      autofocus,
      outputFormat,
      initialValue,
      isDisabled,
      isEditable,
      onInit,
      attachments,
      onChange,
      onFocus,
      onBlur,
      enableDrafts,
      draftKey,
    });

    useImperativeHandle(ref, () => ({
      focus(position, options) {
        editorRef.current?.commands.setContent;
        return editorRef.current?.commands.focus(position, options);
      },
      setContent(content, emitUpdate, parseOptions) {
        return editorRef.current?.commands.setContent(
          content,
          emitUpdate,
          parseOptions,
        );
      },
      clear() {
        editorRef.current?.commands.clearContent();
        draftStorage?.clear();
      },
      clearDraft() {
        draftStorage?.clear();
      },
      isFocused: editorRef.current?.isFocused,
      isEmpty: editorRef.current?.isEmpty,
      getContent: () => {
        return editorRef.current?.getHTML().toString();
      },
      getAttachments: () => {
        return editorRef.current?.storage.attachment.attachments;
      },
    }));

    useEffect(() => {
      if (editor) {
        editor.setEditable(!isDisabled && !!isEditable);
      }
    }, [isEditable]);

    if (!editor) {
      return null;
    }

    editorRef.current = editor;

    return (
      <Box>
        <Box pos="sticky" zIndex="1" top="0">
          <Toolbar
            isEditable={isEditable}
            editor={editor}
            showToolbar={showToolbar}
          />
        </Box>
        <Box {...boxProps}>
          <Prose>
            <EditorContent disabled={isDisabled} editor={editor} />
          </Prose>
        </Box>
      </Box>
    );
  },
);
