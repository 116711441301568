import { Currency } from "@src/__generated__/urql-graphql";

// Nominate/Denominate
type TDenom = number | Pick<Currency, "denomination">;

export const denominate = (value: number, denom: TDenom) =>
  Math.round(value * 10 ** getDenom(denom));

export const nominate = (value: number, denom: TDenom) =>
  value / 10 ** getDenom(denom);

const getDenom = (denom: TDenom): number => {
  if (typeof denom === "number") return denom;
  return denom.denomination;
};
