import { Box, Button, Circle, Stack } from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { useStore } from "@src/utils/hooks";
import { observer, useLocalObservable } from "mobx-react-lite";
import React, { FunctionComponent, useEffect, useRef } from "react";

const DEFAULT_PLACEHOLDER_IMAGE =
  process.env.NODE_ENV === "test" ? "" : require("./user-placeholder.png");

export const AvatarUpload: FunctionComponent<
  React.PropsWithChildren<{
    imageSrc: string;
    onChange(image: File | null): void;
    roundedFull?: boolean;
    placeholderImage?: string;
    objectFit?: "cover" | "contain";
  }>
> = observer(function AvatarUpload({
  imageSrc,
  onChange,
  roundedFull = false,
  placeholderImage = undefined,
  objectFit = "cover",
}) {
  const { UIStore } = useStore();
  const ref = useRef<HTMLInputElement>(null);

  const state = useLocalObservable(() => ({
    image: imageSrc,

    handleChosenFile(file: File) {
      try {
        const fileReader = new FileReader();
        fileReader.onloadend = (e: ProgressEvent<FileReader>) => {
          const content = e.target?.result;
          if (content) this.image = content as string;
        };
        fileReader.readAsDataURL(file);
        onChange(file);
      } catch (e) {
        console.error(e);
        UIStore.toast({
          title: t`Something went wrong with file upload, please try again.`,
          status: "error",
        });
      }
    },
    handleDeleteFile() {
      this.image = "";
      if (ref.current) {
        ref.current.value = "";
      }

      if (imageSrc) {
        onChange(null);
      }
    },
  }));

  useEffect(() => {
    state.image = imageSrc;
  }, [imageSrc]);

  return (
    <Stack align="center">
      <Box as="label" pos="relative" w="34" h="full" cursor="pointer">
        <img
          src={state.image || placeholderImage || DEFAULT_PLACEHOLDER_IMAGE}
          className={`w-32 h-full object-${objectFit} ${roundedFull ? "rounded-full" : "rounded-md"}`}
          alt=""
        />

        <Circle
          pos="absolute"
          top="0"
          right="0"
          bg="blue.500"
          aria-label={t`upload photo`}
          rounded="full"
          size="8"
          style={
            roundedFull
              ? {
                  top: -5,
                  right: -5,
                }
              : {
                  top: -14,
                  right: -14,
                }
          }
        >
          <Icon name={state.image ? "edit-02" : "plus"} color="white" />
        </Circle>
        <input
          name="file"
          type="file"
          ref={ref}
          accept=".jpg, .jpeg, .png"
          className="hidden"
          onChange={(e) => {
            e.target?.files && state.handleChosenFile(e.target?.files[0]);
          }}
        />
      </Box>
      {!!state.image && (
        <Button
          mt="3"
          fontSize={{
            base: "xs",
            md: undefined,
          }}
          colorScheme="red"
          leftIcon={<Icon name="trash-03" />}
          onClick={(e) => {
            e.preventDefault();
            state.handleDeleteFile();
          }}
          variant="ghost"
        >
          <Trans>Delete photo</Trans>
        </Button>
      )}
    </Stack>
  );
});
