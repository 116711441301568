import { Avatar, AvatarProps } from "@src/components/ui-kit/Avatar";
import clientPlaceholder from "./img/client.png";

type ClientLogoProps = Omit<AvatarProps, "icon">;

const ClientLogo = (props: ClientLogoProps) => (
  <Avatar icon={<img src={clientPlaceholder} alt="client" />} {...props} />
);

export default ClientLogo;
