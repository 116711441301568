import { Link } from "@chakra-ui/next-js";
import {
  Badge,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  StyleProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { UtilizationUserReportDataQuery } from "@src/__generated__/urql-graphql";
import {
  Avatar,
  DataTable,
  LoadingOverlay,
  TaskStatusBadge,
  TColumn,
} from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { ProgressBar } from "@src/components/ui-kit/NewProgressBar";
import { PeriodPicker } from "@src/components/widgets/PeriodPicker";
import { appStore } from "@src/stores/AppStore";
import {
  currency,
  HUNDRED_PERCENT,
  percentFormatter,
} from "@src/utils/formatters";
import { useStore } from "@src/utils/hooks";
import { formatSecondsToHours } from "@src/utils/time";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { FC, Fragment, ReactNode } from "react";
import { BillableUtilizationStatCard } from "./stat-cards/BillableUtilization";
import { TotalCapacityStatCard } from "./stat-cards/TotalCapacity";
import { TrackedAllocatedStatCard } from "./stat-cards/TrackedAllocated";
import { UtilizationStatCard } from "./stat-cards/Utilization";

type UtilizationReportRow =
  UtilizationUserReportDataQuery["utilizationTaskReport"][0];

// @ts-expect-error
const cols: TColumn<UtilizationReportRow>[] = [
  {
    key: "project",
    header: () => t`Project`,
    render: (r) =>
      r.task.ourWorkBudgetItem ? (
        <Link
          target="_blank"
          rel="noreferrer noopener"
          href={{
            pathname: "/projects/detail",
            query: { id: r.task.ourWorkBudgetItem.project.id },
          }}
        >
          {r.task.ourWorkBudgetItem.project.code}{" "}
          {r.task.ourWorkBudgetItem.project.title}
        </Link>
      ) : (
        <Trans>Unassigned</Trans>
      ),
  },
  {
    key: "budget-item",
    header: () => t`Budget item`,
    render: (r) =>
      r.task.ourWorkBudgetItem ? (
        <Link
          href={{
            pathname: "/projects/detail",
            query: { id: r.task.ourWorkBudgetItem.project.id },
            hash: r.task.ourWorkBudgetItem.title
              .replaceAll(" ", "-")
              .toLowerCase(),
          }}
          target="_blank"
          rel="noreferrer noopener"
        >
          {r.task.ourWorkBudgetItem.title}
        </Link>
      ) : (
        <Trans>Unassigned</Trans>
      ),
  },
  {
    key: "task",
    header: () => t`Task`,
    render: (r) => (
      <Button
        maxW="300px"
        fontWeight="medium"
        isTruncated
        onClick={() => {
          appStore.taskDetailModalStore.drawerState.onOpen({
            id: r.task.id,
          });
        }}
        role="link"
        size="xs"
        variant="link"
      >
        {r.task.name}
      </Button>
    ),
  },
  {
    key: "status",
    header: () => t`Status`,
    render: (r) => (
      <TaskStatusBadge
        rounded="md"
        name={r.task.status.name}
        foreground_color={r.task.status.foreground_color}
        background_color={r.task.status.background_color}
      />
    ),
  },
  {
    key: "stats",
    header: () => t`Tracked | Allocated | Budget in task`,
    align: "right",
    render: (r) => {
      const { utilizationUserReportModalStore: store } = useStore();

      return (
        <Popover isLazy trigger="hover">
          <PopoverTrigger>
            <VStack>
              <HStack
                align="center"
                justify="space-between"
                w="full"
                h="4"
                fontSize="13px"
                fontWeight="medium"
                divider={<Divider bg="gray.500" orientation="vertical" />}
              >
                <Text>
                  <Icon w="4" h="4" color="green.700" name="clock" />
                  &nbsp;
                  {formatSecondsToHours(r.tracked_time_in_range ?? 0, 1)}&nbsp;(
                  {formatSecondsToHours(r.tracked_time ?? 0, 1)})
                </Text>
                <Text>
                  {formatSecondsToHours(r.allocated_time_in_range ?? 0, 1)}
                  &nbsp;(
                  {formatSecondsToHours(r.allocated_time ?? 0, 1)})
                </Text>
                <Text>
                  {formatSecondsToHours(r.budgeted_time_in_range ?? 0, 1)}
                  &nbsp;(
                  {formatSecondsToHours(r.budgeted_time ?? 0, 1)})
                </Text>
              </HStack>
              <ProgressBar
                segments={[
                  {
                    bg: "teal.200",
                    percent: (r.tracked_time_percent ?? 0) * HUNDRED_PERCENT,
                  },
                  {
                    bg: "teal.400",
                    percent:
                      (r.tracked_time_in_range_percent ?? 0) * HUNDRED_PERCENT,
                  },
                  {
                    bg: "#956FD7",
                    percent: (r.allocated_time_percent ?? 0) * HUNDRED_PERCENT,
                  },
                  {
                    bg: "#c4a1ff",
                    percent:
                      (r.allocated_time_in_range_percent ?? 0) *
                      HUNDRED_PERCENT,
                  },
                  {
                    bg: !!r.over_budget_time ? "red.500" : "gray.200",
                    percent:
                      (!!r.over_budget_time
                        ? r.over_budget_time_in_percent ?? 0
                        : r.remaining_time_in_percent ?? 0) * HUNDRED_PERCENT,
                  },
                ]}
                size="sm"
              />
            </VStack>
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="fit-content" borderColor="gray.700">
              <PopoverBody
                p="0"
                color="whiteAlpha.900"
                bg="gray.700"
                borderRadius="4"
              >
                <VStack align="start" p="4" spacing="4">
                  <StatsTooltipItem
                    time={r.budgeted_time ?? undefined}
                    title={t`Total Task Budget`}
                    amount={r.total_task_budget_amount}
                  />
                  <StatsTooltipItem
                    time={r.budgeted_time_in_range ?? undefined}
                    title={
                      t`Task Budget` +
                      ` (${format(store.dateRange.start, "MMMM")})`
                    }
                    amount={r.total_task_budget_in_range}
                  />
                </VStack>
                <Divider orientation="horizontal" variant="dashed" />
                <VStack align="start" p="4" spacing="4">
                  <HStack minW="340px" spacing="4">
                    <VStack align="start" justify="start">
                      <StatsTooltipItem
                        time={r.tracked_time_in_range ?? undefined}
                        title={t`Tracked (within date range)`}
                        amount={r.tracked_in_range_amount}
                        icon={<Icon name="clock" color="inherit" />}
                        color="teal.400"
                        percent={r.tracked_time_in_range_percent}
                      />
                      <StatsTooltipItem
                        time={r.tracked_time ?? undefined}
                        percent={r.tracked_time_percent}
                        title={t`Tracked (total)`}
                        amount={r.tracked_amount}
                        color="teal.200"
                        icon={<Icon name="clock" color="inherit" />}
                      />
                    </VStack>
                    <VStack align="start" justify="start">
                      <StatsTooltipItem
                        time={r.allocated_time_in_range ?? undefined}
                        title={t`Allocated (within date range)`}
                        amount={r.allocated_in_range_amount}
                        color="#956FD7"
                        percent={r.allocated_time_in_range_percent}
                      />
                      <StatsTooltipItem
                        time={r.allocated_time ?? undefined}
                        percent={r.allocated_time_percent}
                        title={t`Allocated (total)`}
                        amount={r.allocated_amount}
                        color="#C4A1FF"
                      />
                    </VStack>
                  </HStack>
                  {r.over_budget_time ? (
                    <StatsTooltipItem
                      time={r.over_budget_time}
                      amount={r.over_budget_amount}
                      percent={r.over_budget_time_in_percent}
                      color="red.500"
                      title={t`Over Budget`}
                    />
                  ) : (
                    <StatsTooltipItem
                      time={r.remaining_time}
                      amount={r.remaining_amount}
                      percent={r.remaining_time_in_percent}
                      title={t`Left to allocate`}
                    />
                  )}
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      );
    },
  },
];

const StatsTooltipItem: FC<{
  title: string;
  time: number | undefined;
  amount: number | undefined | null;
  percent?: number | undefined | null;
  icon?: ReactNode;
  color?: StyleProps["bg"];
}> = ({
  title,
  time = 0,
  amount = 0,
  icon = null,
  color = "white",
  percent,
}) => {
  return (
    <VStack align="start" spacing="2">
      <Text>{title}</Text>
      <HStack
        align="center"
        color={color}
        divider={<Divider h="4" bg="gray.200" orientation="vertical" />}
        spacing="2"
      >
        <Text color="inherit" fontWeight="medium">
          {icon}
          {icon && <Fragment>&nbsp;</Fragment>}
          {formatSecondsToHours(time, 1)}
        </Text>
        <Text color="inherit">
          {currency.formatByWorkspace(amount ?? 0)}&nbsp;&nbsp;
          {(!!percent || percent === 0) && (
            <Badge bg={color}>
              {percentFormatter().format(percent * 0.01)}
            </Badge>
          )}
        </Text>
      </HStack>
    </VStack>
  );
};

export const UtilizationUserReportModal = observer(
  function UtilizationUserReportModal() {
    const { utilizationUserReportModalStore: store } = useStore();
    const report = store.data?.utilizationUserReport?.report;
    const user = store.data?.utilizationUserReport?.user;

    return (
      <Modal
        /**
         * set `blockScrollOnMount` to `false` to enable scroll in nested modals
         */
        blockScrollOnMount={false}
        isCentered
        isOpen
        onClose={store.modalState.onClose}
        scrollBehavior="inside"
        size="6xl"
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pt="10">
            <LoadingOverlay
              isLoading={store.isFetching}
              minH="200px"
              position="absolute"
              transparent={false}
            >
              {!!store.data && (
                <Stack h="full" dir="vertical">
                  <HStack justifyContent="space-between">
                    <HStack spacing="2">
                      <Avatar
                        w="56px"
                        h="56px"
                        name={user?.full_name}
                        src={user?.image?.urls.thumbnail}
                        colorScheme={user?.profile?.hex_color}
                      />
                      <Stack>
                        <Heading fontSize="24px">{user?.full_name}</Heading>
                        <Text color="grey.500" fontWeight="medium" size="sm">
                          {user?.profile.defaultWorktype.title} |{" "}
                          {user?.team.name}
                        </Text>
                      </Stack>
                    </HStack>
                    <PeriodPicker
                      value={store.dateRange}
                      onChange={(range) => {
                        store.dateRange = range;
                        store.fetchData();
                      }}
                    />
                  </HStack>

                  <DataTable.Stats px="0" spacing="2" pb="0">
                    <TotalCapacityStatCard
                      totalTime={report?.total_time}
                      availableTime={report?.available_time}
                      timeOff={report?.time_off}
                    />
                    <TrackedAllocatedStatCard
                      allocatedTime={report?.allocated_time ?? 0}
                      trackedTime={report?.tracked_time ?? 0}
                      taskBudgetedTime={report?.budgeted_time ?? 0}
                      totalTime={report?.available_time}
                      combinedTime={report?.combined_time ?? 0}
                    />
                    <UtilizationStatCard
                      percent={report?.utilization_percentage ?? 0}
                      totalTime={report?.available_time}
                      combinedTime={report?.combined_time ?? 0}
                      allocatedTime={report?.allocated_time ?? 0}
                      budgetedTime={report?.budgeted_time ?? 0}
                      trackedTime={report?.tracked_time ?? 0}
                      month={store.dateRange.start}
                      utilizationRange={{
                        from: user?.profile.utilization_range_from ?? 80,
                        to: user?.profile.utilization_range_to ?? 100,
                      }}
                    />
                    <BillableUtilizationStatCard
                      totalTime={report?.available_time}
                      percent={report?.utilization_percentage_billable ?? 0}
                      combinedTime={report?.combined_time ?? 0}
                      combinedTimeBillable={report?.combined_time_billable ?? 0}
                      allocatedTime={report?.allocated_time ?? 0}
                      allocatedTimeBillable={
                        report?.allocated_time_billable ?? 0
                      }
                      budgetedTime={report?.budgeted_time ?? 0}
                      budgetedTimeBillable={report?.budgeted_time_billable ?? 0}
                      trackedTime={report?.tracked_time ?? 0}
                      trackedTimeBillable={report?.tracked_time_billable ?? 0}
                      timeOffTime={report?.time_off}
                      utilizationRange={{
                        from: user?.profile.utilization_range_from ?? 80,
                        to: user?.profile.utilization_range_to ?? 100,
                      }}
                    />
                  </DataTable.Stats>

                  <Text my="2" fontSize="lg" fontWeight="bold">
                    <Trans>Active tasks in</Trans>
                    &nbsp;
                    {format(store.dateRange.start, "MMMM yyyy")}
                  </Text>

                  <Box
                    overflow="hidden"
                    borderWidth="1px"
                    borderColor="grey.100"
                    rounded="md"
                  >
                    <DataTable.Body
                      tableKey={store.modalId}
                      columnVisibilityControl={false}
                      // @ts-expect-error
                      data={store.data?.utilizationTaskReport}
                      // @ts-expect-error
                      columns={cols}
                    />
                  </Box>
                </Stack>
              )}
            </LoadingOverlay>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  },
);
