import { ApolloError } from "@apollo/client";
import { appStore } from "@src/stores/AppStore";

const DEFAULT_MSG = "Error";

export const onError = () => {
  const _onError = (error: ApolloError) => {
    if (error.graphQLErrors?.length) {
      error.graphQLErrors.forEach((i) => {
        const messages = parseValidationMessages(i.extensions);

        if (messages.length) {
          messages.forEach((j) =>
            appStore.UIStore.toast({ title: j, status: "error" }),
          );
        } else {
          appStore.UIStore.toast({ title: i.message, status: "error" });
        }
      });
    } else if (error.networkError) {
      appStore.UIStore.toast({
        title: error.networkError.message,
        status: "error",
      });
    } else {
      appStore.UIStore.toast({ title: DEFAULT_MSG, status: "error" });
    }

    if (process.env.NODE_ENV !== "production") {
      console.log(error);
    }
  };

  return { onError: _onError };
};

const parseValidationMessages = (
  extensions: { [key: string]: any } | undefined,
): string[] => {
  let messages: string[] = [];

  if (
    extensions &&
    "validation" in extensions &&
    typeof extensions.validation === "object"
  ) {
    for (const arrayOfMsg of Object.values(extensions.validation)) {
      if (Array.isArray(arrayOfMsg)) {
        messages = messages.concat(arrayOfMsg);
      }
    }
  }

  return messages;
};
