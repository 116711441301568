import { HyperlinkModalRef } from "@src/components/ui-kit/TextEditor/toolbar/HyperlinkButton";
import { Editor } from "@tiptap/core";
import React, { createRef } from "react";

export class ToolbarStore {
  hyperlinkModalRef = createRef<HyperlinkModalRef>();
  editor: Editor;

  constructor(editor: Editor) {
    this.editor = editor;
  }
}

export const ToolbarStoreContext = React.createContext(
  null as any as ToolbarStore,
);
