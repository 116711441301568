import { Box } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { TimeTrackingEntryFragment } from "@src/__generated__/urql-graphql";
import { formatDate as _formatDate } from "@src/utils/formatters";
import { isToday, isYesterday } from "date-fns";
import { observer } from "mobx-react-lite";
import Entry from "./Entry";

interface GroupProps {
  label: Date;
  value: TimeTrackingEntryFragment[];
}

const Group = ({ label, value }: GroupProps) => {
  return (
    <Box px="4">
      <label className="text-xl text-inkLighter">{formatDate(label)}</label>
      {value.map((i) => (
        <Entry key={i.id} value={i} />
      ))}
    </Box>
  );
};

const formatDate = (value: Date) => {
  if (isToday(value)) return t`Today`;
  if (isYesterday(value)) return t`Yesterday`;
  return _formatDate(value);
};

export default observer(Group);
