import {
  User,
  UtilizationReportDataSource,
  UtilizationUserReportDataDocument,
  UtilizationUserReportDataQuery,
  UtilizationUserReportDataQueryVariables,
} from "@src/__generated__/urql-graphql";
import { UtilizationUserReportModal } from "@src/components/modules/reports/utilization/UtilizationUserReportModal";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { DateRange } from "@src/utils/types";
import { endOfMonth, startOfMonth } from "date-fns";
import { action, makeObservable, observable } from "mobx";

type UtilizationUserReportModalOptions = {
  id: User["id"];
  dataSource: UtilizationReportDataSource;
  dateRange: DateRange;
};

export class UtilizationUserReportModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  readonly modalId = "utilizationUserReportModal";

  data: UtilizationUserReportDataQuery | undefined = undefined;
  @observable isFetching = false;

  @observable modalState =
    new DisclosureState<UtilizationUserReportModalOptions>({
      onOpen: () => {
        if (!this.modalState.additionalData) return;
        this.dateRange = this.modalState.additionalData.dateRange;
        this.fetchData();
        this.appStore.UIStore.dialogs.openModal({
          id: this.modalId,
          content: <UtilizationUserReportModal />,
        });
      },
      onClose: () => {
        this.data = undefined;
        this.appStore.UIStore.dialogs.closeModal(this.modalId);
      },
    });
  @observable.ref dateRange: DateRange = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  };

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @action.bound async fetchData() {
    this.isFetching = true;
    const { data } = await client
      .query<
        UtilizationUserReportDataQuery,
        UtilizationUserReportDataQueryVariables
      >(UtilizationUserReportDataDocument, {
        userId: this.modalState.additionalData!.id,
        from: this.dateRange.start,
        to: this.dateRange.end,
        dataSource: this.modalState.additionalData!.dataSource,
      })
      .toPromise();

    this.isFetching = false;

    this.data = data;
  }
}
