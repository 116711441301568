import { t } from "@lingui/macro";
import {
  NotificationThresholdTypeEnum,
  UpdateWorkspaceNotifications,
  WorkspaceNotification,
} from "@src/__generated__/urql-graphql";
import { SettingsModalStore } from "@src/stores/SettingsModalStore";
import { TToFieldStates } from "@src/utils/forms/ts-utils";
import { FieldState, FormState } from "formstate";

type NotificationStateProps = Omit<
  UpdateWorkspaceNotifications["notifications"][0],
  "id" | "threshold"
> & {
  threshold: string;
};
class NotificationState extends FormState<
  TToFieldStates<NotificationStateProps>
> {
  id: WorkspaceNotification["id"];
  name: WorkspaceNotification["name"];
  description: WorkspaceNotification["description"];
  to_roles: WorkspaceNotification["to_roles"];
  threshold_type: WorkspaceNotification["threshold_type"];
  private store: SettingsModalStore;

  constructor(store: SettingsModalStore, notification: WorkspaceNotification) {
    super({
      database_enabled: new FieldState(notification.database_enabled),
      mail_enabled: new FieldState(notification.mail_enabled),
      roles_to_notify: new FieldState(notification.roles_to_notify),
      threshold: new FieldState(String(notification.threshold)).validators(
        (value) => {
          if (this.threshold_type === NotificationThresholdTypeEnum.None) {
            return;
          }

          const numericValue = parseInt(value ?? "", 10);
          if (isNaN(numericValue) || numericValue <= 0) {
            return t`Threshold value must be positive`;
          }

          return;
        },
      ),
    });
    this.store = store;
    this.id = notification.id;
    this.name = notification.name;
    this.description = notification.description;
    this.to_roles = notification.to_roles;
    this.threshold_type = notification.threshold_type;
  }

  get shouldShowThresholdInput() {
    return this.threshold_type !== NotificationThresholdTypeEnum.None;
  }

  get thresholdUnit() {
    switch (this.threshold_type) {
      case NotificationThresholdTypeEnum.Amount:
        return this.store.appStore.workspaceStore.settings?.currency.code ?? "";
      case NotificationThresholdTypeEnum.Days:
        return t`days`;
      case NotificationThresholdTypeEnum.Hours:
        return t`hours`;
      case NotificationThresholdTypeEnum.Percentage:
        return "%";
      default:
        return "";
    }
  }
}

export class NotificationSettingsState {
  store: SettingsModalStore;
  notifications: NotificationState[] = [];
  readonly form = new FormState<NotificationState[]>([]);

  constructor(store: SettingsModalStore) {
    this.store = store;
  }

  init(notifications: WorkspaceNotification[]) {
    this.notifications = notifications.map(
      (notification) => new NotificationState(this.store, notification),
    );
    this.notifications.forEach((notification) => {
      this.form.$.push(notification);
    });
  }
}
