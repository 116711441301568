import {
  GetTaskPositionsDocument,
  GetTaskPositionsQuery,
  GetTaskPositionsQueryVariables,
  UpdateTimeTrackingWorkTypeInTrackingDocument,
  UpdateTimeTrackingWorkTypeInTrackingMutation,
  UpdateTimeTrackingWorkTypeInTrackingMutationVariables,
} from "@src/__generated__/urql-graphql";
import { ChangeTimeTrackingWorkTypeModal } from "@src/components/modules/resource-planning/timeline/ChangeTimeTrackingWorkTypeModal";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { required } from "@src/utils/forms/validators";
import mapToOptions from "@src/utils/map-to-options";
import { WorkTypeOption } from "@src/utils/map-to-options/workTypes";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { FieldState } from "formstate";
import { makeObservable, observable } from "mobx";

interface ChangeTimeTrackingWorkTypeOptions {
  user_id: string;
  task_id: string;
  time_tracking_work_type_id: string;
  capacity_allocation_item_id: string;
  onSubmit?: (new_time_tracking_work_type_id: string) => void;
}

export class ChangeTimeTrackingWorkTypeModalStore
  implements BaseStore, ModalStore
{
  appStore: AppStore;

  readonly modalId = "changeTimeTrackingWorkTypeModal";

  modalState = new DisclosureState<ChangeTimeTrackingWorkTypeOptions>({
    onOpen: () => {
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <ChangeTimeTrackingWorkTypeModal />,
      });
      this.getPositionOptions();
    },
    onClose: () => {
      this.positions = [];
      this.new_time_tracking_work_type_id.reset();
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
    },
  });

  @observable positions: WorkTypeOption[] = [];

  @observable isLoading = false;

  new_time_tracking_work_type_id = new FieldState<
    WorkTypeOption["value"] | undefined
  >(undefined).validators(required);

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  async getPositionOptions() {
    if (!this.modalState.additionalData?.task_id) return;

    this.isLoading = true;

    const { data } = await client
      .query<GetTaskPositionsQuery, GetTaskPositionsQueryVariables>(
        GetTaskPositionsDocument,
        {
          task_id: this.modalState.additionalData.task_id,
        },
      )
      .toPromise();

    this.isLoading = false;

    this.positions = mapToOptions.workTypes(
      data?.task.ourWorkBudgetItem?.project.availableTimeTrackingWorkTypes ??
        [],
    );
  }

  async onSubmit() {
    const res = await this.new_time_tracking_work_type_id.validate();

    if (res.hasError) return;

    const {
      user_id,
      task_id,
      time_tracking_work_type_id,
      capacity_allocation_item_id,
    } = this.modalState.additionalData ?? {};

    if (
      !user_id ||
      !task_id ||
      !time_tracking_work_type_id ||
      !capacity_allocation_item_id
    ) {
      return;
    }

    const new_time_tracking_work_type_id =
      this.new_time_tracking_work_type_id.value!;

    const { data } = await client
      .mutation<
        UpdateTimeTrackingWorkTypeInTrackingMutation,
        UpdateTimeTrackingWorkTypeInTrackingMutationVariables
      >(UpdateTimeTrackingWorkTypeInTrackingDocument, {
        user_id,
        task_id,
        old_time_tracking_work_type_id: time_tracking_work_type_id,
        new_time_tracking_work_type_id,
        capacity_allocation_item_id,
      })
      .toPromise();

    if (data?.updateTimeTrackingWorkTypeInTracking) {
      this.modalState.additionalData?.onSubmit?.(
        new_time_tracking_work_type_id,
      );
      this.modalState.onClose();
    }
  }
}
