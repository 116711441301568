import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import {
  StartTimerMutation,
  TimeTrackingEntryFragment,
  useDeleteTimeTrackingItemMutation,
  useStartTimerMutation,
} from "@src/__generated__/urql-graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import { can } from "@src/utils/components/permissions";
import { observer } from "mobx-react-lite";
import { FC } from "react";

interface TimeTrackingItemMenuActionsProps {
  value: TimeTrackingEntryFragment;
  onEdit?: () => void;
  onDelete?: () => void;
  onStart?: (data: StartTimerMutation | undefined) => void;
  withStart?: boolean;
}

export const TimeTrackingItemMenuActions: FC<TimeTrackingItemMenuActionsProps> =
  observer(function TimeTrackingItemMenuActions({
    value,
    onStart,
    onDelete,
    onEdit,
    withStart = true,
  }) {
    const start = useStartTimerMutation()[1];

    const _onStart = async () => {
      const { data } = await start({
        time_tracking_item_id: value.id,
      });

      onStart?.(data);
    };

    const remove = useDeleteTimeTrackingItemMutation()[1];

    const _onDelete = async () => {
      await remove({ id: value.id });
      onDelete?.();
    };

    const canUse = can([
      "timeTracking_timer_use_all",
      "timeTracking_timer_use_own",
    ]);
    const canUpdate = can([
      "timeTracking_update_all",
      "timeTracking_update_own",
    ]);
    const canDelete = can([
      "timeTracking_delete_all",
      "timeTracking_delete_own",
    ]);

    if (!canUse && !canUpdate && !canDelete) {
      return null;
    }

    return (
      <Menu>
        <MenuButton>
          <Icon name="dots-horizontal" w="5" h="5" color="grey.600" />
        </MenuButton>
        <MenuList>
          {withStart && canUse && (
            <MenuItem onClick={_onStart}>
              <Trans>Start</Trans>
            </MenuItem>
          )}
          {onEdit && canUpdate && (
            <MenuItem onClick={onEdit}>
              <Trans>Edit</Trans>
            </MenuItem>
          )}
          {canDelete && (
            <MenuItem onClick={_onDelete}>
              <Trans>Delete</Trans>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    );
  });
