import { Box, Text, useRadio, UseRadioProps } from "@chakra-ui/react";
import { FunctionComponent } from "react";

export const RadioCard: FunctionComponent<
  UseRadioProps & { children: string }
> = ({ children, ...props }) => {
  const { getInputProps, getCheckboxProps, htmlProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" {...htmlProps}>
      <input {...input} hidden />
      <Box
        {...checkbox}
        px="5"
        py="2"
        bg="grey.50"
        opacity={props.isDisabled && !input.checked ? 0.5 : 1}
        borderWidth="1px"
        borderColor="grey.50"
        _checked={{
          bg: "teal.100",
          color: "white",
          borderColor: "teal.600",
        }}
        cursor={props.isDisabled ? "not-allowed" : "pointer"}
        rounded="full"
      >
        <Text
          {...checkbox}
          fontWeight="medium"
          _checked={{
            color: "teal.700",
          }}
        >
          {children}
        </Text>
      </Box>
    </Box>
  );
};
