import {
  Box,
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import { useStore } from "@src/utils/hooks";
import { DateRange as Range } from "@src/utils/types";
import {
  Calendar,
  CalendarControls,
  CalendarDate,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarValues,
  CalendarWeek,
} from "@uselessdev/datepicker";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { formatDate, getDateFromEUFormat, now } from "..";

interface InputDatePickerProps extends Omit<BoxProps, "onChange"> {
  id?: string;
  error?: ReactNode;
  label?: string;
  selected?: Date | undefined;
  isDisabled?: boolean;
  onChange: (val: Range | undefined) => void;
  disableDatesBefore?: Date;
  disableDatesAfter?: Date;
  usePortal?: boolean;
  inputProps?: InputProps;
  placeholder?: string;
}

export const InputDatePicker: FunctionComponent<InputDatePickerProps> =
  observer(function InputDatePicker({
    error,
    label,
    onChange,
    selected,
    disableDatesBefore,
    disableDatesAfter,
    usePortal,
    isDisabled,
    id,
    inputProps,
    placeholder,
    ...props
  }) {
    const { workspaceStore } = useStore();
    const weekStartsOn = computed(
      () => workspaceStore.settings?.startOfWeekNumber,
    ).get();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = useRef(null);
    const calendarRef = useRef(null);
    const [isTyping, setIsTyping] = useState(false);
    const [typedDate, setTypedDate] = useState("");

    useEffect(() => {
      if (isTyping) return;
      if (!selected) {
        setTypedDate("");
        return;
      }
      const dateString = formatDate(selected);
      if (dateString !== typedDate) setTypedDate(dateString);
    }, [selected]);

    const handleDateSelect = (value: CalendarValues | CalendarDate) => {
      if (value instanceof Date) {
        // TODO: rewrite old Range format to single Date
        const oldValueFormat: Range = { start: value, end: value };
        onChange(oldValueFormat);
        setTypedDate(formatDate(value));
        onClose();
      }
    };

    const onBlur = () => {
      const currentYear = new Date().getFullYear();
      const dateSegments = typedDate.split(".");
      const endsWithDot = typedDate.slice(typedDate.length - 1) === ".";

      if (dateSegments[1] && !dateSegments[2]) {
        handleInputChange(
          `${typedDate}${endsWithDot ? "" : "."}${currentYear}`,
        );
      }
      setIsTyping(false);
    };

    const handleInputChange = (val: string) => {
      setIsTyping(true);
      setTypedDate(val);

      try {
        const formattedDate = getDateFromEUFormat(val);

        if (formattedDate) {
          onChange({ start: formattedDate, end: formattedDate });
        } else {
          onChange(undefined);
        }
        onClose();
      } catch (e) {
        console.error(e);
      }
    };

    useOutsideClick({
      ref: calendarRef,
      handler: onClose,
      enabled: isOpen,
    });

    return (
      <Box
        {...props}
        // Event.stopPropagation() to prevent closing table filter dropdown on date select
        onClick={(e) => e.stopPropagation()}
      >
        <Popover
          initialFocusRef={initialRef}
          isLazy
          isOpen={isDisabled ? false : isOpen || isTyping}
          onClose={onClose}
          placement="bottom-start"
        >
          <React.Fragment>
            <PopoverTrigger>
              <Box ref={initialRef} onClick={onOpen}>
                <FormControl isInvalid={!!error}>
                  {label && <FormLabel>{label}</FormLabel>}
                  <InputGroup>
                    <Input
                      {...inputProps}
                      pr="1"
                      pl="9"
                      id={id}
                      isDisabled={!!isDisabled}
                      isInvalid={!!error}
                      onBlur={onBlur}
                      onChange={(e) => handleInputChange(e.target.value)}
                      placeholder={placeholder ?? formatDate(now())}
                      value={typedDate}
                    />
                    <InputLeftElement>
                      <Icon name="calendar" w="5" h="5" color="grey.500" />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{error}</FormErrorMessage>
                </FormControl>
              </Box>
            </PopoverTrigger>

            <WrapComponent
              if={!!usePortal}
              with={(children) => <Portal>{children}</Portal>}
            >
              <PopoverContent
                ref={calendarRef}
                w="min-content"
                p={0}
                color="black"
                border="none"
                shadow="xl"
                _focus={{ boxShadow: "none" }}
                outline="none"
                rounded="xl"
              >
                <Calendar
                  value={{ start: selected }}
                  onSelectDate={handleDateSelect}
                  singleDateSelection
                  weekStartsOn={weekStartsOn}
                  highlightToday
                  allowOutsideDays
                  disableFutureDates={disableDatesAfter}
                  disablePastDates={disableDatesBefore}
                >
                  <PopoverBody p={0}>
                    <Box px="4" py="3" borderBottomWidth="1px">
                      <Text color="grey.600" fontSize="xs">
                        <Trans>
                          supported formats are <b>MM.DD.</b> or{" "}
                          <b>MM.DD.YYYY</b>
                        </Trans>
                      </Text>
                    </Box>
                    <CalendarControls>
                      <CalendarPrevButton
                        as={(props) => (
                          <IconButton
                            h="7"
                            aria-label={t`Prev month`}
                            colorScheme="grey"
                            icon={<Icon name="arrow-narrow-left" w="5" h="5" />}
                            variant="subtle"
                            {...props}
                          />
                        )}
                      />
                      <CalendarNextButton
                        as={(props) => (
                          <IconButton
                            h="7"
                            aria-label={t`Next month`}
                            colorScheme="grey"
                            icon={
                              <Icon name="arrow-narrow-right" w="5" h="5" />
                            }
                            variant="subtle"
                            {...props}
                          />
                        )}
                      />
                    </CalendarControls>

                    <CalendarMonths>
                      <CalendarMonth>
                        <CalendarMonthName />
                        <CalendarWeek />
                        <CalendarDays />
                      </CalendarMonth>
                    </CalendarMonths>
                  </PopoverBody>
                </Calendar>
              </PopoverContent>
            </WrapComponent>
          </React.Fragment>
        </Popover>
      </Box>
    );
  });
