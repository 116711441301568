import { Card } from "@chakra-ui/react";
import React, { FC } from "react";

type StatCardProps = {
  children: React.ReactNode;
};

export const StatCard: FC<StatCardProps> = ({ children }) => {
  return (
    <Card flex="1" p="4" bg="grey.50" shadow="none" rounded="lg">
      {children}
    </Card>
  );
};
