import { useCallback, useMemo } from "react";
import { TableSelectable } from "./Body";
import { TRow } from "./types";

export const useSelectRows = <S extends { id: string }>(
  data: TRow<S>[],
  selected: string[],
  options: {
    onSelect?: (selected: string[]) => void;
    subRowsMode?: boolean;
    selectable?: TableSelectable;
  },
): {
  isPageSelected: boolean;
  isPageIndeterminate: boolean;
  isRowSelected: (id: string, subRows?: S[]) => boolean;
  isRowIndeterminate: (subRows?: S[]) => boolean;
  onRowSelect: (state: boolean, id?: string | string[]) => void;
  onPageSelect: (state: boolean) => void;
} => {
  // Available visible ids
  const ids = useMemo(() => {
    if (options.subRowsMode) {
      return data.flatMap((row) => row.__subRows?.map((sub) => sub.id) ?? []);
    } else {
      return data.map((row) => row.id);
    }
  }, [data, options.subRowsMode]);

  // Indicate whether all rows on page are selected
  const isPageSelected = useMemo(
    () => !!ids.length && ids.length === selected.length,
    [selected, ids],
  );

  const isPageIndeterminate = useMemo(
    () => !!ids.length && !isPageSelected && !!selected.length,
    [selected, ids, isPageSelected],
  );

  const isRowSelected = (id: string, subRows?: S[]) => {
    let isSelected = false;
    const subRowIds = subRows?.map((i) => i.id) ?? [];

    switch (options.selectable) {
      case "subrows":
        isSelected = !!subRows?.length
          ? subRows.every((subRow) => selected.includes(subRow.id))
          : selected.includes(id);
        break;
      case "subrowsOrParent":
        isSelected = !!subRows?.length
          ? subRowIds.every((id) => selected.includes(id)) ||
            selected.includes(id)
          : selected.includes(id);
        break;
      case "subrowsOrEmptyParent":
      default:
        isSelected = selected.includes(id);
        break;
    }

    return isSelected;
  };

  const isRowIndeterminate = (subRows?: S[]): boolean => {
    if (!subRows) return false;
    return (
      subRows.some(({ id }) => selected.includes(id)) &&
      !subRows.every(({ id }) => selected.includes(id))
    );
  };

  const onRowSelect = (state: boolean, id?: string | string[]) => {
    if (id === undefined) return;

    const ids = Array.isArray(id) ? id : [id];
    options.onSelect?.(
      state
        ? [...selected, ...ids]
        : selected.filter((id) => !ids.includes(id)),
    );
  };
  const onRowSelectedMemoized = useCallback(onRowSelect, [
    options.onSelect,
    selected,
  ]);

  const onPageSelect = (state: boolean) => options.onSelect?.(state ? ids : []);

  return {
    isPageSelected,
    isPageIndeterminate,
    isRowSelected,
    isRowIndeterminate,
    onRowSelect: onRowSelectedMemoized,
    onPageSelect,
  };
};
