import React from "react";

interface Options extends IntersectionObserverInit {
  onIntersect: () => void;
  target: React.RefObject<HTMLElement>;
}

export default function useIntersectionObserver({
  root = null,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = "0px",
}: Options) {
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        root,
        rootMargin,
        threshold,
      },
    );

    if (target.current) {
      observer.observe(target.current);
    }

    return () => {
      if (target.current) observer.unobserve(target.current);
    };
  }, [target, onIntersect]);
}
