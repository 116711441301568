import { t } from "@lingui/macro";
import { useTimeOffTypeOptionsQuery } from "@src/__generated__/urql-graphql";
import { Select, SelectProps } from "@src/components/ui-kit";
import mapToOptions from "@src/utils/map-to-options";
import {
  TimeOffTypeOption,
  toTimeOffTypeOptionsParams,
} from "@src/utils/map-to-options/timeOffTypes";
import { FunctionComponent, useEffect, useState } from "react";

type TimeOffTypeSelectProps = Omit<SelectProps, "options"> & {
  optionModifier?: toTimeOffTypeOptionsParams["modifier"];
  options?: SelectProps["options"];
};

export const TimeOffTypeSelect: FunctionComponent<TimeOffTypeSelectProps> = ({
  optionModifier = (option) => option,
  options: passedOptions,
  ...props
}) => {
  const [options, setOptions] = useState<TimeOffTypeOption[]>([]);
  const [{ data, fetching }] = useTimeOffTypeOptionsQuery({
    pause: !!passedOptions,
  });

  useEffect(() => {
    if (data?.timeOffTypesQuery) {
      setOptions(
        mapToOptions.timeOffTypes(data.timeOffTypesQuery, optionModifier),
      );
    }
  }, [data]);

  return (
    <Select
      isLoading={fetching}
      placeholder={t`Select type`}
      options={passedOptions || options}
      {...props}
    />
  );
};
