import { useCallback, useEffect, useMemo, useState } from "react";
import { TRow } from "./types";

export const useCollapseRows = <S extends { id: string }>(
  data: TRow<S>[],
  options: { subRowsVisible?: boolean },
): [
  boolean,
  {
    isRowOpen: (id: string) => boolean;
    onCollapseRow: (id: string) => void;
    onCollapseAllRows: () => void;
  },
] => {
  const collapsible = useMemo(
    () => data.some((i) => i.__subRows?.length),
    [data],
  );

  const [openRowIds, setOpenRowIds] = useState<string[]>([]);

  // Filter old ids
  useEffect(() => {
    if (options.subRowsVisible) {
      setOpenRowIds(data.map((i) => i.id));
    } else {
      setOpenRowIds((ids) =>
        data.map((i) => i.id).filter((i) => ids.includes(i)),
      );
    }
  }, [data]);

  const isRowOpen = (id: string) => openRowIds.includes(id);

  const onCollapseRow = useCallback((id: string) => {
    setOpenRowIds((ids) =>
      ids.includes(id) ? ids.filter((i) => i !== id) : [...ids, id],
    );
  }, []);

  const onCollapseAllRows = () => {
    // Get the first collapsible closed row
    const open = data.some(
      (i) => !openRowIds.includes(i.id) && i.__subRows?.length,
    );

    if (open) {
      setOpenRowIds(data.map((i) => i.id));
    } else {
      setOpenRowIds([]);
    }
  };

  return [
    collapsible,
    {
      isRowOpen,
      onCollapseRow,
      onCollapseAllRows,
    },
  ];
};
