import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBoolean,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { Form, Select } from "@src/components/ui-kit";
import { CancelButton } from "@src/components/ui-kit/Button/CancelButton";
import { fieldToSelectProps } from "@src/utils/forms/inputHelpers";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

export const ChangeTimeTrackingWorkTypeModal = observer(
  function ChangeTimeTrackingWorkTypeModal() {
    const { changeTimeTrackingWorkTypeModalStore: store } = useStore();
    const [isSubmitting, setIsSubmitting] = useBoolean(false);

    const onSubmit = async () => {
      setIsSubmitting.on();
      await store.onSubmit();
      setIsSubmitting.off();
    };

    return (
      <Modal
        isCentered
        isOpen
        onClose={store.modalState.onClose}
        size="xl"
        trapFocus={false}
      >
        <ModalOverlay />
        <Form onSubmit={onSubmit}>
          <ModalContent>
            <ModalHeader>
              <Trans>Choose position for time tracking</Trans>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Select
                isLoading={store.isLoading}
                placeholder={t`Select position`}
                {...fieldToSelectProps(
                  store.new_time_tracking_work_type_id,
                  store.positions,
                )}
              />
            </ModalBody>
            <ModalFooter>
              <ButtonGroup spacing="3">
                <CancelButton onClick={store.modalState.onClose} />

                <Button isLoading={isSubmitting} type="submit">
                  <Trans>Save</Trans>
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    );
  },
);
