import {
  PlanningDailyViewPeopleDocument,
  PlanningDailyViewPeopleQuery,
  PlanningDailyViewPeopleQueryVariables,
} from "@src/__generated__/urql-graphql";
import { PlanningDailyViewUserByDayModel } from "@src/components/modules/resource-planning/timeline/models";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { makeObservable } from "mobx";
import { ResourcePlanningViewStore } from "./extendables/ResourcePlanningViewStore";

export class ResourcePlanningDayStore
  extends ResourcePlanningViewStore
  implements BaseStore
{
  appStore: AppStore;

  constructor(appStore: AppStore) {
    super(appStore, () => this.fetchData());
    makeObservable(this);
    this.appStore = appStore;
  }

  private fetchData = async () => {
    const [from, to] = this.selectedDateRange;
    const filterBase = {
      from,
      to,
      search: this.queryParams.filters.search,
      first: this.queryParams.first,
    };

    const { data } = await client
      .query<
        PlanningDailyViewPeopleQuery,
        PlanningDailyViewPeopleQueryVariables
      >(PlanningDailyViewPeopleDocument, {
        dailyViewFilters: {
          ...filterBase,
          where: this.queryParams.filters.where!,
          page: this.queryParams.page,
        },
      })
      .toPromise();

    if (!data) return;

    const { planningDailyViewPeople } = data;

    this.lastSyncedAt = data.planningDailyViewPeople.last_updated_at;

    this.data = planningDailyViewPeople.data.map(
      (item) => new PlanningDailyViewUserByDayModel(item),
    );
  };
}
