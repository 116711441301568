import { Center, Spinner, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { AttachmentFragment } from "@src/__generated__/urql-graphql";
import { AttachmentStorage } from "@src/components/ui-kit/TextEditor/extensions/AttachmentNode";
import { Attachment } from "@src/components/ui-kit/TextEditor/extensions/UploadAttachmentPlugin";
import { AttachmentView } from "@src/components/widgets/Modals/ModalCommunication/components/Attachments/TaskAttachment";
import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { FunctionComponent, useEffect, useState } from "react";

// TODO: add attachment prop types to NodeViewProps somehow to have it more type-safe
export const AttachmentNodeView: FunctionComponent<NodeViewProps> = (props) => {
  const { id, loading } = props.node.attrs;
  const [file, setFile] = useState<Attachment | undefined>(undefined);

  useEffect(() => {
    if (id && typeof id?.then === "function") {
      Promise.resolve<AttachmentFragment | undefined>(id).then(function (file) {
        if (file) {
          const storage = props.editor.storage.attachment as AttachmentStorage;
          storage.attachments.push({
            id: file.public_id,
            mime_type: file.mime_type,
            filename: file.filename,
            viewable: file.urls.viewable ?? undefined,
            thumbnail: file.urls.thumbnail ?? undefined,
            original: file.urls.original ?? undefined,
          });
          props.editor.view.dispatch(
            props.editor.view.state.tr.setNodeMarkup(
              props.getPos(),
              undefined,
              {
                id: file.public_id,
                loading: false,
              },
            ),
          );
        } else {
          props.editor.view.dispatch(
            props.editor.view.state.tr.setNodeMarkup(
              props.getPos(),
              undefined,
              {
                loading: false,
                id: null,
              },
            ),
          );
        }
      });
    }
  }, [id]);

  useEffect(() => {
    const storage = props.editor.storage.attachment as AttachmentStorage;
    setFile(
      storage.attachments?.find(
        (attachment: Attachment) => attachment.id === id,
      ),
    );
  }, [props]);

  if (loading) {
    return (
      <NodeViewWrapper>
        <Center w="200px" h="100px" bg="grey.50" rounded="md">
          <Spinner color="teal.600" size="xl" />
        </Center>
      </NodeViewWrapper>
    );
  }

  if (!loading && !file) {
    return (
      <NodeViewWrapper>
        <Center w="200px" h="100px" bg="grey.50" rounded="md">
          <Text fontWeight="bold">
            <Trans>Error</Trans>
          </Text>
        </Center>
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper>
      {file && (
        <AttachmentView
          mimeType={file.mime_type}
          id={id}
          name={file.filename}
          urls={{
            viewable: file.viewable,
            original: file.original,
            thumbnail: file.thumbnail,
          }}
          removeDisabled={!props.editor.isEditable}
          customRemove={() => props.deleteNode()}
        />
      )}
    </NodeViewWrapper>
  );
};
