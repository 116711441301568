import { HStack, IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { useToggleTaskCommentReactionMutation } from "@src/__generated__/urql-graphql";
import { EmojiPopover } from "@src/components/ui-kit/EmojiPopover";
import { Icon } from "@src/components/ui-kit/Icon";
import { CommentReaction } from "@src/components/widgets/Modals/ModalCommunication/components/comment/CommentReaction";
import { CommentStoreCtx } from "@src/components/widgets/Modals/ModalCommunication/components/comment/CommentStore";
import { map } from "lodash";
import { observer } from "mobx-react-lite";
import { useContext } from "react";

export const CommentReactions = observer(function CommentReactions() {
  const store = useContext(CommentStoreCtx);
  const [_, toggleCommentReaction] = useToggleTaskCommentReactionMutation();

  const toggleReaction = (emoji_id: string) => {
    toggleCommentReaction({
      input: {
        task_comment_id: store.comment.id,
        emoji_id: emoji_id,
      },
    }).then(store.comment.setReactionsFromServer);
  };

  return (
    <HStack pt="2">
      {map(store.comment.reactionsByEmoji, (emojiReactions, emoji_id) => (
        <CommentReaction
          onClick={() => {
            toggleReaction(emoji_id);
          }}
          key={emoji_id}
          {...{ emoji_id, users: emojiReactions!.map((r) => r!.user) }}
        />
      ))}
      <EmojiPopover
        onEmojiSelect={({ id }) => {
          toggleReaction(id);
        }}
        trigger={
          <Tooltip label={t`Add reaction...`}>
            <IconButton
              bg="white"
              aria-label={t`add reaction`}
              colorScheme="grey"
              icon={<Icon name="face-happy" />}
              rounded="full"
              size="xs"
              variant="outline"
            />
          </Tooltip>
        }
      />
    </HStack>
  );
});
