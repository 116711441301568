export const getSymbolFromCode = (code: string): string => {
  return CurrencySymbols[code] ?? code;
};

const CurrencySymbols: Record<string, string> = {
  EUR: "€",
  CZK: "Kč",
  HUF: "Ft",
  USD: "$",
  AUD: "$",
};
