import { observer } from "mobx-react-lite";
import { p } from ".";
import s from "./ProgressBar.module.css";

export const ProgressBarExpensesInvoiced = observer<{
  real_expenses: number;
  planned_expenses: number;
  planned_commission: number;
  credit: number;
  credit_balance: number;
}>(function ProgressBarExpensesInvoiced({
  planned_commission,
  real_expenses,
  planned_expenses,
  credit,
  credit_balance,
}) {
  const total_budget = planned_commission + planned_expenses;

  const spendWidth = Math.max(real_expenses, total_budget);
  const creditsWidth = Math.max(credit, total_budget);
  const maxWidth = Math.max(spendWidth, creditsWidth);

  let width = (100 * creditsWidth) / maxWidth;

  let green = 0;
  let red = 0;
  let gray = 0;
  let dotted = 0;
  let dottedGreen = 0;
  let darkGray = 0;
  let dottedDarkGray = 0;

  if (credit < planned_expenses) {
    green = (credit / total_budget) * 100;
    dotted = (-credit_balance / total_budget) * 100;

    if (real_expenses < total_budget) {
      darkGray = (planned_commission / total_budget) * 100;
    }

    if (real_expenses > planned_expenses) {
      if (-credit_balance > planned_commission) {
        dottedDarkGray = (planned_commission / total_budget) * 100;
        dotted = dotted - dottedDarkGray;
      } else {
        dottedDarkGray =
          ((real_expenses - planned_expenses) / total_budget) * 100;
        darkGray = darkGray - dottedDarkGray;
      }
    } else {
      const creditBalanceSameAsInvoiced = Math.abs(dotted) === green;
      const grayWithoutCreditBalanceIncluded =
        100 - darkGray - green - dottedDarkGray;
      const grayWithCreditBalanceIncluded =
        grayWithoutCreditBalanceIncluded - dotted;

      gray = creditBalanceSameAsInvoiced
        ? grayWithoutCreditBalanceIncluded
        : grayWithCreditBalanceIncluded;
    }
  } else {
    if (credit_balance < 0) {
      green = (credit / total_budget) * 100;
      dottedDarkGray = (-credit_balance / total_budget) * 100;

      if (real_expenses <= total_budget) {
        darkGray = 100 - dottedDarkGray - green;
      }
      if (credit > total_budget) {
        red = ((credit - total_budget) / total_budget) * 100;
      }
    } else {
      green = (real_expenses / total_budget) * 100;

      if (credit > total_budget) {
        red = ((credit - total_budget) / total_budget) * 100;
        dottedGreen = Math.max(0, (credit_balance / total_budget) * 100 - red);
      } else {
        dottedGreen = (credit_balance / total_budget) * 100;
      }
    }
  }

  if ([total_budget, planned_expenses].includes(0)) {
    gray = 100;
    width = 100;
  }

  return (
    <div>
      <div className={s.box} style={{ width: p(width) }}>
        {!!green && (
          <div className={s.green} style={{ width: p(green) }}>
            {/* {p(green)} */}
          </div>
        )}
        {!!dottedGreen && (
          <div className={s.dottedGreen} style={{ width: p(dottedGreen) }}>
            {/* {dottedGreen} */}
          </div>
        )}
        {!!dotted && (
          <div className={s.dottedGray} style={{ width: p(dotted) }}></div>
        )}
        {!!gray && (
          <div className={s.gray} style={{ width: p(gray) }}>
            {/* {p(gray)} */}
          </div>
        )}
        {!!dottedDarkGray && (
          <div
            className={s.darkGrayDotted}
            style={{ width: p(dottedDarkGray) }}
          ></div>
        )}
        {!!red && (
          <div className={s.red} style={{ width: p(red) }}>
            {/* {p(red)} */}
          </div>
        )}
        {!!darkGray && (
          <div className={s.darkGray} style={{ width: p(darkGray) }}>
            {/* {p(darkGray)} */}
          </div>
        )}
      </div>
    </div>
  );
});
