import {
  TimeTrackingItemsQuery,
  TimeTrackingSummaryPerDay,
  TimeTrackingUsersQuery,
  WeekTimeReportQuery,
} from "@src/__generated__/graphql";
import { AppStore } from "@src/stores/AppStore";
import { can } from "@src/utils/components/permissions";
import { toApiDate } from "@src/utils/dates";
import { UserOption } from "@src/utils/map-to-options/users";
import { FieldState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import { WeekRow } from "../models";
import { DateStepper } from "./DateStepper";

export class Store {
  appStore: AppStore;
  stepper: DateStepper;

  @observable weekMode = false;

  @observable.ref users: TimeTrackingUsersQuery["userSimpleMap"] = [];
  @observable.ref userOptions: UserOption[] = [];
  @observable usersLoading = false;

  userId: FieldState<string>;

  @observable entries: TimeTrackingItemsQuery["timeTrackingItems"] = [];
  @observable.ref summaryPerDay: TimeTrackingSummaryPerDay[] = [];
  @observable runningTimerDate?: Date;
  @observable timeTrackingItemsLoading = false;
  timeTrackingItemsRefetch?: () => void;

  @observable weekRows: WeekRow[] = [];
  @observable weekTimeReportLoading = false;
  weekTimeReportRefetch?: () => void;

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
    this.stepper = new DateStepper();
    this.userId = new FieldState(appStore.authStore.user!.id);
  }

  @computed get currentEntries() {
    return this.entries.filter(
      (i) => i.tracked_for_date === toApiDate(this.stepper.current),
    );
  }

  @computed get isMyTimesheet() {
    return this.userId.value === this.appStore.authStore.user!.id;
  }

  @computed get currentUser() {
    return this.users.find((i) => i.id === this.userId.value);
  }

  @computed get canReadUsers() {
    return can(["timeTracking_read_all", "timeTracking_read_team"]);
  }

  @computed get canCreateEntry() {
    return this.isMyTimesheet
      ? can([
          "timeTracking_create_all",
          "timeTracking_create_team",
          "timeTracking_create_own",
        ])
      : can("timeTracking_create_all") ||
          can(
            {
              do: "timeTracking_create_team",
              eval: (user) => user.team.id === this.currentUser?.team_id,
            },
            "timeTracking_create_all",
          );
  }

  @computed get canUpdateEntry() {
    return this.isMyTimesheet
      ? can([
          "timeTracking_update_all",
          "timeTracking_update_team",
          "timeTracking_update_own",
        ])
      : can(["timeTracking_update_all", "timeTracking_update_team"]);
  }

  @computed get canUseEntry() {
    return this.isMyTimesheet
      ? can([
          "timeTracking_timer_use_all",
          "timeTracking_timer_use_team",
          "timeTracking_timer_use_own",
        ])
      : can(["timeTracking_timer_use_all", "timeTracking_timer_use_team"]);
  }

  @action setUsers(src: TimeTrackingUsersQuery) {
    this.users = src.userSimpleMap;
  }

  @action toggleWeekMode(value: boolean) {
    this.weekMode = value;
  }

  @action setUsersLoading(value: boolean) {
    this.usersLoading = value;
  }

  @action setTimeTrackingItems(src: TimeTrackingItemsQuery) {
    this.entries = src.timeTrackingItems;
    this.summaryPerDay = src.timeTrackingSummaryPerDay || [];

    const runningTimerDate =
      src.runningTimer?.timeTrackingItem?.tracked_for_date;
    this.runningTimerDate = runningTimerDate
      ? new Date(runningTimerDate)
      : undefined;
  }

  @action setWeekTimeReport(src: WeekTimeReportQuery) {
    this.weekRows = src
      ? src.weekTimeReport.rows.map(
          (i) => new WeekRow(i, this.stepper.startOfWeek),
        )
      : [];
    this.summaryPerDay =
      src.weekTimeReport.dailyTotals.map((total) => ({
        date: total.date,
        tracked_time: total.total_tracked_time,
        // TODO, temporary fix to prevent TSC error
        auto_stopped: false,
      })) || [];
  }

  @action setTimeTrackingItemsLoading(value: boolean) {
    this.timeTrackingItemsLoading = value;
  }

  @action setWeekTimeReportLoading(value: boolean) {
    this.weekTimeReportLoading = value;
  }

  @action.bound setEntryHasRunningTimer(id: string, start: boolean) {
    if (start) {
      // Start one and stop other
      this.entries.forEach((i) => {
        if (i.id === id) {
          i.has_running_timer = true;
          this.runningTimerDate = new Date(i.tracked_for_date);
        } else {
          i.has_running_timer = false;
        }
      });
    } else {
      this.entries.find((i) => {
        if (i.id === id) {
          i.has_running_timer = false;
          return true;
        }
        return false;
      });
    }
  }
}
