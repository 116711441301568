import { Role } from "@src/__generated__/graphql";
import { lowerCase, upperFirst } from "lodash";

export type toRoleOptionsParams = {
  roles: Role[];
  modifier: (option: RoleOption) => RoleOption;
};

export type RoleOption = {
  label: Role["name"];
  value: Role["id"];
};

export const toRoleOptions = (
  roles: toRoleOptionsParams["roles"],
  modifier: toRoleOptionsParams["modifier"] = (option) => option,
): RoleOption[] =>
  roles.map(({ name, id }) =>
    modifier({
      label: upperFirst(lowerCase(name)),
      value: id,
    }),
  );
