import { t } from "@lingui/macro";
import errorImg from "@src/assets/img/error-placeholder.svg";
import { ButtonLink } from "@src/components/ui-kit/Link";
import { Route } from "nextjs-routes";

interface ErrorScreenProps {
  title: string;
  link?: { text: string; href: Route["pathname"] };
}

const ErrorScreen = ({ title, link }: ErrorScreenProps) => {
  return (
    <div className="flex h-full items-center justify-around">
      <div className="w-1/2 mx-12 space-y-12">
        <h1 className="font-bold text-5xl">{title}</h1>
        {link && <ButtonLink href={link.href}>{link.text}</ButtonLink>}
      </div>
      <div className="w-1/2 mx-12">
        <img
          className="mx-auto"
          style={{ width: "90%" }}
          src={errorImg}
          alt={t`Error placeholder`}
        />
      </div>
    </div>
  );
};

export { ErrorScreen };
