const POLARIS_FIX_MULTIPLIER = 1.6;

const typography = {
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },

  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    "3": `${0.75 * POLARIS_FIX_MULTIPLIER}rem`,
    "4": `${1 * POLARIS_FIX_MULTIPLIER}rem`,
    "5": `${1.25 * POLARIS_FIX_MULTIPLIER}rem`,
    "6": `${1.5 * POLARIS_FIX_MULTIPLIER}rem`,
    "7": `${1.75 * POLARIS_FIX_MULTIPLIER}rem`,
    "8": `${2 * POLARIS_FIX_MULTIPLIER}rem`,
    "9": `${2.25 * POLARIS_FIX_MULTIPLIER}rem`,
    "10": `${2.5 * POLARIS_FIX_MULTIPLIER}rem`,
  },

  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },

  fonts: {
    heading: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
  },

  fontSizes: {
    "2xs": `${0.625 * POLARIS_FIX_MULTIPLIER}rem`,
    xs: `${0.75 * POLARIS_FIX_MULTIPLIER}rem`,
    sm: `${0.875 * POLARIS_FIX_MULTIPLIER}rem`,
    md: `${1 * POLARIS_FIX_MULTIPLIER}rem`,
    lg: `${1.125 * POLARIS_FIX_MULTIPLIER}rem`,
    xl: `${1.25 * POLARIS_FIX_MULTIPLIER}rem`,
    "2xl": `${1.5 * POLARIS_FIX_MULTIPLIER}rem`,
    "3xl": `${1.875 * POLARIS_FIX_MULTIPLIER}rem`,
    "4xl": `${2.25 * POLARIS_FIX_MULTIPLIER}rem`,
    "5xl": `${3 * POLARIS_FIX_MULTIPLIER}rem`,
    "6xl": `${3.75 * POLARIS_FIX_MULTIPLIER}rem`,
    "7xl": `${4.5 * POLARIS_FIX_MULTIPLIER}rem`,
    "8xl": `${6 * POLARIS_FIX_MULTIPLIER}rem`,
    "9xl": `${8 * POLARIS_FIX_MULTIPLIER}rem`,
  },
};

export default typography;
