import { action, makeObservable, observable } from "mobx";
import * as React from "react";
import { v4 as uuid } from "uuid";

export class DialogsStore {
  @observable modalStack = new Map<string, React.ReactElement>();

  constructor() {
    makeObservable(this);
  }

  @action.bound openModal = ({
    id,
    content,
    replace,
  }: {
    id?: string;
    content: React.ReactElement;
    replace?: boolean;
  }) => {
    const autoGeneratedId = uuid();

    if (replace) {
      this.modalStack.clear();
    }

    this.modalStack.set(id ?? autoGeneratedId, content);
  };

  @action closeModal = (id: string) => {
    this.modalStack.delete(id);
  };

  @action closeAllModals = () => {
    this.modalStack.clear();
  };
}
