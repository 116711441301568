import { chakra, ChakraProps, forwardRef } from "@chakra-ui/react";
import React from "react";

const ChakraForm = chakra("form");

interface FormProps extends ChakraProps {
  children: React.ReactNode;
  onSubmit: JSX.IntrinsicElements["form"]["onSubmit"];
}

export const Form = forwardRef<FormProps, "form">(
  ({ children, onSubmit, ...props }, ref) => {
    return (
      <ChakraForm
        ref={ref}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit?.(e);
        }}
        {...props}
      >
        {children}
      </ChakraForm>
    );
  },
);
