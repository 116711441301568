const FULL_WIDTH = 100;

export function ouWorkSpendWidth(
  credit: number,
  total_budget: number,
  spend_budget: number,
) {
  if (credit > total_budget && spend_budget > total_budget) {
    // both overflow, we need to find out which one is more overflowed and that one will have FULL_WIDTH and the other will be reduced
    if (credit > spend_budget) {
      return (FULL_WIDTH * spend_budget) / credit;
    } else if (credit < spend_budget) {
      return FULL_WIDTH;
    } else {
      return FULL_WIDTH;
    }
  } else if (spend_budget > total_budget) {
    // only Work spend is overflowed, we need to reduce the other one
    return FULL_WIDTH;
  } else if (credit > total_budget) {
    // only Invoiced is overflowed, we need to reduce the other one
    return (FULL_WIDTH * total_budget) / credit;
  } else {
    // nothing overflows
    return FULL_WIDTH;
  }
}

export function ouWorkInvoicedWidth(
  credit: number,
  total_budget: number,
  spend_budget: number,
) {
  if (credit > total_budget && spend_budget > total_budget) {
    // both overflow, we need to find out which one is more overflowed and that one will have FULL_WIDTH and the other will be reduced
    if (credit > spend_budget) {
      return FULL_WIDTH;
    } else if (credit < spend_budget) {
      return (FULL_WIDTH * credit) / spend_budget;
    } else {
      return FULL_WIDTH;
    }
  } else if (spend_budget > total_budget) {
    // only Work spend is overflowed, we need to reduce the other one

    return (FULL_WIDTH * total_budget) / spend_budget;
  } else if (credit > total_budget) {
    // only Invoiced is overflowed, we need to reduce the other one

    return FULL_WIDTH;
  } else {
    // nothing overflows

    return FULL_WIDTH;
  }
}
