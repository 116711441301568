import { AttachmentFragment } from "@src/__generated__/urql-graphql";
import { Editor } from "@tiptap/core";
import { Plugin, Selection } from "prosemirror-state";

export interface Attachment {
  id: string;
  mime_type: string;
  filename: string;
  viewable: string | undefined;
  thumbnail: string | undefined;
  original: string | undefined;
}

/**
 * function for image drag n drop(for tiptap)
 * @see https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521#gistcomment-3744392
 */
export type OnUpload = () => {
  upload: (file: File) => Promise<AttachmentFragment | undefined>;
  cancel: () => void;
};

export const UploadAttachmentPlugin = (onUpload: OnUpload, editor: Editor) => {
  return new Plugin({
    props: {
      handleDOMEvents: {
        paste(view, _event: Event) {
          // event param must be Event type. don't change it to ClipboardEvent type. (source: https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521?permalink_comment_id=4254697#gistcomment-4254697)
          const event = _event as ClipboardEvent;

          const items = Array.from(event.clipboardData?.items || []);
          const { schema } = view.state;

          // Pasting copied text from Word, pastes multiple items from clipboard first 3 are kind 'string', last one is of kind 'file'
          // If I don't return here, it uploads photo of text, instead of text itself
          // see https://brackets.atlassian.net/browse/ALLF-3931
          if (items.some(({ kind }) => kind === "string")) return;

          // It looks like pasting multiple files is not supported in Firefox. (https://stackoverflow.com/questions/71796358/javascript-issue-clipboarddata-items-and-clipboarddata-files-are-empty-when-pas)
          items.forEach((item, index) => {
            const attachment = item.getAsFile();

            if (attachment) {
              // If item is file prevent default action of copying files name as a text to editor
              event.preventDefault();

              const node = schema.nodes.attachment.create({
                loading: true,
                id: onUpload().upload(attachment),
              });

              const transaction = view.state.tr.replaceSelectionWith(node);
              view.dispatch(transaction);

              if (items.length === index + 1) {
                // to move cursor after attachment (can it be done better?)
                editor.commands.insertContentAt(
                  Selection.atEnd(editor.state.doc).$head.pos,
                  "<p></p>",
                );
                editor.commands.focus("end");
              }
            }
          });

          return false;
        },
        drop(view, _event: Event) {
          // event param must be Event type. don't change it to DragEvent type. (source: https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521?permalink_comment_id=4254697#gistcomment-4254697)
          const event = _event as DragEvent;

          event.preventDefault();

          const hasFiles =
            event.dataTransfer &&
            event.dataTransfer.files &&
            event.dataTransfer.files.length;

          if (!hasFiles) {
            return false;
          }

          const attachments: File[] = Array.from(event.dataTransfer.files);

          if (attachments.length === 0) {
            return false;
          }

          const { schema } = view.state;
          const coordinates = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });

          attachments.forEach((attachment, index) => {
            const node = schema.nodes.attachment.create({
              loading: true,
              id: onUpload().upload(attachment),
            });
            const transaction = view.state.tr.insert(coordinates!.pos, node);
            view.dispatch(transaction);

            if (attachments.length === index + 1) {
              // to move cursor after attachment (can it be done better?)
              editor.commands.insertContentAt(
                coordinates!.pos + node.nodeSize * attachments.length + 1,
                "<p></p>",
              );
              editor.commands.focus("end");
            }
          });
          return false;
        },
      },
    },
  });
};
