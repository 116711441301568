import { useTaskCommentsLazyQuery } from "@src/__generated__/graphql";
import { ModalCommunicationStore } from "@src/components/widgets/Modals/ModalCommunication/ModalCommunicationStore";
import { CommentModel } from "@src/components/widgets/Modals/ModalCommunication/models/CommentModel";
import { onError } from "@src/utils/apollo";
import { useEffect } from "react";

const useComments = (
  args: { taskId?: string },
  isModalOpen: boolean,
  store: ModalCommunicationStore,
): [
  CommentModel[],
  {
    loadingComments: boolean;
    hasMoreComments: boolean;
    fetchMoreComments: () => void;
    addComment: (comment: CommentModel) => void;
    replaceComment: (toReplaceId: string, comment: CommentModel) => void;
    deleteComment: (id: string) => void;
  },
] => {
  const [fetchComments, { loading }] = useTaskCommentsLazyQuery({
    onCompleted(result) {
      if (result.taskComments) {
        store.comments.set([
          ...store.comments.value,
          ...result.taskComments.data.map(
            (comment) => new CommentModel(comment),
          ),
        ]);
        store.commentsPagination.setFromPaginatorInfo(
          result.taskComments.paginatorInfo,
        );
      }
    },
    ...onError(),
  });

  const getLastCommentID = (): string | undefined => {
    if (store.comments.value.length === 0) return undefined;
    return store.comments.value[store.comments.value.length - 1].id;
  };

  const fetchMore = () => {
    if (store.comments.value && args.taskId) {
      fetchComments({
        variables: {
          id: args.taskId,
          lastCommentId: getLastCommentID(),
        },
      });
    }
  };

  const addComment = (comment: CommentModel) => {
    store.comments.set(
      store.comments.value
        ? [comment, ...store.comments.value]
        : store.comments.value,
    );
  };

  const replaceComment = (toReplaceId: string, comment: CommentModel) => {
    store.comments.set(
      store.comments.value
        ? store.comments.value.map((i) => (i.id === toReplaceId ? comment : i))
        : store.comments.value,
    );
  };

  const deleteComment = (id: string) => {
    store.comments.set(
      store.comments.value
        ? store.comments.value.filter((i) => i.id !== id)
        : store.comments.value,
    );
  };

  useEffect(() => {
    if (isModalOpen && args.taskId) {
      store.comments.set([]);
      fetchComments({ variables: { id: args.taskId } });
    } else {
      store.comments.set([]);
    }
  }, [isModalOpen, args.taskId]);

  const hasMore = !!store.commentsPagination.hasNextPage;

  return [
    store.comments.value,
    {
      loadingComments: loading,
      hasMoreComments: hasMore,
      fetchMoreComments: fetchMore,
      addComment,
      replaceComment,
      deleteComment,
    },
  ];
};

export default useComments;
