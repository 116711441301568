import {
  EmailDataFragment,
  GetInvoiceEmailDataDocument,
  GetInvoiceEmailDataQuery,
  GetInvoiceEmailDataQueryVariables,
  GetProformaEmailDataDocument,
  GetProformaEmailDataQuery,
  GetProformaEmailDataQueryVariables,
  SendOutgoingInvoiceDocument,
  SendOutgoingInvoiceMutation,
  SendOutgoingInvoiceMutationVariables,
  SendOutgoingProformaDocument,
  SendOutgoingProformaMutation,
  SendOutgoingProformaMutationVariables,
} from "@src/__generated__/urql-graphql";
import { EMPTY_EDITOR_LENGTH } from "@src/components/ui-kit/TextEditor/TextEditor";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import {
  minLength,
  multipleEmails,
  required,
} from "@src/utils/forms/validators";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import { SendInvoiceModal } from "../Modal";

type TInvoiceType = "outgoing" | "proforma";

type TAdditionalData = {
  id: string;
  number: string;
  type: TInvoiceType;
  preview_url: string | null | undefined;
  onSubmit: () => void;
};

export class SendInvoiceModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  readonly modalId = "sendInvoiceModal";
  @observable fetching = false;
  @observable sendingInvoice = false;

  form = new FormState({
    recipients: new FieldState("")
      .validators(multipleEmails, required)
      .enableAutoValidation(),
    subject: new FieldState<string | undefined>(undefined),
    content: new FieldState("")
      .validators(required, minLength(EMPTY_EDITOR_LENGTH - 1))
      .enableAutoValidation(),
  });

  @observable modalState = new DisclosureState<TAdditionalData>({
    onOpen: (data) => {
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <SendInvoiceModal />,
      });

      if (!data) return;

      this.fetchEmailData(data.id, data.type);
    },
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
      this.modalState.additionalData = undefined;
      this.form.reset();
    },
  });

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  fillForm({ recipients, subject, content }: EmailDataFragment) {
    this.form.$.recipients.onChange(recipients.join(", "));
    this.form.$.subject.onChange(subject);
    this.form.$.content.onChange(content);
  }

  async serialize(): Promise<EmailDataFragment | undefined> {
    const { hasError } = await this.form.validate();
    if (hasError) return;
    if (!this.modalState.additionalData?.id) return;

    return {
      id: this.modalState.additionalData.id,
      content: this.form.$.content.$.replaceAll("<p></p>", "<br/>"),
      subject: this.form.$.subject.$ ?? "",
      recipients: this.form.$.recipients.$.replaceAll(" ", "").split(","),
    };
  }

  @action async fetchEmailData(invoiceId: string, type: TInvoiceType) {
    this.fetching = true;
    let emailData: EmailDataFragment | undefined | null;

    switch (type) {
      case "outgoing":
        const invoiceResponse = await client.query<
          GetInvoiceEmailDataQuery,
          GetInvoiceEmailDataQueryVariables
        >(GetInvoiceEmailDataDocument, {
          outgoing_invoice_id: invoiceId,
        });

        emailData = invoiceResponse.data?.getInvoiceEmailData;
        break;
      case "proforma":
        const proformaResponse = await client.query<
          GetProformaEmailDataQuery,
          GetProformaEmailDataQueryVariables
        >(GetProformaEmailDataDocument, {
          proforma_invoice_id: invoiceId,
        });

        emailData = proformaResponse.data?.getProformaInvoiceEmailData;
        break;
      default:
        break;
    }

    this.fetching = false;

    if (!emailData) return;

    this.fillForm(emailData);
  }

  @action.bound async sendEmail() {
    const formData = await this.serialize();
    if (!formData) return;

    this.sendingInvoice = true;

    switch (this.modalState.additionalData?.type) {
      case "outgoing":
        await client.mutation<
          SendOutgoingInvoiceMutation,
          SendOutgoingInvoiceMutationVariables
        >(SendOutgoingInvoiceDocument, formData);
        break;
      case "proforma":
        await client.mutation<
          SendOutgoingProformaMutation,
          SendOutgoingProformaMutationVariables
        >(SendOutgoingProformaDocument, formData);
        break;
      default:
        break;
    }

    this.sendingInvoice = false;

    this.modalState.additionalData?.onSubmit();
  }
}
