export const CZK = (
  <svg
    viewBox="0 0 40 40"
    height="20"
    width="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0h40v20H20L0 0z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0l20 20L0 40V0z"
      fill="#0C4480"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 20h20v20H0l20-20z"
      fill="#D90E08"
    />
  </svg>
);
