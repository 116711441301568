import { Box, Flex, Table, Tr } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { FC, ReactNode } from "react";

interface SummaryBoxProps {
  label?: ReactNode;
  justify?: "space-around" | "space-between" | "space-evenly" | "stretch";
  hideBg?: boolean;
}

export const SUMMARY_BOX_BG = "#f1f6f8";

const SummaryBox: FC<React.PropsWithChildren<SummaryBoxProps>> = ({
  label,
  children,
  hideBg,
}) => (
  <Box mb="4" px="2">
    <Flex
      align="center"
      flex="1"
      mx={!hideBg ? "2" : undefined}
      bg={!hideBg ? SUMMARY_BOX_BG : undefined}
      dir="column"
      rounded="lg"
    >
      <Box h="full">
        {!!label && (
          <Flex px="4">
            <label className="font-semibold text-inkLighter">{label}</label>
          </Flex>
        )}
      </Box>
      <Table
        sx={{
          // eslint-disable-next-line lingui/no-unlocalized-strings
          borderSpacing: "var(--chakra-space-2) 0",
          borderCollapse: "separate",
        }}
      >
        <Tr>{children}</Tr>
      </Table>
    </Flex>
  </Box>
);

export default observer(SummaryBox);
