import {
  Box,
  Button,
  Center,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useClipboard,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  Banner,
  Link,
  LoadingOverlay,
  ModalConfirm,
  UserTooltip,
} from "@components/ui-kit";
import { t, Trans } from "@lingui/macro";
import {
  useCheckTaskRevisionLazyQuery,
  useDeleteTaskMutation,
  useGetPrioritiesOptionsQuery,
  useMarkTaskNotificationsAsReadMutation,
} from "@src/__generated__/graphql";
import {
  SimpleTaskFragment,
  Task,
  useTaskQuery,
  useUpdateTaskPriorityMutation,
} from "@src/__generated__/urql-graphql";
import { BudgetIcon, Icon, TrackedTimeIcon } from "@src/components/ui-kit/Icon";
import { SharepointLink } from "@src/components/ui-kit/SharepointLink";
import { TaskPriorityBadge } from "@src/components/ui-kit/TaskPriorityBadge";
import { UncollapseOnHover } from "@src/components/ui-kit/UncollapseOnHover";
import { TaskTabs } from "@src/components/widgets/Modals/ModalCommunication/components/tabs/TaskTabs";
import { CommentModel } from "@src/components/widgets/Modals/ModalCommunication/models/CommentModel";
import { PositionBadgeWithHours } from "@src/components/widgets/Modals/ModalCommunication/PositionBadgeWithHours";
import { SectionHeading } from "@src/components/widgets/Modals/ModalCommunication/SectionHeading";
import { ModalTaskEdit } from "@src/components/widgets/Modals/ModalTask/ModalTaskEdit";
import { TaskTabIdEnum } from "@src/constants/tasks";
import { trackEvent } from "@src/services/amplitude";
import { onError } from "@src/utils/apollo";
import { useStore as useAppStore, useStore } from "@src/utils/hooks";
import { useScreenType } from "@src/utils/hooks/useIsMobile";
import mapToOptions from "@src/utils/map-to-options";
import { FormMode } from "@src/utils/types";
import { format } from "date-fns";
import { kebabCase } from "lodash";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import React, {
  Fragment,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  CommunicationModalHeader,
  TASK_ID_QUERY_KEY,
} from "./CommunicationModalHeader";
import { CommentInput } from "./components";
import { TaskModel } from "./models";

const COLLAPSED_POSITIONS_HEIGHT = 100;

export const TASK_REVISION_CHECK_INTERVAL = 10000;

export const timeToHrs = (time: number, decimals = 1): string => {
  const timeNum = typeof time === "number" ? time : Number(time ?? 0);
  return (timeNum / 3600).toFixed(decimals);
};

export function useHasTaskIdInUri(onHasTaskId: (taskId: Task["id"]) => void) {
  const { query } = useRouter();

  useEffect(() => {
    if (query && query[TASK_ID_QUERY_KEY]) {
      onHasTaskId(query[TASK_ID_QUERY_KEY] as Task["id"]);
    }
  }, [query]);
}

export function useHasCommentIdInUri(onHasCommentId: () => void) {
  const { query } = useRouter();

  useEffect(() => {
    if (!query) return;
    if (!query[TASK_ID_QUERY_KEY]) return;
    if (!query.commentId?.length) return;

    onHasCommentId();
  }, [query]);
}

export const ModalCommunication = observer(function ModalCommunication() {
  const appStore = useAppStore();
  const { taskDetailModalStore: store, tasksListingStore } = useStore();
  const { isMobile } = useScreenType();
  const budgetItem = store.task.value?.ourWorkBudgetItem;
  const discardModal = useDisclosure();
  const [showAllPositions, setShowAllPositions] = useState(false);
  const collapsePositionsRef = useRef<HTMLDivElement>(null);
  const [shouldCollapsePositions, setShouldCollapsePositions] = useState(false);
  const PositionsWrapper = shouldCollapsePositions ? Collapse : Fragment;
  const positionsProps = shouldCollapsePositions
    ? { in: showAllPositions, startingHeight: COLLAPSED_POSITIONS_HEIGHT }
    : {};

  useLayoutEffect(() => {
    const rect = collapsePositionsRef.current?.getBoundingClientRect();
    if (rect) {
      setShouldCollapsePositions(rect.height >= COLLAPSED_POSITIONS_HEIGHT);
    }
  }, [store.isLoading.value, store.isLoadingDuplicate.value]);

  const { onCopy } = useClipboard(
    `${location.origin}/notifications?${TASK_ID_QUERY_KEY}=${store.task.value?.id}`,
  );
  const toast = useToast();

  const [checkTaskRevision] = useCheckTaskRevisionLazyQuery({
    variables: { id: store.taskId.value! },
    onCompleted(data) {
      if (!data.task.revision) return;
      if (!store.task.value?.revision) return;
      if (data.task.revision !== store.task.value.revision) {
        store.needsRevision.on();
      } else {
        store.needsRevision.off();
      }
    },
    ...onError(),
  });

  const [{ data, fetching }, fetchTask] = useTaskQuery({
    pause: true,
    variables: { id: store.taskId.value! },
  });

  const [{ fetching: updatingPriority }, updatePriority] =
    useUpdateTaskPriorityMutation();

  useEffect(() => {
    if (fetching) {
      store.isLoading.on();
    } else {
      store.isLoading.off();
    }
  }, [fetching]);

  useEffect(() => {
    if (store.taskId.value && store.drawerState.isOpen) {
      fetchTask();
      store.fetchTask = fetchTask;
      markTaskNotificationsAsRead({
        variables: {
          task_id: store.taskId.value,
        },
      });
    }

    const id = setInterval(() => {
      if (
        store.taskId.value &&
        store.drawerState.isOpen &&
        !store.descriptionLoading.value &&
        !store.editTaskModal.isOpen
      ) {
        checkTaskRevision();
      }
    }, TASK_REVISION_CHECK_INTERVAL);

    return () => clearInterval(id);
  }, [
    store.taskId.value,
    store.drawerState.isOpen,
    store.editTaskModal.isOpen,
  ]);

  useGetPrioritiesOptionsQuery({
    skip: !store.drawerState.isOpen,
    onCompleted(data) {
      if (data.taskPriorities) {
        store.priorityOptions = mapToOptions.priorities.toOptions(
          data.taskPriorities,
        );
      }
    },
  });

  useEffect(() => {
    if (data?.task) {
      store.task.set(new TaskModel(data.task, data.taskPositionStat));
      store.taskStatuses.set(data.taskStatuses);
    } else {
      store.task.set(null);
      store.taskStatuses.set([]);
    }
  }, [data]);

  const onUpdate = (task: TaskModel | SimpleTaskFragment) => {
    store.drawerState.additionalData?.onChange?.(task);
    store.fetchTask?.();
    tasksListingStore.resetPagination();
  };

  const [markTaskNotificationsAsRead] = useMarkTaskNotificationsAsReadMutation({
    ...onError(),
  });

  const [deleteTask] = useDeleteTaskMutation({
    onCompleted() {
      appStore.UIStore.toast({
        title: t`The task has been deleted`,
        status: "success",
      });
      store.drawerState.onClose();
      trackEvent("task", "Deleted task");
      if (store.task.value) {
        store.drawerState.additionalData?.onDelete?.(store.task.value);
        tasksListingStore.removeTask(store.task.value);
      }
    },
    ...onError(),
  });

  const onCommentCreated = (comment: CommentModel) => {
    store.newlyCreatedCommentID = comment.id;
    store.comments.set(
      store.comments.value
        ? [comment, ...store.comments.value]
        : store.comments.value,
    );

    // scroll to view new comment
    store.commentsContainerRef.current?.scrollIntoView();
  };

  return (
    <Fragment>
      <Drawer
        // Keep false, otherwise emoji popovers wont be able to scroll
        blockScrollOnMount={false}
        isFullHeight
        isOpen={store.drawerState.isOpen}
        onClose={() => {
          if (store.descriptionChanged || store.commentChanged) {
            discardModal.onOpen();
          } else {
            store.drawerState.onClose();
          }
        }}
        placement="right"
        trapFocus={false}
      >
        <DrawerContent
          w={{
            base: "full",
            md: "900px",
            "1.5xl": "980px",
          }}
          maxW={{
            base: "full",
            md: "900px",
            "1.5xl": "980px",
          }}
          h="100dvh"
          borderColor="grey.100"
          borderLeftWidth="1px"
          shadow="2xl"
        >
          <CommunicationModalHeader
            onTimeTrackingItemCreated={(trackedTime) => {
              store.drawerState.additionalData?.onTimeTrackingItemCreated?.(
                trackedTime,
              );
            }}
          />
          <LoadingOverlay
            isLoading={store.isLoading.value || store.isLoadingDuplicate.value}
          />
          {store.task.value && !store.isLoadingDuplicate.value && (
            <React.Fragment>
              {store.needsRevision.value && (
                <Banner
                  status="warning"
                  variant="contained"
                  pl="8"
                  pr="6"
                  borderTop="4px"
                  borderTopColor="orange.500"
                  title={t`Someone has already edited the task`}
                  description={t`Copy your changes to paste it after reloading the task.`}
                  primaryAction={{
                    title: t`Reload task`,
                    loading: fetching,
                    onClick: () => {
                      fetchTask();
                    },
                    leftIcon: <Icon name="repeat-04" />,
                  }}
                />
              )}

              <DrawerBody
                ref={store.drawerBodyRef}
                overflowX="hidden"
                px="0"
                py="4"
              >
                <Box justifyItems="start" w="full">
                  <Box w="full" px="8" pb="4">
                    <HStack
                      alignItems="start"
                      justifyContent={isMobile ? "space-between" : undefined}
                      wrap={isMobile ? "wrap" : undefined}
                      mb="5"
                      fontWeight="medium"
                      spacing="4"
                    >
                      <VStack
                        align="stretch"
                        flex="1"
                        flexBasis={isMobile ? "100%" : undefined}
                        spacing="3px"
                      >
                        <Heading
                          maxW={{
                            base: "300px",
                            "1.5xl": "350px",
                          }}
                          fontWeight="semibold"
                          size="md"
                        >
                          <UncollapseOnHover
                            hiddenComponent={
                              <Tooltip label={t`Copy link to task`}>
                                <Button
                                  verticalAlign="top"
                                  color="inherit"
                                  fontSize="xs"
                                  fontWeight="medium"
                                  onClick={() => {
                                    onCopy();
                                    toast({
                                      title: t`Link copied to clipboard`,
                                      status: "info",
                                      isClosable: true,
                                      duration: 3000,
                                    });
                                  }}
                                  variant="link"
                                >
                                  {store.task.value.id}
                                </Button>
                              </Tooltip>
                            }
                            dataComponentName="task-id-copy-link"
                            width="4"
                          >
                            <Text
                              fontSize="inherit"
                              fontWeight="inherit"
                              isTruncated
                            >
                              {store.task.value.name}
                            </Text>
                          </UncollapseOnHover>
                        </Heading>
                        <SectionHeading>
                          <Trans>Project:</Trans>{" "}
                          {store.task.value.ourWorkBudgetItem ? (
                            <Tooltip
                              label={`${store.task.value.ourWorkBudgetItem.project.brand.client.name} - ${store.task.value.ourWorkBudgetItem.project.brand.name} - ${store.task.value.ourWorkBudgetItem.project.title}`}
                            >
                              <span>
                                <Link
                                  href={{
                                    pathname: "/projects/detail",
                                    query: {
                                      id: store.task.value.ourWorkBudgetItem
                                        .project.id,
                                    },
                                  }}
                                  color="inherit"
                                  onClick={() => store.drawerState.onClose()}
                                >
                                  {`${store.task.value.ourWorkBudgetItem.project.code} / ${store.task.value.ourWorkBudgetItem.project.title}`}
                                </Link>
                              </span>
                            </Tooltip>
                          ) : (
                            <Trans>Unassigned</Trans>
                          )}
                        </SectionHeading>
                        <SectionHeading>
                          <Trans>Budget item:</Trans>{" "}
                          {store.task.value.ourWorkBudgetItem ? (
                            <Tooltip
                              label={`${store.task.value.ourWorkBudgetItem.project.brand.client.name} - ${store.task.value.ourWorkBudgetItem.project.brand.name} - ${store.task.value.ourWorkBudgetItem.project.title}`}
                            >
                              <span>
                                <Link
                                  href={{
                                    pathname: "/projects/detail",
                                    query: {
                                      id: store.task.value.ourWorkBudgetItem
                                        .project.id,
                                    },
                                    hash: kebabCase(
                                      store.task.value.ourWorkBudgetItem.title,
                                    ),
                                  }}
                                  color="inherit"
                                  onClick={() => store.drawerState.onClose()}
                                >
                                  {store.task.value.ourWorkBudgetItem.title}
                                </Link>
                              </span>
                            </Tooltip>
                          ) : (
                            <Trans>Unassigned</Trans>
                          )}
                        </SectionHeading>
                        {store.task.value.createdBy && (
                          <SectionHeading>
                            <HStack spacing="1">
                              <Trans>Creator:</Trans>
                              <UserTooltip user={store.task.value.createdBy}>
                                {store.task.value.createdBy.full_name}
                              </UserTooltip>
                            </HStack>
                          </SectionHeading>
                        )}
                      </VStack>
                      <Grid gap="4" templateColumns="repeat(3, 1fr)">
                        <GridItem>
                          <TaskHeaderInfo
                            title={t`Priority`}
                            icon={
                              <Icon
                                name="bar-chart-03"
                                transform="rotate(90deg)"
                                boxSize="5"
                              />
                            }
                          >
                            <TaskPriorityBadge
                              rounded="base"
                              priority={store.task.value.priority}
                              isEditable
                              taskPriorities={data?.taskPriorities}
                              isLoading={updatingPriority}
                              onChange={async (newPriorityId) => {
                                if (
                                  !store.task.value?.id ||
                                  typeof newPriorityId !== "string"
                                )
                                  return;
                                updatePriority({
                                  task_id: store.task.value.id,
                                  priority_id: newPriorityId,
                                }).then(({ data }) => {
                                  if (!data || !store.task.value) return;
                                  store.task.value.priority =
                                    data.updateTaskPriority.priority;
                                });
                              }}
                            />
                          </TaskHeaderInfo>
                        </GridItem>

                        <GridItem>
                          <TaskHeaderInfo
                            title={t`Tracked`}
                            icon={<TrackedTimeIcon boxSize="5" />}
                          >
                            <Link
                              href={{
                                pathname: "/projects/time-report",
                                query: {
                                  id: budgetItem?.project.id,
                                  budgetItemId: budgetItem?.id,
                                },
                              }}
                              onClick={() => store.drawerState.onClose()}
                            >
                              <Text fontWeight="medium" size="xs">
                                {timeToHrs(
                                  store.task.value.stats?.spent_time ?? 0,
                                )}
                              </Text>
                            </Link>
                          </TaskHeaderInfo>
                        </GridItem>

                        <GridItem>
                          <TaskHeaderInfo
                            title={t`Budget`}
                            icon={<BudgetIcon boxSize="5" />}
                          >
                            <Link
                              href={{
                                pathname: budgetItem?.project?.uses_budgetovac
                                  ? "/projects/budgets"
                                  : "/projects/budget",
                                query: { id: budgetItem?.project.id },
                              }}
                              onClick={() => store.drawerState.onClose()}
                            >
                              <Text fontWeight="medium" size="xs">
                                {timeToHrs(
                                  store.task.value.stats?.planned_time ?? 0,
                                )}
                              </Text>
                            </Link>
                          </TaskHeaderInfo>
                        </GridItem>

                        {store.showDurationInfo && (
                          <GridItem
                            gridColumn={store.showDeadlineInfo ? -3 : -2}
                          >
                            <TaskHeaderInfo
                              title={t`Duration`}
                              icon={
                                <Icon name="clock-fast-forward" boxSize="5" />
                              }
                            >
                              {store.task.value.from && (
                                <Text
                                  color="black"
                                  fontWeight="medium"
                                  size="xs"
                                >
                                  {format(store.task.value.from, "MMM d, yyyy")}
                                </Text>
                              )}
                              {store.task.value.to && (
                                <Text
                                  color="black"
                                  fontWeight="medium"
                                  size="xs"
                                >
                                  {format(store.task.value.to, "MMM d, yyyy")}
                                </Text>
                              )}
                            </TaskHeaderInfo>
                          </GridItem>
                        )}

                        {store.showDeadlineInfo && (
                          <GridItem gridColumn={-2}>
                            <TaskHeaderInfo
                              title={t`Deadline`}
                              icon={<Icon name="calendar-date" boxSize="5" />}
                            >
                              <Text color="black" fontWeight="medium" size="xs">
                                {format(
                                  store.task.value.deadlineDate ?? new Date(),
                                  "MMM d, yyyy",
                                )}
                              </Text>
                            </TaskHeaderInfo>
                          </GridItem>
                        )}
                      </Grid>
                    </HStack>

                    <SectionHeading iconName="users-01">
                      <Trans>Assigned users</Trans>
                    </SectionHeading>

                    <Box mt="2" mb="4">
                      <Flex ref={collapsePositionsRef} direction="column">
                        <PositionsWrapper {...positionsProps}>
                          <Flex wrap="wrap">
                            {store.task.value.positionsStats.map(
                              (position, index) => (
                                <PositionBadgeWithHours
                                  key={index}
                                  mr="2"
                                  mb="2"
                                  workTypeTitle={
                                    position.timeTrackingWorkType.title
                                  }
                                  user={position.user!}
                                  reportedValue={position.spent_time}
                                  plannedValue={position.planned_time}
                                />
                              ),
                            )}
                          </Flex>
                        </PositionsWrapper>
                        <Flex
                          pos="relative"
                          direction="column"
                          hidden={!shouldCollapsePositions}
                        >
                          <Box
                            pos="absolute"
                            top="-20px"
                            w="100%"
                            h="20px"
                            bg={
                              showAllPositions
                                ? ""
                                : "linear-gradient(180deg, transparent 0%, #fff 100%)"
                            }
                            pointerEvents="none"
                          />
                          <IconButton
                            aria-label={t`Show all positions`}
                            icon={
                              <Icon
                                name={
                                  showAllPositions
                                    ? "chevron-up"
                                    : "chevron-down"
                                }
                              />
                            }
                            onClick={() => {
                              setShowAllPositions(!showAllPositions);
                            }}
                            variant="ghost"
                          />
                        </Flex>
                      </Flex>
                      {!!store.task.value.otherPeopleTrackedTotal &&
                        store.task.value.otherPeopleTracked?.length && (
                          <Tooltip
                            label={store.task.value.otherPeopleTracked?.map(
                              ({
                                user,
                                tracked_time,
                                time_tracking_work_type_id,
                              }) => {
                                const foundPosition =
                                  store.task.value?.ourWorkBudgetItem?.project.availableTimeTrackingWorkTypes.find(
                                    ({ id }) =>
                                      id === time_tracking_work_type_id,
                                  );

                                return (
                                  <Text
                                    key={
                                      user.full_name +
                                      time_tracking_work_type_id
                                    }
                                  >
                                    {user.full_name +
                                      " / " +
                                      foundPosition?.title +
                                      ": " +
                                      timeToHrs(tracked_time) +
                                      "h"}
                                  </Text>
                                );
                              },
                            )}
                          >
                            <Text w="fit-content" color="grey.600">
                              <Trans>Tracked by unassigned users:</Trans>&nbsp;
                              <Text display="inline" color="grey.900">
                                {timeToHrs(
                                  store.task.value.otherPeopleTrackedTotal,
                                )}
                              </Text>
                            </Text>
                          </Tooltip>
                        )}

                      {!!store.task.value.cloudUrl && (
                        <SharepointLink
                          mt="6"
                          href={store.task.value.cloudUrl}
                        />
                      )}
                    </Box>

                    {/* Workaround - to rerender editors initial value after edit from modal  */}
                    {!store.isLoading.value && <TaskTabs />}
                  </Box>
                </Box>
              </DrawerBody>
              {store.activeTabId.value === TaskTabIdEnum.Communication && (
                <DrawerFooter
                  px="8"
                  borderColor="grey.100"
                  borderTopWidth="1px"
                >
                  <CommentInput
                    taskId={store.task.value.id}
                    onCreated={onCommentCreated}
                  />
                </DrawerFooter>
              )}
            </React.Fragment>
          )}
        </DrawerContent>
      </Drawer>

      {store.task.value && (
        <React.Fragment>
          <ModalConfirm
            destructive
            cancelBtnTitle={t`Cancel`}
            confirmBtnTitle={t`Delete`}
            onConfirm={() => {
              if (store.task.value) {
                deleteTask({ variables: { id: store.task.value.id } });
              }
            }}
            isOpen={store.deleteModal.isOpen}
            onClose={store.deleteModal.onClose}
          >
            <Box>
              {store.task.value.cloudUrl && (
                <Banner
                  title={t`When you delete the task, the corresponding folder on SharePoint will be automatically removed.`}
                  status="warning"
                  mb="4"
                />
              )}
              <Text>
                <Trans>Are you sure that you want to delete task</Trans>{" "}
                {store.task.value.name}?
              </Text>
            </Box>
          </ModalConfirm>
          <ModalTaskEdit
            taskId={store.task.value.id}
            projectId={store.task.value.ourWorkBudgetItem?.project.id}
            onUpdate={onUpdate}
            isOpen={store.editTaskModal.isOpen}
            onClose={() => {
              store.editTaskModal.onClose();

              runInAction(() => {
                store.editTaskModal.additionalData = undefined;
              });
            }}
            priorityOptions={store.priorityOptions}
            statusOptions={store.statusOptions}
            mode={
              store.editTaskModal.additionalData?.moveMode
                ? FormMode.MOVE
                : FormMode.EDIT
            }
          />
        </React.Fragment>
      )}
      <ModalConfirm
        isOpen={discardModal.isOpen}
        onClose={discardModal.onClose}
        onConfirm={() => {
          store.descriptionChanged = false;
          store.commentChanged = false;
          store.drawerState.onClose();
        }}
        confirmBtnTitle={t`Discard changes`}
        destructive
      >
        <Trans>
          Your have unsaved changes. Would you like to discard them?
        </Trans>
      </ModalConfirm>
    </Fragment>
  );
});

type TaskHeaderInfoProps = {
  title: string;
  icon: ReactElement;
};
const TaskHeaderInfo = observer(function TaskHeaderInfo({
  title,
  icon,
  children,
}: PropsWithChildren<TaskHeaderInfoProps>) {
  return (
    <HStack align="start" spacing="3">
      <Center boxSize="10" bg="gray.200" rounded="lg">
        {icon}
      </Center>
      <VStack align="start" spacing="0">
        <Text color="grey" fontSize="xs">
          {title}
        </Text>
        {children}
      </VStack>
    </HStack>
  );
});
