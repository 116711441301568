import { t, Trans } from "@lingui/macro";
import { UserSimple } from "@src/__generated__/urql-graphql";
import { Banner } from "@src/components/ui-kit";
import { observer } from "mobx-react-lite";

interface BannerNotMySheetProps {
  meId: string;
  currentUser?: Pick<UserSimple, "id" | "full_name">;
  onAction: () => void;
}

const BannerNotMySheet = ({
  meId,
  currentUser,
  onAction,
}: BannerNotMySheetProps) => {
  if (!currentUser || meId === currentUser.id) {
    return null;
  }

  return (
    <Banner
      mb="6"
      status="warning"
      title={t`You are viewing and editing:`}
      description={
        <Trans>
          <b>{currentUser.full_name}</b> timesheet.
        </Trans>
      }
      primaryAction={{
        title: t`Go to my timesheet`,
        onClick: onAction,
      }}
    />
  );
};

export default observer(BannerNotMySheet);
