import { Box } from "@chakra-ui/react";
import { Spinner } from "@src/components/ui-kit";

const LoadingEntries = () => (
  <Box pos="sticky" left="0" w="100vw" h="24">
    <Spinner />
  </Box>
);

export default LoadingEntries;
