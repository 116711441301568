import {
  CreateFilterTemplateDocument,
  CreateFilterTemplateMutation,
  CreateFilterTemplateMutationVariables,
  FilterTemplatesDocument,
  FilterTemplatesQuery,
  FilterTemplatesQueryVariables,
} from "@src/__generated__/graphql";
import { client } from "@src/services/apollo-client";
import { makeObservable } from "mobx";
import { AppStore } from "../AppStore";
import { BaseStore } from "../BaseStore";

export type FilterModelType = "rfi" | "oi";
export type FilterType = "listing" | "detail";

export class FilterTemplatesStore implements BaseStore {
  constructor(public appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  async fetchTemplate(
    modelId: string,
    modelType: FilterModelType,
    type?: FilterType,
  ) {
    const { data, error } = await client.query<
      FilterTemplatesQuery,
      FilterTemplatesQueryVariables
    >({
      query: FilterTemplatesDocument,
      variables: {
        modelId: modelId,
        modelType: modelType,
        type: type,
      },
    });

    if (!data || error) return;

    return data.filterTemplates;
  }

  async saveTemplate(
    name: string,
    modelId: string,
    modelType: FilterModelType,
    params: string,
    id?: string,
    type?: FilterType,
  ) {
    const { data, errors } = await client.mutate<
      CreateFilterTemplateMutation,
      CreateFilterTemplateMutationVariables
    >({
      mutation: CreateFilterTemplateDocument,
      variables: {
        input: {
          id: id,
          model_id: modelId,
          model_type: modelType,
          type: type,
          name: name,
          params: params,
        },
      },
    });

    if (!data || errors) return;

    return data.createFilterTemplate;
  }
}
