import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { CollapseIcon } from "@src/components/ui-kit/Icon";

interface CollapseAllButtonProps
  extends Omit<IconButtonProps, "onClick" | "aria-label"> {
  onClick: () => void;
}

const CollapseAllButton = ({ onClick, ...props }: CollapseAllButtonProps) => (
  <IconButton
    aria-label={t`toggle all rows`}
    colorScheme="grey"
    onClick={() => onClick()}
    variant="ghost"
    {...props}
  >
    <CollapseIcon />
  </IconButton>
);

export default CollapseAllButton;
