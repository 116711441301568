import { t } from "@lingui/macro";
import { captureEvent } from "@sentry/nextjs";
import {
  CreateExpenseBudgetItemDocument,
  CreateExpenseBudgetItemMutation,
  CreateExpenseBudgetItemMutationVariables,
  CreateOurWorkBudgetItemDocument,
  CreateOurWorkBudgetItemMutation,
  CreateOurWorkBudgetItemMutationVariables,
} from "@src/__generated__/graphql";
import { client } from "@src/services/apollo-client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { required } from "@src/utils/forms/validators";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { FieldState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import { ProjectCreateBudgetItemModal } from ".";
import { ProjectCreateBudgetItemVariables } from "../Store";

export enum ProjectCreateBudgetItemTypeEnum {
  Work = "WORK",
  Expense = "EXPENSE",
}

type DisclosureStateData = {
  projectId: string;
  budgetItemType: ProjectCreateBudgetItemTypeEnum;
  expenseBudgetCategoryId?: string;
  onCreate: (vars: ProjectCreateBudgetItemVariables) => void;
};

export class ProjectCreateBudgetItemModalStore
  implements BaseStore, ModalStore
{
  readonly modalId = "create-budget-item-modal";
  readonly errorToastMessage = t`Something went wrong unexpectedly, please try again.`;

  @observable projectId: string | null = null;
  @observable budgetItemType: ProjectCreateBudgetItemTypeEnum | null = null;
  @observable expenseBudgetCategoryId: string | null = null;
  @observable isFetching = false;
  budgetItemTitle = new FieldState("").validators(required);

  appStore: AppStore;
  modalState = new DisclosureState<DisclosureStateData>({
    onOpen: (additionalData) => {
      if (!additionalData) {
        this.appStore.UIStore.toast({
          status: "warning",
          title: this.errorToastMessage,
        });
        captureEvent({
          message:
            "FE: No additional data was passed while trying to open ProjectCreateBudgetItemModal.",
        });
        return;
      }

      this.projectId = additionalData.projectId;
      this.budgetItemType = additionalData.budgetItemType;
      this.expenseBudgetCategoryId =
        additionalData?.expenseBudgetCategoryId ?? null;

      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <ProjectCreateBudgetItemModal />,
      });
    },
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
      this.projectId = null;
      this.budgetItemType = null;
      this.expenseBudgetCategoryId = null;
      this.budgetItemTitle.reset();
    },
  });

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @action.bound async createBudgetItem() {
    if (!this.projectId) {
      captureEvent({
        message:
          "FE: Missing project ID while trying to create budget item from ProjectCreateBudgetItemModalStore.",
      });
      this.appStore.UIStore.toast({
        status: "error",
        title: this.errorToastMessage,
      });
      return;
    }

    const { hasError } = await this.budgetItemTitle.validate();

    if (hasError) return;

    this.isFetching = true;
    let response:
      | NonNullable<CreateOurWorkBudgetItemMutation["createOurWorkBudgetItem"]>
      | NonNullable<CreateExpenseBudgetItemMutation["createExpenseBudgetItem"]>
      | undefined = undefined;

    switch (this.budgetItemType) {
      case ProjectCreateBudgetItemTypeEnum.Expense:
        if (!this.expenseBudgetCategoryId) {
          this.appStore.UIStore.toast({
            status: "error",
            title: this.errorToastMessage,
          });
          captureEvent({
            message:
              "FE: Missing expense budget category ID in ProjectCreateBudgetItemModalStore.",
          });
          break;
        }
        response = await this.createExpenseBudgetItem(
          this.projectId,
          this.budgetItemTitle.$,
          this.expenseBudgetCategoryId,
        );

        this.modalState.additionalData?.onCreate({
          budgetItemType: this.budgetItemType,
          budgetItem: response,
          expenseBudgetCategoryId: this.expenseBudgetCategoryId,
        });
        break;
      case ProjectCreateBudgetItemTypeEnum.Work:
        response = await this.createOurWorkBudgetItem(
          this.projectId,
          this.budgetItemTitle.$,
        );
        this.modalState.additionalData?.onCreate({
          budgetItemType: this.budgetItemType,
          budgetItem: response,
        });
        break;
      default:
        this.appStore.UIStore.toast({
          status: "error",
          title: this.errorToastMessage,
        });
        captureEvent({
          message:
            "FE: No budget item type was passed to ProjectCreateBudgetItemModalStore.",
        });
        break;
    }

    this.isFetching = false;

    if (!response) {
      this.appStore.UIStore.toast({
        status: "error",
        title: this.errorToastMessage,
      });

      return;
    }

    this.modalState.onClose();
  }

  async createOurWorkBudgetItem(projectId: string, title: string) {
    let response: CreateOurWorkBudgetItemMutation["createOurWorkBudgetItem"] =
      undefined;

    try {
      const { data, errors } = await client.mutate<
        CreateOurWorkBudgetItemMutation,
        CreateOurWorkBudgetItemMutationVariables
      >({
        mutation: CreateOurWorkBudgetItemDocument,
        variables: {
          projectId,
          title,
        },
      });

      if (!data?.createOurWorkBudgetItem || errors !== undefined) {
        return response;
      }

      response = data.createOurWorkBudgetItem;
    } catch (e) {
      console.error(e);
    }

    return response;
  }

  async createExpenseBudgetItem(
    projectId: string,
    title: string,
    expenseBudgetCategoryId: string,
  ) {
    let response: CreateExpenseBudgetItemMutation["createExpenseBudgetItem"] =
      undefined;

    try {
      const { data, errors } = await client.mutate<
        CreateExpenseBudgetItemMutation,
        CreateExpenseBudgetItemMutationVariables
      >({
        mutation: CreateExpenseBudgetItemDocument,
        variables: {
          projectId,
          title,
          expenseBudgetCategoryId,
        },
      });

      if (!data?.createExpenseBudgetItem || errors !== undefined) {
        return response;
      }

      response = data.createExpenseBudgetItem;
    } catch (e) {
      console.error(e);
    }

    return response;
  }
}
