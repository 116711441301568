import {
  Badge,
  BadgeProps,
  Box,
  Center,
  HStack,
  Link,
  Text,
} from "@chakra-ui/react";
import { isPathActive } from "@src/utils/routing";
import { observer } from "mobx-react-lite";
import NextLink, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import { StaticRoute } from "nextjs-routes";
import { MouseEvent, ReactNode, useMemo } from "react";

type Props = {
  active?: boolean;

  /** default: true */
  underline?: boolean;

  badgeContent?: string | number;
  badgeColorScheme?: BadgeProps["colorScheme"];
  children: ReactNode;
  isDisabled?: boolean;
} & (
  | {
      href: LinkProps["href"];
      onClick?: undefined;
    }
  | {
      href?: undefined;
      onClick: () => void;
    }
);

type ItemProps = Omit<LinkProps, "href"> & Props;

const Item = ({
  active,
  underline = true,
  badgeContent,
  badgeColorScheme,
  children,
  onClick,
  isDisabled,
  ...props
}: ItemProps) => {
  const router = useRouter();

  const _active = useMemo(() => {
    return isPathActive(props.href as StaticRoute<"pathname">, active);
  }, [active, router.route]);

  const onClickWrapper = (e: MouseEvent<HTMLElement>): void => {
    if (onClick) {
      onClick();
      e.preventDefault();
    }
  };

  return (
    <NextLink
      {...props}
      // @ts-expect-error
      href={props.href ?? ""}
      passHref
      legacyBehavior
      aria-disabled={isDisabled}
    >
      <Link
        pos="relative"
        alignItems="center"
        justifyContent="center"
        display="flex"
        h="full"
        _hover={{
          textDecoration: "none",
        }}
        _disabled={{ cursor: "not-allowed", color: "grey.400" }}
        aria-disabled={isDisabled}
        onClick={onClickWrapper}
      >
        <HStack
          h="60%"
          px={{
            base: "2",
            md: "3",
          }}
          _hover={{
            bg: isDisabled ? "gray.200" : "teal.50",
          }}
          transition="all 0.2s ease"
          role="group"
          rounded="md"
        >
          <Text
            color="grey.600"
            fontWeight="semibold"
            _groupHover={{
              textDecoration: "none",
              color: "teal.800",
            }}
            transition="all 0.2s ease"
            {...(_active && {
              color: "teal.800",
            })}
          >
            {children}
          </Text>
          {badgeContent !== undefined && (
            <Badge ml="2" colorScheme={badgeColorScheme}>
              {badgeContent.toString()}
            </Badge>
          )}
        </HStack>
        {underline && _active && (
          <Center pos="absolute" right="0" bottom="0" left="0">
            <Box
              w="full"
              h="4px"
              bg={isDisabled ? "gray.400" : "teal.500"}
              borderTopRadius="6px"
            />
          </Center>
        )}
      </Link>
    </NextLink>
  );
};

export default observer(Item);
