import { spacing } from "./spacing";
const POLARIS_FIX_MULTIPLIER = 1.6;

const largeSizes = {
  max: "max-content",
  min: "min-content",
  full: "100%",
  "3xs": `${14 * POLARIS_FIX_MULTIPLIER}rem`,
  "2xs": `${16 * POLARIS_FIX_MULTIPLIER}rem`,
  xs: `${20 * POLARIS_FIX_MULTIPLIER}rem`,
  sm: `${24 * POLARIS_FIX_MULTIPLIER}rem`,
  md: `${28 * POLARIS_FIX_MULTIPLIER}rem`,
  lg: `${32 * POLARIS_FIX_MULTIPLIER}rem`,
  xl: `${36 * POLARIS_FIX_MULTIPLIER}rem`,
  "2xl": `${42 * POLARIS_FIX_MULTIPLIER}rem`,
  "3xl": `${48 * POLARIS_FIX_MULTIPLIER}rem`,
  "4xl": `${56 * POLARIS_FIX_MULTIPLIER}rem`,
  "5xl": `${64 * POLARIS_FIX_MULTIPLIER}rem`,
  "6xl": `${72 * POLARIS_FIX_MULTIPLIER}rem`,
  "7xl": `${80 * POLARIS_FIX_MULTIPLIER}rem`,
  "8xl": `${90 * POLARIS_FIX_MULTIPLIER}rem`,
};

const container = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
};

const sizes = {
  ...spacing,
  ...largeSizes,
  container,
};

export default sizes;
