import { Button, HStack } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { useStore as useAppStore } from "@src/utils/hooks";
import cs from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Store, useStore } from "../../store";
import s from "./DayEntry.module.css";
import Duration from "./Duration";
import PlayPauseButton from "./PlayPauseButton";
import { TimeTrackingLabel } from "./TimeTrackingLabel";

type TEntry = Store["entries"][0];

interface DayEntryProps {
  item: TEntry;
  onEdit: (item: TEntry) => void;
}

const DayEntry = ({ item, onEdit }: DayEntryProps) => {
  const store = useStore();
  const { timeTrackingStore, authStore } = useAppStore();

  useEffect(() => {
    if (!authStore.user?.id || !timeTrackingStore.refetch) return;

    timeTrackingStore.refetch({ user_id: authStore.user.id });
  }, [item.has_running_timer]);

  return (
    <div
      className={cs(s.EntryItemWrapper, {
        "bg-skyLighter": item.has_running_timer,
      })}
    >
      {item.task.ourWorkBudgetItem && (
        <TimeTrackingLabel
          project={item.project}
          brandName={item.project.brand.name}
          budgetItemTitle={item.task.ourWorkBudgetItem.title}
          taskName={item.task.name}
          taskId={item.task.id}
          workTypeTitle={item.timeTrackingWorkType.title}
          note={item.note}
        />
      )}
      <Duration
        duration={item.tracked_time_with_running_timer}
        autoStopped={item.auto_stopped}
        billable={item.billable}
      />
      <HStack spacing="3">
        {store.canUseEntry && (
          <PlayPauseButton id={item.id} isRunning={item.has_running_timer} />
        )}
        {store.canUpdateEntry && (
          <Button
            colorScheme="grey"
            disabled={item.has_running_timer}
            leftIcon={<Icon name="edit-02" w="5" h="5" color="grey.500" />}
            onClick={() => onEdit(item)}
            variant="outline"
          >
            <Trans>Edit</Trans>
          </Button>
        )}
      </HStack>
    </div>
  );
};

export default observer(DayEntry);
