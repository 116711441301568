import { t } from "@lingui/macro";
import {
  CapacityAllocationItemTimeTrackingItemsDocument,
  CapacityAllocationItemTimeTrackingItemsQuery,
  CapacityAllocationItemTimeTrackingItemsQueryVariables,
  CapacityAllocationUserItemDetailQuery,
  CompleteCapacityAllocationItemDocument,
  CompleteCapacityAllocationItemMutation,
  CompleteCapacityAllocationItemMutationVariables,
  StopTimerForCapacityAllocationItemDocument,
  StopTimerForCapacityAllocationItemMutation,
  StopTimerForCapacityAllocationItemMutationVariables,
} from "@src/__generated__/urql-graphql";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { ResourcePlanningDayStore } from "@src/stores/ResourcePlanningDayStore";
import { ResourcePlanningWeekStore } from "@src/stores/ResourcePlanningWeekStore";
import { required } from "@src/utils/forms/validators";
import mapToOptions from "@src/utils/map-to-options";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { Option } from "@src/utils/types";
import { CompleteCapacityAllocationModal } from "@src/widgets/CompleteCapacityAllocationModal";
import { FieldState, FormState } from "formstate";
import { uniqueId } from "lodash";
import { ObservableMap, computed, makeObservable, observable } from "mobx";

type TUserItemDetail = NonNullable<
  CapacityAllocationUserItemDetailQuery["capacityAllocationItemDetailQuery"]
>;
type TUserItemDetailItemTask = NonNullable<
  TUserItemDetail["capacityAllocation"]["task"]
>;

export interface CapacityAllocationCompleteOptions {
  item:
    | {
        id: TUserItemDetail["id"];
        item_id: TUserItemDetail["id"];
        user_id: TUserItemDetail["user"]["id"];
        task_id: TUserItemDetailItemTask["id"] | null | undefined;
        task_name: TUserItemDetailItemTask["name"] | null | undefined;
        note: TUserItemDetail["note"];
        date: TUserItemDetail["date"];
        time_tracking_work_type_id: TUserItemDetail["time_tracking_work_type_id"];
        total_time: TUserItemDetail["total_time"];
        budget_item_title:
          | NonNullable<TUserItemDetailItemTask["ourWorkBudgetItem"]>["title"]
          | null
          | undefined;
        project_code:
          | NonNullable<
              TUserItemDetailItemTask["ourWorkBudgetItem"]
            >["project"]["code"]
          | null
          | undefined;
      }
    | undefined;
  resourcePlanningStore: ResourcePlanningDayStore | ResourcePlanningWeekStore;
  mode: "complete" | "timeTracking";
}

type ExistingTimeTrackingItem = FormState<{
  id: FieldState<string>;
  tracked_for_date: FieldState<Date>;
  task_id: FieldState<string>;
  tracked_time: FieldState<number | undefined>;
  time_tracking_work_type_id: FieldState<string | undefined>;
  note: FieldState<string | undefined>;
  billable: FieldState<boolean>;
}>;

export class CompleteCapacityAllocationModalStore
  implements BaseStore, ModalStore
{
  appStore: AppStore;

  readonly modalId = "completeCapacityAllocationModal";

  requiredIfTrackedTimeSpecified = (
    value: string | undefined,
  ): string | null => {
    return (
      (!!this.newTimeTrackingItem.$.tracked_time.value &&
        this.newTimeTrackingItem.$.tracked_time.value !== 0 &&
        required(value)) ||
      null
    );
  };

  modalState = new DisclosureState<CapacityAllocationCompleteOptions>({
    onOpen: (additionalData) => {
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <CompleteCapacityAllocationModal />,
      });
      this.getExistingTimeTrackingItems();
      this.newTimeTrackingItem.$.note.onChange(
        additionalData?.item?.note ?? "",
      );
    },
    onClose: () => {
      this.modalState.additionalData?.resourcePlanningStore?.syncData();
      this.reset();
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
    },
  });

  @observable isLoading = false;

  @observable.ref timeTrackingWorkTypeOptions: Option<string, string>[] = [];

  existingTimeTrackingItems = new FormState<
    ObservableMap<string, ExistingTimeTrackingItem>
  >(observable.map([]));
  newTimeTrackingItem = new FormState({
    tracked_time: new FieldState<number | undefined>(undefined),
    time_tracking_work_type_id: new FieldState<string | undefined>(
      undefined,
    ).validators(this.requiredIfTrackedTimeSpecified),
    note: new FieldState<string | undefined>(""),
    billable: new FieldState(true),
  });

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @computed get trackedTimeSummary() {
    return (
      Array.from(this.existingTimeTrackingItems.$.values()).reduce(
        (acc, item) => acc + (item.$.tracked_time.value ?? 0),
        0,
      ) + (this.newTimeTrackingItem.$.tracked_time.value ?? 0)
    );
  }

  stopTimerForAllocationItem = async () => {
    const itemId = this.modalState.additionalData?.item?.item_id;
    const userId = this.modalState.additionalData?.item?.user_id;

    if (!itemId || !userId) return;

    await client
      .mutation<
        StopTimerForCapacityAllocationItemMutation,
        StopTimerForCapacityAllocationItemMutationVariables
      >(StopTimerForCapacityAllocationItemDocument, {
        input: {
          capacity_allocation_item_id: itemId,
          user_id: userId,
        },
      })
      .toPromise();

    this.appStore.timeTrackingStore.refetch?.();

    const itemDate = this.modalState.additionalData?.item?.date;
    if (!itemDate) return;

    this.modalState.additionalData?.resourcePlanningStore
      .findUserItem({
        id: itemId,
        userId: userId,
        date: itemDate,
      })
      ?.setHasRunningTimer(false);
  };

  async getExistingTimeTrackingItems() {
    if (!this.modalState.additionalData?.item) return;
    if (!this.modalState.additionalData?.item?.task_id) return;

    this.isLoading = true;

    await this.stopTimerForAllocationItem();

    const { data } = await client
      .query<
        CapacityAllocationItemTimeTrackingItemsQuery,
        CapacityAllocationItemTimeTrackingItemsQueryVariables
      >(CapacityAllocationItemTimeTrackingItemsDocument, {
        id: this.modalState.additionalData.item.item_id,
        user_id: this.modalState.additionalData.item.user_id,
        task_id: this.modalState.additionalData.item.task_id,
      })
      .toPromise();

    this.isLoading = false;

    const timeTrackingItems =
      data?.capacityAllocationItemTimeTrackingItems ?? [];
    const trackedForDate = this.modalState.additionalData?.item.date;
    const taskId = this.modalState.additionalData?.item.task_id;

    this.timeTrackingWorkTypeOptions = mapToOptions.workTypes(
      data?.task.ourWorkBudgetItem?.project?.availableTimeTrackingWorkTypes ??
        [],
    );

    const workTypeOption = this.timeTrackingWorkTypeOptions.find(
      (option) =>
        option.value ===
        this.modalState.additionalData?.item?.time_tracking_work_type_id,
    );
    this.newTimeTrackingItem.$.time_tracking_work_type_id.onChange(
      workTypeOption?.value,
    );
    this.newTimeTrackingItem.$.billable.onChange(data?.task.billable ?? true);
    if (!timeTrackingItems.length) {
      this.newTimeTrackingItem.$.tracked_time.onChange(
        this.modalState.additionalData?.item.total_time,
      );
    }

    if (!timeTrackingItems.length || !taskId || !trackedForDate) return;

    timeTrackingItems.forEach((item) => {
      this.existingTimeTrackingItems.$.set(
        uniqueId("existingTimeTrackingItem"),
        new FormState({
          id: new FieldState(item.id),
          tracked_for_date: new FieldState(trackedForDate),
          task_id: new FieldState(taskId),
          tracked_time: new FieldState<number | undefined>(
            item.tracked_time,
          ).validators(required),
          time_tracking_work_type_id: new FieldState<string | undefined>(
            item.timeTrackingWorkType.id,
          ).validators(required),
          note: new FieldState<string | undefined>(item.note ?? undefined),
          billable: new FieldState(item.billable),
        }),
      );
    });
  }

  removeExistingTimeTrackingItem(id: string): void {
    this.existingTimeTrackingItems.$.delete(id);
  }

  reset() {
    this.existingTimeTrackingItems.$.clear();
    this.newTimeTrackingItem.reset();
  }

  locallyToggleCompleted(
    {
      user,
      completed,
      id,
    }: CompleteCapacityAllocationItemMutation["completeCapacityAllocationItem"],
    trackedTime?: number,
    resourcePlanningStore?:
      | ResourcePlanningDayStore
      | ResourcePlanningWeekStore,
  ) {
    const userByDayModel =
      this.modalState.additionalData?.resourcePlanningStore?.data ??
      resourcePlanningStore?.data ??
      [];

    const allocationItem = userByDayModel
      .find((userItem) => userItem.user.id === user.id)
      ?.items.flatMap(({ items }) => items)
      .find((i) => i.id === id);

    if (!allocationItem) return;

    allocationItem.completed = completed;
    if (trackedTime !== undefined) {
      allocationItem.tracked_for_item = trackedTime;
    }
  }

  async completeAllocation() {
    const res1 = await this.existingTimeTrackingItems.validate();
    const res2 = await this.newTimeTrackingItem.validate();

    if (res1.hasError || res2.hasError) return;

    const mode = this.modalState.additionalData?.mode ?? "complete";
    const tracked_for_date = this.modalState.additionalData?.item?.date;
    const task_id = this.modalState.additionalData?.item?.task_id;
    const id = this.modalState.additionalData?.item?.id;
    const itemId = this.modalState.additionalData?.item?.item_id;
    const trackTimeSummary = this.trackedTimeSummary;

    if (!tracked_for_date || !task_id || !id || !itemId) return;

    const { data } = await client
      .mutation<
        CompleteCapacityAllocationItemMutation,
        CompleteCapacityAllocationItemMutationVariables
      >(CompleteCapacityAllocationItemDocument, {
        input: {
          id,
          complete: mode === "complete" ? true : false,
          existingTimeTrackingItems: Array.from(
            this.existingTimeTrackingItems.$.values(),
          ).map((form) => ({
            id: form.$.id.value,
            tracked_time: form.$.tracked_time.value!,
            task_id: form.$.task_id.value,
            time_tracking_work_type_id:
              form.$.time_tracking_work_type_id.value!,
            tracked_for_date: form.$.tracked_for_date.value,
            capacity_allocation_item_id: itemId,
            note: form.$.note.value,
            billable: form.$.billable.$,
          })),
          newTimeTrackingItem:
            this.newTimeTrackingItem.$.tracked_time.value !== 0 &&
            this.newTimeTrackingItem.$.tracked_time.value !== undefined &&
            this.newTimeTrackingItem.$.time_tracking_work_type_id.value !==
              undefined
              ? {
                  tracked_for_date,
                  task_id,
                  tracked_time: this.newTimeTrackingItem.$.tracked_time.value,
                  time_tracking_work_type_id:
                    this.newTimeTrackingItem.$.time_tracking_work_type_id.value,
                  note: this.newTimeTrackingItem.$.note.value,
                  capacity_allocation_item_id: itemId,
                  billable: this.newTimeTrackingItem.$.billable.$,
                }
              : null,
        },
      })
      .toPromise();

    if (!data?.completeCapacityAllocationItem) return;

    this.appStore.UIStore.toast({
      title: t`Capacity allocation has been completed`,
      status: "success",
    });
    this.locallyToggleCompleted(
      data.completeCapacityAllocationItem,
      trackTimeSummary,
    );
    this.modalState.onClose();
  }
}
