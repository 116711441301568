import {
  Box,
  Center,
  Text,
  useCheckbox,
  UseCheckboxProps,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";

export const CheckboxCard: FunctionComponent<
  UseCheckboxProps & { children: string }
> = ({ children, ...props }) => {
  const { state, getInputProps, getCheckboxProps, getLabelProps, htmlProps } =
    useCheckbox(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const label = getLabelProps();

  return (
    <Box as="label" {...htmlProps}>
      <input {...input} hidden />
      <Center
        rounded="full"
        {...checkbox}
        sx={
          state.isChecked
            ? {
                bg: "teal.100",
                color: "white",
                borderColor: "teal.600",
              }
            : undefined
        }
        w="14"
        h="8"
        bg="grey.50"
        opacity={props.isDisabled && !input.checked ? 0.5 : 1}
        borderWidth="1px"
        borderColor="grey.50"
        cursor={props.isDisabled ? "not-allowed" : "pointer"}
      >
        <Text
          {...label}
          sx={
            state.isChecked
              ? {
                  color: "teal.700",
                }
              : undefined
          }
          fontWeight="medium"
        >
          {children}
        </Text>
      </Center>
    </Box>
  );
};
