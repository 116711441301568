import { Box, Flex } from "@chakra-ui/layout";
import {
  Spinner as _Spinner,
  SpinnerProps as _SpinnerProps,
} from "@chakra-ui/spinner";
import React, { FC } from "react";

interface SpinnerProps {
  size?: _SpinnerProps["size"];
  absolute?: boolean;
}

/**
 * @deprecated use LoadingOverlay instead
 */
export const Spinner: FC<React.PropsWithChildren<SpinnerProps>> = ({
  size = "xl",
  absolute,
}) => (
  <Flex
    w="100%"
    h="100%"
    justifyContent="center"
    alignItems="center"
    pos={absolute ? "fixed" : undefined}
    top={absolute ? 0 : undefined}
    left={absolute ? 0 : undefined}
  >
    <_Spinner thickness="3px" label="loading" size={size} />
    {absolute && (
      <Box
        pos="fixed"
        w="100%"
        h="100%"
        top="0"
        left="0"
        bg="white"
        opacity="0.5"
        zIndex="420"
      />
    )}
  </Flex>
);
