import { t } from "@lingui/macro";
import { useRoleSelectOptionsQuery } from "@src/__generated__/urql-graphql";
import { Select, SelectProps, SelectRef } from "@src/components/ui-kit";
import mapToOptions from "@src/utils/map-to-options";
import {
  RoleOption,
  toRoleOptionsParams,
} from "@src/utils/map-to-options/roles";
import { forwardRef, useEffect, useState } from "react";

type RoleSelectProps = Omit<SelectProps, "options"> & {
  optionModifier?: toRoleOptionsParams["modifier"];
  options?: SelectProps["options"];
};

export const RoleSelect = forwardRef<SelectRef, RoleSelectProps>(
  function RoleSelect(
    { optionModifier = (option) => option, options: passedOptions, ...props },
    ref,
  ) {
    const [options, setOptions] = useState<RoleOption[]>([]);
    const [{ data, fetching }] = useRoleSelectOptionsQuery({
      pause: !!passedOptions,
    });

    useEffect(() => {
      if (!data?.roles) return;

      setOptions(mapToOptions.roles(data.roles, optionModifier));
    }, [data]);

    return (
      <Select
        ref={ref}
        isLoading={fetching}
        placeholder={t`Select role`}
        options={passedOptions ?? options}
        {...props}
      />
    );
  },
);
