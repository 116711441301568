import { HStack, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { useStore } from "@src/utils/hooks";
import { useScreenType } from "@src/utils/hooks/useIsMobile";
import { useRouter } from "next/router";
import { Route } from "nextjs-routes";
import { FC } from "react";
import { NavBar } from "./NavBar";

type BackButtonProps = {
  defaultUrl: Route["pathname"];
  allowedUrls?: Route["pathname"][];
};

export const BackButton: FC<BackButtonProps> = ({
  defaultUrl,
  allowedUrls = [],
}) => {
  const { UIStore } = useStore();
  const router = useRouter();
  const { isMobile } = useScreenType();
  const goBack = async () => {
    const storage = globalThis?.sessionStorage;
    const prevPath = storage.getItem("prevPath");

    /**
     * Automatically include defaultUrl to allowedUrls to persist query params when going back
     */
    const allowedUrlsIncludingDefaultUrl = [...allowedUrls, defaultUrl];

    await UIStore.onBackButtonClick?.();

    !!prevPath &&
    allowedUrlsIncludingDefaultUrl.includes(prevPath as Route["pathname"])
      ? router.back()
      : router.push(defaultUrl);
  };

  return (
    <NavBar.Item active={false} onClick={goBack}>
      <HStack>
        <Icon name="chevron-left" w="5" h="5" color="grey.500" />
        {!isMobile && (
          <Text fontWeight="semibold">
            <Trans>Back</Trans>
          </Text>
        )}
      </HStack>
    </NavBar.Item>
  );
};
