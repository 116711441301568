import { Spinner } from "@components/ui-kit";
import { PushNotifications } from "@src/components/PushNotifications";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import NextError from "next/error";
import { useRouter } from "next/router";
import React, { FC, Fragment, useEffect } from "react";

const LoginProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { pathname } = useRouter();
  const { authStore } = useStore();

  useEffect(() => {
    if (!authStore.PUBLIC_PAGES.includes(pathname)) {
      authStore.authenticate();
    }
  }, []);

  if (authStore.PUBLIC_PAGES.includes(pathname)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Fragment>{children}</Fragment>;
  }

  return {
    loading: <Spinner absolute />,
    error: <NextError statusCode={authStore.authenticationStatusCode ?? 500} />,
    success: (
      <Fragment>
        {children}
        <PushNotifications />
      </Fragment>
    ),
  }[authStore.authenticationStatus];
};

export default observer(LoginProvider);
