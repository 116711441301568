import { Input, InputProps } from "@chakra-ui/react";
import {
  HOURS_IN_ONE_DAY,
  ONE_HOUR_IN_SECONDS,
  formatDuration,
} from "@src/utils/formatters";
import { observer } from "mobx-react-lite";
import { ChangeEvent, forwardRef, useMemo, useState } from "react";

type TimeInputProps = {
  /** Value in seconds */
  value: number | undefined;
  onChange: (value: number | undefined) => void;
  mode24?: boolean;
} & Omit<InputProps, "value" | "onChange">;

const TimeInput = forwardRef<HTMLInputElement | null, TimeInputProps>(
  function TimeInput(
    { value, onChange, onFocus, mode24, isDisabled, onBlur, ...props },
    inputRef,
  ) {
    const [lastValue, setLastValue] = useState<[string, number]>(["", 0]);

    const textValue = useMemo(() => {
      if (value === undefined) return "";
      return formatDuration(value);
    }, [value]);

    const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      if (val === "") onChange(undefined);
      if (!validateTime(val)) return;

      const time = timeToNumber(val, { mode24 });
      if (mode24 && !lte24(time)) return;

      setLastValue([val, time]);
      onChange(time);
    };

    const _onBlur = () => {
      setLastValue((val) => [formatDuration(val[1]), val[1]]);
    };

    return (
      <Input
        ref={inputRef}
        textAlign="center"
        autoComplete="off"
        inputMode="numeric"
        isDisabled={isDisabled}
        onBlur={(e) => {
          _onBlur();
          onBlur?.(e);
        }}
        onChange={_onChange}
        onFocus={(e) => {
          e.target.select();
          onFocus?.(e);
        }}
        placeholder="00:00"
        type="text"
        value={value === lastValue[1] ? lastValue[0] : textValue}
        {...props}
      />
    );
  },
);

const validateTime = (val: string): boolean => {
  if (val === "") return false;
  // eslint-disable-next-line regexp/no-unused-capturing-group
  return /^\d*[:,.]?([0-5]\d?|[6-9]?|60)$/.test(val);
};

const lte24 = (val: number): boolean => {
  return val / ONE_HOUR_IN_SECONDS <= HOURS_IN_ONE_DAY;
};

/**
 * 1 - 01:00
 * 12 - 12:00
 * 123 - 01:23
 * 1234 - 12:34
 * 12345 - 12:34
 * 1261 - 12:59
 */
const toFullTimeFormat = (val: string) => {
  if (val.length === 1) return [`0${val}`, "00"];
  if (val.length === 2) return [val, "00"];
  if (val.length === 3) {
    const minutes = val.slice(1, 3);
    const minutesNumber = Number(val.slice(1, 3));
    return [val.slice(0, 1), minutesNumber > 59 ? "59" : minutes];
  }
  const minutes = val.slice(2, 4);
  const minutesNumber = Number(val.slice(2, 4));
  return [val.slice(0, 2), minutesNumber > 59 ? "59" : minutes];
};

const timeToNumber = (val: string, options?: { mode24?: boolean }): number => {
  let seconds = 0;
  const [h, m] = val.match(/[:,.]/)
    ? val.split(/[:,.]/)
    : toFullTimeFormat(val);
  if (h) {
    let convertedHours = h.slice(0, 2);
    if (
      options?.mode24 &&
      Number(convertedHours.toString().charAt(0)) !== 2 &&
      Number(convertedHours.toString().charAt(0)) !== 1 &&
      Number(convertedHours.toString().charAt(0)) !== 0
    ) {
      convertedHours = h.slice(0, 1);
    }
    seconds = +convertedHours * ONE_HOUR_IN_SECONDS;
  }
  if (m) {
    seconds += +m.slice(0, 2) * 60;
  }
  return seconds;
};

export default observer(TimeInput);
