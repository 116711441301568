import { t } from "@lingui/macro";
import { isAfter, isBefore } from "date-fns";
import { FieldState } from "formstate";
import validator from "validator";
import { NumberModel } from "../models";

export function required($: string | number | undefined | object) {
  return $ == null || $ === "" ? t`Required field` : null;
}

export function email($: string) {
  if ($ === "" || $ == null) return;
  return validator.isEmail($) ? null : t`Invalid email`;
}

export function multipleEmails($: string) {
  if ($ === "" || $ == null) return;
  return $.replaceAll(" ", "")
    .split(",")
    .some((mail) => !validator.isEmail(mail))
    ? t`Invalid email`
    : null;
}

export function onlyNumeric($: string) {
  if ($ === "" || $ == null) return;
  return validator.isNumeric($) ? null : t`Must be numeric value`;
}

export function password($: string) {
  if ($ === "" || $ == null) return;
  return /[A-Z].*\d|\d.*[A-Z]/.test($)
    ? null
    : t`must contain least one number and one capital letter`;
}

export function onlyNumericWithoutDecimal($: string) {
  if ($ === "" || $ == null) return;
  return validator.isNumeric($, { no_symbols: true })
    ? null
    : t`Must be numeric value`;
}

export function minValue(
  minVal: number,
  format = (_minVal: number) => _minVal.toString(),
) {
  return ($: string | number | NumberModel) => {
    if ($ === "" || $ == null) return;
    const val = $ instanceof NumberModel ? $.value : Number($);
    return val >= minVal ? null : t`Min value is ${format(minVal)}`;
  };
}

export function maxValue(
  maxVal: number,
  format = (_maxVal: number) => _maxVal.toString(),
) {
  return ($: string | number | NumberModel) => {
    const val = $ instanceof NumberModel ? $.value : Number($);
    return val <= maxVal ? null : t`Max value is ${format(maxVal)}`;
  };
}

export function gt(value: number, format?: (value: number) => string) {
  return ($: string | number | NumberModel | undefined) => {
    if ($ === undefined) return null;

    $ = $ instanceof NumberModel ? $.value : Number($);
    if ($ > value) return null;
    return t`Must be greater than ${format ? format(value) : value}`;
  };
}

export function gte(value: number, format?: (value: number) => string) {
  return ($: string | number | NumberModel | undefined) => {
    if ($ === undefined) return null;

    $ = $ instanceof NumberModel ? $.value : Number($);
    if ($ >= value) return null;
    return t`Must be greater than or equal to ${format ? format(value) : value}`;
  };
}

export function lt(value: number, format?: (value: number) => string) {
  return ($: string | number | NumberModel | undefined) => {
    if ($ === undefined) return null;

    $ = $ instanceof NumberModel ? $.value : Number($);
    if ($ < value) return null;
    return t`Must be less than ${format ? format(value) : value}`;
  };
}

export function lte(value: number, format?: (value: number) => string) {
  return ($: string | number | NumberModel | undefined) => {
    if ($ === undefined) return null;

    $ = $ instanceof NumberModel ? $.value : Number($);
    if ($ <= value) return null;
    return t`Must be less than or equal to ${format ? format(value) : value}`;
  };
}

export function maxLength(len: number) {
  return ($: string) => {
    if ($ === "" || $ == null) return;
    return $.length > len ? t`String has to contain up to ${len} chars` : null;
  };
}

export function minLength(len: number) {
  return ($: string) => {
    if ($ === "" || $ == null) return;
    return $.length < len ? t`has to least ${len} chars` : null;
  };
}

export function arrayLengthMin(len: number) {
  return ($: Array<any>) => {
    if ($ == null) return;
    return $.length < len ? t`You must select at least ${len} item/s` : null;
  };
}

export function date($: any) {
  return $ instanceof Date ? null : t`Must be valid date`;
}

export function mustBeAfter(
  toCompare: FieldState<any> | Date | undefined,
  readableToCompare: string,
) {
  return ($: any) => {
    if (!toCompare) return null;

    let valueToCompare =
      toCompare instanceof FieldState ? toCompare.value : toCompare;
    if (!($ instanceof Date) || !(valueToCompare instanceof Date)) return null;

    $ = new Date($).setHours(0, 0, 0, 0);
    valueToCompare = new Date(valueToCompare).setHours(0, 0, 0, 0);

    if (isBefore($, valueToCompare)) {
      return t`Must be after ${readableToCompare}`;
    }

    return null;
  };
}

export function mustBeBefore(
  toCompare: FieldState<any> | Date | undefined,
  readableToCompare: string,
) {
  return ($: any) => {
    if (!toCompare) return null;

    let valueToCompare =
      toCompare instanceof FieldState ? toCompare.value : toCompare;
    if (!($ instanceof Date) || !(valueToCompare instanceof Date)) return null;

    $ = new Date($).setHours(0, 0, 0, 0);
    valueToCompare = new Date(valueToCompare).setHours(0, 0, 0, 0);

    if (isAfter($, valueToCompare)) {
      return t`Must be before ${readableToCompare}`;
    }

    return null;
  };
}

export function time($: string | undefined) {
  if ($ === "" || $ === null || $ === undefined) return;
  return validator.isTime($) ? null : t`Invalid time. Must be in HH:MM format`;
}
