import {
  ProjectCodeGeneratorEnum,
  WorkspaceProjectSettings,
} from "@src/__generated__/urql-graphql";
import { FieldState, FormState } from "formstate";
import { autorun } from "mobx";

export class ProjectsSettingsState {
  readonly project_code_generator = new FieldState<
    WorkspaceProjectSettings["project_code_generator"]
  >(ProjectCodeGeneratorEnum.NumberPerBrand);
  readonly project_code_length = new FieldState<
    WorkspaceProjectSettings["project_code_length"]
  >(6);
  readonly project_code_with_year = new FieldState<
    WorkspaceProjectSettings["project_code_with_year"]
  >(false);
  readonly can_close_expense_budget_item_with_unapproved_expenses =
    new FieldState<
      WorkspaceProjectSettings["can_close_expense_budget_item_with_unapproved_expenses"]
    >(false);
  readonly create_default_task_enabled = new FieldState<
    WorkspaceProjectSettings["create_default_task_enabled"]
  >(false);
  readonly create_default_task_only_to_our_work_category = new FieldState<
    WorkspaceProjectSettings["create_default_task_only_to_our_work_category"]
  >(false);
  readonly automate_invoicing_plan_approval = new FieldState<
    WorkspaceProjectSettings["automate_invoicing_plan_approval"]
  >(false);

  readonly form = new FormState({
    project_code_generator: this.project_code_generator,
    project_code_length: this.project_code_length,
    project_code_with_year: this.project_code_with_year,
    can_close_expense_budget_item_with_unapproved_expenses:
      this.can_close_expense_budget_item_with_unapproved_expenses,
    create_default_task_enabled: this.create_default_task_enabled,
    create_default_task_only_to_our_work_category:
      this.create_default_task_only_to_our_work_category,
    automate_invoicing_plan_approval: this.automate_invoicing_plan_approval,
  });

  constructor() {
    autorun(() => {
      if (!this.create_default_task_enabled.$) {
        this.create_default_task_only_to_our_work_category.onChange(false);
      }
    });
  }

  init(settings: WorkspaceProjectSettings) {
    this.project_code_generator.onChange(settings.project_code_generator);
    this.project_code_length.onChange(settings.project_code_length);
    this.project_code_with_year.onChange(settings.project_code_with_year);
    this.can_close_expense_budget_item_with_unapproved_expenses.onChange(
      settings.can_close_expense_budget_item_with_unapproved_expenses,
    );
    this.create_default_task_enabled.onChange(
      settings.create_default_task_enabled,
    );
    this.create_default_task_only_to_our_work_category.onChange(
      settings.create_default_task_only_to_our_work_category,
    );
    this.automate_invoicing_plan_approval.onChange(
      settings.automate_invoicing_plan_approval,
    );
  }
}
