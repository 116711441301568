import { HStack, Text } from "@chakra-ui/react";
import { Icon } from "@src/components/ui-kit/Icon";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import { formatDuration } from "@src/utils/formatters";
import { observer } from "mobx-react-lite";

interface DurationProps {
  value: number;
  highlighted?: boolean;
  billable?: boolean;
}

function Duration({ value, highlighted, billable }: DurationProps) {
  return (
    <WrapComponent
      if={!billable}
      with={(children) => (
        <HStack spacing="2">
          <Icon
            name="non-billable"
            stroke={highlighted ? undefined : "gray.400"}
          />
          {children}
        </HStack>
      )}
    >
      <Text
        color={!highlighted ? "#637381" : undefined}
        fontSize={highlighted ? "4xl" : "3xl"}
        fontWeight={highlighted ? "semibold" : undefined}
      >
        {formatDuration(value, { noPadStart: true })}
      </Text>
    </WrapComponent>
  );
}

export default observer(Duration);
