import {
  Box,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { RemoveButton } from "@components/ui-kit";
import { t } from "@lingui/macro";
import { useDeleteUploadedFileMutation } from "@src/__generated__/graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import { onError } from "@src/utils/apollo";
import { formatFileSize, formatFileType } from "@src/utils/formatters";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import { Preview } from "./Preview";
import { MediaModel } from "./models";

const ButtonPreview: FunctionComponent<
  React.PropsWithChildren<{ media: MediaModel }>
> = observer(function ButtonPreview({ media }) {
  return (
    <IconButton
      aria-label={t`view file`}
      colorScheme="grey"
      icon={<Icon name="file-search-02" w="5" h="5" />}
      onClick={() => window.open(media.urls?.preview || "", "_blank")}
      variant="ghost"
    />
  );
});

const ButtonDelete: FunctionComponent<
  React.PropsWithChildren<{
    media: MediaModel;
    onDelete(media: MediaModel): void;
  }>
> = observer(function ButtonDelete({ media, onDelete }) {
  const [deleteUploadedFile, { loading }] = useDeleteUploadedFileMutation({
    onCompleted() {
      onDelete?.(media);
    },
    ...onError(),
  });

  const deleteFile = async () => {
    if (media.isMedia) {
      deleteUploadedFile({
        variables: { id: media.id },
      });
    } else {
      onDelete?.(media);
    }
  };

  return (
    <RemoveButton colorScheme="red" isLoading={loading} onClick={deleteFile} />
  );
});

const ButtonDownload: FunctionComponent<
  React.PropsWithChildren<{ media: MediaModel }>
> = observer(function ButtonDownload({ media }) {
  return (
    <IconButton
      aria-label={t`download file`}
      colorScheme="grey"
      icon={<Icon name="file-download-02" w="5" h="5" />}
      onClick={() => window.open(media.urls?.download ?? "", "_blank")}
      variant="ghost"
    />
  );
});

export const MediaRow: FunctionComponent<
  React.PropsWithChildren<{
    file: MediaModel;
    showPreview?: boolean;
    showDownload?: boolean;
    showDelete?: boolean;
    onDelete?(media: MediaModel): void;
  }>
> = observer(function MediaRow({
  file,
  onDelete,
  showDelete,
  showDownload,
  showPreview,
}) {
  return (
    <Flex
      key={file.id}
      align="center"
      justify="space-between"
      w="full"
      py="3"
      borderBottomWidth="thin"
      borderBottomColor="blue.100"
      _last={{
        borderBottomWidth: "0",
      }}
    >
      <HStack>
        <Text
          maxW={{
            base: "124px",
            md: "440px",
          }}
          isTruncated
        >
          {file.fileName}
        </Text>
        <Box ml="2">
          ({formatFileType(file.mimeType || "")},{" "}
          {formatFileSize(Number(file.fileSize))})
        </Box>
      </HStack>
      <ButtonGroup>
        {showPreview && <ButtonPreview media={file} />}
        {showDownload && <ButtonDownload media={file} />}
        {showDelete && onDelete && (
          <ButtonDelete media={file} onDelete={onDelete} />
        )}
      </ButtonGroup>
    </Flex>
  );
});

export const MediaList: FunctionComponent<
  React.PropsWithChildren<{
    files: MediaModel[];
    showPreview?: boolean;
    showDownload?: boolean;
    showDelete?: boolean;
    onDelete?(media: MediaModel): void;
    showPreviewIfOnlyOne?: boolean;
  }>
> = observer(function MediaList({
  files,
  showDelete = true,
  showDownload = true,
  showPreview = true,
  onDelete,
  showPreviewIfOnlyOne,
}) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>
      {showPreviewIfOnlyOne && files.length === 1 ? (
        <Preview media={files[0]} />
      ) : (
        files.map((file) => (
          <MediaRow
            key={file.id}
            file={file}
            onDelete={onDelete}
            showDelete={showDelete}
            showDownload={showDownload}
            showPreview={showPreview}
          />
        ))
      )}
    </React.Fragment>
  );
});
