import { useBreakpointValue } from "@chakra-ui/react";

export const useScreenType = (): {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
} => {
  const isMobile =
    useBreakpointValue({ md: false, base: true, sm: true }, { ssr: false }) ??
    false;
  const isTablet =
    useBreakpointValue(
      { base: false, md: true, lg: false, xl: false, "2xl": false },
      { ssr: false },
    ) ?? false;
  const isDesktop =
    useBreakpointValue(
      { base: false, lg: true, xl: true, "2xl": true },
      { ssr: false },
    ) ?? false;

  return { isMobile, isTablet, isDesktop };
};
