import { useToken } from "@chakra-ui/react";
import { TaskComment } from "@src/__generated__/urql-graphql";
import { useStore } from "@src/utils/hooks";
import { AnimatePresence, motion, Variant } from "framer-motion";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";

enum Variants {
  Created = "created",
  Normal = "normal",
}

export const CommentAppearance: FunctionComponent<
  React.PropsWithChildren<{ commentId: TaskComment["id"] }>
> = observer(function CommentAppearance({ commentId, children }) {
  const { taskDetailModalStore: store } = useStore();
  const [white, yellow200] = useToken("colors", ["white", "yellow.200"]);
  const variants: Record<Variants, Variant> = {
    [Variants.Created]: {
      background: yellow200,
    },
    [Variants.Normal]: {
      background: white,
    },
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={
          store.newlyCreatedCommentID === commentId
            ? Variants.Created
            : Variants.Normal
        }
        variants={variants}
        animate={Variants.Normal}
        transition={{ duration: 1.5 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
});
