import { IconButton, Tooltip } from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { FieldState } from "formstate";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { Icon } from "./Icon";

type Props = {
  field: FieldState<boolean>;
  size?: "md" | "lg";
};

export const BillableButton: FunctionComponent<Props> = observer(
  function BillableButton({ field, size = "md" }) {
    const iconSize = size === "lg" ? "7" : undefined;

    return (
      <Tooltip
        label={
          <Trans>
            Time entry is billable. Click to change for Non-Billable
          </Trans>
        }
        placement="top"
        shouldWrapChildren
      >
        <IconButton
          aria-label={t`Tracking item billability switch`}
          icon={
            field.$ ? (
              <Icon name="billable" w={iconSize} h={iconSize} />
            ) : (
              <Icon name="non-billable" w={iconSize} h={iconSize} />
            )
          }
          onClick={() => {
            field.onChange(!field.$);
          }}
          size={size}
          variant="ghost"
        />
      </Tooltip>
    );
  },
);
