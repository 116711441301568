const POLARIS_FIX_MULTIPLIER = 1.6;

const radii = {
  none: "0",
  sm: `${0.125 * POLARIS_FIX_MULTIPLIER}rem`,
  base: `${0.25 * POLARIS_FIX_MULTIPLIER}rem`,
  md: `${0.375 * POLARIS_FIX_MULTIPLIER}rem`,
  lg: `${0.5 * POLARIS_FIX_MULTIPLIER}rem`,
  xl: `${0.75 * POLARIS_FIX_MULTIPLIER}rem`,
  "2xl": `${1 * POLARIS_FIX_MULTIPLIER}rem`,
  "3xl": `${1.5 * POLARIS_FIX_MULTIPLIER}rem`,
  full: "9999px",
};

export default radii;
