import { ErrorScreen } from "@components/ui-kit";
import { t } from "@lingui/macro";

export const NotFoundProject = () => (
  <ErrorScreen
    title={t`I didn’t find the project you are looking for.`}
    link={{
      text: t`See all projects`,
      href: "/projects",
    }}
  />
);
