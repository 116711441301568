import { useEffect } from "react";
import { TextEditorRef } from "../TextEditor";

type UseSetEditorContentProps = {
  ref: TextEditorRef | null;
  note: string | null | undefined;
};

export const useSetEditorContent = ({
  ref,
  note,
}: UseSetEditorContentProps) => {
  useEffect(() => {
    if (ref?.getContent() === note) return;
    if (!ref || !note || !note?.length) {
      ref?.setContent("");
      return;
    }

    ref.setContent(note);
  }, [ref, note]);
};
