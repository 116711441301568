import { Center, HStack } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { ReactNode } from "react";

interface CustomControlProps {
  prefix?: ReactNode;
}

const CustomControl = (
  { children, ...props }: any,
  customProps: CustomControlProps,
) => (
  <chakraComponents.Control {...props}>
    <HStack w="full">
      {!!customProps.prefix && <Center pl="2">{customProps.prefix}</Center>}
      {children}
    </HStack>
  </chakraComponents.Control>
);

export default CustomControl;
