import {
  Button,
  HStack,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  StackProps,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import {
  MultiMonthCalendar,
  MultiMonthCalendarProps,
} from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { DateRange } from "@src/utils/types";
import { CalendarValues } from "@uselessdev/datepicker";
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getWeek,
  isSameDay,
  isSameYear,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import { action, makeObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { CalendarActionsProps } from "../ui-kit/datePicker/CalendarActions";

type PeriodPickerProps = {
  value: DateRange;
  onChange: (value: DateRange) => void;
  isDisabled?: boolean;
} & Omit<StackProps, "onChange"> &
  Pick<CalendarActionsProps, "customActions"> &
  Pick<MultiMonthCalendarProps, "topExtraContent">;

type PeriodType = "month" | "week" | "range";

class PeriodState {
  onChange: (value: DateRange) => void;
  constructor(onChange: (value: DateRange) => void) {
    makeObservable(this);
    this.onChange = onChange;
  }

  getCurrentPeriodType(period: DateRange): PeriodType {
    if (
      isSameDay(period.start, startOfMonth(period.start)) &&
      isSameDay(period.end, endOfMonth(period.end)) &&
      Math.abs(differenceInDays(period.start, period.end)) <= 31
    ) {
      return "month";
    } else if (
      isSameDay(period.start, startOfWeek(period.start)) &&
      isSameDay(period.end, endOfWeek(period.end))
    ) {
      return "week";
    } else {
      return "range";
    }
  }

  getCurrentPeriodLabel(period: DateRange) {
    switch (this.getCurrentPeriodType(period)) {
      case "month":
        return format(period.start, "MMMM yyyy");
      case "week":
        return t`Week ${getWeek(period.start)} (${format(period.start, "d.M")} - ${format(
          period.end,
          "d.M.yyyy",
        )})`;
      case "range":
        if (isSameYear(period.start, period.end)) {
          return `${format(period.start, "d.M")} - ${format(period.end, "d.M.yyyy")}`;
        }
        return `${format(period.start, "d.M.yyyy")} - ${format(period.end, "d.M.yyyy")}`;
    }
  }

  @action.bound goToNextPeriod(period: DateRange) {
    switch (this.getCurrentPeriodType(period)) {
      case "month":
        this.onChange({
          start: startOfMonth(addMonths(period.start, 1)),
          end: endOfMonth(addMonths(period.end, 1)),
        });
        break;
      case "week":
        this.onChange({
          start: startOfWeek(addWeeks(period.start, 1)),
          end: endOfWeek(addWeeks(period.end, 1)),
        });
        break;
      case "range":
        const daysCount = eachDayOfInterval({
          start: period.start,
          end: period.end,
        }).length;
        this.onChange({
          start: addDays(period.start, daysCount),
          end: addDays(period.end, daysCount),
        });
        break;
    }
  }
  @action.bound goToPrevPeriod(period: DateRange) {
    switch (this.getCurrentPeriodType(period)) {
      case "month":
        this.onChange({
          start: startOfMonth(subMonths(period.start, 1)),
          end: endOfMonth(subMonths(period.end, 1)),
        });
        break;
      case "week":
        this.onChange({
          start: startOfWeek(subWeeks(period.start, 1)),
          end: endOfWeek(subWeeks(period.end, 1)),
        });
        break;
      case "range":
        const daysCount = eachDayOfInterval({
          start: period.start,
          end: period.end,
        }).length;
        this.onChange({
          start: subDays(period.start, daysCount),
          end: subDays(period.end, daysCount),
        });
        break;
    }
  }
}

export const PeriodPicker: FC<PeriodPickerProps> = observer(
  function PeriodPicker({
    value,
    onChange,
    customActions,
    topExtraContent,
    isDisabled,
    ...props
  }) {
    const [state] = useState(() => new PeriodState(onChange));

    return (
      <HStack spacing="0" {...props}>
        <IconButton
          aria-label={t`Prev period`}
          colorScheme="grey"
          icon={<Icon name="chevron-left" />}
          isDisabled={isDisabled}
          onClick={() => state.goToPrevPeriod(value)}
          roundedRight="none"
          variant="outline"
        />
        <Popover placement="bottom-start">
          <PopoverTrigger>
            <Button
              borderX="none"
              colorScheme="grey"
              isDisabled={isDisabled}
              rightIcon={<Icon name="chevron-down" />}
              rounded="none"
              variant="outline"
            >
              {state.getCurrentPeriodLabel(value)}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="auto">
              <MultiMonthCalendar
                topExtraContent={topExtraContent}
                customActions={customActions}
                value={{ start: value.start, end: value.end }}
                onSelectDate={(date) => {
                  if (!date) return;
                  onChange({
                    start: (date as unknown as CalendarValues).start as Date,
                    end: (date as unknown as CalendarValues).end as Date,
                  });
                }}
              />
            </PopoverContent>
          </Portal>
        </Popover>
        <IconButton
          aria-label={t`Next period`}
          colorScheme="grey"
          icon={<Icon name="chevron-right" />}
          isDisabled={isDisabled}
          onClick={() => state.goToNextPeriod(value)}
          roundedLeft="none"
          variant="outline"
        />
      </HStack>
    );
  },
);
