import { Box, IconButton } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import {
  CalendarActions,
  CalendarActionsProps,
} from "@src/components/ui-kit/datePicker/CalendarActions";
import { Icon } from "@src/components/ui-kit/Icon";
import { useStore } from "@src/utils/hooks";
import {
  Calendar,
  CalendarControls,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarWeek,
} from "@uselessdev/datepicker";
import { ReactNode } from "react";

export type MultiMonthCalendarProps = Calendar & {
  hideCalendarActions?: boolean;
  topExtraContent?: ReactNode;
} & Pick<CalendarActionsProps, "customActions">;

const MONTHS = 2;

export const MultiMonthCalendarControls = () => {
  return (
    <CalendarControls>
      <CalendarPrevButton
        as={(props) => (
          <IconButton
            h="7"
            aria-label={t`Prev month`}
            colorScheme="grey"
            icon={<Icon name="arrow-narrow-left" w="5" h="5" />}
            variant="subtle"
            {...props}
          />
        )}
      />
      <CalendarNextButton
        as={(props) => (
          <IconButton
            h="7"
            aria-label={t`Next month`}
            colorScheme="grey"
            icon={<Icon name="arrow-narrow-right" w="5" h="5" />}
            variant="subtle"
            {...props}
          />
        )}
      />
    </CalendarControls>
  );
};

export const MultiMonthCalendar = ({
  hideCalendarActions,
  customActions,
  topExtraContent,
  ...props
}: MultiMonthCalendarProps) => {
  const { workspaceStore } = useStore();

  return (
    <Calendar
      months={MONTHS}
      weekStartsOn={workspaceStore.settings?.startOfWeekNumber}
      highlightToday
      allowSelectSameDay
      {...props}
    >
      {!hideCalendarActions && (
        <CalendarActions
          setDates={props.onSelectDate}
          customActions={customActions}
        />
      )}
      <Box pos="relative">
        {topExtraContent}
        <MultiMonthCalendarControls />

        <CalendarMonths
          display="flex"
          flexDir={{
            base: "column",
            md: "row",
          }}
        >
          {[...Array(MONTHS).keys()].map((month) => (
            <CalendarMonth month={month} key={month}>
              <CalendarMonthName />
              <CalendarWeek />
              <CalendarDays />
            </CalendarMonth>
          ))}
        </CalendarMonths>
      </Box>
    </Calendar>
  );
};
