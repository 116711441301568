import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { MediaModel } from "@src/utils/components/dropzone";
import { observer } from "mobx-react-lite";

type MediaUrlListTypes = "preview" | "download";

interface ILabeledUrl {
  label: string;
  url: string;
}

interface ILabeledMedia {
  label: string;
  value?: MediaModel | Partial<MediaModel["urls"]> | null;
}

const getLabelAndUrlFromMediaModel = (
  media: MediaModel,
  type: MediaUrlListTypes,
): ILabeledUrl | undefined => {
  const url = media.urls?.[type];
  if (!url) return undefined;

  return { label: media.name, url };
};

const getLabelAndUrlFromILabeledMedia = (
  labeledMedia: ILabeledMedia,
  type: MediaUrlListTypes,
): ILabeledUrl | undefined => {
  const media = labeledMedia.value;
  if (!media) return undefined;

  const url = media instanceof MediaModel ? media.urls?.[type] : media[type];
  if (!url) return undefined;

  return { label: labeledMedia.label, url };
};

export interface MediaPopoverProps {
  value: MediaModel | ILabeledMedia | (MediaModel | ILabeledMedia)[];
  type: MediaUrlListTypes;
}

const MediaPopover = ({ value, type }: MediaPopoverProps) => {
  if (!Array.isArray(value)) {
    value = [value];
  }

  let filesMap: ILabeledUrl[] = [];

  // Filter and transform to ILabeled[]
  filesMap = value.flatMap((i) => {
    let labelAndUrl: ILabeledUrl | undefined = undefined;

    if (i instanceof MediaModel) {
      labelAndUrl = getLabelAndUrlFromMediaModel(i, type);
    } else {
      labelAndUrl = getLabelAndUrlFromILabeledMedia(i, type);
    }

    return labelAndUrl ? [labelAndUrl] : [];
  });

  const tooltip = type === "preview" ? t`preview` : t`download`;

  const icon = (
    <Icon name={type === "preview" ? "file-search-02" : "file-download-02"} />
  );

  return (
    <div className="flex items-center space-x-5">
      {(filesMap.length === 1 && (
        <Tooltip label={tooltip}>
          <a
            className="bg-transparent border-none"
            href={filesMap[0].url}
            target="_blank"
            rel="noreferrer"
          >
            {icon}
          </a>
        </Tooltip>
      )) ||
        (filesMap.length > 1 && (
          <Menu>
            <MenuButton>{icon}</MenuButton>
            <MenuList>
              {filesMap.map((item) => (
                <MenuItem
                  className="Polaris-Link"
                  key={item.url}
                  as="a"
                  href={item.url}
                  rel="noreferrer"
                  target="_blank"
                >
                  {item.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ))}
    </div>
  );
};

export default observer(MediaPopover);
