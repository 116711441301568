import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FormRow, Select, SelectProps, SelectRef } from "@components/ui-kit";
import { Trans, t } from "@lingui/macro";
import {
  useCreateProjectCategoryMutation,
  useGetProjectCategoriesQuery,
} from "@src/__generated__/urql-graphql";
import { can, cannot } from "@src/utils/components/permissions";
import { useStore } from "@src/utils/hooks";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FC, Fragment, useEffect, useRef } from "react";

type TProjectCategorySelectProps = Omit<SelectProps, "options">;
type TProjectCategorySelectTopExtraContentProps = {
  onClick: () => void;
};
type TProjectCategorySelectBottomExtraContentProps = {
  addPadding?: boolean;
};

const ProjectCategorySelectTopExtraContent: FC<
  TProjectCategorySelectTopExtraContentProps
> = ({ onClick }) => {
  return can("projectCategory_create") ? (
    <Button p="3" onClick={onClick} variant="link">
      <Trans>+ Add new category</Trans>
    </Button>
  ) : null;
};

export const ProjectCategorySelectBottomExtraContent: FC<
  TProjectCategorySelectBottomExtraContentProps
> = ({ addPadding }) => {
  const { projectCategoryModalStore: store } = useStore();

  return can("projectCategory_update") ? (
    <Button
      justifyContent="start"
      w="full"
      p={addPadding ? "3" : "0"}
      pt="3"
      bg="white"
      onClick={() => {
        store.modalState.onOpen();
      }}
      variant="link"
    >
      <Trans>Edit categories</Trans>
    </Button>
  ) : null;
};

export const ProjectCategorySelect: FC<TProjectCategorySelectProps> = observer(
  function ProjectCategorySelect(props) {
    const { projectCategorySelectStore: store } = useStore();
    const categoryModal = useDisclosure();
    const categorySelectRef = useRef<SelectRef>(null);

    const [{ fetching: creatingCategory }, createCategory] =
      useCreateProjectCategoryMutation();
    const [
      { data: categories, fetching: fetchingCategories },
      fetchCategories,
    ] = useGetProjectCategoriesQuery({
      pause: true,
    });

    useEffect(() => {
      if (store.categories.length > 0 || cannot("projectCategory_query"))
        return;
      fetchCategories();

      return () => {
        store.categories = [];
      };
    }, []);

    useEffect(() => {
      if (!categories?.projectCategories) return;

      store.categories = categories.projectCategories.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    }, [categories?.projectCategories]);

    const handleCreateCategory = async () => {
      const { hasError } = await store.categoryForm.validate();
      if (hasError || !store.categoryForm.$.name.$) return;
      const { error, data } = await createCategory({
        name: store.categoryForm.$.name.$,
      });
      if (error || !data) return;
      handleCloseCategoryModal();

      const newCategoryIds = Array.isArray(props.value)
        ? props.value
        : [props.value];
      runInAction(() => {
        store.categories = [
          ...store.categories,
          {
            value: data.createProjectCategory.id,
            label: data.createProjectCategory.name,
          },
        ];
      });
      runInAction(() => {
        props.onChange?.([...newCategoryIds, data.createProjectCategory.id]);
      });
    };

    const handleCloseCategoryModal = () => {
      categoryModal.onClose();
      store.categoryForm.reset();
    };

    return can("projectCategory_query") ? (
      <Fragment>
        <Select
          ref={categorySelectRef}
          isLoading={fetchingCategories}
          isMulti
          topExtraContent={
            <ProjectCategorySelectTopExtraContent
              onClick={() => {
                categorySelectRef.current?.onMenuClose();
                categoryModal.onOpen();
              }}
            />
          }
          bottomExtraContent={
            <ProjectCategorySelectBottomExtraContent addPadding />
          }
          {...props}
          options={store.categories}
        />

        <Modal
          isOpen={categoryModal.isOpen}
          onClose={() => {
            if (creatingCategory) return;
            handleCloseCategoryModal();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Trans>Add Project category</Trans>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormRow title={t`Project category`} mb="0">
                <FormControl isInvalid={store.categoryForm.$.name.hasError}>
                  <Input
                    onChange={({ target }) => {
                      store.categoryForm.$.name.onChange(target.value);
                    }}
                    value={store.categoryForm.$.name.value}
                  />
                  <FormErrorMessage>
                    {store.categoryForm.$.name.error}
                  </FormErrorMessage>
                </FormControl>
              </FormRow>
            </ModalBody>
            <ModalFooter>
              <HStack spacing="4">
                <Button
                  colorScheme="grey"
                  isLoading={creatingCategory}
                  onClick={() => {
                    categoryModal.onClose();
                  }}
                  variant="outline"
                >
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  isLoading={creatingCategory}
                  onClick={handleCreateCategory}
                >
                  <Trans>Save</Trans>
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Fragment>
    ) : null;
  },
);
