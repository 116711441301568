import { TabList, TabPanels, Tabs, useDisclosure } from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { ModalConfirm } from "@src/components/ui-kit";
import { CommunicationTab } from "@src/components/widgets/Modals/ModalCommunication/components/tabs/CommunicationTab";
import { DescriptionTab } from "@src/components/widgets/Modals/ModalCommunication/components/tabs/DescriptionTab";
import { LinkedTasksTab } from "@src/components/widgets/Modals/ModalCommunication/components/tabs/LinkedTasksTab";
import { TaskAttachmentsTab } from "@src/components/widgets/Modals/ModalCommunication/components/tabs/TaskAttachmentsTab";
import {
  TTaskTab,
  TaskTab,
} from "@src/components/widgets/Modals/ModalCommunication/components/tabs/TaskTab";
import { TaskTabIdEnum } from "@src/constants/tasks";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { Fragment, useState } from "react";

export const TaskTabs = observer(function TaskTabs() {
  const [newId, setNewId] = useState<TaskTabIdEnum | undefined>(undefined);
  const TASK_TABS: TTaskTab[] = [
    {
      id: TaskTabIdEnum.Description,
      label: t`Description`,
      icon: "file-02",
      component: <DescriptionTab />,
    },
    {
      id: TaskTabIdEnum.Communication,
      label: t`Communication`,
      icon: "message-chat-square",
      component: <CommunicationTab />,
    },
    {
      id: TaskTabIdEnum.Attachments,
      label: t`Attachments`,
      icon: "paperclip",
      component: <TaskAttachmentsTab />,
    },
    {
      id: TaskTabIdEnum.LinkedTasks,
      label: t`Linked tasks`,
      icon: "check-done-01",
      component: <LinkedTasksTab />,
    },
  ];

  const { taskDetailModalStore: store } = useStore();
  const discardModal = useDisclosure();

  return (
    <Fragment>
      <Tabs
        index={store.activeTabId.value}
        onChange={(index) => {
          if (store.commentChanged) {
            setNewId(TASK_TABS[index].id);
            discardModal.onOpen();
          } else {
            store.activeTabId.set(TASK_TABS[index].id);
          }
        }}
      >
        <TabList
          overflowX={{
            base: "scroll",
            md: undefined,
          }}
          overflowY={{
            base: "hidden",
            md: undefined,
          }}
        >
          {TASK_TABS.map((tab) => (
            <TaskTab key={tab.id} tab={tab} />
          ))}
        </TabList>

        <TabPanels minH="400px">
          {TASK_TABS.map((tab) => (
            <Fragment key={tab.id}>{tab.component}</Fragment>
          ))}
        </TabPanels>
      </Tabs>
      <ModalConfirm
        isOpen={discardModal.isOpen}
        onClose={discardModal.onClose}
        onConfirm={() => {
          store.commentChanged = false;
          if (!newId) return;
          store.activeTabId.set(newId);
          setNewId(undefined);
        }}
        confirmBtnTitle={t`Discard changes`}
        destructive
      >
        <Trans>
          Your have unsaved changes. Would you like to discard them?
        </Trans>
      </ModalConfirm>
    </Fragment>
  );
});
