import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { action, makeObservable, observable } from "mobx";
import Router from "next/router";
import { route } from "nextjs-routes";
import QRCode from "qrcode";
import { AuthTwoFactorChallengeState } from "../forms/AuthTwoFactorChallengeState";
import { AuthTwoFactorConfirmState } from "../forms/AuthTwoFactorConfirmState";

type TPageStatus = "loading" | "ready" | "submitting" | "error";

export class TwoFactorAuthenticationStore implements BaseStore {
  appStore: AppStore;

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @action.bound private setStatus(
    field: "setup" | "confirm" | "challenge",
    status: TPageStatus,
  ) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this[`${field}Status`] = status;
  }

  @observable confirmData?: {
    qr_code_data_url: string;
    two_factor_secret: string;
  };

  /*
   * Setup
   */
  @observable setupStatus: TPageStatus = "loading";
  private setupRedirectTo = "";
  private setupCsrfToken?: string;

  async initEnableStep(redirect_to?: string) {
    this.setStatus("setup", "loading");
    if (redirect_to) {
      this.setupRedirectTo = redirect_to;
    }
    this.setupCsrfToken = await this.appStore.authStore.getCsrfToken();
    if (!this.setupCsrfToken) {
      this.setStatus("setup", "error");
      return;
    }
    this.setStatus("setup", "ready");
  }

  async enableTwoFactorAuthentication() {
    this.setStatus("setup", "submitting");

    try {
      const response = await this.appStore.authStore.apiRequest(
        "/user/two-factor-authentication",
        {
          _token: this.setupCsrfToken,
          redirect_to: this.setupRedirectTo,
        },
      );

      if (response.status < 400) {
        const data = (await response.json()) as {
          qr_code_svg: string;
          two_factor_secret: string;
        };

        this.confirmData = {
          qr_code_data_url: await QRCode.toDataURL(data.qr_code_svg, {
            margin: 0,
          }),
          two_factor_secret: data.two_factor_secret,
        };

        Router.push({
          pathname: route({ pathname: "/auth/two-factor/confirm" }),
          query: {
            redirect_to: this.setupRedirectTo,
          },
        });

        return;
      }
    } catch (error) {
      console.error(error);
    }

    this.setStatus("setup", "error");
  }

  /*
   * Confirm
   */
  confirmForm = new AuthTwoFactorConfirmState();
  @observable confirmStatus: TPageStatus = "loading";

  async setConfirmCsrfToken() {
    const token = await this.appStore.authStore.getCsrfToken();
    if (!token) {
      this.setStatus("confirm", "error");
      return;
    }
    this.confirmForm._token = token;
    this.setStatus("confirm", "ready");
  }

  async initConfirmStep(redirect_to?: string) {
    if (!this.confirmData) {
      Router.push({
        pathname: route({ pathname: "/auth/two-factor/enable" }),
        query: { redirect_to },
      });
    }

    this.setStatus("confirm", "loading");
    if (redirect_to) {
      this.confirmForm.redirect_to = redirect_to;
    }
    await this.setConfirmCsrfToken();
  }

  async confirmTwoFactorAuthentication() {
    this.setStatus("confirm", "submitting");

    try {
      const response = await this.appStore.authStore.apiRequest(
        "/user/confirmed-two-factor-authentication",
        {
          _token: this.confirmForm._token,
          redirect_to: this.confirmForm.redirect_to,
          code: this.confirmForm.code.$,
        },
      );

      if (response.status < 300) {
        Router.push({
          pathname: route({ pathname: "/auth/two-factor/success" }),
          query: {
            redirect_to: this.confirmForm.redirect_to,
          },
        });
        return;
      }

      const data = (await response.json()) as {
        message: string;
        errors?: {
          code?: string[];
        };
      };

      if (data.errors && data.errors.code) {
        this.confirmForm.code.setError(data.errors.code[0]);
        await this.setConfirmCsrfToken();
        return;
      }
    } catch {}

    this.setStatus("confirm", "error");
  }

  /*
   * Challenge
   */
  challengeForm = new AuthTwoFactorChallengeState();
  @observable challengeStatus: TPageStatus = "loading";

  async setChallengeCsrfToken() {
    const token = await this.appStore.authStore.getCsrfToken();
    if (!token) {
      this.setStatus("challenge", "error");
      return;
    }
    this.challengeForm._token = token;
    this.setStatus("challenge", "ready");
  }

  async initChallengeStep(redirect_to?: string) {
    this.setStatus("challenge", "loading");
    if (redirect_to) {
      this.challengeForm.redirect_to = redirect_to;
    }
    await this.setChallengeCsrfToken();
  }

  async challengeTwoFactorAuthentication() {
    this.setStatus("challenge", "submitting");

    try {
      const response = await this.appStore.authStore.apiRequest(
        "/two-factor-challenge",
        {
          _token: this.challengeForm._token,
          redirect_to: this.challengeForm.redirect_to,
          code: this.challengeForm.code.$,
        },
      );

      if (response.status < 300) {
        window.location.href = this.challengeForm.redirect_to || "/";
        return;
      }

      const data = (await response.json()) as {
        message: string;
        errors?: {
          code?: string[];
        };
      };

      if (data.errors && data.errors.code) {
        this.challengeForm.code.setError(data.errors.code[0]);
        await this.setChallengeCsrfToken();
        return;
      }
    } catch {}

    this.setStatus("challenge", "error");
  }
}
