import { Currency } from "@src/__generated__/urql-graphql";

type toCurrencyOptionsParams = {
  currencies: {
    id: Currency["id"];
    code: Currency["code"];
  }[];
  modifier: (option: CurrencyOption) => CurrencyOption;
};

type CurrencyOption = {
  label: Currency["code"];
  value: Currency["id"];
};

export const toCurrencyOptions = (
  currencies: toCurrencyOptionsParams["currencies"],
  modifier: toCurrencyOptionsParams["modifier"] = (option) => option,
): CurrencyOption[] =>
  currencies.map((i) =>
    modifier({
      label: i.code,
      value: i.id,
    }),
  );
