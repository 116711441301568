import { t } from "@lingui/macro";
import { useGetProjectOptionsLazyQuery } from "@src/__generated__/graphql";
import {
  SimpleTaskFragment,
  useCheckTaskRevisionQuery,
  useUpdateTaskMutation,
} from "@src/__generated__/urql-graphql";
import { IOption } from "@src/components/ui-kit";
import { ModalTaskForm } from "@src/components/widgets/Modals/ModalTask";
import { useAutocompletePositionsFromOurWorkBudgetItem } from "@src/components/widgets/Modals/ModalTask/useAutocompletePositionsFromOurWorkBudgetItem";
import { trackEvent } from "@src/services/amplitude";
import { useStore } from "@src/utils/hooks";
import mapToOptions from "@src/utils/map-to-options";
import { FormMode } from "@src/utils/types";
import { omit } from "lodash";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { TASK_REVISION_CHECK_INTERVAL } from "../ModalCommunication/ModalCommunication";
import { TaskModel } from "../ModalCommunication/models";
import { ModalTask, ModalTaskProps } from "./ModalTask";

type ModalTaskEditProps = Omit<ModalTaskProps, "onSubmit" | "mode" | "form"> & {
  taskId: TaskModel["id"];
  onUpdate: (data: SimpleTaskFragment | TaskModel) => void;
  mode?: FormMode;
};

export const ModalTaskEdit = observer(function ModalTaskEdit({
  onUpdate,
  taskId,
  mode = FormMode.EDIT,
  ...props
}: ModalTaskEditProps) {
  const { UIStore } = useStore();
  const { form } = useLocalObservable(() => ({
    form: new ModalTaskForm(),
  }));
  const [alreadyEdited, setAlreadyEdited] = useState(false);
  const [{ fetching: updateLoading }, updateTask] = useUpdateTaskMutation();
  const [projectOptions, setProjectOptions] = useState<IOption[]>([]);

  const [{ data: taskRevisionData }, checkTaskRevision] =
    useCheckTaskRevisionQuery({
      pause: true,
      variables: { id: taskId },
    });

  const [fetchProjectOptions] = useGetProjectOptionsLazyQuery({
    onCompleted(data) {
      if (data.projectsSimpleMap) {
        setProjectOptions(mapToOptions.projects(data.projectsSimpleMap));
      }
    },
  });

  const { autocompletePositions } =
    useAutocompletePositionsFromOurWorkBudgetItem(form);

  useEffect(() => {
    if (!props.isOpen) return;
    form.fetchTask(taskId);
    fetchProjectOptions();
  }, [props.isOpen]);

  useEffect(() => {
    if (!form.task) return;
    if (!form.task.positions.length) {
      if (!form.task.ourWorkBudgetItem) return;
      autocompletePositions({
        variables: {
          our_work_budget_item_id: form.task.ourWorkBudgetItem.id,
        },
      });
    }
  }, [form.task]);

  useEffect(() => {
    if (!form.task?.id) return;
    if (props.isOpen) {
      checkTaskRevision({ id: form.task.id });
    }

    const interval = setInterval(() => {
      if (!props.isOpen) return;
      checkTaskRevision({ id: form.task?.id });
    }, TASK_REVISION_CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, [form.task?.id, props.isOpen]);

  useEffect(() => {
    if (!form.task?.revision || !taskRevisionData?.task?.revision) return;
    setAlreadyEdited(form.task.revision !== taskRevisionData.task.revision);
  }, [taskRevisionData, form.task?.revision]);

  const onSubmitWrapper = async (hasError: boolean) => {
    if (hasError) return false;
    const input = form.serialize();

    const { data } = await updateTask({
      input: { id: taskId, ...omit(input, "files"), addFiles: input.files },
      propagate_billable:
        (mode === FormMode.EDIT || mode === FormMode.MOVE) &&
        form.billabilityChanged
          ? form.propagate_billable.$
          : false,
    });

    if (!data?.updateTask) return false;

    onUpdate(data.updateTask);
    trackEvent("task", "Updated task");
    UIStore.toast({ title: t`Updated` });
    return true;
  };

  return (
    <ModalTask
      form={form}
      mode={mode}
      taskId={taskId}
      projectId={form.projectId.$}
      projectOptions={projectOptions}
      onSubmitLoading={updateLoading}
      onSubmit={onSubmitWrapper}
      attachments={form.task?.files}
      loading={form.taskFetcher.isLoading.value}
      alreadyEdited={alreadyEdited}
      refetchTask={() => {
        form.fetchTask(taskId);
      }}
      {...props}
    />
  );
});
