import { captureEvent } from "@sentry/nextjs";
import {
  TeamUsersDocument,
  TeamUsersQuery,
  TeamUsersQueryVariables,
} from "@src/__generated__/urql-graphql";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { UserManagementState } from "@src/stores/forms/SettingsModalState/UserManagementState";
import { makeObservable, observable } from "mobx";

export class UserManagementSettingsStore implements BaseStore {
  @observable.ref teamMembers: TeamUsersQuery["userSimpleMap"] = [];
  @observable teamMembersFetchState: "loading" | "error" | "success" =
    "loading";

  constructor(
    public appStore: AppStore,
    public form: UserManagementState,
  ) {
    makeObservable(this);
  }

  fetchTeamUsers = async (team_id: string) => {
    this.teamMembersFetchState = "loading";
    try {
      const { data } = await client.query<
        TeamUsersQuery,
        TeamUsersQueryVariables
      >(TeamUsersDocument, { team_id });

      if (!data) {
        throw new Error("No data returned from the server");
      }

      this.teamMembers = data.userSimpleMap;
    } catch (error) {
      this.teamMembersFetchState = "error";
      captureEvent({
        message: "Unable to load team users",
        extra: { error },
      });
      return;
    }
    this.teamMembersFetchState = "success";
  };
}
