import { VatRate } from "@src/__generated__/urql-graphql";
import { percent } from "@src/utils/formatters";

type toVatRateOptionsParams = {
  vatRates: {
    id: VatRate["id"];
    amount: VatRate["amount"];
  }[];
  modifier: (option: VatRateOption) => VatRateOption;
};

type VatRateOption = {
  label: string;
  value: VatRate["id"];
};

export const toVatRateOptions = (
  vatRates: toVatRateOptionsParams["vatRates"],
  modifier: toVatRateOptionsParams["modifier"] = (option) => option,
): VatRateOption[] =>
  vatRates.map((i) =>
    modifier({
      label: percent.format(i.amount),
      value: i.id,
    }),
  );
