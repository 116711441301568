import { Box, BoxProps, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { p } from ".";

type UtilizationProgressBarProps = {
  percent: number;
} & BoxProps;

export const UtilizationProgressBar: FC<UtilizationProgressBarProps> = ({
  percent,
  ...props
}) => {
  const width = 100;
  const remainingSpace = width - percent;

  return (
    <Box {...props}>
      <Flex
        overflow="hidden"
        h="16px"
        bg="grey.100"
        rounded="lg"
        style={{ width: p(width) }}
      >
        <Box bg="teal.500" style={{ width: p(percent) }} />
        {!!remainingSpace && (
          <Box
            bg={`repeating-linear-gradient(-65deg, #586876, #515f6c 2px, #586876 4px )`}
            style={{ width: p(remainingSpace) }}
          />
        )}
      </Flex>
    </Box>
  );
};
